import React from 'react';
import Warning from '../styleguide/Warning';

const DebitCardWarning = () => (
  <Warning isCompact>
    <b>Debit Card Payment</b> requires a bank-issued debit card. Our payment
    terminals DO&nbsp;NOT accept <b>Chime&reg;</b> or other non-traditional debit cards.
  </Warning>
)

export default DebitCardWarning;
