import * as React from 'react';

/**
 * Zyp Run Privacy Policy as of 1/2/23
 */
const ZypRunPrivacyPolicy = () => {
  
  const iframeStyle = {
    border: 'none',
    height: '60vh',
    marginBottom: 16,
  }

  // Document from /public folder
  return (
    <iframe
      title="Zyp Run Privacy Policy"
      style={iframeStyle}
      width="100%" 
      src="/PrivacyPolicy.html">
    </iframe>
  ) 
}

export default ZypRunPrivacyPolicy;
