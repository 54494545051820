import * as React from 'react';

import { devLog } from '../util/util';
import { activeController } from '../util/util';
import { fetchPreferredAeropayAccount } from '../payment/payment-utils';

/**
 * This hook provides the user's bank account name/details if the user has a linked account. 
 * The accountId is a proxy that's used on the back end to reference the linked account.
 * 
 * API response is slow when doing the initial setup (set/get)   
 */
const useBankAccountId = (user) => {
  // For the initial fetch
  const controllerRef = React.useRef(new AbortController());
  // Store the proxy account number
  const [accountId, setAccountId] = React.useState(); 
  // accountLast4 is deprecated, we now use bankName
  const [bankName, setBankName] = React.useState();

  const [fetchComplete, setFetchComplete] = React.useState();

  /**
   * The API fetch will return an account details object or {} 
   *
   * @param {object} - account details or {}  
   */
  const callback = React.useCallback((response) => {
    const { bankAccountId, bankName:bank } = response || {};
    if (bankAccountId) {
      setAccountId(bankAccountId);
      setBankName(bank);
      devLog(`useBankAccountId, Id:${bankAccountId}, Bank:${bank}`);
    }
    if (!response.error) {
      setFetchComplete(true);
    }
  },[]);

  // External calls provide a controller
  const refetch = React.useCallback((controller) => {
    if (controller?.signal) {
      fetchPreferredAeropayAccount(callback, controller);
    }
  }, [callback]);

  React.useEffect(() => {
    const controller = activeController(controllerRef);
    if (user && !user.isAnonymous && !fetchComplete) {
      fetchPreferredAeropayAccount(callback, controller);   
    }
    return () => controller?.abort();
  }, [user, accountId, callback, fetchComplete]);

  return {
    bankAccountId: accountId, 
    bankName,
    refetch
  }; 
}

export default useBankAccountId;
