import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import ToolTip from '../common/ToolTip';

const useStyles = makeStyles({
  secondaryHdr: {
    marginBottom: '.5rem'
  },
  details: {
    maxWidth: '600px', 
    margin:'.5rem 0'
  },
  idTypes: {
    padding: '.25rem',
    '& li': {
      font: 'var(--zrFontSemi)',
      fontSize: '.875rem',
      fontStyle: 'italic',
      marginBottom: '.25rem'
    }
  }
});

const PhotoIdTooltip = () => {
  
  const classes= useStyles();
  
  return (
    <ToolTip 
      enableFlip
      tipTitle="Photo Identification"
      footerText="A Photo ID image (front) is required for 
      verification prior to your first delivery. You can use your phone to
      take a picture."
    >
      <div className={classes.details}>
        <div className={classes.secondaryHdr}>
          The following types of identification are accepted:
        </div>
        <ul className={classes.idTypes}>
          <li>Valid Drivers License (any US State)</li>
          <li>Real ID (any US State)</li>
          <li>Passport Card</li>
        </ul>
      </div>
    </ToolTip>
  );
}

export default PhotoIdTooltip;
