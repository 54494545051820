import * as React from 'react';
import { devLog } from './util';

/**
 * Currently this hook just runs callbacks on
 * visibility state change (tab active/tab inactive) 
 * 
 * @param {function} onVisibilityChange
 */
const usePageVisibility = (onVisibilityChange) => {

  const delayRef = React.useRef();

  React.useEffect(() => {
    const onVisChange = () => {
      if (document.visibilityState && onVisibilityChange) {
        delayRef.current = window.setTimeout(() => {
          onVisibilityChange(document.visibilityState);
          devLog(`Running onVizChange with state:${document.visibilityState}`);
        }, 500);
      }
    };
    document.addEventListener("visibilitychange", onVisChange);
    // Unsubscribe and cancel
    return () => {
      document.removeEventListener("visibilitychange", onVisChange);
      window.clearTimeout(delayRef.current);
    };
  }, [onVisibilityChange]);
}

export default usePageVisibility;
