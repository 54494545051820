import * as React from 'react';

import useProductDetailsBySku from './useProductDetailsBySku';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import Spinner from '../common/Spinner';
import AddedToCartCheck from '../products/AddedToCartCheck';
import BackLink from '../common/BackLink';
import ProductDetailsCard from './ProductDetailsCard';
import ProductNotFound from './ProductNotFound';
import ErrorMessage from '../styleguide/ErrorMessage';
import PageTitle from '../common/PageTitle';
import PropTypes from 'prop-types';

/**
 * Product Detail Page by Sku
 * 
 * This component is for the single product page (SEO+Promotions mostly)
 * NOTE: bySku fetch can return multiple products (same product different batch)
 * so we have to check that quantity > 0 after fetching 
 * 
 * QA2 Sample: /zrProduct/P1HB-OGC-SAT
 * Prod Sample: /zrProduct/FSH-HSP-FOK-IND
 */
const ProductDetailBySkuPage = ({itemSku}) => {
  
  const [showAddedCheck, setShowAddedCheck] = React.useState();

  const { product, status, error } = useProductDetailsBySku(itemSku);

  // Show the "added" check
  const onAddToCart = () => {
    setShowAddedCheck(true);
  };

  // Path for back link: Hhome page since this is mainly an SEO/Promo link page 
  const returnToPath = '/';
  
  return ( 
    <BodyWrapper pageLayout={LayoutTypes.Narrow}>
      { product
        ? <>
            <PageTitle title={product.display_info?.name} />
            <BackLink backToPath={returnToPath} backToText="Shop All Cannabis Products" />
            <div style={{margin:'1rem 0'}}>
              <ProductDetailsCard
                product={product}
                onAddToCart={onAddToCart}
                isModal={false}
              />
            </div>  
          </>
        :  status === 404
          ? <ProductNotFound returnToPath={returnToPath} />
          : error
            ? <ErrorMessage text={error} />
            : <Spinner />
      }
      { showAddedCheck && 
        <AddedToCartCheck closeFn={() => { setShowAddedCheck(false)} } />
      }
    </BodyWrapper>
  )
}  

ProductDetailBySkuPage.propTypes = {
  itemSku: PropTypes.string  /* delayed, via router */
};

export default ProductDetailBySkuPage;
