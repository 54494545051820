import * as React from 'react';

import { devLog } from '../util/util';
import { customerCreditEndpoint } from '../../constants/api-endpoints';
import useFetch from '../util/useFetch';
import { dollarDisplay } from '../providers/OrderPricingProvider';

/**
 * Fetch user referral credits and determine
 * the next available credit to use.
 */
export const useCustomerCredits = (user) => {

  const { 
    data, 
    ...rest 
  } = useFetch(
    user, 
    customerCreditEndpoint, 
    false /* allowAnonymous */,
    user?.uid, /* urlSuffix */
    !user || user.isAnonymous /* paused */
  );

  const [activeCredits, setActiveCredits] = React.useState();
  const [creditToUse, setCreditToUse] = React.useState();
  const [invalidCredits, setInvalidCredits] = React.useState();
  const [activeCreditTotal, setActiveCreditTotal] = React.useState();
  
  React.useEffect(() => {
    let active, total, invalid;
    if (user && typeof(data?.length) === 'number') {
      // active credits - careful: active credits can have a 0 remaining_usa_cents value
      active = data.filter(credit => 
        credit.is_active && 
        !credit.is_suspected_fraud &&
        credit.value.remaining_usa_cents > 0);
      if (active?.length !== activeCredits?.length) {
        // Sort oldest first: FIFO
        setActiveCredits(active.sort((a, b) => a.created_at > b.created_at ? 1 : -1));
        setCreditToUse(active[0]);
      }
      // active total cents
      total = active.reduce((sum, credit) => {
        sum+= credit.value.remaining_usa_cents
        return sum;
      }, 0);
      if (total !== activeCreditTotal) {
        setActiveCreditTotal(total);
      }
      // invalid/fraud credits
      invalid = data.filter(credit => credit.is_suspected_fraud);
      if (invalid?.length) {
        setInvalidCredits(invalid.sort((a, b) => a.created_at > b.created_at ? 1 : -1));
      }
    } else {
      // Flush on signin from user with no data/credits
      if (activeCredits) {
        setActiveCredits(undefined);
        setCreditToUse(undefined);
        setActiveCreditTotal(undefined);
        setInvalidCredits(undefined);
      }
    }
    devLog(`useCustomerCredits: Credits:${data?.length}, Active:${active?.length}, Value: ${total}`);
  }, [user, data, activeCredits, activeCreditTotal]);

  return {
    activeCredits,
    activeCreditCount: activeCredits?.length || 0,
    creditToUse,
    activeCreditTotal,
    activeCreditDisplay: dollarDisplay(activeCreditTotal),
    invalidCredits,
    ...rest,
  };
}

export default useCustomerCredits;
