import React from 'react';

import { devLog } from '../util/util';
import PromoBanner from './PromoBanner';
import useCarouselAnimation from './useCarouselAnimation';
import PropTypes from 'prop-types'

import styles from '../common/SimpleCarousel.module.css';

/**
 * Carousel for promo banners with auto-rotate (designed for mobile)
 */
const BannerCarousel = ({
  carouselItemProps,
  maxItemCount=4,
}) => {
  
  const promoSpacing = 16;
  const carouselRef = React.useRef();

  // Enforce max count 
  const itemProps = carouselItemProps.slice(0, maxItemCount);

  const [itemWidth, setItemWidth] = React.useState(0);
  const [touched, setTouched] = React.useState();

  // Animation stops on carousel touch
  const handleTouch = () => {
    setTouched(true);  
  }

  const scrollToPromo  = useCarouselAnimation(itemProps.length, touched)

  // Determine how much to scroll (1 promo)
  React.useEffect(() => { 
    devLog('promo rotate: set promo item width');
    if (carouselRef.current) {
      const firstPromo = document.querySelector('div[class*="carouselItem"]');
      if (firstPromo) { 
        setItemWidth(firstPromo.offsetWidth + promoSpacing);
      }  
    }  
  }, []);

  // Scroll to next promo
  React.useEffect(() => {
    if (carouselRef.current && itemWidth) {
      if ( scrollToPromo === 0) {
        carouselRef.current.scrollTo({
          top: 0,
          left: 0,
          behavior: "instant"
        })  
      } else {
        carouselRef.current.scrollBy({
          top: 0,
          left: itemWidth,
          behavior: "smooth"
        })
      }    
    }    
  },[itemWidth, scrollToPromo])

  return (
    <div className={`${styles.carouselWrap} ${styles.promos}`} 
         onTouchStart={handleTouch}>
      <div ref={carouselRef} className={styles.carousel}>
        { itemProps?.length 
          ? itemProps.map((props) => (
              <div key={`carousel_item_${props.itemKey}`} className={styles.carouselItem}>
                <PromoBanner                
                  trackingLabel="promo_rotate"
                  {...props} />
              </div>
            ))
          : null
        }
      </div>
    </div>
  );
}

BannerCarousel.propTypes = {
  carouselItemProps: PropTypes.array.isRequired,
  maxItemCount: PropTypes.number,
}

export default BannerCarousel;
