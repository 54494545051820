import * as React from 'react';

import ReferralLinkInput from './ReferralLinkInput';
import MoneyBagIcon from '../icons/MoneyBagIcon';
import { CREDIT_DETAILS } from './GetReferCreditDetails';
import { makeStyles } from '@material-ui/core';

// Money Bag
const iconStyle = {
  height: 24, 
  verticalAlign: -4,
  margin: '0 2px 0 0'
};

const useStyles = makeStyles({
  message: {
    margin: '0 0 .75rem',  
  },
  title: {
    color: 'var(--zrPrimaryDark)',
    font: 'var(--zrFontSemi)',
    fontSize: '1.125rem',
  },
  footnote: {
    margin: '.5rem 0',
    fontSize: '.75rem',
  }
});

const ReferralLinkForm = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.message}>
        <MoneyBagIcon fillColor="var(--zrPrimaryDark)" withStyle={iconStyle} />
        <span className={classes.title}>Give and Get ${CREDIT_DETAILS.total} in Zyp&nbsp;Run Credits</span> when your friends register 
        using your referral link and order delivery!*
      </div>
      <ReferralLinkInput />
      <div className={classes.footnote}>
        * Zyp&nbsp;Run credits are easily applied at checkout, ${CREDIT_DETAILS.per_order} per order (subject to our delivery order minimum). 
      </div>
    </>
  );
};

export default ReferralLinkForm;
