import * as React from 'react';

import { navigateTopWithState } from '../routing/router-utils';
import Button from '../styleguide/Button';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: '1.5rem',
    backgroundColor: 'var(--zrPrimaryBackground)',
    borderRadius: 8,
    padding: '.5rem 1rem',    
    fontSize: '1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.125rem',
    }, 
  },
  hdr: {
    font: 'var(--zrFontBold)',
    fontSize: '1.25rem',
    textAlign: 'center',
    color: 'var(--zrPrimaryVeryDark)',
    marginBottom: '.75rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
      marginBottom: '.25rem'
    }, 
  },
  grid: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      maxWidth: 850,
      margin: '0 auto'
    }   
  },
  body: {
    [theme.breakpoints.up('sm')]: {
      flex: 1,
      maxWidth: 650
    }
  },
  btnWrap: {
    textAlign: 'center',
    padding: '1rem 0',
    margin: '2px 0 0 0',
    [theme.breakpoints.up('sm')]: {
      flex: 0,
      padding: '0 0 0 1.5rem',
      margin: 0
    }, 
  },
  hilite: {
    font: 'var(--zrFontSemi)',
    fontSize: 'inherit',
    color: 'var(--zrPrimaryDark)'
  },
  sup: {
    verticalAlign: 0,
    fontSize: '1.25rem'
  },
  disclaimer: {
    fontSize: '.75rem',
    fontStyle: 'italic',
    [theme.breakpoints.up('sm')]: {
      marginTop: 6,
      textAlign: 'center',
      flex: '0 0 100%' 
    }  
  } 
}));

// Current referral credit settings (for the entire app)
export const CREDIT_DETAILS = {
  total: 100,
  per_order: 50,
  order_count: 2,
};

const GetReferCreditDetails = ({referrerName}) => {

  const classes = useStyles();

  const btnStyle = {
    padding:'.5rem 3.5rem', 
    minWidth:'auto',
    marginTop: 0
  }

  return (
    <div id="refer_credit_banner" className={classes.wrapper}>
      <div className={classes.hdr}>Welcome to Zyp Run!</div>
      <div className={classes.grid}>
        <div className={classes.body}>
          Unlock {' '}
          <span className={classes.hilite}>
            ${CREDIT_DETAILS.total} in Zyp&nbsp;Run Credits<sup className={classes.sup}>*</sup>
          </span>
          {' '}by completing your free registration today!
        </div>
        <div className={classes.btnWrap}>
          <Button 
            withStyle={btnStyle} 
            text="Sign Up" 
            handleClick={() => 
              navigateTopWithState('/signUp', {
                returnToPath: '/',
                continuePath: '/'
              })
            } /> 
        </div>
        <div className={classes.disclaimer}>
          * A <b>${CREDIT_DETAILS.per_order} credit per order</b> will be applied at checkout.
          { referrerName &&
            <> (<b>{referrerName}</b> will also receive ${CREDIT_DETAILS.total} in credits once your first order is complete).</>
          }  
        </div>
      </div>  
    </div>   
  );
};

export default GetReferCreditDetails;
