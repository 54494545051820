import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { getSessionVal, removeSessionVal, referringUserKey } from "../util/storage-utils";

const useStoredReferringUser = () => {

  const { user } = React.useContext(UserContext);
  // Referring user details from session storage
  const [storedReferringUser, setStoredReferringUser] = React.useState();
    
  React.useEffect(() => {
    if (user) {
      if (user.isAnonymous) {
        const referrer = getSessionVal(referringUserKey);
        if (referrer) {
          const referrerJson = JSON.parse(referrer);
          setStoredReferringUser(referrerJson);
        }
      } else {
        // Clear referrer if we have a registered user
        setStoredReferringUser(undefined);
        removeSessionVal(referringUserKey);
      }
    }  
  }, [user])

  return storedReferringUser;
}

export default useStoredReferringUser;
