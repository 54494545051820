import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import { trackingString } from '../analytics/tracking';
import { FilteredProductsContext, SHOW_ALL, FLOWER_CATEGORY_NAME} from '../products/FilteredProductsProvider';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import styles from './ProductInfoFilter.module.css';

/**
 * This component will provide Flower Type filters (Small Buds, etc.) for both Desktop and Mobile layouts
 */ 
const FlowerTypeFilter = ({
  startOpen=false,
  inModal=false
}) => {
  
  const { trackEvent } = useAnalyticsStore();

  // Current filters context
  const { categoryName, flowerType:selectedFlowerType, subCategoryFlowerTypes, setFilters } = React.useContext(FilteredProductsContext);
  // Add the 'All {category}' option
  const subCategoryFlowerTypesWithAll = [ ...subCategoryFlowerTypes, SHOW_ALL];
  // Reverse the order, so that Whole Flower is second
  subCategoryFlowerTypesWithAll.reverse();
  // Toggle the display
  const [showCategories, setShowCategories] = React.useState(startOpen);

  const toggleShowCategories = () => {
    if (typeRef.current) {
      typeRef.current.setAttribute('data-show-content', !showCategories);
    } 
    setShowCategories(!showCategories);
  };

  const flowerTypeClick = (flowerType) => {
    trackEvent(`filter_flower_cat_${trackingString(flowerType)}_clk`); 
    setFilters({flowerType});
  };
  
  const typeRef = React.useRef();
  
  const addlClass = inModal ? styles.inModal : '';

  // Flower-only filter!
  return (categoryName === FLOWER_CATEGORY_NAME && 
          subCategoryFlowerTypesWithAll.length > 1
    ? <div className={`${styles.productInfoFilter} ${addlClass}`}> 
        <div ref={typeRef} data-show-content={showCategories} className={styles.sectionHdr} onClick={toggleShowCategories}>
          CATEGORY <ChevronRightRoundedIcon style={{verticalAlign:'-7px'}}/>
        </div>
        { showCategories &&
          <ul className={styles.filterLinks}>
            { subCategoryFlowerTypesWithAll.map(type => {
              const selectedClass = type === selectedFlowerType
                ? styles.selected
                : '';
              const label = type === SHOW_ALL
                          ? `All Categories`
                          : type;
              return <li key={`t${type}`} 
                         className={`${styles.filterLink} ${selectedClass}`} 
                         onClick={() => {flowerTypeClick(type)}}>
                       {label}
                       <span className={styles.selectedIcon}>
                         <CheckCircleRoundedIcon style={{color: '#054052', verticalAlign:'-6px'}}/>
                       </span>
                     </li>
            })}
          </ul>
        }  
      </div>
    : null 
  );
}

export default FlowerTypeFilter;
