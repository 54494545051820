import * as React from 'react';

import { devLog } from '../util/util';
import { useOnVisible } from './useOnVisible';
import { SHOPPING_MILESTONES } from './analytics-utils';
import { UserContext } from '../providers/UserProvider';
import useCommerceLogging from './useCommerceLogging';
import commerceDebugReducer from './commerceDebugReducer';

// Reducer for logging state and AnalyticsDebug component 
const initialState = {
  commerceId: undefined,
  commerceEvents: [],
};

export const CommerceLoggingContext = React.createContext(initialState);

const CommerceLoggingProvider = ({children}) => {
  const { user } = React.useContext(UserContext);
  const [commerceTracking, dispatch] = React.useReducer(commerceDebugReducer, initialState);
  const { logCommerceAction } = useCommerceLogging(commerceTracking, dispatch);

  // Initialize commerceId
  React.useEffect(() => {
    if (user) {
      const date = new Date();
      const encodedDate = window.btoa(`${date.toLocaleDateString()}-${date.getMilliseconds()}`);
      dispatch({
        type: 'setCommerceId',
        commerceId: `${user.uid}-${encodedDate}`
      });  
      devLog(`new commerce id: ${user.uid}-${encodedDate}`);
    }
  }, [user, dispatch]);

  // We define "menu_visible" as when the header has been 100% in view for 1 second
  const [isVisible] = useOnVisible('#header', 1, 1000);
  React.useEffect(() => {
    if (isVisible && commerceTracking.commerceId && !commerceTracking.commerceEvents.includes(SHOPPING_MILESTONES.MENU_VISIBLE)) {
      logCommerceAction(SHOPPING_MILESTONES.MENU_VISIBLE);
    }
  }, [commerceTracking, isVisible, logCommerceAction]); 

  // We define "menu_interacted " as when the user clicks on something
  React.useEffect(() => {
    const onMenuClick = () => {
      devLog('logging interraction');
      if (commerceTracking.commerceId && 
          !commerceTracking.commerceEvents.includes(SHOPPING_MILESTONES.MENU_INTERACTED) &&
          !document.body.getAttribute('data-body-click')) {
        logCommerceAction(SHOPPING_MILESTONES.MENU_INTERACTED);
        document.body.setAttribute('data-body-click', true);
      }  
    }
    devLog('add interraction listener');
    document.body.addEventListener('click', onMenuClick, { once: true });
    return () => document.body.removeEventListener('click', onMenuClick);
  }, [commerceTracking, logCommerceAction]);

  return (
    <CommerceLoggingContext.Provider value={{
      commerceTracking, 
      dispatch,
      logCommerceAction, 
    }}>
      {children}
    </CommerceLoggingContext.Provider>
  )
}

export default CommerceLoggingProvider;
