// url-safe base64
import base64url from "base64url";

// The string will be in the format of encoded(id)|encoded(name)
export const encode = (id, name) => {
  // change first letter
  const first = String.fromCharCode(id.charCodeAt(0) + 1); // o => p
  // encodedId + "|" + encodedName
  return base64url.encode(`${first}${id.substring(1)}zyp`.split('').reverse().join(''))
    + '|' + base64url.encode(name);
}

const decodeId = (encodedId) => {
  const decoded = base64url.decode(encodedId);
  // reverse
  const reversed = decoded.split('').reverse().join('');
  // revert first letter
  const first = String.fromCharCode(reversed.charCodeAt(0) - 1); // p => o
  return `${first}${reversed.slice(0, reversed.length - 3).substring(1)}`;
}; 

// Return { id, name }
export const decode = (encodedData) => {
  const fields = encodedData.split('|');
  return fields.length === 2
    ? {
        id: decodeId(fields[0]),
        name: base64url.decode(fields[1])
      }
    : {};
};
