import * as React from 'react';

import { TypeColors } from './LabResults';

import PropTypes from 'prop-types';

/**
 * Product name colored by strain
 *
 * @param {string} name - the product name
 * @param {string} cannabisType - the strain: indica, sativa etc.
 */
const ProductName = ({
  name,
  cannabisType
}) => { 

  const colorStyle = {
    color: TypeColors[cannabisType] || TypeColors.DEFAULT
  };

  return ( 
    <div style={colorStyle}>
      {name} 
    </div>
  )
}

ProductName.propTypes = {
  name: PropTypes.string.isRequired,
  cannabisType: PropTypes.string
}

export default ProductName;
