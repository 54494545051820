import { useContext, useReducer, useEffect } from "react";
import { DeliveryOptionsContext } from "../providers/DeliveryOptionsProvider";
import { DebitTip } from "../providers/DeliveryOptionsProvider";

/**
 * The Zyp Run API supplies: "on_site_cash", but accepts "on-site-cash"
 * which means we need to do extra mapping.
 */
export const aeropayPaymentTypeValue = 'aeropay';
export const cashPaymentTypeValue = 'on-site-cash';
export const debitPaymentTypeValue = 'on-site-debit';

/**
 * Aeropay is first if the user is Aeropay-enabled
 */
const usePaymentOptionReducer = () => {

  const { paymentMethods:enabledMethods={} } = useContext(DeliveryOptionsContext);

  const PAYMENT_TYPE_DETAILS = {
    AEROPAY: {
      label: 'Aeropay',
      value: aeropayPaymentTypeValue,
      desc: 'Pay now via linked account',
      tag: 'No Debit Fees!',
      enableKey: 'aeropay',
    },
    ON_SITE_DEBIT: {
      label: 'Debit',
      value: debitPaymentTypeValue,
      desc: 'Pay driver with debit card',
      Tooltip: DebitTip,
      enableKey: 'on_site_debit',
    },
    ON_SITE_CASH: {
      label: 'Cash',
      value: cashPaymentTypeValue,
      desc: 'Pay driver with cash',
      enableKey: 'on_site_cash',
    }
  };

  // Default if not linked
  const DEFAULT_PAYMENT_OPTIONS = [
    PAYMENT_TYPE_DETAILS.ON_SITE_DEBIT,
    PAYMENT_TYPE_DETAILS.ON_SITE_CASH,
    PAYMENT_TYPE_DETAILS.AEROPAY,
  ];

  const AEROPAY_FIRST_PAYMENT_OPTIONS = [
    PAYMENT_TYPE_DETAILS.AEROPAY,
    PAYMENT_TYPE_DETAILS.ON_SITE_DEBIT,
    PAYMENT_TYPE_DETAILS.ON_SITE_CASH,
  ];

  const initialState = {
    paymentOptions: DEFAULT_PAYMENT_OPTIONS,
    selectedMethod: enabledMethods.on_site_debit
      ? PAYMENT_TYPE_DETAILS.ON_SITE_DEBIT.value
      : PAYMENT_TYPE_DETAILS.ON_SITE_CASH.value,
    aeropayFirst: false, // only if user is linked, enabled via dispatch
    enabledMethods,
  };

  const paymentOptionsReducer = (state, action) => {
    switch(action.type) {
      case 'updateEnabledMethods':
        return {
          ...state,
          selectedMethod: enabledMethods.on_site_debit
            ? PAYMENT_TYPE_DETAILS.ON_SITE_DEBIT.value
            : PAYMENT_TYPE_DETAILS.ON_SITE_CASH.value,
          enabledMethods, // Used for rendering radio options
        }
      case 'aeropayLinked': /* customer is linked with Aeropay */
        return enabledMethods.aeropay
          ? {
              ...state,
              paymentOptions: AEROPAY_FIRST_PAYMENT_OPTIONS,
              selectedMethod: PAYMENT_TYPE_DETAILS.AEROPAY.value,
              aeropayFirst: true,
            }
          : state;
      case 'selectedMethod':
        return {
          ...state,
          selectedMethod: action.selectedMethod
        }
      default:
        return state
    }
  }

  // ensure state is defined!
  const [state={}, dispatch] = useReducer(paymentOptionsReducer, initialState);

  // If you refresh the /PlaceOrder page, the enabled payment method data
  // will arrive too late, so we update the reducer here.
  useEffect(() => {
    dispatch({type:'updateEnabledMethods'});
  }, [enabledMethods]);

  return [state, dispatch];
}

export default usePaymentOptionReducer;
