import config from '../../config';
import { trackError } from '../analytics/tracking';

// localhost user, zyprun-qa2 user, etc.
const buildDevUserObject = (userObj) => {
  const host = window.location.host.split('.')[0].split(':')[0];
  userObj.name = `${host} user`;         // Full name
  userObj.email = `${host}@ziprun.com`;  // Email address
  userObj.test_user = true;
  userObj.user_id = `${host}-userid`;
  if (userObj.created_at) {
    userObj.created_at = 1617371938;     // Signup date timestamp (seconds)
  }
  return userObj;
}

/**
 * Log an update for the user with Intercom.
 * Users in DEV are consolidated into a single account for each ENV
 */
export const logWithIntercom = (name, email, id, created_at, actionObj) => {
  // Verify logged in user and Intercom API
  if (window.Intercom && name && email && id) {
    let userObj = {
      name,
      email,
      user_id: id,
    };
    if (created_at) {
      userObj.created_at = created_at;
    }
    // Override logging in DEV
    if (config.IS_DEV) {
      userObj = buildDevUserObject(userObj);
    }
    const updateObj = Object.assign(userObj, actionObj);
    window.Intercom("update", updateObj);
  } else {
    trackError('intercom_logging_error');
  }
};
