import * as React from 'react';

import Header, { HeaderSize } from '../styleguide/Header';
import RefreshAppPrompt from '../common/RefreshAppPrompt';
import BackLink from '../common/BackLink';
import { navigateTop } from '../routing/router-utils';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: '0 auto',
    maxWidth: 600,
  },
  notFoundMsg: {
    fontSize: '1.125rem',
    padding: '0 1.75rem',
    [theme.breakpoints.down('xs')]: {
      padding: 0
    }
  },
  shopLink: {
    cursor: 'pointer',
    font: 'var(--zrFontSemi)',
    fontSize: '1.25rem',
    textDecoration: 'underline',
    margin: '1rem 0',
    color: 'var(--zrPrimaryDark)'
  } 
}));

const ProductNotFound = ({ 
  returnToPath // Used on SEO product page
}) => {

  const classes = useStyles();
  
  return (
    <div className={classes.wrapper}>
      { returnToPath && // Suppress on product modal
        <BackLink backToPath={returnToPath} backToText="Shop All Cannabis Products" />
      }
      <Header 
        isCentered
        withStyle={{marginTop:'1.5rem'}} 
        size={HeaderSize.Large} 
        text="Sorry, Sold Out!" />
      <div className={classes.notFoundMsg}>
        <p>
          We don't seem to have any more of this product in the warehouse &mdash;we could be sold out.
        </p>
        <p>
          Hit us up on <b>Chat</b> to find out when we'll have it be back in stock.
        </p>
        { returnToPath  // Suppress on product modal
          ? <div className={classes.shopLink} onClick={() => navigateTop(`/`)}>
              Shop Cannabis Delivery
            </div>
          : <RefreshAppPrompt />    
        }
      </div>
    </div>
  )
}

ProductNotFound.propTypes = {
  returnToPath: PropTypes.string,
}

export default  ProductNotFound;
