import * as React from 'react';

import { GoogleMapsContext } from '../providers/GoogleMapsProvider';
import { initSingleLocationMap, destroyMap, getFormattedAddress } from '../googlemaps/google-maps-utils';

/**
 * @param {string} address - formatted address string
 * @param {object} addressObj - address in JSON format
 * @param {number} zoom - map zoom level
 * @param {string} height - e.g. 400px
 */
const GoogleMap = ({
  address,
  addressObj={},
  zoom=16,
  height='275px'}) => {

  const { mapsAPILoaded } = React.useContext(GoogleMapsContext);

  const mapId = 'map';

  const locationString = address
    ? address
    : getFormattedAddress(addressObj);

  React.useEffect(() => {
    if (mapsAPILoaded) {
      initSingleLocationMap(mapId, zoom, locationString);
    }
    // destroy map on unmount
    return () => destroyMap();
  }, [locationString, zoom, mapsAPILoaded]);

  return (
    <div id={mapId} style={{height:height, margin:'2rem 0'}}></div>
  );
}

export default GoogleMap;
