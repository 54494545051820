import * as React from 'react';

import PageHeader from './PageHeader';
import PageFooter from './PageFooter';

const layoutStyle = {
  position: 'relative',
  boxSizing: 'border-box',
  minHeight: 'calc(100vh - 188px)'
}

/**
 *  Page layout
 */
const PageLayout = ({children}) => {
  return (
    <div id="pageWrapper">
      <PageHeader />
      <div id="layout" style={layoutStyle}>
        {children}
      </div>
      <PageFooter />
    </div> 
  );
};

export default PageLayout;
