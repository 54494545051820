import * as React from 'react';

import { devLog } from '../util/util';
import { trackEvent } from '../analytics/tracking';
import { UserContext } from '../providers/UserProvider';
import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { CurrentTimeContext } from './CurrentTimeProvider';
import { getFormattedHourLabel, getFormattedTimeLabel } from '../deliveryWindows/scheduled-delivery-utils';
import { getMaxDaysOut, getDefaultSlotForToday,
         getSlotsStartingToday, getLatestOrderTimeMS } from './timeslot-utils';

// No same-day 2 hour windows available
export const NO_SAME_DAY_SLOTS_IDX = -1;

// Priority Delivery config
export const PRIORITY_ROUNDING_MINUTES = 5; // Use 1 for no rounding
export const PRIORITY_UPDATE_MINUTES = 5; // Update display every X minutes
export const PRIORITY_DELIVERY_MINUTES = 60 * 60 * 1000; // 60 minutes

export const getPriorityDeliveryETA = (currentDate) => {
  // Adjust time to target delivery time
  currentDate.setTime(currentDate.getTime() + PRIORITY_DELIVERY_MINUTES);
  const end_hour = currentDate.getHours();
  const end_minute = Math.ceil(currentDate.getMinutes() / PRIORITY_ROUNDING_MINUTES) * PRIORITY_ROUNDING_MINUTES;
  // "2:45pm"
  return getFormattedTimeLabel(end_hour, end_minute);
};

const markExpiredSlots = (currentDaySlots, currentTimeMS) => {
  currentDaySlots.forEach(slot => {
    const slotCloseMS = getLatestOrderTimeMS(0, slot);
    slot.isExpired = slotCloseMS <= currentTimeMS;
  });
};

const useCurrentTimeSlots = (timeSlotsByDay) => {

  // Check for zip code exclusions on slots
  const { location } = React.useContext(UserContext);

  // Current time is only updated every minute or so
  const currentTimeMS = React.useContext(CurrentTimeContext);

  // Priority delivery configs (by zip code)
  const { priorityDeliveryConfigs, getTodaysBusinessHours } = React.useContext(DispensaryContext);

  // Default time slot, -1 when unset
  const [defaultSlot, setDefaultSlot] = React.useState(NO_SAME_DAY_SLOTS_IDX);
  // Slots for display (given the day of week + days out)
  const [displayTimeSlots, setDisplayTimeSlots] = React.useState();

  // The time after which we need to refresh the UI
  const staleTimeMS = React.useRef(0);

  /**
   * Determine the valid/available delivery time slots based on currentTimeMS
   */
  React.useEffect(() => {
    // NOTE: Wait for location as priority fees need to be calced
    if (currentTimeMS && timeSlotsByDay && location) {
      // Update the time of last refresh
      staleTimeMS.current = currentTimeMS;

      const { zip_code } = location || {};

      // e.g. 2 for Tuesday
      const todayNum = new Date(currentTimeMS).getDay();
      const weeklySlotsStartingToday = getSlotsStartingToday(todayNum, timeSlotsByDay);

      // add .isExpired attribute to expired slots
      markExpiredSlots(weeklySlotsStartingToday[0], currentTimeMS);

      // Display only bookable days
      const maxDaysOut = getMaxDaysOut(weeklySlotsStartingToday, currentTimeMS);
      let validSlots =  weeklySlotsStartingToday.slice(0, maxDaysOut);

      // Get first available slot or -1
      const defaultSlot = getDefaultSlotForToday(validSlots[0], currentTimeMS, zip_code);
      setDefaultSlot(defaultSlot);

      // Priority delivery settings for zip code
      const zipCodePriorityConfig = priorityDeliveryConfigs?.find(config => config.zip_code === zip_code);
      // [ start_hour, end_hour ]
      const businessHours = getTodaysBusinessHours() || [0,0];
      const currentHour = new Date(currentTimeMS).getHours();

      /**
       * Create Priority Delivery Slot if available in zip code
       * One reason to do this here: If there's no current-day 2 hour slots available,
       * the priority 1 hour slot will be suppressed.
       */
      if ( zipCodePriorityConfig?.is_enabled &&
           currentHour >= businessHours[0] &&
           currentHour < businessHours[1]
      ) {

        // Remove existing priority slot
        validSlots[0] = validSlots[0].filter(slot => !slot.on_demand);
        const prioritySlot =  {
          start_hour : 0,    // unused?
          start_minute : 0,  // unused?
          isExpired: false
        };

        const currentDate = new Date(currentTimeMS);
        currentDate.setTime(currentDate.getTime() + PRIORITY_DELIVERY_MINUTES);
        const end_hour = currentDate.getHours();
        const end_minute =  Math.ceil(currentDate.getMinutes() / PRIORITY_ROUNDING_MINUTES) * PRIORITY_ROUNDING_MINUTES;

        prioritySlot.on_demand = { ...zipCodePriorityConfig }

        prioritySlot.hours = {
          end_hour,
          end_minute,
        };
        /**
         * We want the priority slot to be the default, so we
         * insert it at the defaultSlot position.
         */
        // Splice! Insert the priority slot at the current default slot index
        if (defaultSlot > NO_SAME_DAY_SLOTS_IDX) {
          validSlots[0].splice(defaultSlot, 0, prioritySlot);
        } else {
          // There are no current-day 2 hour slots, so just append priority slot
          validSlots[0].push(prioritySlot);
          setDefaultSlot(validSlots[0].length - 1);
        }
        trackEvent('1hr_delivery_available');
        trackEvent(`1hr_delivery_${getFormattedHourLabel(end_hour)}_shown`);
      }

      setDisplayTimeSlots(validSlots);

      devLog('*** UPDATE AVAILABLE SLOT DISPLAY! ***');
    }
  }, [currentTimeMS, timeSlotsByDay,
      priorityDeliveryConfigs, getTodaysBusinessHours, location]);

  return {
    /* Provide slots for display */
    defaultSlot,
    displayTimeSlots,
  }
};

export default useCurrentTimeSlots;
