import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useAnalyticsStore } from '../../App';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import PropTypes from 'prop-types';

const useStyles = makeStyles({
  buttonText: {
    margin:'1px 0 0 0.25rem',
    font: 'var(--zrFontBold)',
    fontSize:'.675rem',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  buttonTextBefore: {
    paddingRight: 4
  },
  wrapper: {
    color: 'rgba(0, 0, 0, 0.87)',
    border: '1px solid #ccc',
    fontSize: '0.8125rem',
    borderRadius: '.375rem',
    backgroundColor: '#f7f7f7',
    maxWidth: 308,
    boxShadow: '0 0 4px #000c',
    padding: '.25rem .75rem',
    boxSizing:  'border-box',
    whiteSpace: 'normal',
  },
  titleIcon: {
    verticalAlign: '-4px',
    marginRight: '.25rem'
  },
  title: {
    font: 'var(--zrFontBold)',
    fontSize: '1rem',
    margin: '.675rem 0 .75rem',
    paddingRight: '1.375rem'
  },
  closeIcon: {
    position: 'absolute',
    zIndex: 1,
    top: '.375rem',
    right: '.375rem',
    cursor:'pointer'
  },
  details: {
    margin: '.5rem 0'
  },
  footerText: {
    fontSize:'.75rem',
    lineHeight:'120%',
    paddingTop: '.75rem',
    borderTop: '1px solid #ccc',
    margin:'.5rem 0',
    whiteSpace: 'normal',
  }
});

const ToolTip = ({
  buttonIcon,
  buttonIconHeight=18,
  buttonText,
  buttonTextBefore,
  buttonStyle={},
  toolTipStyle={},
  iconSize=20,
  iconOpacity='0.87',
  tipTitleIcon,
  tipTitle,
  footerText,
  placement='top',
  inModal, /* NOTE: There are layout and event bubbling issues if not set correctly! */
  enableFlip=false,
  trackingLabel,
  children
}) => {

  const classes= useStyles();
  const { trackEvent } = useAnalyticsStore();
  const [open, setOpen] = React.useState(false);

  const handleOpen = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpen(true);
    if (trackingLabel) {
      trackEvent(trackingLabel);
    }
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };

  const noTextStyle = {
    minWidth:'auto',
    verticalAlign: -5,
    padding: 4
  };

  const tipStyle = Object.assign({zIndex:1}, toolTipStyle);
  const btnStyle = buttonText ? buttonStyle : Object.assign(noTextStyle, buttonStyle);

  const muiButtonIconStyle = {cursor:'pointer', fontSize: iconSize, color:`rgba(0, 0, 0, ${iconOpacity})`};
  const anchorRef = React.useRef();

  return (
    <span>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement={placement}
        disablePortal={inModal}
        style={tipStyle}
        modifiers={{
          flip: {
            enabled: enableFlip,
          },
          arrow: {
            enabled: false,
            element: anchorRef.current,
          },
        }}>
        <div className={classes.wrapper}>
          <div className={classes.title}>
            { tipTitleIcon &&
              <img className={classes.titleIcon} alt="" src={tipTitleIcon} height="20" />
            }
            {tipTitle}
          </div>
          <div className={classes.closeIcon} onClick={handleClose}>
            <CloseRoundedIcon style={{fontSize:24}} />
          </div>
          <div className={classes.details}>
            {children}
          </div>
          { footerText &&
            <div className={classes.footerText}>
              {footerText}
            </div>
          }
        </div>
      </Popper>

      {/* This is the icon/button that triggers the tool tip */}
      <Button ref={anchorRef} style={btnStyle} onClick={(event) => handleOpen(event)}>
        { buttonTextBefore &&
          <span className={`${classes.buttonText} ${classes.buttonTextBefore}`}>{buttonTextBefore}</span>
        }
        { buttonIcon
          ? <img alt="" src={buttonIcon} height={buttonIconHeight} />
          : <InfoIcon style={muiButtonIconStyle} />
        }
        { buttonText &&
          <span className={classes.buttonText}>{buttonText}</span>
        }
      </Button>
    </span>
  );
}

ToolTip.propTypes = {
  buttonIcon: PropTypes.string, /* an image src */
  buttonText: PropTypes.string,
  buttonTextBefore: PropTypes.string,
  buttonStyle: PropTypes.object, /* for the button text */
  toolTipStyle: PropTypes.object, /* for the tip (Popper) */
  iconSize: PropTypes.number,
  iconOpacity: PropTypes.string,
  tipTitleIcon: PropTypes.string,
  tipTitle: PropTypes.string.isRequired,
  footerText: PropTypes.string,
  placement: PropTypes.string,
  inModal: PropTypes.bool,
  enableFlip: PropTypes.bool,
  childen: PropTypes.oneOfType([PropTypes.object,PropTypes.array])
};

export default ToolTip
