import React from 'react';

import config from '../../config';
import { UserContext } from '../providers/UserProvider';
import { promoTextEndpoint } from '../../constants/api-endpoints';
import { dispensaryEndpointRequest } from './dispensary-utils';
import PromoTextRenderer from './PromoTextRenderer';
import ErrorBoundary from '../common/ErrorBoundary';

import styles from './PromoText.module.css';

/**
 * Render promo text as provided by the Dispensary App (settings)
 */
const PromoText = () => {

  const dispensaryId = config.ZYP_RUN_DISPENSARY_ID;
  const { user } = React.useContext(UserContext); 

  const [promoTextLft, setPromoTextLft] = React.useState();
  const [promoTextRgt, setPromoTextRgt] = React.useState();

  React.useEffect(() => {
    const callback = (data) => {
      if (data.text) {
        const textNodes = JSON.parse(data.text);
        // An empty Array signals no promo content
        if (textNodes.left && textNodes.left.length !== 0) {
          setPromoTextLft(textNodes.left);
          setPromoTextRgt(textNodes.right);
        }
      }
    }
    if (config.ENABLE_PROMO_TEXT && user && !promoTextLft) {
      dispensaryEndpointRequest(promoTextEndpoint, dispensaryId, callback, new AbortController()); 
    } 
  }, [dispensaryId, user, promoTextLft]); 
  // We are using this content as an Alert currently
  const themeStyle = {
    backgroundColor: '#fff4e5',
    color: '#663c00',
    borderRadius: 8, 
  };

  return (
    <ErrorBoundary>
      { promoTextLft
        ? <div className={styles.promo} style={themeStyle}>
            <div className={styles.innerGrid}>
              <div className={styles.gridCol}>  
                <PromoTextRenderer contentArray={promoTextLft} column="left" />
              </div>
              <div className={styles.gridCol}> 
                <PromoTextRenderer contentArray={promoTextRgt} column="right" />
              </div>
            </div>
          </div>
        : <></>
      }
    </ErrorBoundary>
  );
} 

export default PromoText;
