import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import Warning from '../styleguide/Warning';

const ActiveOrdersWarning = () => {
 
  const linkStyle = {
    font: 'var(--zrFontSemi)',
    textDecoration: 'underline',
    cursor: 'pointer'
  }; 

  return (
    <Warning>
      <>
        Note: You currently have active orders and won't be able to 
        {' '}update your account until <span style={linkStyle} 
        onClick={() => navigateTop(`/orders`)}>Your Orders</span> 
        {' '} are completed (delivered).
      </> 
    </Warning>
  )
}

export default ActiveOrdersWarning;
