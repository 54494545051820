import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { DispensaryContext } from './DispensaryProvider';
import { DeliveryOptionsContext } from '../providers/DeliveryOptionsProvider';

/**
 * Check the user's deliverability
 * and the state of our delivery service
 */
const useDeliverability = () => {

  const { currentLocation } = React.useContext(UserContext);
  const { enabledDeliveryZipCodes, getNoDeliveryLocation } = React.useContext(DispensaryContext);
  const { deliveryEnabled } = React.useContext(DeliveryOptionsContext);

  const [isDeliverable, setIsDeliverable] = React.useState();
  const [isUndeliverableAddress, setIsUndeliverableAddress] = React.useState();
  const [noDeliveryLocation, setNoDeliveryLocation] = React.useState();

  React.useEffect(() => {
    let addressOK;
    if (currentLocation && enabledDeliveryZipCodes?.length) {
      addressOK = enabledDeliveryZipCodes.includes(currentLocation?.zip_code);
    }
    // We don't currently deliver to {city or zip_code}
    setNoDeliveryLocation(addressOK
      ? ''
      : getNoDeliveryLocation(currentLocation)
    )
    setIsUndeliverableAddress(!addressOK);
    setIsDeliverable(addressOK && deliveryEnabled);
  }, [currentLocation, enabledDeliveryZipCodes, getNoDeliveryLocation, deliveryEnabled]);

  return ({
    isDeliverable,
    isDeliveryPaused: !deliveryEnabled,
    isUndeliverableAddress,
    noDeliveryLocation,
  });
};

export default useDeliverability;
