import * as React from "react";

import { ProductPreviewContext } from "../products/ProductPreviewProvider";
import { groupSaleProductsByStandardCategories } from '../products/product-utils';

export const SaleItemsContext = React.createContext({});

/**
 * This product provider is used for the /popular/Deals page
 */
const SaleItemsProvider = ({children}) => {

  const { allProducts } = React.useContext(ProductPreviewContext);
  // All dispensary products keyed by category name
  const [saleItemsByCategory, setSaleItemsByCategory] = React.useState();

  React.useEffect(() => {
    if (allProducts) {
      // Map sale products by category
      setSaleItemsByCategory(
        groupSaleProductsByStandardCategories(allProducts)
      );
    }
  }, [allProducts]);

  return (
    <SaleItemsContext.Provider value={{
      saleItemsByCategory,
    }}>
      {children}
    </SaleItemsContext.Provider>
  );
};

export default SaleItemsProvider;
