import * as React from 'react';

import config from '../../config';
import { UserContext } from "../providers/UserProvider";
import { fetchPromos } from "../dispensary/dispensary-utils";

export const BANNER_TYPES = {
  BRAND: 'brand',
  PAGE: 'page',
  PRODUCT: 'product'
};
  
export const PromoBannerContext = React.createContext();

/**
 * Provider supplying the promo banner data
 */
const PromoBannerProvider = ({children}) => {
  const dispensaryId = config.ZYP_RUN_DISPENSARY_ID;
  const { user } = React.useContext(UserContext);
  const [promoBannerData, setBannerData] = React.useState();
  const controllerRef = React.useRef(new AbortController());
  
    React.useEffect(() => {
      const controller = controllerRef.current &&  
                         !controllerRef.current.signal.aborted
        ? controllerRef.current
        : new AbortController();

      const callback = (response) => {
        if (response.data) { // No status:200, should be at least []
          // Sort banners by start date so we can control order
          if (response.data.length) {
            // By sort_order, then start_timestamp
            response.data.sort((a,b) => {
              const { sort_order:sortA=0, start_timestamp:tsA } = a;
              const { sort_order:sortB=0, start_timestamp:tsB } = b;
              return sortA < sortB
                ? -1
                : sortA > sortB
                  ? 1
                  : tsA <= tsB
                    ? 1
                    : -1;
            });
          }
          setBannerData(response.data);
        }
      }  
  
      if (user && dispensaryId && !promoBannerData) {
        fetchPromos(dispensaryId, callback, controller);
      }
      return () => controller.abort();
    }, [user, dispensaryId, promoBannerData]);
  
  return (
    <PromoBannerContext.Provider value={{
      promoBannerData
    }}>
      {children}
    </PromoBannerContext.Provider>
  );
};

export default PromoBannerProvider;
