import * as React from 'react';

import { makeStyles } from '@material-ui/core';
import { getPaymentLabel, OrderType } from '../checkout/order-utils';
import { getDeliveryWindowLabel } from '../deliveryWindows/scheduled-delivery-utils';
import CancelOrderButton from '../orders/CancelOrderButton';
import Warning from '../styleguide/Warning';

import styles from './OrderConfirmation.module.css';

const useStyles = makeStyles({
  'detailValue': {
    color: 'var(--zrPrimaryDark)',
    font: 'var(--zrFontSemi)',
    lineHeight: '133%'
  }
});

const OrderDetails = ({
  order={}
}) => {
  
  const classes = useStyles();

  const { id, type, status, costs, fulfillment_time_slot={}, dispensary_id } = order;
  const totalCost = costs.total ? parseFloat(costs.total/100).toFixed(2) : 'XXX.XX';

  const { date:fulfillmentDate, hours:timeslot} = fulfillment_time_slot;
  
  let deliveryDate = '';
  if (fulfillmentDate?.year) {
    const { year, month, day } = fulfillmentDate;
    const { start_hour } = timeslot; 
    deliveryDate = new Date(year, month - 1, day, start_hour, 0, 0, 0).toDateString();
  }
  let deliveryWindow = '';
  if (timeslot)  {
    deliveryWindow = getDeliveryWindowLabel(timeslot);
  }

  // on-site versus pre-paid
  const paymentLabel = getPaymentLabel(order.payment_details);
  const windowLabel = order.type === OrderType.PICKUP
    ? 'PICKUP WINDOW'
    : 'DELIVERY WINDOW';

  return ( order.type 
    ? <div className={styles.detailsGrid}>
        <div className={styles.detailItem}>
          <div className={styles.detailLabel}>ORDER TYPE</div>
          <div className={`${styles.detailValue} ${classes.detailValue}`}>
            {type.toUpperCase()}
            <div className={styles.paymentLabel}>{paymentLabel}</div>
          </div>
        </div>
        <div className={styles.detailItem}>
          <div className={styles.detailLabel}>{windowLabel}</div>
          <div className={`${styles.detailValue} ${classes.detailValue}`}>
            <div>{deliveryDate}</div>
            <div>{deliveryWindow}</div>
          </div>
        </div>
        <div className={styles.detailItem} style={{margin:0}}>
          <div className={styles.detailLabel}>TOTAL</div>
          <div className={`${styles.detailValue} ${classes.detailValue}`}>${totalCost}</div>
        </div>
        <div className={styles.detailItem} style={{margin:0}}>
          <CancelOrderButton
            orderId={id}
            orderStatus={status} // Only useful for initial 'requested' status 
            dispensaryId={dispensary_id}
            />
        </div>
        <div>
          { order.payment_details.processor !== 'aeropay' &&
            <Warning severity="info"
                     withClass={styles.alert} 
                     text={(<><b>PAYMENT</b>: This order can be paid by either <b>Cash OR Debit Card</b>!</>)} />
          }
        </div>
      </div>
    : null
  )    
}

export default OrderDetails;
