import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import { ProductPreviewContext } from '../products/ProductPreviewProvider';
import ProductCarousel, { MAX_CAROUSEL_PRODUCTS } from './ProductCarousel';
import Spinner from '../common/Spinner';
import PropTypes from 'prop-types';

const PreviewProducts = ({
  headerPrefix
}) => {
  
  // The provider currently guarantees at least 1 product (or displays error)
  const { productsByCategory } = React.useContext(ProductPreviewContext);

  return ( productsByCategory
    ? productsByCategory.size > 0
      ? <>
          {[...productsByCategory.keys()].map(categoryName => {
            const catProducts = productsByCategory.get(categoryName);
            const max = Math.min(catProducts.length, MAX_CAROUSEL_PRODUCTS);
            const previewProducts = catProducts.slice(0,max);
        
            return <ProductCarousel
                key={categoryName}
                carouselTitle={`${headerPrefix} ${categoryName}`}
                categoryCount={catProducts.length}
                carouselProducts={previewProducts}
                withMoreCard
                seeAllClick={() => {
                  navigateTop(`/popular/${categoryName}`);
                }}
              />
          })}
        </>
      : <>{/* No products found */}</>
    :<Spinner />
  )
}

PreviewProducts.propTypes = {
  headerPrefix: PropTypes.string
}

export default PreviewProducts;
