import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { fetchAeroSyncCredentials } from './payment-utils';
import { activeController } from '../util/util';

const useAerosyncToken = () => {

  const { user } = React.useContext(UserContext);
  const [token, setToken] = React.useState();

  const controllerRef = React.useRef(new AbortController());

  React.useEffect(() => {
    const controller = activeController(controllerRef);

    // Fetch token
    const tokenCallback = (response) => {
      const { token } = response || {};
      if (token) {
        setToken(token);
      }
    };

    if (user && !user.isAnonymous) {
      fetchAeroSyncCredentials(tokenCallback, controller);
    }
    return () => controller.abort();
  }, [user]);
  
  return {
    token
  }
}

export default useAerosyncToken;
