import * as React from 'react';

import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import Header, { HeaderSize } from '../styleguide/Header';
import PageTitle from '../common/PageTitle.jsx';
import BackLink from '../common/BackLink';
import SearchTypeAhead from './SearchTypeAhead.jsx';
import SearchExamples from './SearchExamples.jsx';

import styles from './ProductSearch.module.css';

const ProductSearch = ({location}) => {

  // Back link
  const [backLink, setBackLink] = React.useState();
  React.useEffect(() => {
    const { returnToPath } = location?.state || {};
    setBackLink(returnToPath);
  }, [location])

  return (
    <>
      <BodyWrapper pageLayout={LayoutTypes.Narrow}>
        <PageTitle title="Product Search" />
        <Header isCentered size={HeaderSize.Medium_Static} withClass={styles.header}>
          { backLink &&
            <BackLink backToPath={backLink} inHeader />
          }
          <span className={styles.hdrLabel}>Product Search</span>
        </Header>
        <div className={styles.searchPageWrapper}>
          <SearchTypeAhead autoFocus={true} />
        </div>
      </BodyWrapper>
      {/* Safari has layout issues without the display:flex here  */}
      <BodyWrapper pageLayout={LayoutTypes.FullBleed} withStyle={{display:'flex', flexDirection:'column'}}>
        <SearchExamples />
      </BodyWrapper>
    </>
  );
};

export default ProductSearch;
