import * as React from 'react';

import DisplayModal from '../common/DisplayModal';
import RegistrationSuccessMsg from '../registration/RegistrationSuccessMsg';
import PropTypes from 'prop-types';

const RegistrationSuccessModal = ({ modalProps }) => {
  const { onContinue, trackContext } = modalProps;
  return (
    <DisplayModal 
      title="Registration Complete" 
      trackContext={trackContext}>
      <RegistrationSuccessMsg onContinue={onContinue} />
    </DisplayModal>
  );
};

RegistrationSuccessModal.propTypes = {
  modalProps: PropTypes.object.isRequired 
}

export default RegistrationSuccessModal;
