import React from 'react';

const MoneyBagIcon = ({fillColor='#3f4246', size='27px', withStyle={}}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      style={withStyle} 
      width={size} height={size} viewBox="0 0 147.000000 180.000000"
      preserveAspectRatio="xMidYMid meet">
    
      <g transform="translate(0.000000,180.000000) scale(0.100000,-0.100000)"
        fill={fillColor} stroke="none">
        <path d="M543 1789 c-8 -8 -6 -23 8 -56 30 -71 29 -73 -48 -67 -38 3 -99 14
        -136 25 -37 11 -72 17 -78 13 -23 -14 24 -173 71 -244 l29 -44 -29 -12 c-61
        -26 -68 -103 -12 -140 l33 -23 -66 -78 c-36 -43 -90 -108 -120 -144 -75 -91
        -121 -170 -152 -264 -21 -67 -26 -99 -26 -195 0 -128 17 -193 74 -282 68 -105
        218 -206 364 -243 144 -37 341 -37 490 0 212 53 385 211 425 391 15 63 14 200
        0 269 -29 141 -93 248 -252 424 -60 66 -75 91 -50 85 345 -81 354 -81 387 -30
        21 32 12 81 -18 101 -12 8 -46 19 -75 25 -56 12 -66 24 -29 35 95 29 86 156
        -11 154 -20 0 -89 -15 -152 -33 -107 -30 -116 -32 -127 -16 -10 15 -5 34 32
        116 56 126 80 213 63 231 -10 9 -23 10 -53 2 -101 -25 -449 -20 -519 7 -7 3
        -17 -1 -23 -7z m206 -685 c12 -15 21 -39 21 -54 0 -23 8 -32 53 -54 77 -37
        117 -102 117 -188 0 -65 -24 -98 -72 -98 -42 0 -68 32 -68 83 0 48 -6 59 -37
        76 -29 15 -77 14 -118 -3 -27 -11 -35 -22 -40 -49 -13 -66 24 -97 114 -97 58
        0 138 -38 170 -80 27 -37 51 -103 51 -139 0 -80 -55 -162 -130 -196 -35 -16
        -40 -22 -40 -50 0 -43 -34 -85 -68 -85 -41 1 -65 25 -71 72 -5 35 -11 43 -36
        51 -74 24 -135 104 -135 179 0 28 7 42 26 57 15 12 34 21 43 21 30 0 60 -30
        71 -71 12 -43 36 -59 89 -59 79 0 121 47 100 109 -12 35 -42 48 -128 54 -84 7
        -135 41 -174 117 -24 46 -28 65 -25 112 6 81 47 145 116 178 48 23 52 28 52
        61 0 73 77 107 119 53z"/>
      </g>
    </svg>
  );
}

export default MoneyBagIcon;
