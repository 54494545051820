import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { ModalType } from './ModalProvider';
import { removeStoredValue, emailSubscribeFormShownKey } from '../util/storage-utils';
import { navigateTopWithState } from '../routing/router-utils';
import DisplayModal from '../common/DisplayModal';
import AccountLink from './AccountLink';
import AddressForm from '../address/AddressForm';

import PropTypes from 'prop-types';

// We could use a controller pattern here for return to product modal etc.
const AddressPromptModal = ({ displayModal, modalProps }) => {
  
  const { user, setSearchLocation } = React.useContext(UserContext);
  const { enabledDeliveryZipCodes, serviceAreaZipCodes } = React.useContext(DispensaryContext);
  const { trackContext, continueModal } = modalProps;

  // Close and check for possible product modal display
  const handleModalClose = React.useCallback((isDeliverableAddress) => {
    displayModal(ModalType.NONE);
    if (ModalType.PRODUCT === continueModal) {
      displayModal(ModalType.PRODUCT, {
        ...modalProps,
        cartItemOnRender: isDeliverableAddress
      });
    }
  }, [displayModal, modalProps, continueModal]);
  
  const onSignIn = () => {
    displayModal(ModalType.NONE);
    // include current page in state
    navigateTopWithState('/signIn', {
      returnToPath: `${window.location.pathname}`,
      continuePath: `${window.location.pathname}`
    });
  }

  // Show email prompt for undeliverable addresses
  const handleAddressSelect = React.useCallback((address) => {
    setSearchLocation(address);
    // Re-enable modal for new undeliverable address
    removeStoredValue(emailSubscribeFormShownKey);
    const { zip_code } = address || {}; 
    // If delivery is not currently available
    if (zip_code && !enabledDeliveryZipCodes.includes(zip_code)) {
      // Pass continueModal, itemId etc. in modalProps
      const noDeliveryModal = serviceAreaZipCodes.includes(zip_code)
        ? ModalType.SERVICE_PAUSED // Show "check back tomorrow"
        : ModalType.NO_DELIVERY;   // Show Email subscribe for out-of-area
      displayModal(noDeliveryModal, {
        ...modalProps,
        cartItemOnRender: false
      }); 
    } else {
      handleModalClose(true);
    }
  }, [displayModal, handleModalClose, modalProps,
      enabledDeliveryZipCodes, serviceAreaZipCodes, setSearchLocation]);

  return (
    <DisplayModal title="Delivery Address"
                  trackContext={trackContext}
                  isShrinkToFit >
        { user.isAnonymous
          ? <AddressForm
              onAddressSelect={handleAddressSelect}
              onSignIn={onSignIn}
              placeholder="A​ddress"/> /* zero width space in Address */
          : <AccountLink 
              onNavigate={()=>{
                displayModal(ModalType.NONE);
                // include current page in state
                navigateTopWithState('/account', {
                  returnToPath: `${window.location.pathname}`,
                  continuePath: `${window.location.pathname}`
                });
              }} />
        }
    </DisplayModal>
  );
};

AddressPromptModal.propTypes = {
  displayModal: PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired 
}

export default AddressPromptModal;
