import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(props => ({
  icon: {
    padding: '2px 3px 2px;',
    '&:hover path:nth-of-type(2)': {
      fill: (props) => props.hoverFillColor
    },
    '&:hover path:nth-of-type(3)': {
      fill: (props) => props.hoverFillColor
    },
    '&:hover path:nth-of-type(5)': {
      fill: (props) => props.hoverFillColor
    }
  }
}));

/* Converted PNG to SVG using Adobe site */
/* Active state handled in FixedFooterNav.module.css */
const AccountIcon = ({fillColor='var(--zrPrimaryVeryDark)', 
                      hoverFillColor='var(--zrPrimaryDark)', 
                      size='25px'}) => {
  const classes = useStyles({ hoverFillColor });
  return (
    <svg className={classes.icon}
         version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
	       width={size} viewBox="0 0 306 320">
      <path fill="transparent" opacity="1.000000" stroke="none" 
      	d="M175.000000,321.000000 
      	C116.666695,321.000000 58.833389,321.000000 1.000062,321.000000 
      	C1.000041,214.333405 1.000041,107.666824 1.000021,1.000176 
      	C102.999878,1.000118 204.999756,1.000118 306.999725,1.000059 
      	C306.999817,107.666496 306.999817,214.332993 306.999908,320.999756 
      	C263.166656,321.000000 219.333328,321.000000 175.000000,321.000000 
        M129.581512,203.984100 
      	C188.643097,194.534348 232.929153,217.501816 265.792664,266.081268 
      	C266.524384,265.285583 267.330872,264.474274 268.064301,263.601654 
      	C311.410217,212.029465 316.894287,133.953735 280.545715,75.950615 
      	C254.403702,34.234562 216.811630,9.120258 167.464783,4.515523 
      	C119.105942,0.002981 77.507668,16.391956 45.299641,52.573063 
      	C3.239574,99.821533 -6.348796,154.705612 12.541253,214.654556 
      	C18.598743,233.878464 28.888504,250.946609 42.468887,266.582153 
      	C52.162544,250.522507 64.208229,237.123367 78.976646,226.251724 
      	C93.938240,215.237869 110.579491,207.953140 129.581512,203.984100 
        M93.551300,245.016083 
      	C79.248962,255.057739 68.753983,268.434845 59.839798,283.196136 
      	C116.335442,332.938141 197.046844,329.464386 248.179947,282.897766 
      	C212.377457,221.536652 143.433044,209.839157 93.551300,245.016083 
      z"/>
      <path fill={fillColor} opacity="1.000000" stroke="none" 
      	d="M129.158325,204.036789 
      	C110.579491,207.953140 93.938240,215.237869 78.976646,226.251724 
      	C64.208229,237.123367 52.162544,250.522507 42.468887,266.582153 
      	C28.888504,250.946609 18.598743,233.878464 12.541253,214.654556 
      	C-6.348796,154.705612 3.239574,99.821533 45.299641,52.573063 
      	C77.507668,16.391956 119.105942,0.002981 167.464783,4.515523 
      	C216.811630,9.120258 254.403702,34.234562 280.545715,75.950615 
      	C316.894287,133.953735 311.410217,212.029465 268.064301,263.601654 
      	C267.330872,264.474274 266.524384,265.285583 265.792664,266.081268 
      	C232.929153,217.501816 188.643097,194.534348 129.158325,204.036789 
        M194.848297,164.362640 
      	C213.036087,147.317642 219.752930,126.370956 215.988495,101.913223 
      	C210.133102,63.870449 171.085052,39.366108 135.137238,51.132946 
      	C108.266541,59.928562 90.407623,85.415649 91.134659,115.747543 
      	C91.764359,142.018311 103.789917,162.307205 127.659218,173.799057 
      	C151.370209,185.214691 173.914642,181.352982 194.848297,164.362640 
      z"/>
      <path fill={fillColor} opacity="1.000000" stroke="none" 
      	d="M93.842255,244.808624 
      	C143.433044,209.839157 212.377457,221.536652 248.179947,282.897766 
      	C197.046844,329.464386 116.335442,332.938141 59.839798,283.196136 
      	C68.753983,268.434845 79.248962,255.057739 93.842255,244.808624 
      z"/>
      <path fill="transparent" opacity="1.000000" stroke="none" 
      	d="M194.582672,164.598068 
      	C173.914642,181.352982 151.370209,185.214691 127.659218,173.799057 
      	C103.789917,162.307205 91.764359,142.018311 91.134659,115.747543 
      	C90.407623,85.415649 108.266541,59.928562 135.137238,51.132946 
      	C171.085052,39.366108 210.133102,63.870449 215.988495,101.913223 
      	C219.752930,126.370956 213.036087,147.317642 194.582672,164.598068 
        M122.009033,88.556801 
      	C114.589279,98.947701 111.828697,110.402466 113.844345,123.064392 
      	C116.888519,142.187393 132.397644,156.963181 151.566742,158.818268 
      	C168.607132,160.467346 185.941193,148.745117 192.485001,131.147171 
      	C200.007172,110.918106 191.968430,87.658195 173.834076,77.181160 
      	C156.518707,67.177299 135.966064,71.492218 122.009033,88.556801 
      z"/>
      <path fill={fillColor} opacity="1.000000" stroke="none" 
      	d="M122.219238,88.265060 
      	C135.966064,71.492218 156.518707,67.177299 173.834076,77.181160 
      	C191.968430,87.658195 200.007172,110.918106 192.485001,131.147171 
      	C185.941193,148.745117 168.607132,160.467346 151.566742,158.818268 
      	C132.397644,156.963181 116.888519,142.187393 113.844345,123.064392 
      	C111.828697,110.402466 114.589279,98.947701 122.219238,88.265060 
      z"/>
    </svg>
  );
}

export default AccountIcon;
