import * as React from 'react';

import { trackEvent } from './tracking';
import { useAnalyticsStore } from '../../App';
import { UserContext } from '../providers/UserProvider';
import { PartnerContext } from '../brand/PartnerProvider';
import { regFlowSuccessKey, getStoredValue } from '../util/storage-utils';

const ONE_DAY_MS = 86400000;

// Unregistered or registered less than 24 hours ago
const newlyRegistered = () => {
  const nowMS = new Date().getTime();
  // timestamp or legacy: true
  const regDateMS = getStoredValue(regFlowSuccessKey);
  return regDateMS
    ? typeof regDateMS === "number"
      ? nowMS - regDateMS < ONE_DAY_MS
      : false // legacy boolean
    : true // unregistered
}

// We want to get an idea of how old people's sessions are
const SESSION_GRANULARITY = 4; // 4 Hours
const SESSION_TRACKING_MAX = 48; // 48 Hours

function getSessionLengthLabel(sessionStartMS) {
  const date1 = new Date(sessionStartMS);
  const date2 = new Date();
  const diffInMS = date2.getTime() - date1.getTime();
  const diffInHours = diffInMS / (1000 * 60 * 60);
  const hourInterval = Math.floor(diffInHours/SESSION_GRANULARITY) * SESSION_GRANULARITY;
  return hourInterval <= SESSION_TRACKING_MAX
    ? `ses${hourInterval}`
    : 'sesMax';
}

// On-demand MVP tracking
export const ONE_HOUR_TRACKING_LABEL = "1hr";
export const ONE_HOUR_ZIP_CODES = [
  "02170", "02171", "02169", "02269",    // Quincy
  "02121", "02122", "02124", "02125"     // Dorchester
];

export const TRACK_CONTEXT = {
  NEW_USER: 'new_user',
  DEVICE: 'device',
  PARTNER: 'partner',
  ONEHOUR: '1hr',
  SESSION_LENGTH: 'session',
}

const useAddlContextTracking = () => {

  // New user tracking
  const { hasCompletedFirstOrder, currentLocation } = React.useContext(UserContext);
  // Url path: /brand/{foo} matches partner.name from API to determine partner context
  const { partnerReferralDomain:partnerName } = React.useContext(PartnerContext);

  const { sessionStartMS } = useAnalyticsStore();

  const trackEventWithContext = React.useCallback((label, context=[]) => {
    // Handle string or array context param
    const contextArray = Array.isArray(context) ? context : [context];
    // Track the original/vanilla event
    trackEvent(label);
    // Track the additional contexts
    // New user/same day checkout flow
    if (contextArray.includes(TRACK_CONTEXT.NEW_USER)) {
      if(!hasCompletedFirstOrder && newlyRegistered()) {
         trackEvent(`new_user_${label}`);
      }
    }
    if (contextArray.includes(TRACK_CONTEXT.DEVICE)) {
      trackEvent(`${label}_${window.screen.width > 480 ? 'dt' : 'mb'}`);
    }
    if (contextArray.includes(TRACK_CONTEXT.PARTNER)) {
      if (partnerName) {
        trackEvent(`${label}_${partnerName}`);
      }
    }
    if (contextArray.includes(TRACK_CONTEXT.ONEHOUR)) {
      const { zip_code } = currentLocation || {};
      if (zip_code && ONE_HOUR_ZIP_CODES.includes(zip_code)) {
        trackEvent(`${ONE_HOUR_TRACKING_LABEL}_${label}`);
      }
    }
    if (contextArray.includes(TRACK_CONTEXT.SESSION_LENGTH)) {
      if (sessionStartMS) {
        trackEvent(`${label}_${getSessionLengthLabel(sessionStartMS)}`);
      }
    }
  }, [hasCompletedFirstOrder, currentLocation, partnerName, sessionStartMS])

  return {
    trackEventWithContext,
  }
}

export default useAddlContextTracking;
