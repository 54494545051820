import * as React from 'react';

import { CarouselStyle } from '../common/SimpleCarousel';
import ProductCarousel from '../home/ProductCarousel';

import PropTypes from 'prop-types';

/**
 *  If the user is viewing a bundle-priced product, show the related
 *  same-bundle-id products.
 *  Fall back to the normal suggested products carousel if no
 *  same-bundle-id products are available.
 */
const BundledProductsCarousel = ({
  currentProductId,
  bundleId,
  bundleMinQuantity,
  carouselTitle,
  relatedProducts,
}) => {

  const [otherSubCatProducts, setOtherSubCatProducts] = React.useState();
  const [showBundleProducts, setShowBundleProducts] = React.useState();

  // Remove the current product from the suggested carousel
  React.useEffect(() => {
    if (relatedProducts) {
      // Fallback related
      const related = relatedProducts.filter(
        product => product.id !== currentProductId);
      // Same bundle id
      const bundleRelated = related.filter(
        product => product.bundle_deal_data?.id === bundleId);

      setOtherSubCatProducts(bundleRelated.length ? bundleRelated : related);
      setShowBundleProducts(!!bundleRelated.length);
    }
  }, [relatedProducts, currentProductId, bundleId]);

  return ( otherSubCatProducts
    ? <ProductCarousel
        carouselTitle={showBundleProducts ? `Mix & Match Any ${bundleMinQuantity}!` : carouselTitle}
        /* caregoryCount is for the "See All" link which we don't use here */
        categoryCount={otherSubCatProducts.length}
        carouselProducts={otherSubCatProducts}
        /* Restore full product list during modal to modal viewing  */
        allRelatedProducts={relatedProducts}
        carouselStyle={CarouselStyle.SUGGESTED_PRODUCTS}
        isSuggestedProduct />
    : <></>
  )
}

BundledProductsCarousel.propTypes = {
  currentProductId: PropTypes.string.isRequired,
  bundleId: PropTypes.string.isRequired,
  bundleMinQuantity: PropTypes.number.isRequired,
  carouselTitle: PropTypes.string.isRequired,
  relatedProducts: PropTypes.array.isRequired,
};

export default BundledProductsCarousel;
