import * as React from 'react';

import { DeliveryOptionsContext } from '../providers/DeliveryOptionsProvider';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

const DeliveryBlacklistMessage = ({
  location,
}) => {

  const { isBlockedAddress } = React.useContext(DeliveryOptionsContext);

  return ( isBlockedAddress
    ? <Alert severity='warning' style={{margin:'1rem 0'}}>
        Sorry, but it looks like your address: <b>{location.street_address}</b> in {location.city}, 
        is in a restricted zone (college campus, public housing, etc.) and we cannot 
        legally deliver to this address.  
      </Alert>
    : null    
  )
}

DeliveryBlacklistMessage.propTypes = {
  location: PropTypes.shape({
    street_address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip_code: PropTypes.string.isRequired,
    geo_coordinates: PropTypes.any,
  })    
}

export default DeliveryBlacklistMessage
