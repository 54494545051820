import * as React from 'react';

import { DispensaryContext, CHECKOUT_PATH } from '../dispensary/DispensaryProvider';
import usePhoneSignInReducer, { SIGNIN_STEPS } from './usePhoneSignInReducer';
import { navigateTop, navigateTopWithState } from '../routing/router-utils';
import RequestLoginCode from "./RequestLoginCode";
import SubmitLoginCode from './SubmitLoginCode';
import CompleteSignIn from './CompleteSignIn';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import Header, { HeaderSize } from "../styleguide/Header";
import PageTitle from '../common/PageTitle';
import BackLink from '../common/BackLink';
import HavingTroubleCallout from '../registration/HavingTroubleCallout';
import Alert from '@material-ui/lab/Alert';

import styles from '../registration/SignUpInline.module.css';

export const DEFAULT_ERROR = 'Unknown error...';

/**
 * Request a custom Firebase token by using SMS/Phone verification
 * Submitting the custom token switches the user to their ZR user/account
 */
const PhoneSignIn = ({location}) => {

  const [loginState, dispatch] = usePhoneSignInReducer();
  const { isCheckingOut } = React.useContext(DispensaryContext);

  // Get navigation paths
  React.useEffect(() => {
    if (location?.state) {
      const {
        continuePath="/", 
        returnToPath="/",
        priorNavigationState,
      } = location.state;
      dispatch({
        type:'savePaths',
        continue_path: continuePath,
        return_to_path: returnToPath,
        prior_navigation_state: priorNavigationState,
      });
    }
  }, [location, dispatch]);

  const dispatchError = React.useCallback((error) => {
    dispatch({
      type:'error',
      error_message: error.message
    });
  }, [dispatch]);

  return (
    <BodyWrapper pageLayout={LayoutTypes.Narrow}>
      <PageTitle title='Log In' />
      <div className={styles.phoneSignInWrapper}>
        { loginState.return_to_path &&
          <BackLink
            backToPath={loginState.return_to_path}
            priorNavigationState={loginState.prior_navigation_state} />
        }
        <Header isCentered size={HeaderSize.Medium_Static} withStyle={{marginTop:'1rem'}}>
          Log In by Phone
          <span className={styles.subHead}>(For Registered Users)</span>
        </Header>  
        <div className={styles.phoneSignInSection}>
          {(() => {
            switch (loginState.current_step) {
              case SIGNIN_STEPS.ENTER_PHONE:
                return <RequestLoginCode
                         loginState={loginState}
                         dispatch={dispatch} 
                         onSuccess={(response) => dispatch({
                          type:'phoneNumberSuccess',
                          phone_number: response.recipient_tel_number,
                          qa_only_code: response.qa_only_code 
                         })}
                         onFailure={dispatchError} />
              case SIGNIN_STEPS.ENTER_CODE:
                return <SubmitLoginCode
                         loginState={loginState}  
                         onSuccess={(data) => dispatch({
                           type:'smsCodeSuccess',
                           custom_token: data.token,
                         })}
                         onFailure={dispatchError} />
              case SIGNIN_STEPS.COMPLETE_LOGIN:
                return <CompleteSignIn
                         loginState={loginState}
                         onSuccess={() => {
                           // Ignore complex sign in/up paths 
                           if (isCheckingOut) {
                             navigateTop(CHECKOUT_PATH);
                           } else if (loginState.continue_path) { 
                             navigateTop(loginState.continue_path);
                           } else if (loginState.return_to_path) { 
                             navigateTopWithState(
                               loginState.return_to_path,
                               loginState.prior_navigation_state || {});
                           } else {
                             navigateTop('/');
                           }
                         }}
                         onFailure={dispatchError} />
              default:
                return <></>
            }
          })()}
        </div>
        <div className={styles.phoneSignInSection}>
          { loginState.success_message &&
            <Alert severity='success'>
              {loginState.success_message}
            </Alert>
          }
          { loginState.info_message &&
            <Alert severity='info'>
              {loginState.info_message}
            </Alert>
          }
          { loginState.error_message &&
            <>
              <Alert severity='error'>
                {loginState.error_message}
              </Alert>
              { loginState.current_step === SIGNIN_STEPS.ENTER_CODE &&
                <div className={styles.phoneSignInTryAgain}>
                  <span onClick={() => dispatch({type:'sendCodeAgain'})}>
                    Send New Code
                  </span>
                </div>
              }
              <HavingTroubleCallout 
                delaySeconds={0} 
                trackingLabel={loginState.current_step} />
            </>
          }
        </div>
      </div>
    </BodyWrapper>
  );
};

export default PhoneSignIn;
