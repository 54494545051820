import * as React from "react";

import { makeStyles } from '@material-ui/core';  
import { formatDateString } from '../notifications/notification-utils';
import EditableFullName from "./EditableFullName";
import PropTypes from 'prop-types';

import styles from './YourAccount.module.css';

const useStyles = makeStyles({
  'accountVal': {
    color: 'var(--zrPrimaryDark)',
    font: 'var(--zrFontSemi)'
  }
});

/**
 * The Account page header containing name, email, reg date.
 */
const YourAccountHeader = ({fullName, email, user, refetch}) => {

  const classes = useStyles();
  
  const { createdAt } = user?.metadata || {}

  return (
    <div className={styles.headerWrap}>
      { user &&   
        <>
          <div className={`${styles.headerItem} ${styles.headerItemName}`}>
            <div className={styles.accountLbl}>Name</div>
            <div className={`${styles.accountVal} ${classes.accountVal}`}>
              <EditableFullName fullName={fullName} refetch={refetch} />
            </div>
          </div>
          <div className={styles.headerItem}>
            <div className={styles.accountLbl}>Email</div>
            <div className={`${styles.accountVal} ${classes.accountVal}`}>{email}</div>
          </div>
          <div className={styles.headerItem}>
            <div className={styles.accountLbl}>Since</div> 
            <div className={`${styles.accountVal} ${classes.accountVal}`}>{ 
              formatDateString(new Date(parseInt(createdAt, 10)).toDateString())
            }</div>
          </div>
        </>
      }
    </div>
  );
}

YourAccountHeader.propTypes = {
  fullName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default YourAccountHeader;
