import * as React from "react";

import ToolTip from "../common/ToolTip";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  wrapper: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    marginTop: 4,
    marginLeft: 6,
  },
  label: {
    margin: '6px 0 4px',
  },
  list: {
    padding: '2px 0 2px 6px',
  },
  detail: {
    display: 'flex',
    fontSize: '14px',
  },
  code: {
    fontWeight: 700,
    paddingRight: 6,
    minWidth: 90,    
  },
  discount: {
    flex: 1,
  },
  minimum: {
    minWidth: 104,
  }
});

const CodeDetail = ({code, discount, minimum}) => {
  const classes = useStyles();
  return (
    <li>
      <div className={classes.detail}>
        <span className={classes.code}>{code}</span>
        <span className={classes.discount}>${discount} OFF</span>
        <span className={classes.minimum}>Min order: ${minimum}</span>
      </div> 
    </li>
  );
};
 
const CurrentCodesTooltip = () => {
  const classes = useStyles();
  return ( 
    <div className={classes.wrapper}>
      <ToolTip
        buttonText="Popular Promo Codes"
        tipTitle="Popular Promo Codes*"
        footerText="*Minimum order reflects the subtotal after ALL discounts."
        placement="bottom-start"
        enableFlip
        trackingLabel="promo_code_tooltip"
        buttonStyle={{verticalAlign:-5, padding:4, marginLeft:-10}}>
        <div className={classes.label}>SINGLE USE:</div>
        <ul className={classes.list}>
          <CodeDetail code="WELCOMEZYP" discount={30} minimum={50} />
        </ul>
        <div className={classes.label}>UNLIMITED USE:</div>
        <ul className={classes.list}>
          <CodeDetail code="BIGZYP" discount={30} minimum={120} />
          <CodeDetail code="HUGEZYP" discount={40} minimum={160} />
          <CodeDetail code="FULLZYP" discount={50} minimum={200} />
        </ul>
      </ToolTip>
    </div>
  );
};

export default CurrentCodesTooltip;
