import * as React from 'react';

import Alert from '@material-ui/lab/Alert';

const ErrorMessage = ({text, isInline, isCentered, withStyle={}, children}) => {

  const centeredStyle = isCentered 
    ? { 
        position: 'relative',
        left: '50%',
        transform: 'translateX(-50%)',
        margin: '2rem auto'
      } 
    : {};

  const addlStyles = Object.assign(centeredStyle, withStyle);

  const defaultStyle = {
    margin: isInline ? '0' : '1.5rem 0', 
    borderRadius:'.5rem', 
    overflow:'hidden'
  };

  const errorStyle = Object.assign(defaultStyle, addlStyles);

  return (
    <div style={errorStyle}>
      <Alert severity='error'>
        {text}{children}
      </Alert>
    </div>
  )
}

export default ErrorMessage;
