import React from 'react';

import { DeliveryOptionsContext } from '../providers/DeliveryOptionsProvider';
import Alert from '@material-ui/lab/Alert';

/**
 *  For any important pickup service-related messages we need to display
 *  "Not accepting Delivery orders at this time", etc.
 */
const DeliveryMessages = () => {
  const { deliveryEnabled } = React.useContext(DeliveryOptionsContext);

  const alertStyle = {
    margin:'1.5rem 0', 
    borderRadius:'.5rem', 
    overflow:'hidden'
  };

  return (
    deliveryEnabled === false
      ? <div style={alertStyle}>
          <Alert severity='warning'>
            Online ordering for delivery has been temporarily paused.
            We apologize for the inconvenience, please check back&nbsp;later...
          </Alert>
        </div>
      : null
  );
} 

export default DeliveryMessages;
