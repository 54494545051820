import * as React from 'react';

import BodyWrapper from '../layout/BodyWrapper';
import { Warning } from '@material-ui/icons';
import { Link } from '@reach/router';
import { useAnalyticsStore } from '../../App';
import XLargeHeader from '../styleguide/XLargeHeader';
import PageTitle from './PageTitle';

import styles from './PageNotFound.module.css';

const PageNotFound = ({location}) => {

  const [badPath, setBadPath] = React.useState();  
  React.useEffect(() => {
    const { referrer } = location?.state || {};
    if (referrer) {
      setBadPath(referrer);
    // e.g. /foo, pageNotFound is default  
    } else if (window.location.pathname !== '/pageNotFound') {
      setBadPath(window.location.pathname);
    }
  }, [location])

  const { trackError } = useAnalyticsStore();

  // Log the 404 error
  React.useEffect(() => {
    if (badPath) {
      trackError(`error_404_${badPath.substring(1)}`);
    }
  }, [trackError, badPath]);

  return (
    <BodyWrapper>
      <PageTitle title="Page Not Found" />
      <div className={styles.wrap404}>
        <XLargeHeader>
          <Warning style={{ fontSize:'3rem', verticalAlign:'bottom', marginRight:'.5rem' }}/>
          <span>Wait... What?</span>
        </XLargeHeader>
        <div className={styles.notFoundContent}>
          { badPath &&
            <div className={styles.notFoundMsg}>
              Sorry, I'm not finding anything for the&nbsp;page:&nbsp;{badPath}.
            </div>
          }
          <p><Link to="/">Return to the Zyp Run Home page</Link></p>
        </div>
      </div>
    </BodyWrapper> 
  );
};

export default PageNotFound;
