import React from 'react';

import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

/**
 *  The MUI Alert with rounded corners and margin
 */   
const Warning = ({
  text, 
  severity='warning',
  withClass='',
  isCompact,
  children
}) => {

  const wrapperStyle = {
    margin:'1.5rem 0', 
    borderRadius:'.5rem', 
    overflow:'hidden'
  };

  const compactWrapperStyle = {
    margin:'.75rem 0',
    borderRadius:'.5rem', 
    overflow:'hidden' 
  };

  const compactAlert = {
    padding: '0 12px'
  }
  
  return (
    <div className={withClass} style={isCompact ? compactWrapperStyle : wrapperStyle}>
      <Alert severity={severity} style={isCompact ? compactAlert : {}}>
        {text}{children}
      </Alert>
    </div>
  );
} 

Warning.propTypes = {
  text:PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  severity: PropTypes.oneOf(['error','warning','info','success']),
  withClass: PropTypes.string,
  isCompact: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array])
};

export default Warning;
