import * as React from 'react';

import { trackEvent } from '../analytics/tracking.jsx';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import PageTitle from '../common/PageTitle.jsx';
import Header from '../styleguide/Header';
import { Checkmark } from 'react-checkmark';
import Button from '../styleguide/Button.jsx';
import GoogleReviewLink from '../common/GoogleReviewLink.jsx';
import { navigateTop } from '../routing/router-utils.jsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  tyWrap: {
    width: 600,
    maxWidth: '100%',
    padding: '3rem 0',
    margin: '0 auto',
  },  
  tyHeader: {
    color: 'var(--zrPrimaryDark)',
  },
  tyHeaderText: {
    marginTop: '1rem',
    display: 'block',
    fontSize: '1.75rem',
    color: 'var(--zrPrimaryDark)',
  },
  tyBody: {
    fontSize: '1.125rem',
    margin: '1.5rem 0 2rem',
  },
  tyReviewBody: {
    fontSize: '1.125rem',
    margin: '3rem 0 2rem',  
  },
  tyReviewBtn: {
    marginTop: '2rem',
  },
})

const SurveyThanksPage = () => {
  
  const classes = useStyles();

  React.useEffect(() => {
    trackEvent('survey_thank_you_display');
  },[])

  return (
    <BodyWrapper pageLayout={LayoutTypes.Narrow}>
      <PageTitle title="Thank You" />        
      <div className={classes.tyWrap}> 
        <Header isCentered withClass={classes.tyHeader}>
          <Checkmark size="48" color="var(--zrPrimaryDark)" />            
          <span className={classes.tyHeaderText}>THANK YOU!</span>
        </Header>
        <div className={classes.tyBody}>
          Thanks for your survey response. At <b>Zyp Run</b>, we're continually working to provide 
          you with the best cannabis delivery experience.
        </div>
        <Button isCentered text="Shop Delivery" handleClick={() => navigateTop('/')} />
        <div className={classes.tyReviewBody}>
          Lastly, if you have a minute, please leave us a <b>Google Review</b>. You'll be supporting a locally-owned business
          and we'd greatly appreciate it!
          <GoogleReviewLink
            trackingLabel='survey_thank_you' />
        </div>
      </div>
    </BodyWrapper>
  );
};

export default SurveyThanksPage;
