import { auth } from "../../firebase";
import { trackEvent, trackError } from "../analytics/tracking";
import { outOfServiceAreaEndpoint } from "../../constants/api-endpoints"; 

export const postNotifyMeData = (email, location, onSuccess, onFailure, controller) => {
  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(outOfServiceAreaEndpoint, { 
      method: 'POST',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        city: location.city,
        state: location.state,
        zip: location.zip_code,
      }),
      signal: controller.signal
    }).then(response => {
      // 204: Success no message
      if (response.status === 204) {
        trackEvent('notify_me_success');
        if (onSuccess) {
          onSuccess();
        }
      } else {
        trackError(`notify_me_error_${response.status}`);
        response.json().then(body => {
          onFailure(body.error); 
        });
      }
    }).catch(error => {
      trackError('notify_me_error');
      onFailure(false, error);
    });
  });
}
