/**
 * Reducer for the commerce events consumed by the AnalyticsDebug component
 * via the CommerceDebugProvider
 */
const commerceDebugReducer = (state, action) => {
  switch(action.type) {
    case 'setCommerceId': 
      return {
        ...state,
        commerceId: action.commerceId,
        commerceEvents: [], // Reset when creating a new ID (e.g. user goes anonymous => registered)
      }
    case 'trackCommerceEvent':
      return {
        ...state,
        commerceEvents: [action.commerceEvent, ...state.commerceEvents],
      }
    default:
      return state
  }
}

export default commerceDebugReducer;
