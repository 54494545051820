import * as React from 'react';

// Detect clicks outside a specific element/wrapper
const useOutsideClick = (callback) => {
  // Ref for the "inside" element
  const insideRef = React.useRef();
  // Exclude modals, tooltips, etc. we want outside click to be on the main page
  const pageRef = React.useRef();

  React.useEffect(() => {
    const page = document.getElementById('layout');
    if (page) {
      pageRef.current = page; 
    }
  }, [])

  React.useEffect(() => {
    const handleClick = (event) => {
      if (pageRef.current.contains(event.target) &&
          insideRef.current &&
          !insideRef.current.contains(event.target)
        ) {
        callback();
      }
    };
    document.addEventListener('click', handleClick, true);
    
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [callback, insideRef]);

  return insideRef;
};

export default useOutsideClick;
