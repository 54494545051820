import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';

/**
 * Validation errors mapped generally by input id
 */
export const helpText = { 
  'name': (<><ErrorIcon /><span>Your full name is required</span></>),
  'email': (<><ErrorIcon /><span>A valid email address is required</span></>),
  'password': (<><ErrorIcon /><span>A strong password is required</span></>),
  'address' : (<><ErrorIcon /><span>Your address is required (for&nbsp;delivery)</span></>),
  'street_address': (<><ErrorIcon /><span>A street address is required for delivery</span></>),
  'city': (<><ErrorIcon /><span>A city is required for delivery</span></>),
  'state': (<><ErrorIcon /><span>Required</span></>),
  'zip_code': (<><ErrorIcon /><span>Valid MA zip code required</span></>),
  'delivery_not_available': (<><ErrorIcon /><span>It looks like delivery is not available in your area&nbsp;yet</span></>),
  'birthDate': (<><ErrorIcon /><span>Please enter a valid MM/DD/YYYY birth date</span></>),
  'month': (<><ErrorIcon /><span>Required</span></>),
  'day': (<><ErrorIcon /><span>Required</span></>),
  'year': (<><ErrorIcon /><span>Required</span></>),
  'accept_terms': (<><ErrorIcon /><span>You must agree to the terms and conditions</span></>),
  'tel_number': (<><ErrorIcon /><span>A valid mobile number is required for delivery notifications</span></>),
  'send_code': (<><ErrorIcon /><span>There was an error sending a verification code to your phone number</span></>),
  'verify_code': (<><ErrorIcon /><span>There was an error verifying the code sent to your phone number</span></>),
  'id_photo': (<><span>A picture of your Photo ID is required for registration</span></>),
  'login': (<><ErrorIcon /><span>Invalid email or password</span></>),
  'validation': (<><ErrorIcon /><span>Please correct your registration entries to continue.</span></>)
};

/* Only show helper text on error */
export const errMsg = (inputName, isError) => {
  return isError
    ? helpText[inputName]
    : null;
}

/* on error show dyamic error message */
export const customErrMsg = (text, isError) => {
  return isError
    ? <><ErrorIcon /><span>{text}</span></>
    : null;
}