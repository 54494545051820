import * as React from 'react';
import { devLog } from './util';

const useInterval = (callback, delay, paused) => {
  const callbackRef = React.useRef();

  // Set a reference to the callback.
  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      devLog('Running useInterval callback');
      callbackRef.current();
    }
    if (delay !== null && !paused) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, paused]);
}

export default useInterval;
