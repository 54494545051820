import { navigateTop } from '../routing/router-utils';

/** 
 * Redirect to 404 page
 **/
const PageNotFoundRedirect = () => {
  navigateTop('/PageNotFound');
  return null;
} 

export default PageNotFoundRedirect;