import * as React from 'react';

import { ProductPreviewContext } from '../products/ProductPreviewProvider';
import HavingTroubleCallout from '../registration/HavingTroubleCallout';
import Alert from '@material-ui/lab/Alert';

import PropTypes from 'prop-types';

/**
 * When a product is SOLD OUT the API can't return a product name,
 * we only get an array of IDs.
 * This component uses the cached product data to display a SOLD OUT message to
 * the user: 
 * 
 * "1 product in your order is currently unavailable: 
 *    * Deep Purple Gummies"
 */
const OrderSubmissionErrors = ({errorMsg}) => {
  
  const { getCachedProductById } = React.useContext( ProductPreviewContext);
  
  const [productErrorMsg, setProductErrorMsg] = React.useState();
  const [productNames, setProductNames] = React.useState();
  
  const listingStyle = {
    fontWeight: 600,
    listStyleType: 'disc',
    listStylePosition: 'inside',
  }

  React.useEffect(() => {
   // ...currently unavailable. ID: [000299, 00031]
   if (/ID: \[/.test(errorMsg)) {
      const [ arrayInString ] = errorMsg.match(/\[(.*?)\]/g);
      try {
        // Remove [] and split
        const productIds = arrayInString.substring(1, arrayInString.length - 1).split(",");
        const productDisplayNames = [];
        productIds.forEach(id => {
          // Trim is needed: [" 000124", " 000122"]
          const product = getCachedProductById(id.trim());
          if (product) {
            productDisplayNames.push(product.display_info.name);
          }
        })
        // Remove original array from error string
        if (productDisplayNames.length) {
          const prefix = errorMsg.substring(0, errorMsg.indexOf('ID:') - 2);
          setProductErrorMsg(`${prefix}:`);
          setProductNames(productDisplayNames); 
        }
      } catch(e) {
        // Fall back to original error returned by API
      }
    }
  }, [errorMsg, getCachedProductById]);

  return (
    <>
      <Alert severity="error" style={{margin:'1rem 0'}}>
        { productErrorMsg
          ? <>
              {productErrorMsg}
              <ul>
                {productNames.map(name => (
                  <li key={name} style={listingStyle}>{name}</li>
                ))}
              </ul>
            </>  
          : errorMsg
        }
      </Alert>
      <HavingTroubleCallout delaySeconds={0} trackingLabel='order_submit' />
    </>
  );
}

OrderSubmissionErrors.propTypes = {
  errorMsg: PropTypes.string.isRequired
}

export default OrderSubmissionErrors;
