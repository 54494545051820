import * as React from 'react';

import { activeController } from '../util/util';
import useIntercomLogging, { INTERCOM_MILESTONES } from '../intercom/useIntercomLogging';
import useAerosyncToken from './useAerosyncToken';
import { postAerosyncCredentials } from './payment-utils';
import AerosyncWidget from './AerosyncWidget';
import PropTypes from 'prop-types';

/**
 * Configure and load Aerosyncwidget (AeroPay) so users 
 * can link a bank account
 */
const LinkAccountsWithAerosync = ({
  launchWidget,
  onWidgetLoad,
  onWidgetClose,
  onWidgetSuccess,
  onWidgetError,
  onPostbackSuccess,
  onPostbackError,
}) => {

  /**
   * For AeroPay staging/dev environments use:
   *  
   * staging bank: 'Aerosync etc.'.
   * staging username: anything
   * staging password: anything 
   * mobile 2 factor code: any number
   */
  
  const { logIntercomAction } = useIntercomLogging();

  // API fetch and response messages
  const controllerRef = React.useRef(new AbortController());
  
  // token required by widget
  const { token } = useAerosyncToken();

  const handleWidgetSuccess = (aerosyncSDKPayload) => {
    // Track/Display payload in dev
    onWidgetSuccess(aerosyncSDKPayload);

    const controller = activeController(controllerRef);
    // Final step: handle ZR API reponse when posting acct info
    const postbackSuccessCb = (responseJSON) => {
      // TODO: Implement bank name
      const { bankName } = responseJSON.userBankInfo || {};
      onPostbackSuccess({
        message: `Bank account sucessfully linked`,
        bankName,
      });
      logIntercomAction(INTERCOM_MILESTONES.AEROPAY_LINKED);
    }
    const postbackErrorCb = (responseJSON) => {
      const { error="Unknown error"} = responseJSON;
      const message = `Error updating Aeropay: ${error}`;
      onPostbackError({ message });
    }

    postAerosyncCredentials(
      { aerosyncSDKPayload },
      postbackSuccessCb,
      postbackErrorCb,
      controller
    );
  };

  // Handle abort/errors post widget signin" 
  const handleWidgetError = (response) => {
    const { error="Unkown error" } = response;
    const message = `Aerosync Error: ${error}`;
    onWidgetError({ message });
  }

  // TODO: Lazy Load aerosync script. if (window.AerosyncConnect)
  return ( token && launchWidget
    ? <AerosyncWidget
        token={token}
        onWidgetLoad={onWidgetLoad}
        onWidgetClose={onWidgetClose}
        onWidgetSuccess={handleWidgetSuccess}
        onWidgetError={handleWidgetError} />
    : null
  )
}

LinkAccountsWithAerosync.propTypes = {
  launchWidget: PropTypes.bool,
  onWidgetLoad: PropTypes.func.isRequired,
  onWidgetClose: PropTypes.func.isRequired,
  onWidgetSuccess: PropTypes.func.isRequired,
  onWidgetError: PropTypes.func.isRequired,
  onPostbackSuccess: PropTypes.func.isRequired,
  onPostbackError: PropTypes.func.isRequired,
};

export default LinkAccountsWithAerosync;
