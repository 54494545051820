import React from 'react';

import styles from './EmptyCart.module.css';

const EmptyCart = ({
  isGridDisplay
}) => {

  return (
    <div className={`${styles.emptyBag} ${isGridDisplay ? styles.inGrid : ''}`}>
      <img alt="" src="/img/emptyCartBag.jpg" className={styles.bagImage} />
      <div>Your bag is empty</div>
      <div>Add Items to get started</div>
    </div>
  )
}

export default EmptyCart;
