import React from 'react';

import PropTypes from 'prop-types';

import styles from './Header.module.css';

// Various header sizes - TODO use in place of PrimaryHeader, MediumHeader etc.
export const HeaderSize = {
  Large: "large",
  Medium: "medium",
  /* Good for modals, where desktop and mobile layout is a similar width */
  Medium_Static: "mediumStatic",  
  Small: "small"
};

const Header = ({
  text, 
  isCentered, 
  size=HeaderSize.Large,
  withStyle={},
  withClass='',
  onClick, 
  children
}) => {

  const centerStyle = isCentered ?  {textAlign: 'center'} : {};
  const propsStyle = Object.assign(centerStyle, withStyle);
  
  const clickStyle = onClick ? styles.clickable : '';


  // Unclickable headers
  const noClick = ()=>{};

  return (
    <div className={`${styles.header} ${styles[size]} ${clickStyle} ${withClass}`} onClick={onClick || noClick} style={propsStyle}>
      {text}
      {children} 
    </div>
  );
};

Header.propTypes = {
  text: PropTypes.string,
  isCentered: PropTypes.bool,
  size: PropTypes.string,
  withStyle: PropTypes.object,
  withClass: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Header;
