import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import { optimizedImage } from '../products/CroppedProductImage';
import { useAnalyticsStore } from '../../App';
import { trackingString } from '../analytics/tracking';
import { BANNER_TYPES } from './PromoBannerProvider';
import { makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';

const useStyles = makeStyles({
  wrapper: {
    cursor: 'pointer',
    overflow: 'hidden',
    borderRadius: '.5rem'  
  },
  nonClickable: {
    cursor: 'default',
  },
  bannerImage: {
    display: 'block',
    maxWidth: '100%'
  }
});

/**
 * Promo Banners
 * 
 * NOTE: The banner endpoint will only return banners that are ACTIVE based on 
 * start/end timestamps, so that data is only needed for tracking.
 */
const PromoBanner = ({
  type,
  value,
  image_url,
  trackingLabel="promo"
  /*
    currently unused:
    id,
    go_to_url,
    start_timestamp,
    end_timestamp,
  */
}) => {

  const { trackEvent, trackError } = useAnalyticsStore();
  const classes = useStyles();

  const handleClick = () => {
    // Click tracking
    const val = trackingString(value, 8, true);
    const trackLabel = `${trackingLabel}_${type}_${val}_clk`;
    trackEvent(trackLabel);

    // Navigate
    if (type === BANNER_TYPES.PRODUCT) {
      navigateTop(`/product/${value}`);
    } else if (type === BANNER_TYPES.BRAND) {
      navigateTop(`/brand/${value}`);
    } else if (type === BANNER_TYPES.PAGE) {
      if (value?.length > 1) {
        const pagePath = value.startsWith('/') ? value : `/${value}`;
        navigateTop(pagePath);
      }
    } else {
      trackError(`unknown_promo_type_${type}`); 
    }  
  };

  // Handle image-only banners
  const [notClickable, setNotClickable] = React.useState();
  React.useEffect(() => {
    if (type === BANNER_TYPES.PAGE && ["/",""].includes(value)) {
      setNotClickable(true);
    }
  },[type, value]);

  return ( image_url
    ? <div className={`${classes.wrapper} ${notClickable ? classes.nonClickable : ''}`} 
           onClick={handleClick}>
        <img alt={`${type}_promo`} 
             className={classes.bannerImage} 
             src={optimizedImage(image_url, 600)} />
      </div>
    : null
  );
}

PromoBanner.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  go_to_url: PropTypes.string.isRequired,
  start_timestamp: PropTypes.number.isRequired,
  end_timestamp: PropTypes.number.isRequired,
  image_url: PropTypes.string.isRequired,
  trackingLabel: PropTypes.string
};

export default PromoBanner;
