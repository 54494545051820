import * as React from 'react';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import PropTypes from 'prop-types';

import styles from './Stylesheet.module.css';

const SuccessMessage = ({text, isInline, withStyle={}, children}) => {
 
  const inlineStyle = isInline ? { marginTop: 0 } : {};
  const msgStyle = Object.assign(withStyle, inlineStyle);

  const iconStyle = {
    color: 'var(--zrPrimaryDark)',
    fontSize:'20px', 
    verticalAlign: -4  
  };

  return (
    <div className={styles.successMessage} style={msgStyle}>
      <span className={styles.successIcon}>
        <CheckCircleRoundedIcon style={iconStyle} />
      </span>
      {' '}
      <span className={styles.successText}>{text}</span>
      {children}   
    </div>
  )
}

SuccessMessage.propTypes = {
  text: PropTypes.string.isRequired, 
  isInline: PropTypes.bool,
  withStyle: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array])
};

export default SuccessMessage;
