const PARTNERS_BY_CATEGORY = {
  'Edibles' : [41049],
};

const PARTNER_NAME_BY_ID = {
  '41049' : 'Happy Valley',
};

/**
 * Return featured brands for a given product category
 *
 * @param {string} categoryName
 * @param {object} categoryProducts
 * @returns {Map or null}
 */
export const getFeaturedProducts = (categoryName, categoryProducts) => {
  if (PARTNERS_BY_CATEGORY[categoryName]) {
    const featuredBrands = PARTNERS_BY_CATEGORY[categoryName];
    const brandProductMap = new Map();
    featuredBrands.forEach(partnerId => {
      const brandProducts = categoryProducts.filter(itm => itm.vendor_id === partnerId);
      if (brandProducts.length) {
        brandProductMap.set(PARTNER_NAME_BY_ID[partnerId.toString()], brandProducts);
      }
    });
    return brandProductMap.size > 0 ? brandProductMap : null;
  }
  return null;
}
