import * as React from 'react';

import { navigateTopWithState } from '../routing/router-utils';
import { RegistrationContext } from '../registration/RegistrationProvider';
import { setSessionVal, referringUserKey } from '../util/storage-utils';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import Header, { HeaderSize } from '../styleguide/Header';
import Spinner from '../common/Spinner';

import { decode } from './refer-util';

const GetCreditLander = ({encodedReferrer}) => {

  const { setReferrerId, setReferrerName } = React.useContext(RegistrationContext);

  // Process referrerId from path
  React.useEffect(() => {
    if (encodedReferrer) {
      const referringUser = decode(encodedReferrer) || {};
      // Add data to RegistrationProvider
      if (referringUser?.id) {
        setReferrerId(referringUser.id);
        setReferrerName(referringUser.name);        
        // Add to session storage
        setSessionVal(referringUserKey, JSON.stringify(referringUser));
      }
      // Continue to Home Page
      // TODO: Error message for invalid refer URL
      navigateTopWithState('/', {
        referringUser
      })
    }
  }, [encodedReferrer, setReferrerId, setReferrerName]);

  return (
    <BodyWrapper pageLayout={LayoutTypes.Narrow}>
      <Header size={HeaderSize.Large} isCentered withStyle={{color:'var(--zrPrimaryDark)', marginTop:'3rem'}}>
        Processing Referral...
      </Header>  
      <Spinner /> 
    </BodyWrapper> 
  );
};

export default GetCreditLander;
