import React, { useState, useRef } from "react";

import { auth } from '../../firebase'; 
import { signInWithEmailAndPassword } from "firebase/auth";
import { navigateTop, navigateTopWithState } from "../routing/router-utils";
import { useAnalyticsStore } from '../../App';
import { DispensaryContext, CHECKOUT_PATH } from '../dispensary/DispensaryProvider';
import BodyWrapper, { LayoutTypes } from "../layout/BodyWrapper";
import Header, { HeaderSize } from '../styleguide/Header';
import BackLink from "../common/BackLink";
import TextField from '@material-ui/core/TextField';
import Button from "../styleguide/Button";
import VisibilityButton from "../common/VisibilityButton";
import ErrorMessage from "../styleguide/ErrorMessage";
import ActionLink from "../styleguide/ActionLink";
import DetourButton from "../styleguide/DetourButton";
import SendResetEmailForm from '../resetPassword/SendResetEmailForm';
import PageTitle from '../common/PageTitle';
import { Grid } from "@material-ui/core";

import styles from './SignIn.module.css';

// "Log In" is friendlier than "Sign In" for older folks?
export const SIGN_IN_TEXT = 'Log\u00A0In';
export const SIGN_OUT_TEXT = 'Log\u00A0Out';

const SignIn = ({location}) => {

  const { isCheckingOut } = React.useContext(DispensaryContext);
  const { trackEvent, trackError } = useAnalyticsStore();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();
  // Used to track recovery from signin error
  const errorRef = useRef(false);

  const [showPasswordReset, setShowPasswordReset] = React.useState(false);
  // Password visibility
  const [showPassword, setShowPassword] = React.useState(false);

  const [returnToPath, setReturnToPath] = React.useState('/');
  const [continuePath, setContinuePath] = React.useState('/');
  
  React.useEffect(() => {
    // Set paths from router state/props
    const { returnToPath:backPath, continuePath:fwdPath } = location?.state || {};
    if ( backPath ) {
      setReturnToPath(backPath);
    }  
    if ( isCheckingOut ) {
      setContinuePath(CHECKOUT_PATH);
    } else if ( fwdPath ) {
      setContinuePath(fwdPath);
    }
  }, [location, isCheckingOut, returnToPath, continuePath]);

  const signInWithEmailAndPasswordHandler = React.useCallback((event, email, password) => {
    event && event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          trackEvent('signin_success');
          if (errorRef.current) {
            trackEvent('signin_failure_recovery');
          }
          // signed in user
          // const user = userCredential.user;
          navigateTop(continuePath, { replace: true });
        }).catch((error) => {
          trackError('signin_failure');
          // the Firebase error is not great, use this:
          setError('Invalid email or password');
          errorRef.current = true;
          // cont errorCode = error.code;
        });
    },[continuePath, trackError, trackEvent]);
    
  const onChange = event => {
    const {id, value} = event.currentTarget;
    setError(false);
    if(id === 'loginEmail') {
      setEmail(value);
    } else if(id === 'loginPassword'){
      setPassword(value);
    }
  };

  return (
    <BodyWrapper pageLayout={LayoutTypes.Default}>
      <PageTitle title="Log In" />
      {/* toggle between Log In and forgot password */}
      { showPasswordReset
        ? <SendResetEmailForm handleCancel={() => setShowPasswordReset(false)} />
        : <div className={styles.signInForm}>
            
            <BackLink backToText="Back" backToPath={returnToPath} />
          
            <Header size={HeaderSize.Large} isCentered withStyle={{marginTop:'1rem'}} text={SIGN_IN_TEXT} />

            { error && 
              <ErrorMessage text={error} />
            }
            
            <div className={styles.loginForm}>
              <TextField id="loginEmail" value={email} label="Email" error={false} 
                type="email" variant="filled"  onChange={onChange} />
              <div className={styles.passwordWrap}>
                <TextField id="loginPassword" value={password} label="Password" error={false}
                  type={showPassword ? 'text' : 'password'} variant="filled" autoComplete="current-password"
                  onChange={onChange} />
                <VisibilityButton
                  withClass={styles.showPassword} 
                  visible={showPassword} 
                  onClick={() => setShowPassword(!showPassword)} />
              </div>  

              <div className={styles.loginForgotPassword}>
                <Grid container alignItems="baseline" justifyContent="space-between">  
                  <ActionLink text="Forgot password?" handleClick={() => setShowPasswordReset(true)} />
                  <span>
                    <ActionLink 
                      text="Log in with Phone" 
                      handleClick={() => {
                        navigateTopWithState('/signInPhone', {
                          continuePath,
                          returnToPath: '/signIn',
                          priorNavigationState: location?.state
                        });
                      }
                    } />
                  </span>
                </Grid>
              </div>
      
              <Button
                text={SIGN_IN_TEXT}
                withStyle={{width:'100%', fontSize:'1.25rem', padding:'1rem', marginBottom:'1.5rem'}}
                handleClick = {(event) => {
                  signInWithEmailAndPasswordHandler(event, email, password);
                }} />
            </div>
              
            <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={12} sm={8}>
                <div className={styles.signUpLink}>Don't have a Zyp Run account?</div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className={styles.btnWrap}>
                  <DetourButton
                    text="Sign Up"
                    handleClick={() => navigateTop('/signUp', { 
                      state: { 
                        returnToPath: '/signIn',
                        continuePath  // e.g. /checkOut
                      }
                    })} />
                </div>
              </Grid>
            </Grid>
          </div>
      }  
    </BodyWrapper>
  );  
};

export default SignIn;
