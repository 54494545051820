import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import useBrandPartnerData from './useBrandPartnerData';
import BrandProductsProvider from './BrandProductsProvider';
import FilteredProductsProvider from '../products/FilteredProductsProvider';
import BrandProducts from './BrandProducts';
import Spinner from '../common/Spinner';
import { PropTypes } from 'prop-types';

const BrandProductsPage = ({
  brand, 
  category, 
  subCategory,
  location
}) => {
  
  // For return to brand page etc. on Category Filter back link
  const [backLink, setBackLink] = React.useState();
  React.useEffect(() => {
    const { returnToPath } = location?.state || {};
    if (returnToPath) {
      setBackLink(returnToPath);
    }
  }, [location])

  const { data } = useBrandPartnerData(brand);
  // Use the proper display name: "Berkshire Roots"
  const { 
    name:displayName, 
    default_category,
    error: no_partner_data_error } = data || {};

  // From path or fallback to brand default
  const [initialCategory, setInitialCategory] = React.useState();
  
  React.useEffect(() => {
    if(category && category !== initialCategory) {
      setInitialCategory(category);
    } else if (!initialCategory && default_category) {
      setInitialCategory(default_category);
    } else if (no_partner_data_error) {
      navigateTop('/pageNotFound', {
        state: {
          referrer: `${window.location.pathname}` 
        },
        replace: true
      });  
    }
  }, [initialCategory, category, default_category, no_partner_data_error])

  return ( displayName && initialCategory
    ? <BrandProductsProvider brandName={displayName}>
        <FilteredProductsProvider brandName={displayName}>
          <BrandProducts
            brand={displayName} 
            category={initialCategory}
            subCategory={subCategory}
            backLink={backLink} />
        </FilteredProductsProvider> 
      </BrandProductsProvider>
    : <Spinner />
  )
}

BrandProducts.propTypes = {
  brand: PropTypes.string, /* via router */
  category: PropTypes.string, /* via router */
  subCategory: PropTypes.string, /* via router */
  location: PropTypes.object /* via router */
}

export default BrandProductsPage;
