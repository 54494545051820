import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import DisplayModal from '../common/DisplayModal';
import Header, { HeaderSize } from '../styleguide/Header';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

const BlockedAddressModal = ({ modalProps }) => {
  
  const { currentLocation:location } = React.useContext(UserContext);
  const { trackContext } = modalProps;
  
  return (
    <DisplayModal title="Restricted Address"
                  trackContext={trackContext}>
      <div>
        <Header isCentered size={HeaderSize.Medium_Static} withStyle={{marginBottom:'.5rem'}}>
          Restricted Address    
        </Header>                   
        <Alert severity='warning' style={{margin:'1rem 0'}}>
          Sorry, but it looks like your address: <b>{location.street_address}</b> in {location.city}, 
          is in a restricted zone (college campus, public housing, etc.) and we cannot 
          legally deliver to this address.  
        </Alert>
      </div>
    </DisplayModal>
  );
};

BlockedAddressModal.propTypes = {
  modalProps: PropTypes.object.isRequired 
}

export default BlockedAddressModal;
