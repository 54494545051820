import { trackError, trackEvent } from '../analytics/tracking';

// Data we request from the Places API, skipping "formatted_address"
export const GOOGLE_PLACES_REQUESTED_DATA = ["address_components", "geometry"]

// The global map instance
let map, geocoder;
export const destroyMap = () => {
  map = null;
};

/**
 * Create a full-function google map centered on the location
 *
 * @param {string} elemId - the target div element id
 * @param {number} zoom - map zoom level
 * @param {string} formattedAddress - address, e.g. 123 Main Street, Boston...
 */
export const initSingleLocationMap = (elemId, zoom, formattedAddress) => {
  // We're centering on map pin so no center param needed
  const options = {
    zoom,
    mapTypeControl: false,
    streetViewControl: false,
    scaleControl: true,
    mapId: elemId,
  };
  // Retrieve the address lat/long
  geocoder = new window.google.maps.Geocoder();
  // Configure map
  codeAddress(geocoder, map);

  function codeAddress(geocoder, map) {
    geocoder.geocode({'address':formattedAddress}, function(results, status) {
      if (status === 'OK') {
        const elem = document.getElementById(elemId);
        if (elem) {
          map = new window.google.maps.Map(
            elem,
            {
              ...options,
              center: results[0].geometry.location,
            },
          );
          // The advanced marker, positioned
          const pinBackground = new window.google.maps.marker.PinElement({
            glyphColor: '#fff',
            background: '#000',
            borderColor: '#000',
            scale: 1.25,
          });
          /* eslint-disable-next-line no-unused-vars */
          const marker = new window.google.maps.marker.AdvancedMarkerElement({
            map,
            position: results[0].geometry.location,
            title: formattedAddress,
            content: pinBackground.element,
          });
        }
      } else {
        // return error image?
        trackError(`map_load_failure_${status}`);
      }
    });
  }
};

/**
 * The parseGooglePlace method was pulled from the parse-google-place package
 * which is not a proper React package
 */
export const parseGooglePlace = (place) => {
  place = place || {};

  // map place by type
  const byType = (place.address_components || []).reduce(function (acc, data) {
    data.types.forEach(function (type) {
      acc[type] = data;
    })
    return acc;
  }, {});

  const result = {
    streetNumber: placeGet('street_number'),
    streetName: placeGet('route'),
    city: placeGet('locality') ||
      placeGet('sublocality') ||
      placeGet('sublocality_level_1') ||
      placeGet('neighborhood') ||
      placeGet('administrative_area_level_3') ||
      placeGet('administrative_area_level_2'),
    county: placeGet('administrative_area_level_2'),
    stateShort: placeGet('administrative_area_level_1', true),
    stateLong: placeGet('administrative_area_level_1'),
    countryShort: placeGet('country', true),
    countryLong: placeGet('country'),
    zipCode: placeGet('postal_code')
  };

  // remove null/undefined
  result.address = [
    result.streetNumber,
    result.streetName
  ].filter(Boolean).join(' ');

  return result;

  // hoisted function - prefer short_name
  function placeGet(key, short) {
    if (!(key in byType)) return '';
    return short ? byType[key].short_name : byType[key].long_name;
  };
};

// Create a street address string from a Zyp Run location object
export const getFormattedAddress = (location) => {
  if (location && location.street_address) {
    const { street_address, apartment_number, city, state, zip_code } = location;
    const apartment = apartment_number ? ` ${apartment_number}`:'';
    return `${street_address}${apartment}, ${city}, ${state}, ${zip_code}`;
  }
  return '';
};

// The global autoComplete instance
let autoComplete;
/**
 * Destroy the autoComplete on component unmount
 */
export const destroyAutoComplete = () => {
  autoComplete = null;
};

/**
 * Configure the Google Places autocomplete for US address lookup
 *
 * Make sure instance is destroyed on component unmount
 *
 * @param {ref} inputRef - Reference to meterial UI TextField
 * @param {function} onInputChange - on input chage
 * @param {function} onSelection - on autocomplete address selection
 */
export const configureAutoComplete = (inputRef, onInputChange, onSelection) => {

  // Assign autoComplete with Google maps place one time
  // If this happens more than once per the getPlace() call will return undefined
  autoComplete = new window.google.maps.places.Autocomplete(
    inputRef.current,
    { types: [("geocode")], componentRestrictions: { country: "us" } }
  );
  // specify what properties we will get from API: address
  autoComplete.setFields(GOOGLE_PLACES_REQUESTED_DATA);
  // add a listener to handle when the place is selected
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(onInputChange, onSelection)
  );
};

/**
 * Handle user selection of the autocomplete suggestion
 *
 * and parse out the various address components
 *
 * @param {function} onInputChange - on input chage
 * @param {function} onSelection - on autocomplete address selection
 */
async function handlePlaceSelect(onInputChange, onSelection) {
  if (autoComplete) {
    // Get place object from the google api
    const addressObject = autoComplete.getPlace();
    // Parse the fields we need: street address, etc.
    if (onSelection) {
      const usableLoc = parseGooglePlace(addressObject);
      // Don't update the input unless user has made a typeahead selection
      if (usableLoc.address) {
        // Append latitude/longitude data
        if (addressObject.geometry?.location) {
          const { lat, lng } = addressObject.geometry.location;
          if (lat && lng) {
            usableLoc.geo_coordinates = {
              latitude: lat(),
              longitude: lng()
            };
          }
        }
        onInputChange(`${usableLoc.address}, ${usableLoc.city}, ${usableLoc.stateShort} ${usableLoc.zipCode}`);
        onSelection(usableLoc);
        trackEvent('autocomplete_address_select');
      }
    }
  }
};
