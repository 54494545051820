import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import { navigateTop, navigateTopWithState } from '../routing/router-utils';
import { makeStyles } from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  backLink: {
    marginTop: '2rem',
    display: 'inline-flex', 
    color: '#000',
    fontSize: '1rem',
    cursor:'pointer' 
  },
  headerLink: {
    display: 'flex', 
    color: '#000',
    fontSize: '1rem',
    cursor:'pointer',
    marginBottom: '1.25rem',
    [theme.breakpoints.up('md')]: {
      display: 'inline-flex',
      marginBottom: 0,
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
    },
  }, 
  linkText: {
    lineHeight: '24px',
    font: 'var(--zrFontSemi)', 
    marginLeft: '.5rem'
  }
}));

const BackLink = ({
  backToPath,
  priorNavigationState,
  backToText='Back',
  inHeader,
  withClass,
  onClick,
  trackLabel,
}) => {

  const classes = useStyles();

  const backClick = (event) => {
    event && event.stopPropagation();
    if (trackLabel) {
      trackEvent(`back_link_${trackLabel}_click`);
    }
    if (typeof onClick === "function") {
      onClick();
    } else if (backToPath) {
      if (backToPath !== window.location.pathname) {
        if (priorNavigationState) {
          navigateTopWithState(backToPath, priorNavigationState);
        } else { 
          navigateTop(backToPath)        
        }
      } else {
        // Home page
        navigateTop("/");
      }
    }
  };

  return (
    inHeader
    ? <div className={`${classes.headerLink} ${withClass ? withClass : ''}`} onClick={backClick}>
        <ArrowBackRoundedIcon />
        <div className={classes.linkText}>{backToText}</div>
      </div> 
    : <div className={`${classes.backLink} ${withClass ? withClass : ''}`} onClick={backClick}>
        <ArrowBackRoundedIcon />
        <div className={classes.linkText}>{backToText}</div>
      </div>  
  );
};

BackLink.propTypes = {
  backToPath: PropTypes.string, // location is delayed
  priorNavigationState: PropTypes.object, // restore prior continuePath, etc.
  backToText: PropTypes.string,
  inHeader: PropTypes.bool,
  withClass: PropTypes.string,
  onClick: PropTypes.func,
  trackLabel: PropTypes.string,
};

export default BackLink;
