import * as React from 'react';

import useNewProductsByCategory from './useNewProductsByCategory';
import ProductCarousel from '../home/ProductCarousel';
import Spinner from '../common/Spinner';

import PropTypes from 'prop-types';

/**
 * Currently we have hard-coded brands by product category
 * in useNewProductsByCategory.js
 * 
 * TODO: Click tracking
 */
const NewProductsCarousel = ({
  categoryName
}) => {

  const { newProducts } = useNewProductsByCategory(categoryName);

  /**
   * Using categoryName is NOT enough here, we need a more distinct key
   * or the carousel will not re-render
   */
  const [carouselKey, setCarouselKey] = React.useState();
  React.useEffect(() => {
    if (newProducts?.length) {
      setCarouselKey(`${categoryName}_${newProducts[0].id}`);   
    }
  }, [categoryName, newProducts])

  return ( newProducts?.length > 0
    ? <ProductCarousel
        key={carouselKey}
        carouselTitle={`${categoryName}: New Products`}
        /* superset count */
        categoryCount={newProducts.length}
        carouselProducts={newProducts} 
        seeAllClick={ null /* for new products */}
        isSponsored // special color wrapper
      />
    : newProducts?.length === 0
      ? null
      : <Spinner />
  )
}

NewProductsCarousel.propTypes = {
  categoryName: PropTypes.string.isRequired
};

export default NewProductsCarousel;
