import * as React from "react";

import { makeStyles } from "@material-ui/core";
import Warning from "../styleguide/Warning";
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  readOnly: {
    borderColor: '#ccc',
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'solid',
    padding: '7px 12px 12px',
    marginTop: '1rem',
    '&legend': {
      font: 'var(--zrFontSemi)',
      letterSpacing: '.25px',
      fontSize: '.875rem',
      color: '#595959',
      paddingBottom: 4,
    },
  },
  alert: {
    margin: '0 0 12px !important',
  },
  statusNote: {
    fontSize: '.75rem',
    padding: '0 1rem'
  },
  smsNumber: {
    font: 'var(--zrFontSemi)',
    fontSize: '.75rem',
    textDecoration: 'none',
    color: 'var(--zrPrimaryDark)',
  }
});

/**
 * Update email/message preferences
 */
const OrderNotifications = ({
  deliveryTextEnabled, /* current state from API - Read Only */
}) => {

  const classes = useStyles();

  return (
    <fieldset className={classes.readOnly}>
      <legend>DELIVERY NOTIFICATIONS</legend>
      { deliveryTextEnabled
        ? <Warning 
            severity="info" 
            withClass={classes.alert}
            text="Delivery updates via text message are currently ENABLED" />
        : <Warning 
            severity="warning" 
            withClass={classes.alert}
            text="Delivery updates via text message are currently DISABLED" />
      }
      <div className={classes.statusNote}>
        Delivery updates via text can be DISABLED/<wbr/>ENABLED by 
        replying STOP/START in the message thread. Delivery notifications are 
        currently sent from:
        {' '}
        <a className={classes.smsNumber} href="sms:+18572142563">(857) 214-2563</a>
      </div>
    </fieldset>   
  );
}

OrderNotifications.propTypes = {
  deliveryTextEnabled: PropTypes.bool,
};

export default OrderNotifications;
