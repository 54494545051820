import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { SERVICE_AREA_CITIES } from '../dispensary/DispensaryProvider';
import { ModalType } from './ModalProvider';
import DisplayModal from '../common/DisplayModal';
import Header, { HeaderSize } from '../styleguide/Header';
import ErrorIcon from '@material-ui/icons/ErrorOutlineRounded';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  modalInner: {
    width: 450,
    maxWidth: '100%',
    margin: '0 .5rem 0.75rem'
  },
  warningMessage: {
    margin: '16px 0'
  },
  buttonWrap: {
    textAlign: 'center',
    margin: '12px 0'
  },
  continueLink: {
    cursor: 'pointer',
    font: 'var(--zrFontBold)',
    color: 'var(--zrPrimaryDark)'
  },
  footnote: {
    margin: '8px 0',
    fontSize: '.75rem',
    fontStyle: 'italic'
  }
});

const ServicePausedModal = ({ displayModal, modalProps }) => {
  
  const { currentLocation } = React.useContext(UserContext);
  const { trackContext, continueModal } = modalProps;
  const classes = useStyles(); 

  const closeModal = () => {
    displayModal(ModalType.NONE);
    if (ModalType.PRODUCT === continueModal) {
      displayModal(ModalType.PRODUCT, modalProps);
    }
  };
  
  return (
    <DisplayModal title="Service Paused"
                  trackContext={trackContext}
                  isShrinkToFit 
                  closeFn={closeModal}>
      
      <div className={classes.modalInner}>
        <Header isCentered size={HeaderSize.Small}>
          <ErrorIcon style={{verticalAlign:-4}} /> Service Currently Unavailable
        </Header>

        { currentLocation?.zip_code && 
          <div className={classes.warningMessage}>
            Dang! while we normally offer speedy delivery to your area ({currentLocation.zip_code}), 
            we're currently sold out or otherwise unavailable to provide delivery 
            service to you today. Please check back tomorrow!
          </div>
        }
 
        <div className={classes.buttonWrap}>
          <span className={classes.continueLink}
                onClick={closeModal}>
            Continue Browsing
          </span>
        </div>

        <div className={classes.footnote}>
          Zyp Run Service Area: { SERVICE_AREA_CITIES.join(', ') }
        </div>
      </div>
    </DisplayModal>
  );
};

ServicePausedModal.propTypes = {
  displayModal: PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired 
}

export default ServicePausedModal;
