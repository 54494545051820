import * as React from 'react';

import { dollarDisplay } from '../providers/OrderPricingProvider';
import styles from './ProductPreviewCard.module.css';
import dealStyles from '../deals/SuperDeal.module.css'
;
const calcPctOff = (origPrice, salePrice) => {
  return Math.floor(((origPrice - salePrice)/origPrice) * 100);
};

export const SuperDealText = () => (
  <>
    <span className={dealStyles.emoji} role="img" aria-label="smile">🤑</span>
      Super Deal!
    <span className={dealStyles.emoji} role="img" aria-label="smile">🤑</span>
  </>
)

export const isSuperDeal = (product) => {
  return product.is_discount_code_restricted;
  // return product.display_info.name.indexOf('Colada') > -1;
}

export const LABEL_TYPE = {
  LIMIT: 'limit',
  BUNDLE: 'bundle',
  SUPER: 'super',
  SALE: 'sale'
}

/**
 *  25% Off sale label for product cards
 *  NOTE: % must be divisible by 5 or we fall back to
 *  "dollar discount" display.
 *
 *  These mutually exclusive additions trump the original 25% Off messaging
 *  1) Support bundled pricing
 *  2) Support "Limit 1" (per_order_max_quantity)
 *  3) Support "Super Deals" - Cannot combine with dscount codes
 */
const useSaleLabelDetails = (product) => {

  const {
    display_info,
    discount_data,
    bundle_deal_data,
    per_order_max_quantity,
  } = product;

  const {
    cost_usa_cents,
  } = display_info;

  const [saleLabelType, setSaleLabelType] = React.useState(LABEL_TYPE.SALE);
  const [saleLabel, setSaleLabel] = React.useState();

  React.useEffect(() => {
    // Determine variant
    if (bundle_deal_data) {
      setSaleLabelType(LABEL_TYPE.BUNDLE);
    } else if (isSuperDeal(product)) {
      setSaleLabelType(LABEL_TYPE.SUPER);
    } else if (per_order_max_quantity) {
      setSaleLabelType(LABEL_TYPE.LIMIT);
    }
    // Calc possible/fallback sale price
    const { cost_usa_cents_discounted } = discount_data || {};
    if (cost_usa_cents_discounted) {
      const pct = calcPctOff(cost_usa_cents, cost_usa_cents_discounted);
      if (pct % 5 === 0) {
        setSaleLabel(`${pct}% Off!`);
      } else {
        const dollarAmt = dollarDisplay(cost_usa_cents - cost_usa_cents_discounted, true);
        setSaleLabel(`${dollarAmt} Off!`);
      }
    }
  },[product, cost_usa_cents, bundle_deal_data, per_order_max_quantity, discount_data]);

  switch(saleLabelType) {
    case(LABEL_TYPE.BUNDLE):
      return {
        saleLabel: 'Bulk Discounts!',
        saleLabelType,
        saleLabelClass: styles.qtyDiscount,
        withTooltip: true,
      }
    case(LABEL_TYPE.SUPER):
      return {
        saleLabel: (<SuperDealText />),
        saleLabelType: LABEL_TYPE.SUPER,
        saleLabelClass: styles.superDeal,
        withTooltip: true,
      }
    case(LABEL_TYPE.LIMIT):
      return {
        saleLabel: `Limit ${per_order_max_quantity}`,
        saleLabelType: LABEL_TYPE.LIMIT,
        saleLabelClass: styles.qtyLimit,
        withTooltip: false,
      }
    default:
      return {
        saleLabel,
        saleLabelType:LABEL_TYPE.SALE,
        saleLabelClass: '',
        withTooltip: false,
     }
  }
}

export default useSaleLabelDetails;
