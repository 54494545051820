import * as React from 'react';

import Header, { HeaderSize } from '../styleguide/Header';

// Basic promo styles
const linkStyle = { textDecoration:'underline', cursor:'pointer', fontWeight:'bold' };
const orderedListStyle = {listStyle:'decimal', paddingLeft: '1.25rem', marginBottom:'1.25rem'};
const listItemStyle = { marginBottom:'.25rem' };

// All parent elements have children and possibly grandchildren
const getChildNodes = (element, column) => {
  const nodeArray = element.children.length
    ? element.children
    : [ element.children ];

  // All this is just to generate a unique node key
  const parentTextNode = nodeArray.find(node => node.text) || {};
  const allChildren = nodeArray.reduce((childNodes, node) => {
    if(node.children) {
      childNodes.push(node.children);
    }
    return childNodes;
  }, []);
  const childTextNode = allChildren.flat().find(node => node.text) || {};
  const textKey = parentTextNode.text || childTextNode.text;
  const nodeKey = `${column}-${element.type}-${textKey}`;

  switch (element.type) {
    case 'numbered-list':
    case 'bulleted-list':
      return nodeArray.map((node,idx) => {
        return <li key={`${nodeKey}-${idx}`} style={listItemStyle}><InlineContent nodes={node.children} /></li>
      });
    case 'link':
      return nodeArray.map((node,idx) => {
        return <a key={`${nodeKey}-${idx}`} style={linkStyle} href={element.href}><InlineContent nodes={node.children} /></a>
      });        
    default:
      return <InlineContent key={`${nodeKey}`} nodes={nodeArray} />
  }
};

// Get styles for the final leaf/deep node (span)
const getNodeStyles = (node) => {
  const nodeStyles = {};
    if (node.bold) {
      nodeStyles.fontWeight = 'bold';
    }
    if (node.italic) {
      nodeStyles.fontStyle = 'italic';
    }
    if (node.color) {
      nodeStyles.color = node.color;
    }
    return nodeStyles;
};

/**
 * Handle top-level elements (headers, lists, paragraphs)
 * @param {object} element
 * @param {string} column - used in keys
 */
const BlockElement = ({
  element, column
}) => {
  
  const childNodes = getChildNodes(element, column);

  const unorderedListStyle = { listStyle:'none', marginBottom:'1.25rem' }

  switch (element.type) {
    case 'heading-one':
      return <Header size={HeaderSize.Large}>{childNodes}</Header>;
    case 'heading-two':
      return <Header size={HeaderSize.Medium}>{childNodes}</Header>;
    case 'heading-three':
      return <Header size={HeaderSize.Small}>{childNodes}</Header>;
    case 'bulleted-list':
      return <ul style={unorderedListStyle}>{childNodes}</ul>;
    case 'numbered-list':
      return <ol style={orderedListStyle}>{childNodes}</ol>;
    default:
      return <p>{childNodes}</p>;
  }
};

/**
 * Generally inline content with font/color styling
 * 
 * @param {*} nodes 
 * @returns 
 */
const InlineContent = ({
  nodes
}) => {
    return ( nodes.map((node, idx) => {
    // Ignore empty text nodes
    if (node.text !== undefined && node.text.length === 0) {
      return null; // Returning <></> will create key errors  
    }
    
    const leafStyles = getNodeStyles(node);
    
    return node.type === 'link'
      ? <InlineLink key={`child-${idx}`} node={node} />
      : <span key={`child-${idx}`} style={leafStyles}>{node.text}</span>
    }))
};

/**
 * Render child node link ( link inside li, h1, etc.) 
 * 
 * @param {*} nodes 
 * @returns 
 */
const InlineLink = ({
  node
}) => {
  const leafStyles = getNodeStyles(node);
  const validLink = node.children?.length && node.children[0].text?.length;
  return validLink
    ? <a href={node.href} style={Object.assign(linkStyle,leafStyles)}>{node.children[0].text}</a>
    : <></>
};

/**
 * The contentArray will be top-level elements (headers, lists, paragraphs) with child nodes.
 * 
 * NOTE: Text alignment was not implemented in dispensary app editor
 * 
 * @param {array} contentArray 
 */
const PromoTextRenderer = ({contentArray, column}) => {
  return ( contentArray?.length
    ? contentArray.map((parentNode, idx) => {  
        return <BlockElement key={`${parentNode.type}-${column}-${idx}`} element={parentNode} column={column} />
      })
    : null  
  );
}

export default PromoTextRenderer;
