import React from 'react';

import { useAnalyticsStore } from '../../App';
import { STACKING } from '../layout/stackingConstants';
import { CommerceLoggingContext } from './CommerceLoggingProvider';
import { makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/HighlightOffRounded';

const useStyles = makeStyles({
  panel: {
    display: 'flex',
    position: 'fixed',
    bottom: 63,
    right: 0,
    backgroundColor: '#efefefdd',
    padding: '3px 6px 7px',
    borderRadius: 4,
    fontSize: '.8125rem',
    lineHeight: 1.3
  },
  iconWrap: {
    cursor: 'pointer',
    paddingRight: 4
  },
  error: {
    color: 'var(--zrPromoRed)'
  },
  expand: {
    cursor: 'pointer'
  },
  label: {
    fontWeight: 600,
  }
});

/**
 * This component is for DEV only
 * 
 * TODO: Add Intercom logging history
 */
const AnalyticsDebug = () => {

  const { recentEvents, lastError, isTestGroup } = useAnalyticsStore();  
  const { commerceTracking } = React.useContext(CommerceLoggingContext);

  // Show/Hide
  const [show, setShow] = React.useState(false);
  
  const classes = useStyles();

  const selectStyle = {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer'
  };

  const DropDown = ({label, items}) => (
    items.length > 0
      ? <div style={{flex:1}}>
          <b>{label}:&thinsp;</b> 
          { items.length > 1
            ? <select style={selectStyle} value={`${items[0]}_0`} readOnly>
                { items.map((event,idx) => {
                  const uniqueVal = `${event}_${idx}`;
                  return <option key={uniqueVal} value={uniqueVal}>
                           {event}
                         </option>
                })}
              </select>
            : <>{items[0]}</>
          }
        </div>
      : null
  );

  return (
    show
      ? <div className={classes.panel} style={{zIndex:STACKING.ANALYTICS_DEBUG}}>
          <div className={classes.iconWrap} onClick={() => setShow(false)}>
            <CloseIcon style={{cursor:'pointer', fontSize:16}} />
          </div>
          <div>
            {/* TODO: Add eCommerce logging failure */}
            <DropDown label="eCommerce" items={commerceTracking.commerceEvents} />
            <DropDown label="Firebase" items={recentEvents} />
            { lastError &&
              <div className={classes.error}>
                <b>Error:</b> {lastError}
              </div>
            }
            <div>
              <span className={classes.label}>In Test Group:</span> {isTestGroup ? 'true' : 'false'}
            </div>
          </div>
        </div>
      : <div className={`${classes.panel} ${classes.expand}`} onClick={() => setShow(true)}>
          &lsaquo; Analytics
        </div>
  );
};

export default AnalyticsDebug;
