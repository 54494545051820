import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import { trackEvent } from '../analytics/tracking';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { ModalContext } from '../modal/ModalProvider';
import { ReOrderContext } from '../reorder/ReOrderProvider';
import { MAX_MA_FLOWER_GRAMS } from '../checkout/QuantityWarning';
import ReOrderItem from './ReOrderItem';
import QuantityWarning from '../checkout/QuantityWarning';
import Button from '../styleguide/Button';
import ReOrderDeliveryButton from './ReOrderDeliveryButton';
import DetourButton from '../styleguide/DetourButton';
import Spinner from '../common/Spinner';

import styles from './ReOrder.module.css';

/**
 * Display prior order items for use in reordering
 */
const ReOrderDetails = () => {

  const { orderItems } = React.useContext(ReOrderContext);
  const { addItem, flowerGrams, itemTotal } = React.useContext(CartItemsContext);
  const { setTriggerCartOpen } = React.useContext(ModalContext);

  return ( 
    orderItems?.length
      ? <div className={styles.reorderWrap}>
          <ul className={styles.reorderItems}>
            { orderItems.map((item) => {
              return <ReOrderItem
                key={item.id}
                product={item}
                totalFlowerGrams={flowerGrams}
                addToCart={(itemMatch, quantity) => {
                  addItem(itemMatch.id, itemMatch, quantity);
                }} />
            })}
          </ul>
          { itemTotal > 0 &&
            <div className={styles.btnWrap}>
              { flowerGrams > MAX_MA_FLOWER_GRAMS
                ? <Button
                    withStyle={{width:'100%'}} 
                    text="Adjust Cart Quantities" 
                    handleClick={() => setTriggerCartOpen(true)} />
                : <ReOrderDeliveryButton />
              }
              {/* quantity warning based on currently carted items */}
              <QuantityWarning gramsInCart={flowerGrams} />
            </div>
          }
          <div className={styles.btnWrap}>  
            <DetourButton text="Continue Shopping"
              withStyle={{width:'100%'}}
              handleClick={() => {
                navigateTop('/');
                trackEvent('reorder_continue_shop_clk');
              }} />
          </div>
        </div>
      : <Spinner />   
  )
}

export default ReOrderDetails;
