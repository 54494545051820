import * as React from 'react';

import useSoldOutStatus from './useSoldOutStatus';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  ribbonWrap: {
    cursor: 'default',
    position: 'absolute',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
  },
  ribbon: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    maxHeight: '66%', /* ProductCardForGrid */
    maxWidth: '50%',  /* ProductPreviewCard */
  }
})

/**
 * For certain sale items, we display "Sold Out" at quantity=1
 * This ensures the product is prodived to the Shopping App by the API for display
 * purposes
 */
const SoldOutRibbon = ({
  display_sold_out_status, // Via Dutchie tag: "sold-out-at-1"
  quantity_available,
}) => { 

  const classes = useStyles();

  const isSoldOut = useSoldOutStatus(
    quantity_available,
    display_sold_out_status,
  );

  return ( isSoldOut
    ? <div className={classes.ribbonWrap}>
        <img alt="Sold Out!" className={classes.ribbon} src='/img/soldOutRibbon.png' />
      </div>
    : null  
  )
}

SoldOutRibbon.propTypes = {
  display_sold_out_status: PropTypes.bool,
  quantity_available: PropTypes.number,
}

export default SoldOutRibbon;
