import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { NotificationsContext } from '../notifications/NotificationsProvider';
import { navigateTopWithState } from '../routing/router-utils';
import { storeValueStringified, referrerFromDeviceKey } from '../util/storage-utils.jsx';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import Header from '../styleguide/Header';
import PageTitle from '../common/PageTitle.jsx';
import DisplayCreditDetails from './DisplayCreditDetails';
import ReferralLinkInput from './ReferralLinkInput';
import { CREDIT_DETAILS } from './GetReferCreditDetails';
import { SIGN_IN_TEXT } from '../registration/SignIn.jsx';
import Spinner from '../common/Spinner';
import BasicButton from '../styleguide/BasicButton';
import BackLink from '../common/BackLink';
import MoneyBagIcon from '../icons/MoneyBagIcon.jsx';

import styles from './GetReferralLink.module.css';

const GetReferralLink = ({location}) => {
  
  const { user, userName, userPhone, userBirthday, location:userLocation } = React.useContext(UserContext);
  const { activeCredits, activeCreditCount, creditsLoading, refetchCredits } = React.useContext(NotificationsContext);

  // For return to brand page etc. on Category Filter back link
  const [backLink, setBackLink] = React.useState();
  React.useEffect(() => {
    const { returnToPath } = location?.state || {};
    if (returnToPath) {
      setBackLink(returnToPath);
    }
  }, [location])

  // For fraud detection
  React.useEffect(() => {
    if (userName && userLocation) {
      storeValueStringified(referrerFromDeviceKey, {
        userName,
        userPhone,
        userBirthday,
        location: userLocation
      })
    }
  }, [userName, userPhone, userBirthday, userLocation])

  // Money Bag
  const iconStyle = {
    height: 32, 
    verticalAlign: -2
  };

  return (
    <BodyWrapper pageLayout={LayoutTypes.Narrow}>
      <PageTitle title="Referral Credits" />        
      <div className={styles.referralOuter}> 
        <Header isCentered withClass={styles.header}>
          { backLink &&
            <BackLink backToPath={backLink} inHeader />
          }
          <MoneyBagIcon fillColor="var(--zrPrimaryDark)" withStyle={iconStyle}/> 
          {' '}Your Zyp Run Credits
        </Header>

        <div className={styles.referralWrap}>
          <div className={styles.instructions}>
            <span className={styles.semiBold}>Get ${CREDIT_DETAILS.total} in Zyp&nbsp;Run referral&nbsp;credits</span> when friends sign up using your referral link 
            and place their first&nbsp;order!
          </div>
          
          <div className={styles.instructions}>
            Your friends will also receive ${CREDIT_DETAILS.total} in Zyp&nbsp;Run credits (${CREDIT_DETAILS.per_order} off 
            each of their first {CREDIT_DETAILS.order_count}&nbsp;orders).
          </div>

          { activeCreditCount > 0 &&
            <>
              <div className={`${styles.instructions} ${styles.lastInstruct}`}>
                You currently have <span className={styles.semiBold}>
                  <span className={styles.circleNum}>{activeCreditCount}</span> Zyp&nbsp;Run&nbsp;credits!*
                </span>
                <div className={styles.creditRules}>
                  *Credits can be applied to your orders, 1 credit per order.
                </div>
              </div>
              <DisplayCreditDetails 
                activeCredits={activeCredits} 
                loading={creditsLoading} />
              <div className={styles.btnWrap}>
                <BasicButton text="Refresh" handleClick={() => refetchCredits()} />
              </div>
            </>
          }
                    
          { user 
            ? !user.isAnonymous  
              ? <> 
                  <div className={styles.shareHeader}>Share your referral link:</div> 
                  <ReferralLinkInput />
                </>  
              : <>
                  <div className={styles.minorAlert}>
                    You need to
                    <span className={styles.signInLink} onClick={() => { navigateTopWithState('/signIn', {
                      returnToPath: '/refer',
                      continuePath: '/refer' 
                    })}}>{SIGN_IN_TEXT}</span> to share your referral&nbsp;link!
                  </div>
                </>
            : <Spinner />
          }
        </div>
      </div>
    </BodyWrapper> 
  );
};

export default GetReferralLink;
