import { makeStyles } from '@material-ui/core';

export const usePricingDetailsForModalStyles = makeStyles(theme => ({
  grid: {
    display:'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 8,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    }
  },
  tipWrap: {
    order: 2,
    [theme.breakpoints.down('xs')]: {
      order: 1,
      marginTop: -4,
    }
  },
  discountedPrice: {
    color: 'var(--zrTextRed)',
    fontStyle: 'italic',
    fontSize: '115%',
    fontWeight: 600,
    order: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: '115%',
      order: 2,
    }
  },
  /* Super Deal! */
  pricingMessage: {
    fontStyle: 'italic',
    fontSize: '115%',
    fontWeight: 600,
    order: 1,
    [theme.breakpoints.down('xs')]: {
      fontSize: '115%',
      order: 2,
    }
  },
}));

export default usePricingDetailsForModalStyles;
