import * as React from 'react';

import ProductCarousel from '../home/ProductCarousel';
import { CarouselStyle } from '../common/SimpleCarousel';

// Used currently for 420 deals
const SpecialSaleCarousel = ({specialSaleProducts}) => {
  return ( specialSaleProducts?.length
    ? <ProductCarousel
        carouselTitle="Daily Deals"
        categoryCount={specialSaleProducts.length}   // superset count!, not the carousel item count
        carouselProducts={specialSaleProducts}
        allRelatedProducts={specialSaleProducts}     // used for suggested products: modal to modal display 
        carouselStyle={CarouselStyle.PRODUCT_CARDS} />
    : null
  )
}

export default SpecialSaleCarousel;
