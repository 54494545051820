import * as React from 'react';

import { makeStyles } from '@material-ui/core';
import useInterval from '../util/useInterval';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles({
  'wrapper': {
    marginTop: '2rem',  
    backgroundColor: 'var(--zrPrimaryBackground)',
    padding: '1.375rem',
    borderRadius: '.5rem'    
  },
  'label': {
    textAlign: 'center',
    color: '#000',
    font: 'var(--zrFontBold)',
    fontSize: '1.5rem',
    padding: '.5rem',
    margin: '0 0 .5rem'
  },

  '@global': {
    '.MuiLinearProgress-root': {
      height: '.5rem',
      borderRadius: '.25rem'
    },
    '.MuiLinearProgress-root.MuiLinearProgress-colorPrimary': { 
      backgroundColor: '#5e81a555'
    },
    '.MuiLinearProgress-root .MuiLinearProgress-barColorPrimary': {
      borderRadius: '.5rem',   
      backgroundColor: 'var(--zrPrimaryDark)'
    }
  }
});

// Designed for 0 to 100%
const ProgressBar = ({ticks=100}) => {
  
  const classes = useStyles();

  const [value, setValue] = React.useState(0);
  
  useInterval(() => {
    // By 1 last 10 ticks
    if ( value + 10 < ticks ) {
      setValue(value + 10);
    } else if (value < ticks) { 
      setValue(value + 1);
    }
  }, 1500);

  return (  
    <div className={classes.wrapper}>
      <div className={classes.label}>
        OK, Let's get your order processed... {value}%
      </div>
      <LinearProgress variant="determinate" value={value} />
    </div>
  )
}

export default ProgressBar;
