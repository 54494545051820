import * as React from 'react';

import SuperDealTooltip from './SuperDealTooltip';
import { SuperDealText, isSuperDeal } from '../products/useSaleLabelDetails';
import styles from './SuperDeal.module.css';

import PropTypes from 'prop-types';

/**
 * Display bulk price teaser and tooltip ( used in ProductCardForGrid )
 */
const SuperDealDetails = ({
  product,
  inModal,
}) => {

  return ( isSuperDeal(product)
    ? <div className={styles.detailsGrid}>
        <div className={styles.dealLabel}>
          <SuperDealText />
        </div>
        <div>
          <SuperDealTooltip
            product={product}
            withStyle={{marginTop:0}}
            inModal={inModal} />
        </div>
      </div>
    : null
  )
}

SuperDealDetails.propTypes = {
  product: PropTypes.object.isRequired,
  inModal: PropTypes.bool
}

export default  SuperDealDetails;
