import * as React from 'react';

import config from '../../config';
import { UserContext } from '../providers/UserProvider';
import { useAnalyticsStore } from '../../App';
import { AttributionContext } from '../providers/AttributionProvider';
import SuccessMessage from '../styleguide/SuccessMessage';
import ErrorMessage from '../styleguide/ErrorMessage';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 400,
    opacity: 1,
    maxWidth: '95vw',
    margin: '.5rem auto 0',
    textAlign: 'center',
    padding: 4,
    borderRadius: 4,
    backgroundColor: 'var(--zrLightBackground)',
    transition: 'opacity .5s ease-in-out',
  },
  errorWrapper: {
    backgroundColor: '#fdecea',
  },
  msg: {
    flex: 1,
  },
  remove: {
    flex: 0
  }
});

// Close icon
const closeValidStyle = {
  cursor: 'pointer',
  borderRadius: '50%',
  verticalAlign: -4,
  fontSize: 24, 
  color: 'var(--zrPrimaryDark)',
  backgroundColor: '#fff',
};

// Close icon
const closeErrorStyle = {
  cursor: 'pointer',
  borderRadius: '50%',
  verticalAlign: -4,
  fontSize: 24, 
  color: '#850000',
  backgroundColor: '#fff',
};

/**
 * Validate Discount Code and display 
 */
const DiscountCodeFromUrlMsg = () => {

  const classes = useStyles();
  const { user } = React.useContext(UserContext);
  const { trackEvent } = useAnalyticsStore();
  const [validCode, setValidCode] = React.useState();
  const [error, setError] = React.useState();
  const [displayMsg, setDisplayMsg] = React.useState();
  const { siteLinkDiscountCode:discountCode } = React.useContext(AttributionContext);

  const msgRef = React.useRef();

  // Fade and remove message after 10s
  const hideMessage = () => {
    window.setTimeout(() => {
       if (msgRef.current) {
        msgRef.current.style.opacity = 0;
        window.setTimeout(() => {
          setDisplayMsg(false);
        }, 600);
       }   
    }, 10000);
  }

  // codeFromContext is a code via site link: ?zrCode=FOO20
  const validateDiscount = React.useCallback((codeFromContext) => {
    if (user && codeFromContext) {
      const code = codeFromContext.toLocaleUpperCase();
      const controller = new AbortController();
      user.getIdToken().then(idToken => {
        fetch(`${config.REACT_APP_API_DOMAIN}/api/v1/order/discount_code/value?discount_code=${code}`, 
          { 
            method: 'GET',
            headers: {
              'Authorization': idToken
            },
            Accept: 'application/json',
            signal: controller.signal
          })
        .then(response => response.json())
        .then(discountObj => {
          if (discountObj.code) {
            setValidCode(discountObj.code);
            setDisplayMsg(true);
            hideMessage();
            trackEvent(`promo_code_url_${discountObj.code}`);
          } else if (discountObj.error) {
            setError(discountObj.error);
            setDisplayMsg(true);
            hideMessage();
          }
        })
        .catch(err => {
          setError(`Error: ${err}`);
        })
      }) 
    }
  }, [user, trackEvent]);

  // Attempt to use discount code from site url
  const siteLinkCodeAttemptRef = React.useRef();
  React.useEffect(() => {
    if (user && discountCode && !siteLinkCodeAttemptRef.current) {
      // Fetch API to validate
      validateDiscount(discountCode);
      siteLinkCodeAttemptRef.current = true;
    }
  }, [user, discountCode, validateDiscount]);

  const msgStyle = {
    paddingLeft: 30,
    textAlign: 'center',    
    margin: 0,
    backgroundColor: 'transparent',
  };

  return ( displayMsg
    ? <div ref={msgRef} className={`${classes.wrapper} ${error? classes.errorWrapper : ''}`}>
        <div className={classes.msg}>
          { validCode 
            ? <SuccessMessage text={`CODE: ${discountCode} applied!`} withStyle={msgStyle} />
            : error
              ? <ErrorMessage text={`CODE: ${discountCode} is not valid.`} withStyle={msgStyle} />
              : null
          }
        </div>
        <div className={classes.remove}> 
          <HighlightOffRoundedIcon 
            style={validCode ? closeValidStyle : closeErrorStyle} 
            onClick={() => setDisplayMsg(false)} />
        </div>
     </div>
    : null
  );
}

export default DiscountCodeFromUrlMsg;
