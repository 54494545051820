import * as React from 'react';

import { ReOrderItemsContext } from './ReOrderItemsProvider';
import ProductCarousel from '../home/ProductCarousel';

const ReOrderCarousel = () => { 

  // Currently stocked items from the last 12 orders
  const { reOrderItems } = React.useContext(ReOrderItemsContext);

  return ( reOrderItems?.length
    ? <ProductCarousel
        carouselTitle="Order Again"
        categoryCount={reOrderItems.length}
        carouselProducts={reOrderItems} 
        isReOrder />
    : <></>   
  )
}

export default ReOrderCarousel;
