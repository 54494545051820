import * as React from 'react';

import Header, { HeaderSize } from '../styleguide/Header';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import zypRunLogo from '../../assets/zyprun_logo_200w.png';

const useStyles = makeStyles({
  wrapper: {
    padding: '1rem 1.25rem',
    backgroundColor: '#fff',
  },
  logoGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  blurb: {
    margin: '12px 0',
    lineHeight: '130%',
    textAlign: 'center',
  },
  confirm:  {
    maxWidth: '75%',
    margin: '1.5rem auto 0',
    textAlign: 'center',
  },
  link: {
    cursor: 'pointer',
    color: 'var(--zrPrimaryDark)',
    font: 'var(--zrFontBold)',
    padding: '0.6125rem 1.75rem',
    borderRadius: '0.25rem',
    whiteSpace: 'nowrap'
  },
  linkBtn: {
    color: '#fff',
    backgroundColor: 'var(--zrPrimaryDark)',
    marginBottom: 4,  
  } 
});

const PartnerAge21Modal = ({
  partnerData, // From useBrandPartnerData (not API)
  on21Click,
  onNot21Click
}) => {

  const classes = useStyles();
  
  const { name="Your dispensary", logo } = partnerData || {};
  
  const hdrStyle = {
    font: 'var(--zrFontBoldItalic)',
    fontSize: 25,
    color: 'var(--zrPrimaryDark)',
    margin: '18px 0 16px',
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.logoGrid}>
        <img alt="" height="42" src={zypRunLogo} />
        { logo &&
          <img alt="" height="52" src={logo} />
        }
      </div>
      <Header size={HeaderSize.Large} isCentered withStyle={hdrStyle}>
        Welcome to Zyp Run!
      </Header> 
      
      <div className={classes.blurb}>
        <b>{name}</b> has partnered with <b>Zyp&nbsp;Run</b> for same-day
        delivery in Greater Boston.
      </div>
      <div className={classes.blurb}>
        7 days a week, <b>Zyp Run</b> offers speedy delivery from our Boston 
        warehouse to your&nbsp;door.
      </div>     
      <div className={classes.confirm}>
        <div className={`${classes.link} ${classes.linkBtn}`} onClick={on21Click}>I AM 21 OR OLDER</div>
        <div className={classes.link} onClick={onNot21Click}>I AM NOT 21 YET</div>
      </div>
    </div>
  )
}

PartnerAge21Modal.propTypes = {
  partnerData: PropTypes.object.isRequired,
  on21Click: PropTypes.func.isRequired,
  onNot21Click: PropTypes.func.isRequired
}

export default  PartnerAge21Modal;
