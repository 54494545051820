import React from 'react';

import { makeStyles } from '@material-ui/core';

import LightbulbIcon from '@material-ui/icons/EmojiObjects';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  tipGrid: {
    font: 'var(--zrFontSemiItalic)',
    fontSize: '.875rem',
    color: 'var(--zrPrimaryDark)',
    display: 'flex',
    columnGap: 2,
    marginTop: 6,
  },  
  tipIcon: {
    flex: 0,
    marginTop: -3,
  },  
  tipText: {
    flex: 1,
  }
});

const CheckoutTip = ({text}) => {
  const classes = useStyles();
  return ( 
    <div className={classes.tipGrid}>
      <div className={classes.tipIcon}>
        <LightbulbIcon style={{color:'var(--zrPrimaryDark)'}} />
      </div>
      <div className={classes.tipText}>
        {text}
      </div>
    </div>
  )
}

CheckoutTip.propTypes = {
  text: PropTypes.string.isRequired
}

export default CheckoutTip;
