import * as React from 'react';

import { productCategoriesEndpoint } from '../../constants/api-endpoints';
// import { UserContext } from '../providers/UserProvider';
import usePublicFetch from '../util/usePublicFetch';

export const ProductCategoryContext = React.createContext();

const ProductCategoryProvider = ({children}) => {

  // const { user } = React.useContext(UserContext);
  const { data:categories } = usePublicFetch(productCategoriesEndpoint);
  const [productCategories, setProductCategories] = React.useState();
  const [defaultCategory, setDefaultCategory] = React.useState();

  // e.g. get category based on path: /Flower
  const getCategoryFromDisplayName = React.useCallback((displayName) => {
    return productCategories?.length
      ? productCategories.find((category) => category.display_name === displayName)
      : undefined
  }, [productCategories]);

  React.useEffect(() => {
    if (categories?.length > 0) {
      // handle missing .sort_order
      categories.forEach((category) => {
        if (typeof category.sort_order !== 'number') {
          category.sort_order = 99;
        }  
      });
      categories.sort((catA, catB) => catA.sort_order - catB.sort_order);
      setDefaultCategory(categories[0]);
      setProductCategories(categories);
    }
  }, [categories]);

  return <ProductCategoryContext.Provider value={{
      productCategories,
      defaultCategory,
      getCategoryFromDisplayName
    }}>
    {children}
  </ProductCategoryContext.Provider>
}

export default ProductCategoryProvider;
