import * as React from "react";

import { auth } from "../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import TextField from '@material-ui/core/TextField';
import { errMsg } from './registration-errors';
import { isValidEmail } from './validation-utils';
import Header from "../styleguide/Header";
import Button from '../styleguide/Button';
import SuccessMessage from "../styleguide/SuccessMessage";
import ErrorMessage from "../styleguide/ErrorMessage";
import PropTypes from 'prop-types'; 

import styles from './PasswordReset.module.css';

/** 
 * Used for the ACCOUNT PAGE modal 
 *
 * TODO: Move to /resetPassword
 */
const PasswordReset = ({userEmail, handleCancel}) => {
  
  const [email, setEmail] = React.useState(userEmail);
  const [emailErr, setEmailErr] = React.useState(false);
  const [emailHasBeenSent, setEmailHasBeenSent] = React.useState(false);
  const [sendEmailError, setSendEmailError] = React.useState();
  
  const sendResetEmail = event => {
    event.preventDefault();
    if(!isValidEmail(email)) {
      setEmailErr(true);
      return;  
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSendEmailError(undefined);
        setEmailHasBeenSent(true);
      })
      .catch((error) => {
        setSendEmailError(`Send Error: ${error.message || 'No details provided.'}`);
        setEmailHasBeenSent(false);
      });
  };

  const handleEmailOnFocus = () => {
    setEmailHasBeenSent(false);
    setSendEmailError(undefined);
    setEmailErr(false);      
  };

  const handleEmailOnBlur = () => {
    setEmailErr(!isValidEmail(email));      
  };

  /**
   * Update email as it's entered
   * @param {event} event 
   */
  const onChange = event => {
    const { name, value:email } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(email);
    } 
  };

  const msgStyle = {
    display: 'block',
    textAlign: 'center',
    padding: '.5rem 0'
  };
 
  return (
    <div className={styles.plainForm}>
      <Header isCentered text="Password Reset" />
      <form action="" className={styles.plainInputs}>
        <TextField id="userEmail" name="userEmail" value={email} label="Your Email" 
                   error={emailErr} helperText={errMsg('email',emailErr)} 
                   disabled
                   onBlur={handleEmailOnBlur}
                   onFocus={handleEmailOnFocus} 
                   variant={'filled'} 
                   onChange={onChange} />
        
        <Button isCentered text="Send me a password reset link" handleClick={sendResetEmail} />

        { emailHasBeenSent &&
          <SuccessMessage withStyle={msgStyle} text="An email has been sent to you!" />
        }
        { sendEmailError &&
          <ErrorMessage text={sendEmailError} />
        }
        { handleCancel &&
          <div className={styles.cancel} onClick={handleCancel}>&larr; Cancel</div>
        }

      </form>
    </div>
  );
};

PasswordReset.propTypes = {
  userEmail: PropTypes.string.isRequired,
  handleCancel: PropTypes.func
};

export default PasswordReset;
