import * as React from 'react';
import { unitSymbolMap } from './LabResults';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  labResults: {
    color: 'var(--zrTextGray)',
    margin: 0,
    padding: 0,  
    listStyle: 'none',
    font: 'var(--zrFontSemi)',
    fontSize: '.75rem',
    lineHeight: '.75rem'    
  },
  labResult: {
    display: 'inline-block',
    margin: '0 2px 0 0',
    lineHeight: 1,
    '&:last-of-type': {
      marginRight: 0
    }
  },
  value: {
    fontSize: '.75rem',
  },
  labResultNotFirst: {
    '& $value': { 
      '&:before':{
        content: '"\u2022"', /* show bullet */
        fontSize: '14px',
        verticalAlign: -1,
        margin: '0 2px 0 0',
      },
    }
  },
  [theme.breakpoints.down('xs')]: {
    labResult: {
      display: 'block',
      marginBottom: 1,
    },
    value: {
      '&:before':{
        display: 'none',  /* hide bullet */
      }
    },
  }
}))

/**
 * The orignal spec for what to show on cards is here:
 * https://app.shortcut.com/zip-run/story/4553/web-app-product-card-testing-information-cards-detail
 *
 * I've added CBN and deviated slightly from the original spec
 */
const LAB_RESULT_SETTINGS = {
  'Flower': {
    displayUnits: 'Percentage',
    thresholds: { THC: 1, CBD: 1, CBN: 1, CBG: 1 }
  },
  'Pre-Rolls': {
    displayUnits: 'Percentage',
    thresholds: { THC: 1, CBD: 1, CBN: 1, CBG: 1 }
  },
  'Concentrates': {
    displayUnits: 'Percentage',
    thresholds: { THC: 1, CBD: 1, CBN: 1, CBG: 1 }
  },
  'Vaporizers': {
    displayUnits: 'Percentage',
    thresholds: { THC: 1, CBD: 1, CBN: 1, CBG: 1 }
  },
  'Edibles': {
    displayUnits: 'Milligrams',
    thresholds: { THC: 1, CBD: 1, CBN: 1, CBG: 1 }
  },
  'Topicals': {
    displayUnits: 'Milligrams',
    thresholds: { THC: 1, CBD: 1, CBN: 1, CBG: 1 }
  },
  'Tinctures': {
    displayUnits: 'Milligrams',
    thresholds: { THC: 1, CBD: 1, CBN: 1, CBG: 1 } /* Originally 10 */
  },
}

const meetsThreshhold = (result, categoryName) => {
  const settings = LAB_RESULT_SETTINGS[categoryName];
  if (settings) {
    return result.labResultUnit === settings.displayUnits &&
           result.value > settings.thresholds[result.labTest];  
  }
  return false;
}

const RESULT_DISPLAY_ORDER = ['THC', 'CBD', 'CBN', 'CBG'];
const RESULT_DISPLAY_MAX = 2;

const LabResultsForCard = ({
  productId,
  categoryName,
  lab_results,
}) => {
  
  const classes = useStyles();

  const [resultsForCard, setResultsForCard] = React.useState();

  React.useEffect(() => {
    const resultsForDisplay = [];
    // THC first, etc.
    RESULT_DISPLAY_ORDER.forEach(enabledTest => {
      const result = lab_results.find(item => item.labTest === enabledTest);
      if (result && meetsThreshhold(result, categoryName)) {
        resultsForDisplay.push(result);
      } 
    })
    setResultsForCard(resultsForDisplay.slice(0,RESULT_DISPLAY_MAX));
  },[lab_results, categoryName])

  return (
    <>
      { resultsForCard?.length > 0
        ? <ol className={classes.labResults} data-count={resultsForCard?.length || 0}>
            { resultsForCard.map((result, idx) => {
              return ( 
                <li className={`${classes.labResult} ${idx > 0 ? classes.labResultNotFirst : ''}`} key={`${productId}-${result.labTest}`}>
                  <div className={classes.value}>
                    {`${result.labTest} ${result.value}${unitSymbolMap.get(result.labResultUnit)}`}
                  </div>
                </li>
              )
            })}
          </ol>  
        : null
      }
    </>
  )
}

LabResultsForCard.propTypes = {
  productId: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  lab_results: PropTypes.array
}

export default  LabResultsForCard;
