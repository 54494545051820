import * as React from "react";

import { trackUserNavigation } from "../analytics/tracking";
import useModal from './useModal';
import AddressPromptModal from './AddressPromptModal';
import BlockedAddressModal from "./BlockedAddressModal";
import CancelOrderModal from './CancelOrderModal';
import NoDeliveryModal from './NoDeliveryModal';
import RegistrationSuccessModal from './RegistrationSuccessModal';
import ServicePausedModal from './ServicePausedModal';
import PartnerInfoModal from './PartnerInfoModal';
import ProductDetailModal from './ProductDetailModal';
import ProductFilterModal from "../productFilters/SubCategoryFilterModal";
import QRCodeModal from "./QRCodeModal";
import VerifyPhoneModal from './VerifyPhoneModal';

export const ModalContext = React.createContext();

export const ModalType = {
  ADDRESS: 'address',
  ADDRESS_BLOCKED: 'blocked_address',
  CANCEL_ORDER: 'cancel_order',
  NO_DELIVERY: 'no_delivery',
  SERVICE_PAUSED: 'service_paused',
  PARTNER_INFO: 'partner_info',
  PRODUCT: 'product',
  PRODUCT_FILTER: 'product_filter',
  REGISTRATION_SUCCESS: 'registration_success',
  SUGGESTED_PRODUCT: 'suggested_product',
  QR_CODE: 'qr_code',
  VERIFY_PHONE: 'verify_phone',
  NONE: 'none'
};

const ModalProvider = ({ children }) => {
  // Convenience method for Opening Header Shoppping Cart
  const [triggerCartOpen, setTriggerCartOpen] = React.useState();

  const { modalType, modalProps, displayModal } = useModal();

  const renderModal = (modalType, modalProps) => {

    // Log when user has navigated beyond first view
    if (modalType !== ModalType.NONE) {
      trackUserNavigation();
    }
    switch(modalType) {
      case ModalType.ADDRESS:
        return <AddressPromptModal
          displayModal={displayModal}
          modalProps={modalProps} />
      case ModalType.ADDRESS_BLOCKED:
        return <BlockedAddressModal
          displayModal={displayModal}
          modalProps={modalProps} />
      case ModalType.PRODUCT:
      case ModalType.SUGGESTED_PRODUCT:
        return <ProductDetailModal
          key={modalProps.itemId}
          displayModal={displayModal}
          modalProps={modalProps} />
      case ModalType.VERIFY_PHONE:
        return <VerifyPhoneModal
          modalProps={modalProps} />
      case ModalType.CANCEL_ORDER:
        return <CancelOrderModal
          displayModal={displayModal}
          modalProps={modalProps} />
      case ModalType.NO_DELIVERY:
        return <NoDeliveryModal
          displayModal={displayModal}
          modalProps={modalProps} />
      case ModalType.REGISTRATION_SUCCESS:
        return <RegistrationSuccessModal
          modalProps={modalProps} />
      case ModalType.SERVICE_PAUSED:
        return <ServicePausedModal
          displayModal={displayModal}
          modalProps={modalProps} />
      case ModalType.PARTNER_INFO:
        return <PartnerInfoModal
          modalProps={modalProps} />
      case ModalType.PRODUCT_FILTER:
        return <ProductFilterModal
          modalProps={modalProps}
          displayModal={displayModal} />
      case ModalType.QR_CODE:
        return <QRCodeModal
          modalProps={modalProps} />
      case ModalType.NONE:
      default:
        return <></>
    }
  }

  return (
    <ModalContext.Provider value={{
      modalType,
      modalProps,
      displayModal,
      triggerCartOpen,
      setTriggerCartOpen,
    }}>
      { modalType === ModalType.NONE
        ? <></>
        : renderModal(modalType, modalProps)
      }
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
