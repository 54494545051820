import React from 'react';

import { DISPENSARY_NAME } from '../dispensary/DispensaryProvider';
import { CartItemsContext } from './CartItemsProvider';
import { OrderPricingContext,
         calcCannabisTaxCents,
         calcTotalTaxCents,
         dollarDisplay } from '../providers/OrderPricingProvider';
import useOrderPricingCalcs from './useOrderPricingCalcs';
import ToolTip from '../common/ToolTip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  detailsGrid: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: '600px',
    margin:'.5rem 0'
  },
  detailLabel: {
    flex: 1,
    flexBasis: '60%',
    paddingBottom: '2px'
  },
  detailValue: {
    flex: 0,
    paddingBottom: '2px'
  },
  totalLabel: {
    font: 'var(--zrFontBold)',
    fontSize: '0.8125rem',
  },
  totalValue: {
    font: 'var(--zrFontBold)',
    fontSize: '0.8125rem',
  }
});

const TaxesTooltip = () => {

  const classes= useStyles();
  // itemTotal needed to force recalc on quantity change
  const { itemTotal, nonCannabisItemTotal } = React.useContext(CartItemsContext);
  const { totalDiscountCents,
          smallOrderFeeApplied,
          serviceFeesAndTaxesDisplay } = useOrderPricingCalcs();

  const {
    cannabisTaxPct,
    nonCannabisSalesTaxPct,
    salesTaxPct,
    serviceFeeCents,
    serviceFeeDisplay,
    smallOrderFeeDisplay,
    smallOrderThresholdCents,
    smallOrderThresholdDisplay
  } = React.useContext(OrderPricingContext);

  const [cannabisTaxDisplay, setCannabisTaxDisplay] = React.useState();
  const [salesTaxDisplay, setSalesTaxDisplay] = React.useState();

  React.useEffect(() => {
    if (typeof cannabisTaxPct === "number" &&
        typeof nonCannabisSalesTaxPct === "number" &&
        typeof salesTaxPct === "number") {

      // Round this one up
      const cannabisTaxCents = calcCannabisTaxCents(
        itemTotal,
        nonCannabisItemTotal,
        totalDiscountCents, /* discount taken from cannabis first */
        cannabisTaxPct);
      setCannabisTaxDisplay(dollarDisplay(cannabisTaxCents));

      // For the total to add up to the penny, we derive the sales tax
      // by subtracting the cannabis tax from the rounded-up total.
      const taxCents = calcTotalTaxCents(
        itemTotal,
        nonCannabisItemTotal,
        totalDiscountCents,
        salesTaxPct,
        nonCannabisSalesTaxPct,
        cannabisTaxPct
      );
      setSalesTaxDisplay(dollarDisplay(taxCents - cannabisTaxCents));
    }
  }, [ itemTotal, nonCannabisItemTotal, salesTaxPct, cannabisTaxPct, serviceFeeCents,
       nonCannabisSalesTaxPct, totalDiscountCents,
       smallOrderThresholdCents]);

  return (
    <ToolTip
      enableFlip
      placement="top-start"
      buttonStyle={{padding:'0 4px', verticalAlign:-5}}
      tipTitle="Fees and Estimated Tax:"
      footerText={`Taxes are set by ${DISPENSARY_NAME} to follow state compliance regulations and
      may include both state and local taxes. Discounts are applied before taxes, and applied to cannabis products first.`}>
      <div className={classes.detailsGrid}>
        <div className={classes.detailLabel}>Service Fee</div>
        <div className={classes.detailValue}>{serviceFeeDisplay}</div>
        { smallOrderFeeApplied > 0 &&
          <>
            <div className={classes.detailLabel}>Small Bag Fee (item total &lt; {smallOrderThresholdDisplay})</div>
            <div className={classes.detailValue}>{smallOrderFeeDisplay}</div>
          </>
        }
        { cannabisTaxDisplay !== "$0.00" &&
          <>
            <div className={classes.detailLabel}>Cannabis Tax</div>
            <div className={classes.detailValue}>{cannabisTaxDisplay}</div>
          </>
        }
       <div className={classes.detailLabel}>Sales Tax</div>
        <div className={classes.detailValue}>{salesTaxDisplay}</div>
        <div className={`${classes.totalLabel} ${classes.detailLabel}`}>Total Fees and Taxes</div>
        <div className={`${classes.totalValue} ${classes.detailValue}`}>{serviceFeesAndTaxesDisplay}</div>
      </div>
    </ToolTip>
  );
}

export default TaxesTooltip
