import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import { trackingString } from '../analytics/tracking';
import { FilteredProductsContext, SHOW_ALL } from '../products/FilteredProductsProvider';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import styles from './ProductInfoFilter.module.css';

/**
 * This component will provide a Mood filter for both Desktop and Mobile layouts
 */ 
const MoodFilter = ({
  startOpen=false,
  inModal=false
}) => {
  
  const { trackEvent } = useAnalyticsStore();

  // Current filters context
  const { mood:selectedMood, subCategoryMoods, setFilters } = React.useContext(FilteredProductsContext);
  // Add the 'all' option 
  const moodsWithAll = [SHOW_ALL, ...subCategoryMoods];
  // Toggle the display
  const [showMoods, setShowMoods] = React.useState(startOpen);

  const toggleShowMoods = () => {
    if (labelRef.current) {
      labelRef.current.setAttribute('data-show-content', !showMoods);
    } 
    setShowMoods(!showMoods);
  };

  const moodClick = (mood) => {
    trackEvent(`filter_mood_${trackingString(mood)}_click`); 
    setFilters({mood});
  };
  
  const labelRef = React.useRef();
  const addlClass = inModal ? styles.inModal : '';

  return ( subCategoryMoods?.length  
    ? <div className={`${styles.productInfoFilter} ${addlClass}`}> 
        <div ref={labelRef} data-show-content={showMoods} className={styles.sectionHdr} onClick={toggleShowMoods}>
          MOOD <ChevronRightRoundedIcon style={{verticalAlign:'-7px'}}/>
        </div>
        { showMoods &&
          <ul className={styles.filterLinks}>
            { moodsWithAll.map(mood => {
              const selectedClass = mood === selectedMood
                ? styles.selected 
                : '';
              const label = mood === SHOW_ALL
                          ? 'Show All'
                          : mood;
              return <li key={mood} 
                         className={`${styles.filterLink} ${selectedClass}`} 
                         onClick={() => {moodClick(mood)}}>
                       {label}
                       <span className={styles.selectedIcon}>
                         <CheckCircleRoundedIcon style={{color: '#054052', verticalAlign:'-6px'}}/>
                       </span>
                     </li>
            })}
          </ul>
        }  
      </div>
    : null 
  );
}

export default MoodFilter;
