import * as React from 'react';

import SuperDealTooltip from './SuperDealTooltip';
import { SuperDealText, isSuperDeal } from '../products/useSaleLabelDetails';
import usePricingDetailsForModalStyles from '../products/usePricingDetailsForModalStyles';
import PropTypes from 'prop-types';

/**
 * Display Super Deal label and tooltip
 */
const SuperDealDetailsForModal = ({
  product,
}) => {

  const classes = usePricingDetailsForModalStyles();

  return (isSuperDeal(product)
    ? <div className={classes.grid}>
        <div className={classes.tipWrap}>
          <SuperDealTooltip
            product={product}
            inModal={true} />
        </div>
        <div className={classes.pricingMessage}>
          <SuperDealText />
        </div>
      </div>
    : null
  )
}

SuperDealDetailsForModal.propTypes = {
  product: PropTypes.object.isRequired,
}

export default  SuperDealDetailsForModal;
