import * as React from 'react';

import Warning from '../styleguide/Warning';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  promote: {
    font: 'var(--zrFontSemi)',
    color: 'var(--zrTextRed)',
  }
});

const AeropayCreditAtCheckout = () => {

  const classes = useStyles();

  return (
    <Warning severity="info" isCompact>
      <b>Aeropay Signup Credit:</b> Get an 
      {' '}<span className={classes.promote}>Additional $10 OFF!</span>
      {' '}this order by linking your checking account now and selecting 
      Aeropay payment.
    </Warning>
  )
}

export default AeropayCreditAtCheckout;
