import * as React from 'react';

import { RegistrationContext } from '../registration/RegistrationProvider';
import { getStoredValueJson, referrerFromDeviceKey } from '../util/storage-utils.jsx';

// Aggressive match: "annemarie" contains "anne" 
const isNameMatch = (name1, name2) => {
  if (!name1 || !name2) {
    return false;
  }
  return name1.length > name2.length
    ? name1.toLocaleLowerCase().indexOf(name2.toLocaleLowerCase()) > -1
    : name2.toLocaleLowerCase().indexOf(name1.toLocaleLowerCase()) > -1;
}

// 12/01/1980 => 12-1-1980
const dateCompareString = (formattedDateString='') => {
  const dateVals = formattedDateString.split('/');
  return dateVals.length > 2
    ? `${parseInt(dateVals[0],10)}-${parseInt(dateVals[1],10)}-${parseInt(dateVals[2],10)}`
    : 'n/a';
}

/**
 * Detect people that are referring themselves for credits by comparing
 * data stored when they copy their referral link (/refer page)
 * 
 * The API will reject credits for any of the following cases:
 * 1) DOB and Address are same
 * 2) DOB and Last name are same
 * 3) Address and First/Last Names are same
 */
const useReferralFraudReducer = () => {

  const { referrerName, name, birthDate, location } = React.useContext(RegistrationContext);

  const [isLikelyFraud, setIsLikelyFraud] = React.useState();
  const [similarFirstName, setSimilarFirstName] = React.useState();
  const [similarLastName, setSimilarLastName] = React.useState();
  const [similarName, setSimilarName] = React.useState();
  const [sameAddress, setSameAddress] = React.useState();
  const [sameDOB, setSameDOB] = React.useState();
  // We have better detection when we have stored address/dob data on device to compare
  const [hasStoredData, setHasStoredData] = React.useState();

  React.useEffect(() => {
    // Check for stored data (includes address, DOB)
    const storedData = getStoredValueJson(referrerFromDeviceKey);
    if (storedData) {
      // Address stored during referral link copy
      const { street_address:stored_street='none', zip_code:stored_zip='none' } = storedData.location || {};
      // Address from in-progress registration
      const { street_address='', zip_code='' } = location || {};
      const addressMatch = `${street_address} ${zip_code}` ===
        `${stored_street} ${stored_zip}`;
      setSameAddress(addressMatch);
  
      const { month, year, day } = storedData.userBirthday || {};
      const signUpDOBdate = dateCompareString(birthDate);
      const dobMatch = signUpDOBdate === `${month}-${day}-${year}`; // 12-1-1980
      setSameDOB(dobMatch);

      setHasStoredData(true);
    }
    // Without device data, we only have the referrer name to work with (from the refer link)
    if (referrerName && name?.length) {
      const referrerNames = referrerName.split(' ');
      const signUpNames = name.split(' ');
      setSimilarFirstName(isNameMatch(referrerNames[0], signUpNames[0]));
      setSimilarLastName(isNameMatch(referrerNames.pop(), signUpNames.pop()));
    }
  }, [referrerName, name, birthDate, location])

  React.useEffect(() => {
    if (hasStoredData) {
      /**
       * Here we're mimicing the back end fraud logic exactly
       * The name matching may be more aggressive here
       */
      // 1) DOB and Address are same
      if (sameDOB && sameAddress) {
        setIsLikelyFraud(true);
      // 2) DOB and Last Name are same
      } else if (sameDOB && similarLastName) {
        setIsLikelyFraud(true);
      // 3) Address and First/Last Names are same
      } else if (sameAddress && similarFirstName && similarLastName) {
        setIsLikelyFraud(true);
      // Important: Unset on reg data change
      } else {
        setIsLikelyFraud(false);
      }          
    } else {
      // Name is all we have to go on
      setIsLikelyFraud(similarFirstName && similarLastName);
    }
    // Name we always have from the referral URL
    // We have extra messaging specifically for same-name
    setSimilarName(similarFirstName && similarLastName);
  }, [hasStoredData, similarFirstName, similarLastName, sameAddress, sameDOB])

  return ({
    similarName,  // Similar first and last
    sameAddress,
    sameDOB,
    isLikelyFraud,
  });
};

export default useReferralFraudReducer;
