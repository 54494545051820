import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import useFetch from '../util/useFetch';
import { productSubCategoriesEndpoint } from '../../constants/api-endpoints';

// This is a sub-category product map key! 
export const ALL_OTHER_PREFIX = 'All Other';

const useProductSubCategories = (category) => {

  const { user } = React.useContext(UserContext);
  const { data } = useFetch(user, productSubCategoriesEndpoint, true);
  
  // We only want the sub-categories for the CURRENT category
  const [productSubCategories, setProductSubCategories] = React.useState([]); 
  React.useEffect(() => {
    if (category && data) {
      // Get the sub-categories for the current category
      const filteredSubCategories = data.filter(subCat => 
        subCat.parent_category_name === category
      );

      // Sort the sub-categories/keys by .sort_order
      // which is set manually in database (for some)
      const sortedSubCategories = filteredSubCategories.sort((a,b) => {
        return (a.sort_order || 999) - (b.sort_order || 999); 
      });
     
      // Add/Handle "All Other ${category}"
      sortedSubCategories.push({
        display_name: `${ALL_OTHER_PREFIX} ${category}`,
        name: `${ALL_OTHER_PREFIX} ${category}`,
        parent_category_name: category
      });

      setProductSubCategories(sortedSubCategories);
    }
  }, [category, data]);

  return productSubCategories;
}

export default useProductSubCategories;
