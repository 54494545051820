import * as React from "react";

import LicenseImageUploader from "../registration/LicenseImageUploader";
import PhotoIdTooltip from '../registration/PhotoIdTooltip';
import photoIDFront from '../../assets/photoIDSmall.png';
import BasicButton from '../styleguide/BasicButton';

import PropTypes from 'prop-types';

import styles from '../registration/PhotoIdInline.module.css';

/**
 *  Update user's photo ID image
 * 
 *  Used on Account page 
 */
const PhotoIDForm = ({
  email, 
  handleSubmit,
  sectionRef,
  msgHandler,
  updateMsg
}) => {

  const [primaryImage, setPrimaryImage] = React.useState();
  
  return (
    <>
      <div className={styles.imageGrid}>
        <div style={{flex:1}}>
          <LicenseImageUploader
            email={email}
            placeholderImage={photoIDFront}
            setPhotoIdImage={setPrimaryImage} />
        </div>
        <div className={styles.disclaimer}>
          <div style={{fontWeight:600, marginBottom:8}}>
            Update your account ID Image&thinsp;<PhotoIdTooltip />
          </div>
          Your Photo ID image will be used for verification
          (by our driver) during your delivery. 
        </div>
      </div>
      { primaryImage && 
        <BasicButton               
          text="Upload Selected ID Image"
          withClass={styles.updateIdButton}
          handleClick={() => {
            handleSubmit({ 
              photo_ids: {
                primary: primaryImage
              }
            }, sectionRef, msgHandler);
        }} />
      }
      {/* success / failure */}
      { updateMsg &&   
        <span className={styles.updateMsg}>{updateMsg}</span> 
      }  
    </>
  )
};  

PhotoIDForm.propTypes = {
  email: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  sectionRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  msgHandler: PropTypes.func.isRequired,
  updateMsg: PropTypes.string
 };

export default PhotoIDForm;
