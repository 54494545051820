import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import { ModalType, ModalContext } from '../modal/ModalProvider';
import { FilteredProductsContext } from '../products/FilteredProductsProvider';
import ProductCardForGrid from '../products/ProductCardForGrid';

import PropTypes from 'prop-types';

/**
 * This component displays the dispensary's filtered product data from the provider.
 * 
 * It is only rendered when we have filteredProducts.length > 0
 *  
 * @param {array} filteredProducts - array containing filtered product subset
 * @param {function} handleClick - The add-to-cart handler
 */ 
const ProductListing = ({
  filteredProducts=[],  // Empty product array handled in parent!
  handleClick
}) => {
 
  const { categoryName } = React.useContext(FilteredProductsContext);
  const { displayModal } = React.useContext(ModalContext);
  const { trackEvent } = useAnalyticsStore(); 

  // TODO: The "No Category Products" message can be moved up to parent
  // as this component is only used when we have category products
  return ( filteredProducts?.length
    ? filteredProducts.map(product => {
        return <ProductCardForGrid
          key={product.id}
          Id={product.id}
          product={product}
          handleDetailClick={() => {
            trackEvent('products_product_card_click');
            displayModal(ModalType.PRODUCT, {
              itemId: product.id,
              title: product.display_info.name,
              categoryName: product.display_info.category.display_name,
              relatedProducts: filteredProducts
            })
          }}
          handleCartClick={(quantity) => {
            handleClick(product.id, product, quantity)
          }} />
      })
    : <div style={{flexBasis:'100%'}}>There are currently no <b>{categoryName}</b> products available.</div>
  );
}

ProductListing.propTypes = {
  filteredProducts: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default ProductListing;
