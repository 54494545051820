import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(props => ({
  icon: {
    padding: '0px 3px 3px;',
    '&:hover path:nth-of-type(2)': {
      fill: (props) => props.hoverFillColor
    }
  }
}));

/* Converted PNG to SVG using Adobe site */
/* Active state handled in FixedFooterNav.module.css */
const OrdersIcon = ({fillColor='var(--zrPrimaryVeryDark)', 
                     hoverFillColor='var(--zrPrimaryDark)',
                     size='22px'}) => {
  const classes = useStyles({ hoverFillColor });
  return (
    <svg className={classes.icon}
         version="1.1" xmlns="http://www.w3.org/2000/svg"
	       width={size} viewBox="0 0 252 308">
      <path fill="transparent" opacity="1.000000" stroke="none"
	      d="M253.000000,119.000000 
        C253.000000,182.666672 253.000000,245.833328 253.000000,309.000000 
        C169.000000,309.000000 85.000000,309.000000 1.000000,309.000000 
        C1.000000,206.333328 1.000000,103.666664 1.000000,1.000000 
        C85.000000,1.000000 169.000000,1.000000 253.000000,1.000000 
        C253.000000,40.166668 253.000000,79.333336 253.000000,119.000000 
        M3.857544,213.499985 
        C3.857616,230.996902 3.949387,248.494507 3.815835,265.990417 
        C3.756509,273.762390 6.446133,279.749268 13.033994,284.216034 
        C20.334019,289.165680 27.291971,294.618835 34.415550,299.829956 
        C42.847687,305.998322 46.706013,305.999847 54.967987,299.861755 
        C62.587700,294.200806 70.172432,288.492493 77.816109,282.864166 
        C84.222717,278.146759 88.580597,278.163452 95.053040,282.890289 
        C102.987236,288.684692 110.853111,294.572815 118.804123,300.343811 
        C124.854851,304.735596 129.691055,304.768250 135.715805,300.409729 
        C143.810806,294.553528 151.804337,288.557312 159.875885,282.668427 
        C165.916656,278.261169 170.284714,278.213165 176.226501,282.678284 
        C185.145630,289.380768 193.934921,296.255890 202.845764,302.969574 
        C208.950562,307.569153 214.192184,307.397552 220.044357,302.507721 
        C227.456680,296.314270 234.712601,289.929199 242.229874,283.867218 
        C247.550980,279.576233 250.159653,274.270264 250.157104,267.461456 
        C250.127716,188.642715 250.200897,109.823891 250.085190,31.005323 
        C250.061508,14.877421 238.586380,3.871868 222.467514,3.866429 
        C158.812622,3.844951 95.157745,3.851295 31.502871,3.882960 
        C29.187756,3.884111 26.809202,3.984072 24.568304,4.503924 
        C11.610087,7.510019 3.872254,17.994907 3.866885,32.533276 
        C3.844733,92.522163 3.857530,152.511078 3.857544,213.499985 
        z" />
      <path fill={fillColor} opacity="1.000000" stroke="none" 
      	d="M3.857512,212.999969 
      	C3.857530,152.511078 3.844733,92.522163 3.866885,32.533276 
      	C3.872254,17.994907 11.610087,7.510019 24.568304,4.503924 
      	C26.809202,3.984072 29.187756,3.884111 31.502871,3.882960 
      	C95.157745,3.851295 158.812622,3.844951 222.467514,3.866429 
      	C238.586380,3.871868 250.061508,14.877421 250.085190,31.005323 
      	C250.200897,109.823891 250.127716,188.642715 250.157104,267.461456 
      	C250.159653,274.270264 247.550980,279.576233 242.229874,283.867218 
      	C234.712601,289.929199 227.456680,296.314270 220.044357,302.507721 
      	C214.192184,307.397552 208.950562,307.569153 202.845764,302.969574 
      	C193.934921,296.255890 185.145630,289.380768 176.226501,282.678284 
      	C170.284714,278.213165 165.916656,278.261169 159.875885,282.668427 
      	C151.804337,288.557312 143.810806,294.553528 135.715805,300.409729 
      	C129.691055,304.768250 124.854851,304.735596 118.804123,300.343811 
      	C110.853111,294.572815 102.987236,288.684692 95.053040,282.890289 
      	C88.580597,278.163452 84.222717,278.146759 77.816109,282.864166 
      	C70.172432,288.492493 62.587700,294.200806 54.967987,299.861755 
      	C46.706013,305.999847 42.847687,305.998322 34.415550,299.829956 
      	C27.291971,294.618835 20.334019,289.165680 13.033994,284.216034 
      	C6.446133,279.749268 3.756509,273.762390 3.815835,265.990417 
      	C3.949387,248.494507 3.857616,230.996902 3.857512,212.999969 
        M176.500000,56.000244 
      	C135.013443,56.000340 93.526886,55.982071 52.040359,56.015579 
      	C43.447811,56.022518 37.189621,61.823277 37.184696,69.559731 
      	C37.179905,77.085381 43.201210,83.073578 51.500565,83.088821 
      	C101.817047,83.181244 152.133865,83.181320 202.450363,83.092766 
      	C210.775436,83.078117 216.753891,77.167557 216.803635,69.600838 
      	C216.854385,61.880699 210.575485,56.072624 201.991943,56.012775 
      	C193.828323,55.955856 185.664017,56.000343 176.500000,56.000244 
        M200.335464,154.999802 
      	C202.645050,154.828369 205.057007,155.024460 207.244675,154.415222 
      	C213.337402,152.718445 217.488785,146.228775 216.660812,140.221298 
      	C215.623337,132.693878 210.176407,128.022232 202.099045,128.016968 
      	C152.111771,127.984329 102.124443,127.986397 52.137150,128.013901 
      	C43.495125,128.018661 37.071606,133.710403 37.239048,141.463516 
      	C37.427898,150.207581 44.602028,155.191345 52.412609,155.145432 
      	C101.398476,154.857361 150.386871,154.999802 200.335464,154.999802 
        M78.500000,227.000885 
      	C90.152931,227.000793 101.806137,227.050003 113.458687,226.983398 
      	C121.305405,226.938538 126.826637,221.578217 127.000008,214.034607 
      	C127.179306,206.233231 121.758148,200.124878 113.900017,200.062851 
      	C92.759636,199.895920 71.616180,199.901184 50.475807,200.070465 
      	C43.018909,200.130173 37.142723,206.444305 37.176899,213.600006 
      	C37.212307,221.013351 43.276566,226.932800 51.031281,226.986542 
      	C59.853798,227.047668 68.677063,227.000809 78.500000,227.000885 
        z"/>
      <path fill="transparent" opacity="1.000000" stroke="none" 
      	d="M177.000000,56.000244 
      	C185.664017,56.000343 193.828323,55.955856 201.991943,56.012775 
      	C210.575485,56.072624 216.854385,61.880699 216.803635,69.600838 
      	C216.753891,77.167557 210.775436,83.078117 202.450363,83.092766 
      	C152.133865,83.181320 101.817047,83.181244 51.500565,83.088821 
      	C43.201210,83.073578 37.179905,77.085381 37.184696,69.559731 
      	C37.189621,61.823277 43.447811,56.022518 52.040359,56.015579 
      	C93.526886,55.982071 135.013443,56.000340 177.000000,56.000244 
        z"/>
      <path fill="transparent" opacity="1.000000" stroke="none" 
      	d="M199.854950,154.999802 
      	C150.386871,154.999802 101.398476,154.857361 52.412609,155.145432 
      	C44.602028,155.191345 37.427898,150.207581 37.239048,141.463516 
      	C37.071606,133.710403 43.495125,128.018661 52.137150,128.013901 
      	C102.124443,127.986397 152.111771,127.984329 202.099045,128.016968 
      	C210.176407,128.022232 215.623337,132.693878 216.660812,140.221298 
      	C217.488785,146.228775 213.337402,152.718445 207.244675,154.415222 
      	C205.057007,155.024460 202.645050,154.828369 199.854950,154.999802 
        z"/>
      <path fill="transparent" opacity="1.000000" stroke="none" 
      	d="M78.000000,227.000885 
      	C68.677063,227.000809 59.853798,227.047668 51.031281,226.986542 
      	C43.276566,226.932800 37.212307,221.013351 37.176899,213.600006 
      	C37.142723,206.444305 43.018909,200.130173 50.475807,200.070465 
      	C71.616180,199.901184 92.759636,199.895920 113.900017,200.062851 
      	C121.758148,200.124878 127.179306,206.233231 127.000008,214.034607 
      	C126.826637,221.578217 121.305405,226.938538 113.458687,226.983398 
      	C101.806137,227.050003 90.152931,227.000793 78.000000,227.000885 
        z"/>
    </svg>
  );
}

export default OrdersIcon;
