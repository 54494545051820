import * as React from 'react';

import { trackEvent } from '../analytics/tracking.jsx';
import DetourButton from '../styleguide/DetourButton';
import PropTypes from 'prop-types';

// Use button widths same as primary buttons
import styles from '../styleguide/Button.module.css';

export const BOSTON_REVIEW_LINK = 'https://g.page/r/CWZEjGf8M6EqEB0/review';
export const CAMBRIDGE_REVIEW_LINK = 'https://g.page/r/CWit48YNTWyiEB0/review';
const REVIEW_CAMBRIDGE_CITIES = [
  'Arlington',
  'Belmont',
  'Cambridge',
  'Somerville',
  'Waltham',
  'Watertown',
];

const GoogleReviewLink = ({
  deliveryLocation,
  trackingLabel="google",
  withStyle={}
}) => {

  // Assign Near-Canbridge deliveries to review our Cambridge location
  const [reviewLink, setReviewLink] = React.useState(BOSTON_REVIEW_LINK);
  React.useEffect(() => {
    if (deliveryLocation?.city &&
        REVIEW_CAMBRIDGE_CITIES.includes(deliveryLocation.city)) {
      setReviewLink(CAMBRIDGE_REVIEW_LINK);
    }
  },[deliveryLocation])

  const btnStyle = Object.assign({
    marginTop: 16,
    marginBottom: 32,
  }, withStyle);

  return (
    <div>
      <DetourButton
        isCentered
        withStyle={btnStyle}
        withClass={styles.primaryWidth}
        text="Leave a Google Review"
        handleClick={() => {
          trackEvent(`${trackingLabel}_review_clk`);
          window.open(`${reviewLink}`);
        }} />
    </div>
  )
}

GoogleReviewLink.propTypes = {
  deliveryLocation: PropTypes.object,
  trackingLabel: PropTypes.string.isRequired,
  withStyle: PropTypes.object,
}

export default GoogleReviewLink;
