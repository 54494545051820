import * as React from 'react';
import { devLog } from './util';
/**
 * Listen to width resize Only 
 */
const useOnWidthResize = (callback) => {

  // Capture current width
  const width = React.useRef(window.innerWidth);
  
  const resizeRef = React.useRef();
  React.useEffect(() => {
    const handleWidthResize = () => {
      window.clearTimeout(resizeRef.current);
      resizeRef.current = window.setTimeout(() => {
        if (window.innerWidth !== width.current) {
          callback();
          devLog('Handle carousel width resize...');
        }
      }, 300);
    };
    window.addEventListener('resize', handleWidthResize);
    return () => { 
      window.removeEventListener('resize', handleWidthResize);
    }
  }, [callback]);  
}

export default useOnWidthResize;
