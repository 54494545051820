import * as React from "react";

import { errMsg } from './registration-errors';
import { RegistrationContext } from './RegistrationProvider';
import PhoneInput from 'react-phone-number-input';
import PropTypes from 'prop-types';

import styles from './SignUpInline.module.css';

/**
 *  Phone number input for inline reg flow, validated by RegistrationProvider     
 */
const PhoneNumberInline = ({
  phoneNumber,
  setPhoneNumber,
  phoneNumberErr,
  setPhoneNumberErr
}) => {
  
  // Flush primary validation error msg on input change
  const { setValidationErrors } = React.useContext(RegistrationContext);

  // Default to US +1
  const countryCodeRef = React.useRef('US');
  
  // handle user input
  const onPhoneInputChange = (value) => {
    // clear errors
    setValidationErrors(false);
    setPhoneNumberErr(false);
    setPhoneNumber(value);
  };

  return ( 
    <div>
      <PhoneInput
        id="tel_number"
        initialValueFormat="national"
        defaultCountry={countryCodeRef.current}
        placeholder="Your Phone Number"
        value={phoneNumber}
        onChange={(value) =>{ onPhoneInputChange(value) }} />
      {/* elem id is for the testing */}
      <div id="tel_number_err_msg" className={styles.phoneNumberErrorMsg}>
        {errMsg('tel_number', phoneNumberErr)}
      </div>
    </div>
  );
}

PhoneNumberInline.propoTypes = {
  phoneNumber: PropTypes.string.isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
  phoneNumberErr: PropTypes.string,
  setPhoneNumberErr: PropTypes.func.isRequired
}

export default PhoneNumberInline;
