import * as React from 'react';

import { ProductPreviewContext } from '../products/ProductPreviewProvider';
import { NotificationsContext } from '../notifications/NotificationsProvider';

export const ReOrderContext = React.createContext();

/**
 * Provides currently stocked product matches (same SKU) from a single prior order
 * If not found, order item is returned: .notAvailable
 */
const ReOrderProvider = ({order, children}) => {

  const { allProducts } = React.useContext(ProductPreviewContext);
  const { priorOrders } = React.useContext(NotificationsContext);

  // Order items with available sku-matches swaped in.
  const [orderItems, setOrderItems] = React.useState();

  React.useEffect(() => {
    if (allProducts && order?.items.length) {
      const products = order.items.reduce((acc, item) => {
        // Match in-stock products by SKU
        const productMatch = allProducts.find(product => product.dutchie_sku === item.dutchie_sku);
        if(productMatch) {
          productMatch.quantity = 1;
          acc.push(productMatch);
        } else {
          // Mutate unavailable order item
          item.notAvailable = true;
          acc.push(item);
        }
        return acc;
      },[]);
      // Sort available first    
      setOrderItems(products.sort((a,b) => a.notAvailable ? 1 : b.notAvailable ? -1 : 0));
    }
  }, [order, allProducts, priorOrders]);

  return (
    <ReOrderContext.Provider value={{orderItems}}>
      {children}
    </ReOrderContext.Provider>
  );
};

export default ReOrderProvider;
