import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import { getSortPrice } from '../products/DisplayPrice';
import { ProductPreviewContext } from '../products/ProductPreviewProvider';

/**
 * Retrieve special sale items using the .special_priced_item:true attribute
 */
const useSpecialSaleProducts = () => {

  const { allProducts } = React.useContext(ProductPreviewContext);
  const [specialSaleProducts, setSpecialSaleProducts] = React.useState();

  React.useEffect(() => {
    let filteredProducts;
    if (allProducts?.length) {
      filteredProducts = allProducts
        .filter(product => (
          !!product.special_priced_item ||
          !!product.per_order_max_quantity
        ))
        .sort((a, b,) => getSortPrice(a) - getSortPrice(b)); // sort low to high price
    }
    setSpecialSaleProducts(filteredProducts);
    if (filteredProducts?.length) {
      trackEvent('special_sale_carousel_display');
    }
  }, [allProducts]);

  return { specialSaleProducts }
}

export default useSpecialSaleProducts;
