import * as React from 'react';

import DisplayModal from '../common/DisplayModal';
import Header, { HeaderSize } from '../styleguide/Header';
import { Grid, makeStyles } from '@material-ui/core';
import { ModalType } from './ModalProvider';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  gridButton: {
    cursor: 'pointer',
    fontSize: '18px',
    borderRadius: 4,
    display: 'block',
    width: '95%',
    margin: '0 auto',
    minHeight: 60,
    padding: '0.375rem .5rem',
    backgroundColor: '#1879c333',
    boxShadow: '1px 1px 3px var(--zrPrimaryDark)',
    border: '1px solid #000',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      width: '95%',
      padding: '0.5rem',
      minHeight: 'auto',
    },
    [theme.breakpoints.up('xs')]: {
      '&:hover': {
        backgroundColor: '#1879c366',
      }
    }  
  },
  bodyWrap: {
    padding: '0 12px',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    } 
  },
  subHead: {
    fontSize: '18px',
    marginBottom: 12,
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    }
  },
  selectReason: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    color: 'var(--zrPrimaryDark)',
  }
}));

const CancelOrderModal = ({ modalProps, displayModal }) => {
  const { onContinue } = modalProps || {};
  const classes = useStyles();
  
  const GridButton = ({text, option}) => (
    <Grid item xs={12} sm={6}>
      <button className={classes.gridButton} onClick={
        () => {
          displayModal(ModalType.NONE);
          onContinue(`${option}|${text}`)
        }}>
        {text}
      </button>
    </Grid>
  );

  return (
    <DisplayModal 
      title="Cancel Order" 
      trackContext='cancel_order'>
      <Header isCentered size={HeaderSize.Medium_Static} text="Cancel Delivery" />
      <div className={classes.bodyWrap}>
        <div className={classes.subHead}>
          Let's get your order canceled. To help us better serve you in the future please
          {' '}<span className={classes.selectReason}>select a reason</span> for your cancelation:
        </div>
        <Grid container spacing={2} style={{marginBottom:8}}>
          <GridButton option={1} text="The delivery window no longer works for me." />
          <GridButton option={2} text="I'm not happy with the products I selected." />
          <GridButton option={3} text="I chose the wrong payment option!" />
          <GridButton option={4} text="I plan to visit a dispensary instead." />
          <GridButton option={5} text="I found a better deal using another service." />
          <GridButton option={6} text="I prefer not to say." />
        </Grid>
      </div>
    </DisplayModal>
  );
};

CancelOrderModal.propTypes = {
  modalProps: PropTypes.shape({
    onContinue: PropTypes.func.isRequired,
  }),
  displayModal: PropTypes.func.isRequired
}

export default CancelOrderModal;
