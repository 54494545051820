import * as React from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';
import Header, { HeaderSize } from '../styleguide/Header';
import ProductRequestSurvey from './ProductRequestSurvey';

import styles from './Survey.module.css';

/**
 * This component hosts the product request survey in an accordion
 */
const ProductRequestAccordion = ({
  currentCategory,
  productCategories,
}) => {
  
  // Accordion expand/collapse
  const [expanded, setExpanded] = React.useState(false);
  
  return ( 
    <div className={styles.accordionWrap}>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary className={styles.accordionTitle} expandIcon={<ExpandMoreOutlined />}>
          <Header withClass={`${styles.formHeader} ${styles.accordionHeader}`}
                  size={HeaderSize.Medium_Static}>
            Not finding your <span className={styles.hdrBig}>Favorite&nbsp;Product?</span>
          </Header>
        </AccordionSummary>
        <AccordionDetails className={styles.surveyAccordionDetails}>
          <ProductRequestSurvey
            currentCategory={currentCategory}
            productCategories={productCategories}
            onSubmit={() => setExpanded(false)} 
            formOnly />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default ProductRequestAccordion;
