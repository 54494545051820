// z-index / zIndex stacking constants, anything over 2 goes here!
// grep -R z-index . | grep -v "0;" | grep -v "1;" | grep -v "2;"
export const STACKING = {
  MODAL_TOOLTIP: 11,
  MODAL: 10,
  FOOTER_CART_BUTTON: 6, // Needs to stack above header (it's outside footer) 
  HEADER_AND_FOOTER: 5,  // Both are fixed which and footer stacks over header due to order
  HOME_SEARCH_WIDGET: 3, // Category filter is at 2 currently
  ANALYTICS_DEBUG: 3,
  ABOVE_PAGE: 2,          // Tooltips etc.
  HAMBURGER_MENU_ICON: 3,
  HAMBURGER_MENU: 2,      // Hamburger menu content
};

/**
 * The tricky bits:
 * Burger Menu stacks over header cart
 * Burger Menu stacks under footer-triggered cart  
 */
