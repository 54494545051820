import React from 'react';

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

// Handle link hover styling
const useStyles = makeStyles({
  link: {
    cursor: 'pointer',
    color: 'var(--zrPrimaryDark)',
    font: 'var(--zrFontSemi)',
    '&:hover': {
      textDecoration: 'underline'
    }
  }   
});

const ActionLink = ({text, href, withStyle={}, handleClick=()=>{}}) => {

  const classes = useStyles();

  return ( href 
    ? <a className={classes.link}
        href={href}
        style={withStyle}
        onClick={handleClick}>
        {text} 
      </a>
    : <span className={classes.link}
        style={withStyle}
        onClick={handleClick}>
        {text} 
      </span>
  );
};

ActionLink.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  withStyle: PropTypes.object, 
  handleClick: PropTypes.func
}

export default ActionLink;