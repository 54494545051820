import * as React from 'react';

import { trackEvent, trackingString as trkStr} from '../analytics/tracking';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Header, { HeaderSize } from '../styleguide/Header';
import Button from '../styleguide/Button';
import PropTypes from 'prop-types';

import styles from './Survey.module.css';

export const TextInput = ({label, value, setValue}) => (
  <TextField
    className={styles.textInput}
    label={label} 
    placeholder={label}
    value={value}
    InputLabelProps={{
      shrink: true,
    }}
    onChange={event => setValue(event.target.value)}            
  />
)

/**
 * Allow product requests via Firebase tracking:
 * e.g. "shop_prod_req_flow_island_guava"
 */
const ProductRequestSurvey = ({
  currentCategory,
  productCategories,
  onSubmit,
  formOnly, // currently we use survey with formOnly=true in an accordion
}) => {

  // Category Dropdown
  const unselected = 'none';

  const [category, setCategory] = React.useState(unselected);
  const [brand, setBrand] = React.useState('');
  const [productName, setProductName] = React.useState('');

  const [submitted, setSubmitted] = React.useState();

  React.useEffect(() => {
    // Check for valid caegory, ignore "Sale" category
    if (currentCategory && 
        productCategories?.find(cat => cat.display_name === currentCategory)) {      
      setCategory(currentCategory);
    }
  },[currentCategory, productCategories])

  const resetRef = React.useRef();
  const trackProductRequest = React.useCallback(() => {
    if (category !== unselected) {
      const productData = [
        `${trkStr(category.substring(0,4))}_`,
        `${trkStr(brand.substring(0,12))}:`,
        trkStr(productName.substring(0,16))
      ];
      setSubmitted(true);
      trackEvent(`prod_req_${productData.join('')}`);
    }
    // Reset form
    resetRef.current = window.setTimeout(() => {
      if (onSubmit) {
        onSubmit();
      }
      setBrand('');
      setProductName('');
      setSubmitted(false);
    }, 3000);

    return () => window.clearTimeout(resetRef.current);
  },[category,brand,productName, onSubmit]);

  return ( productCategories
    ? <div className={`${styles.surveyWrap} ${formOnly ? styles.formOnly : ''}`}> 
        <div className={styles.surveyForm}>
          { formOnly
            ? null
            : <Header withClass={styles.formHeader} size={HeaderSize.Medium_Static}>
                Not Finding Your <span className={styles.hdrBig}>Favorite&nbsp;Product?</span>
              </Header>
          }
          <div className={styles.formLegend}>Tell us what you'd like us to stock!</div>  
          { submitted 
            ? <Alert severity="success" className={styles.successAlert}>
                <span>
                  Thanks for sharing your product suggestion, our buyers will consider this when restocking! 
                </span>
              </Alert>
            : <Grid container className={styles.gridItems} spacing={4}>      
                <Grid item className={styles.gridItem}>
                  <FormControl className={styles.categorySelectWrap}>
                    <InputLabel>Product Category</InputLabel>
                    <Select
                      className={styles.select}
                      label="Product Category"
                      placeholder='Product Category'
                      value={category}   
                      onChange={(event) => setCategory(event.target.value)}>
                        <MenuItem disabled value={unselected}>Product Category</MenuItem>
                        { productCategories.map(category => (
                          <MenuItem
                            key={category.name}
                            className={styles.option} 
                            value={category.display_name}>
                              {category.display_name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
      
                <Grid item className={styles.gridItem}>
                  <TextInput
                    label="Brand"
                    value={brand}
                    setValue={setBrand} /> 
                </Grid>
      
                <Grid item className={styles.gridItem}>
                  <TextInput
                    label="Product Name"
                    value={productName}
                    setValue={setProductName} /> 
                </Grid>
    
                <Grid item className={styles.gridItem}>
                  <Button
                    withClass={styles.submitButton}
                    disabled={!(category !== unselected && brand && productName)}
                    text='Submit'
                    handleClick={trackProductRequest} />
                </Grid>
              </Grid>
          }    
        </div>
      </div>
    : null
  )
}

ProductRequestSurvey.propTypes = {
  currentCategory: PropTypes.string,
  productCategories: PropTypes.array,
  onSubmit: PropTypes.func,
  formOnly: PropTypes.bool,
}

export default ProductRequestSurvey;
