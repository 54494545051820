import * as React from "react";

import { auth } from "../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import TextField from '@material-ui/core/TextField';
import { errMsg } from '../registration/registration-errors';
import { isValidEmail } from '../registration/validation-utils';
import { SIGN_IN_TEXT } from "../registration/SignIn";
import Button from '../styleguide/Button';
import BackLink from '../common/BackLink';
import Header from '../styleguide/Header';
import SuccessMessage from "../styleguide/SuccessMessage";
import ErrorMessage from "../styleguide/ErrorMessage";

import styles from './ResetPassword.module.css';

const SendResetEmailForm = ({isInline=false, handleCancel}) => {
  
  const [email, setEmail] = React.useState();
  const [emailErr, setEmailErr] = React.useState(false);
  const [emailHasBeenSent, setEmailHasBeenSent] = React.useState(false);
  const [sendEmailError, setSendEmailError] = React.useState(false);
  
  const sendResetEmail = event => {
    event.preventDefault();
 
    if(!isValidEmail(email)) {
      setEmailErr(true);
      return;  
    }

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSendEmailError(undefined);
        setEmailHasBeenSent(true);
        window.setTimeout(() => {
          setEmailHasBeenSent(false)  
        }, 3000);
      })
      .catch((error) => {
        setSendEmailError(error.message || 'No details provided.');
        setEmailHasBeenSent(false);
      });
  };

  const handleEmailOnFocus = () => {
    setEmailHasBeenSent(false);
    setSendEmailError(false);
    setEmailErr(false);      
  };

  const handleEmailOnBlur = () => {
    setEmailErr(!isValidEmail(email));      
  };

  /**
   * Update email as it's entered
   * @param {event} event 
   */
  const onChange = event => {
    const { name, value:email } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(email);
    } 
  };     
   
  const msgStyle = {
    display: 'block',
    textAlign: 'center',
    padding: '.5rem 0'
  };

  return (
    <div className={styles.plainForm}>
      { !isInline &&
        <>
          { handleCancel &&
            <BackLink backToText={SIGN_IN_TEXT} onClick={handleCancel} />
          }
          <div className={styles.headerWrap}> 
            <Header isCentered text="Reset Password" />
          </div>
        </>
      }
      <form action="" className={styles.plainInputs}>
        <TextField id="userEmail" name="userEmail" value={email} label="Your Email" error={emailErr} helperText={errMsg('email',emailErr)} 
                   onBlur={handleEmailOnBlur}
                   onFocus={handleEmailOnFocus} 
                   variant={'filled'}  onChange={onChange} />
        
        <Button 
          isCentered
          withStyle={{ width:'100%', fontSize:'1.25rem'}}
          text="Email a password reset link" 
          handleClick={sendResetEmail} />

        { emailHasBeenSent && 
          <SuccessMessage withStyle={msgStyle} text="An email has been sent to you!" />
        }
        { sendEmailError &&
          <ErrorMessage text={` Error sending password reset email: ${sendEmailError}`} />
        }
        { isInline && handleCancel &&
          <BackLink backToText={`Return to ${SIGN_IN_TEXT}`} onClick={handleCancel} />
        }
      </form>
    </div>
  );
};

export default SendResetEmailForm;
