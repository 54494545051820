import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import { CustomerCreditContext } from './CustomerCreditProvider';
import ChatLink from '../intercom/ChatLink';
import StrongText from '../common/StrongText';
import Alert from '@material-ui/lab/Alert';
import { ExpandMoreOutlined } from '@material-ui/icons';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';

import styles from '../common/TableStyles.module.css';

const accordionStyle = {
  borderRadius: 8, 
  overflow:'hidden', 
  color:'#663c00', 
  backgroundColor:'#f2e8d9',
};

const referDate = (dateSeconds) => {
  const dateItems = new Date(dateSeconds * 1000).toDateString().split(' ');
  return `${dateItems[1]} ${dateItems[2]} ${dateItems[3]}`; 
}

const DisplayInvalidCredits = () => {

  const { invalidCredits } = React.useContext(CustomerCreditContext);

  React.useEffect(() => {
    if (invalidCredits?.length) {
      trackEvent('credit_fraud_acct_msg_display');
    }
  }, [invalidCredits])

  return (
    invalidCredits?.length
      ? <Alert severity='warning' style={{marginTop:16}}>
          <div style={{marginBottom:12}}>
            NOTE: You have <StrongText>Zyp&nbsp;Run Credits</StrongText>
            that are currently<StrongText>invalid</StrongText>
            because the other user shares too many of your personal 
            details: <StrongText>Name, Address, ID Image, etc.</StrongText>
            If you think there's been an error on our part, please reach out <ChatLink>via Chat</ChatLink>.
          </div>
          <Accordion style={accordionStyle}>
            <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
              <StrongText>Show Invalid Credits</StrongText>
            </AccordionSummary>
            <AccordionDetails> 
              <table className={styles.fullWidthTable}>
                <tbody>
                  { invalidCredits.map(credit => (
                    <tr key={credit.id} className={styles.rowWithBorder}>
                      <td className={styles.cellWithBorder}>
                        <div><b>Date:</b></div>
                        <div>{referDate(credit.created_at)}</div>
                      </td>
                      <td className={styles.cellWithBorder}>{ 
                        credit.reason === "referral"
                          ? <><div><b>Referral from:</b></div> {credit.referrer_name}</>
                          : <><div><b>Your Referral to:</b></div> {credit.referral_name}</>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </AccordionDetails> 
          </Accordion>
        </Alert>       
      : null
  );
};

export default DisplayInvalidCredits;
