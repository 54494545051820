import * as React from "react";

import { ProductPreviewContext } from "../products/ProductPreviewProvider";
import { CartItemsContext } from "../checkout/CartItemsProvider";

export const CartSuggestedProductsContext = React.createContext({});

/**
 * This product provider is used for suggested products in the shopping cart
 */
const CartSuggestedProductsProvider = ({children}) => {

  const PRODUCT_PRICE_MAX = 1000;

  const { allProducts, loading } = React.useContext(ProductPreviewContext);
  const { cartItems } = React.useContext(CartItemsContext);

  const [suggestedProducts, setSuggestedProducts] = React.useState();

  // Contains inexpensive product that are not in carft already
  React.useEffect(() => {
    if (cartItems?.length && allProducts?.length) { 
      const cartIds = cartItems.map(item => item.id);
      setSuggestedProducts(
        allProducts.filter(product => {
          return product.display_info.cost_usa_cents <= PRODUCT_PRICE_MAX &&
                 !cartIds.includes(product.id);
        })
      );
    } else {
      setSuggestedProducts(undefined);
    }
  }, [cartItems, allProducts]);

  return (
    <CartSuggestedProductsContext.Provider value={{
      suggestedProducts,
      loading,
    }}>
      {children}
    </CartSuggestedProductsContext.Provider>
  );
};

export default CartSuggestedProductsProvider;
