import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import { trackingString } from '../analytics/tracking';
import { FilteredProductsContext, subCategoryAll } from '../products/FilteredProductsProvider';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import styles from './ProductInfoFilter.module.css';

/**
 * This component will provide a product filter for both Desktop and Mobile layouts
 */ 
const SubCategoryFilter = ({
  onSubCategoryClick,
  startOpen=false,
  inModal=false
}) => {
  
  const { trackEvent } = useAnalyticsStore();

  // Current filters context
  const { subCategory, subCategories } = React.useContext(FilteredProductsContext);

  // Add the 'All {category}' option 
  const subCategoriesWithAll = [subCategoryAll, ...subCategories];

  // Toggle the display
  const [showSubCategories, setShowSubCategories] = React.useState(startOpen);

  const toggleShowSubCategories = () => {
    if (labelRef.current) {
      labelRef.current.setAttribute('data-show-content', !showSubCategories);
    } 
    setShowSubCategories(!showSubCategories);
  };

  const subCategoryClick = (subCategory) => {
    trackEvent(`filter_sub_category_${trackingString(subCategory.name)}_click`);
    // Sub-Category changes are handled via page path update
    onSubCategoryClick(subCategory);
  };
  
  const labelRef = React.useRef();

  const addlClass = inModal ? styles.inModal : '';

  return (subCategoriesWithAll.length > 1
    ? <div className={`${styles.productInfoFilter} ${addlClass}`}> 
        <div ref={labelRef} data-show-content={showSubCategories} className={styles.sectionHdr} onClick={toggleShowSubCategories}>
          SUBCATEGORIES <ChevronRightRoundedIcon style={{verticalAlign:'-7px'}}/>
        </div>
        { showSubCategories &&
          <ul className={styles.filterLinks}>
            { subCategoriesWithAll.map(subCat => {
              const selectedClass = subCat.name === subCategory.name
                                  ? styles.selected 
                                  : '';
              const label = subCat.name === subCategoryAll.name
                          ? `All SubCategories`
                          : subCat.display_name;
              return <li key={subCat.name} 
                         className={`${styles.filterLink} ${selectedClass}`} 
                         onClick={() => {subCategoryClick(subCat)}}>
                       {label}
                       <span className={styles.selectedIcon}>
                         <CheckCircleRoundedIcon style={{color: '#054052', verticalAlign:'-6px'}}/>
                       </span>
                     </li>
            })}
          </ul>
        }  
      </div>
    : null 
  );
}

export default SubCategoryFilter;
