import React from 'react';

import Alert from '@material-ui/lab/Alert';
import styles from '../common/Alert.module.css';

import PropTypes from 'prop-types';

/**
 * Display warnings related to cart items when placing an order
 */
const CartWarnings = ({
  warnings=[],
  severity='error'
}) => {

  return ( warnings.length
    ? <Alert severity={severity} className={styles.cartWarnings}>
        <ul>
          { warnings.map(warning => (
            <li key={warning.id} className={styles.warning}>
              <div className={styles.warningMsg}>{warning.message}</div>
              <div>{warning.recommendation}</div>
            </li>
          ))}
        </ul>
      </Alert>
    : null  
  )
}

CartWarnings.propTypes = {
  warnings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      recommendation: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
    })
  ),
  severity: PropTypes.string,
}

export default CartWarnings;
