import * as React from 'react';

import { auth } from '../../firebase';
import { activeController, devLog } from '../util/util';
import { CANCELABLE_STATUSES } from './useCustomerOrders';
import { NotificationsContext } from '../notifications/NotificationsProvider';
import { ModalType, ModalContext } from '../modal/ModalProvider';
import { newOrderEndpoint } from '../../constants/api-endpoints';
import { useAnalyticsStore } from '../../App';
import SpinnerWrapper from '../common/SpinnerWrapper';
import BasicButton from '../styleguide/BasicButton';
import SuccessMessage from '../styleguide/SuccessMessage';
import ErrorMessage from '../styleguide/ErrorMessage';

import PropTypes from 'prop-types';

/**
 * Button with spinner for canceling an order
 * (cancelling orders can take 5 - 10 seconds)
 */
const CancelOrderButton = ({
  orderId, 
  orderStatus, 
  dispensaryId,
  onCancelStart,   // Orders page
  onOrderCanceled  // Orders page
}) => {

  const { trackEvent, trackError } = useAnalyticsStore();
  const { displayModal } = React.useContext(ModalContext);
  // Update the footer's active order status indicator 
  const { activeOrderId, activeOrderStatus, refetchOrders } = React.useContext(NotificationsContext);
  const [showButton, setShowButton] = React.useState();  
  // For updating spinner over button when cancel is complete
  const [canceling, setCanceling] = React.useState();
  const [cancelSuccess, setCancelSuccess] = React.useState();
  const [errorMsg, setErrorMsg] = React.useState();

  const controllerRef = React.useRef(new AbortController());
  
  // Update state on cancel order complete
  const updateStateOnCancel = React.useCallback((isSuccess, errorMessage) => {
    setShowButton(!isSuccess);
    setCanceling(false);
    setCancelSuccess(isSuccess); 
    setErrorMsg(errorMessage);
  }, []);

  const cancelOrder = React.useCallback((cancelReason) => {
    setCanceling(true);
    devLog(`Cancel Reason: ${cancelReason}`);

    // Use the latest status if we have it
    const currentStatus = orderId === activeOrderId
      ? activeOrderStatus
      : orderStatus;

    const controller = activeController(controllerRef);

    const cancelOrderURL = `${newOrderEndpoint}${dispensaryId}/${orderId}/status`;
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
      fetch(cancelOrderURL, { 
        method: 'PATCH',
        headers: {
          'Authorization': idToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          current_status: currentStatus,
          new_status: "canceled",
          reason_for_cancel: cancelReason
        }),
        signal: controller.signal
      }).then(response => {
        /**
         * 204: Success no message
         * 200: Success but with dispensary msg - check Leaf Logix, etc.
         */
        if ([200,204].includes(response.status)) {
          updateStateOnCancel(true, ''); 
          trackEvent('order_cancel_success');
          // Update the parent view, refresh orders etc.
          if (onOrderCanceled) {
            onOrderCanceled();
          }
          // NotificationsProvider
          refetchOrders();
        } else {
          trackError('order_cancel_error');
          response.json().then(body => {
            updateStateOnCancel(false, `${body.error}`); 
          });
        }
        setCanceling(false);
      }).catch(error => {
        setCanceling(false);
        updateStateOnCancel(false, `${error.message}`);
      });
    });
  }, [orderStatus, dispensaryId, updateStateOnCancel,
      onOrderCanceled, orderId, trackError, trackEvent, 
      activeOrderId, activeOrderStatus, refetchOrders]);

  React.useEffect(() => {
    if (CANCELABLE_STATUSES.includes(orderStatus)) {
      setShowButton(true);
    }      
  }, [orderStatus]) 
 
  const handleCancelClick = React.useCallback(() => {
    // We need to send the (accurate) current status with the cancel request
    refetchOrders();
    // Get a cancel reason using the modal
    displayModal(ModalType.CANCEL_ORDER, {
      onContinue: (text) => {
        if (onCancelStart) {
          onCancelStart();
        }
        cancelOrder(text);
      }
    });
  }, [refetchOrders, displayModal, onCancelStart, cancelOrder]);

  const successStyle = {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: 0,
  };

  return ( 
    <>  
      { showButton &&
        <SpinnerWrapper showSpinner={canceling} handleClick={handleCancelClick}>
          <BasicButton text="Cancel Order" />
        </SpinnerWrapper>
      }
      { cancelSuccess && 
        <SuccessMessage withStyle={successStyle} text="Your order has been canceled." />
      }
      { errorMsg && 
        <ErrorMessage text={errorMsg} />
      }
    </>
  )
}

CancelOrderButton.propTypes = {
  orderId: PropTypes.string.isRequired,  
  orderStatus: PropTypes.string.isRequired,  
  dispensaryId: PropTypes.string.isRequired,
  onCancelStart: PropTypes.func,
  onOrderCanceled: PropTypes.func
};

export default CancelOrderButton;
