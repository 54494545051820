import * as React from 'react';

import { SaleItemsContext } from './SaleItemsProvider';
import  ProductCarousel, { MAX_CAROUSEL_PRODUCTS } from '../home/ProductCarousel';
import Spinner from '../common/Spinner';
import Header, { HeaderSize } from '../styleguide/Header';

const SaleItemCarousels = ({ otherSaleItemsCount }) => {
  
  const { saleItemsByCategory } = React.useContext(SaleItemsContext);

  return ( saleItemsByCategory
    ? Object.keys(saleItemsByCategory).length > 0
      ? <>
          { Object.keys(saleItemsByCategory).map(categoryName => {
              const catProducts = saleItemsByCategory[categoryName];
              const max = Math.min(catProducts.length, MAX_CAROUSEL_PRODUCTS);
              const previewProducts = catProducts.slice(0,max);

              return <ProductCarousel
                  key={categoryName}
                  carouselTitle={`${categoryName}: Sale Items`}
                  categoryCount={catProducts.length}
                  carouselProducts={previewProducts}
                />
          })}
        </>
      : otherSaleItemsCount === 0
        ? <div style={{padding:'2rem'}}>
            <Header 
              isCentered
              size={HeaderSize.Small}
              text="Sorry! It looks like we're currently sold out of our Sale Items." />
          </div>
        : null
    :<Spinner />
  )
}

export default SaleItemCarousels;
