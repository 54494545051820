import * as React from "react";

import { auth } from "../../firebase";
import LicenseImageUploader from "./LicenseImageUploader";
import PhotoIdTooltip from './PhotoIdTooltip';
import photoIDFront from '../../assets/photoIDSmall.png';
import { errMsg } from "./registration-errors";
import ErrorMessage from "../styleguide/ErrorMessage";

import PropTypes from 'prop-types';

import styles from './PhotoIdInline.module.css';

/**
 *  Upload/Update ID image to Firebase 
 */
const PhotoIdInline = ({
  email,
  primaryPhotoId,
  setPrimaryPhotoId,
  primaryPhotoIdErr,
  setPrimaryPhotoIdErr,
  setValidationErrors,
}) => {

  React.useEffect(() => {
    // This is a weak attempt at solving the issue of 
    // uploaded ID images with bad image tokens
    // (try getting a fresh user token here)
    auth.currentUser.getIdToken(true);
  }, []);

  return (
    <>
      <div className={styles.idHeader}>
        Photo ID Image <PhotoIdTooltip />
      </div>
      <div className={styles.imageGrid}>
        <LicenseImageUploader
          email={email}
          placeholderImage={photoIDFront}
          primaryPhotoId={primaryPhotoId}
          setPhotoIdImage={(imageInfo) => {
            setPrimaryPhotoId(imageInfo);
            setPrimaryPhotoIdErr(false);
            setValidationErrors(false);
          }}
        />
        <div className={styles.disclaimer}>
          Upload a photo of your license or goverment issued ID.
        </div>
      </div>
      { primaryPhotoIdErr &&
        <ErrorMessage text={errMsg('id_photo', true)} />
      }   
    </>
  )
};  

PhotoIdInline.propTypes = {
  email: PropTypes.string.isRequired,
  primaryPhotoId: PropTypes.shape({
    url: PropTypes.string,
  }),
  setPrimaryPhotoId: PropTypes.func.isRequired,
  primaryPhotoIdErr: PropTypes.bool,
  setPrimaryPhotoIdErr: PropTypes.func.isRequired,
  setValidationErrors: PropTypes.func
};

export default PhotoIdInline;
