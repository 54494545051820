import React from 'react';

import SuccessMessage from '../styleguide/SuccessMessage';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  regFlowSuccess: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    }  
  },
  regFlowSuccessText: {
    color: '#000',
    fontWeight: 400,
    marginLeft: 6,
  }
}))

const msgStyle = {
  margin:0,
  padding:'.5rem', 
}

// For use at checkout
const RegistrationSuccessOneLine = () => {
  const classes = useStyles();
  return ( 
    <div className={classes.regFlowSuccess}>
      <SuccessMessage withStyle={msgStyle}>
        <span>
          You're Registered! 
          <span className={classes.regFlowSuccessText}>
            Select your delivery options below to complete your order
          </span>
        </span>  
      </SuccessMessage>
    </div>
  )
}

export default RegistrationSuccessOneLine;
