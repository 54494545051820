import * as React from 'react';

import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import PageTitle from '../common/PageTitle';
import Header, { HeaderSize } from './Header';
import { makeStyles } from '@material-ui/core';
import Button from './Button';
import DetourButton from './DetourButton';
import BasicButton from './BasicButton';

const useStyles = makeStyles({
  box: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    padding: '1rem 2rem',
    margin: '2rem 0',
    '& img': {
      display: 'block',
      margin: '1rem 0'
    }
  },
  textBox: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    padding: '1rem 2rem',
    margin: '2rem 0',
    border: '1px solid var(--zrPrimaryDark)'
  },
  fontBox: {
    font: 'var(--zrFontBold)',
    fontSize: '1.5rem',
    padding: '1rem 2rem',
    margin: '2rem 0',
    border: '1px solid var(--zrPrimaryDark)'
  }
});

/**
 * Zyp Run Style Guide
 */
const StyleGuide = () => {

  const classes = useStyles();

  return ( 
    <BodyWrapper pageLayout={LayoutTypes.Narrow}>
      <PageTitle title="Zyp Run Style Guide" />

      <Header size={HeaderSize.Medium} withStyle={{marginTop:'2rem'}} isCentered text="Brand Colors" />
      
      <div className={classes.box} style={{ backgroundColor: 'var(--zrPrimaryOrig)'}}>
        Brand Blue Color (original): #85C1F6 ( contrast to white: 1.91 )
      </div>

      <div className={classes.box} style={{ backgroundColor: 'var(--zrPrimary)'}}>
        Brand Blue Color (web): #62b0f4 ( contrast to white: 2.32 )
      </div>

      <div className={classes.textBox} style={{ color: 'var(--zrPrimaryDark)'}}>
        Brand Blue Text Color (web): #1879C3 ( contrast to white: 4.6 )
      </div>

      <Header size={HeaderSize.Medium} withStyle={{marginTop:'2rem'}} isCentered text="Brand Web Font" />

      <div className={classes.fontBox}>
        Eina 
        <div style={{ padding:'1rem 0 0', fontSize:'1rem', fontWeight:'normal' }}>
          <a target="_blank" rel="noopener noreferrer" href="https://extratype.com/fonts/eina">
            https://extratype.com/fonts/eina
          </a>
          <div style={{ padding: '1rem 0 0'}}>
            There are 4 variants of Eina (01,02,03,04). We currently use Eina 01. 
            The letter "g" varies significantly between versions.   
          </div>
        </div>
      </div>

      <Header size={HeaderSize.Medium} withStyle={{marginTop:'2rem'}} isCentered text="Zyp Run Logos" />

      <div className={classes.box}>
        Header Logo (#62b0f4)
        <img alt="zyp run" src="/img/zyprun_logo_232.png" />  
      </div>

      <div className={classes.box} style={{backgroundColor: 'var(--zrPrimary)'}}>
        White Logo (on #62b0f4)
        <img alt="zyp run" src="/img/logo_small_trans.png" /> 
      </div>

      <div className={classes.box}>
        FavIcon (#62b0f4)
        <img alt="zyp run" src="/zrLogo192-232to1.png" /> 
      </div>
       
      <Header size={HeaderSize.Medium} withStyle={{marginTop:'2rem'}} isCentered text="Web Buttons" />

      <div className={classes.box}>
        <Button withStyle={{minWidth:'auto', margin:'1rem 0'}} text="Primary Button" />
        <div>color: #1879c3, hover: #0a416c</div>
      </div>

      <div className={classes.box}>
        <DetourButton withStyle={{minWidth:'auto', margin:'1rem 0'}} text="Secondary Action Button" />
        <div>color: black and white</div>
      </div>  
      
      <div className={classes.box}>
        <BasicButton withStyle={{minWidth:'auto', margin:'1rem 0'}} text="Basic Utility/Settings Button" />
      </div>  

    </BodyWrapper>
  )
}  

export default StyleGuide;
