import { auth } from '../../firebase';
import { aeropayCredentialsEndpoint, aeropayLinkedAccountEndpoint } from '../../constants/api-endpoints';

/**
 * Retrieve user's linked bank accounts, if any 
 * 
 * @param calllback - the function that handles the response
 * @param controller - the abortController the component will use to cancel request on unmount (unlikely here) 
 */
export const fetchPreferredAeropayAccount = async (callback, controller) => {
  // Authentication has been checked prior to displaying /yourOrders
  if (!auth.currentUser) {
    return;
  } 
  
  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(aeropayLinkedAccountEndpoint, { 
      method: 'GET',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      signal: controller.signal
    }).then(response => response.json())
      .then(data => {
        if (data.bank_accounts?.length) {
          const [preferredAccount] = data.bank_accounts.filter((acct) => {
            return acct.isSelected === '1';
          }); 
          callback(preferredAccount);
        } else {
          callback({});
        }
      })
      .catch(error => { 
        callback({ error: error.message });
      });
  });
};

/**
 * Retrieve user's linked bank accounts, if any 
 * 
 * @param calllback - the function that handles the response
 * @param controller - the abortController the component will use to cancel request on unmount (unlikely here) 
 */
export const fetchAeroSyncCredentials = async (callback, controller) => {
  // Authentication has been checked prior to displaying /yourOrders
  if (!auth.currentUser) {
    return;
  } 
  
  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(aeropayCredentialsEndpoint, { 
      method: 'GET',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      signal: controller.signal
    }).then(response => response.json())
      .then(body => {
        callback(body);
      })
      .catch(error => { 
        callback({ error: error.message });
      });
  });
};

/**
 * Post the user's selected bank info (Aerosync) back to Aeropay via the ZR API
 * NOTE: The user's phone number is used by Aeropay for uniqueness
 * 
 * @param widgetResponse - the account linking info returned by Aerosync widget
 * @param onSuccess calllback - handles successful account linking API response
 * @param onError callback - handles API response (400) for account linking failure
 * @param controller - the abortController the component will use to cancel request on unmount
 */
export const postAerosyncCredentials = async (widgetResponse, onSuccess, onError, controller) => {
  // Authentication has been checked prior to displaying /yourOrders
  if (!auth.currentUser) {
    return;
  } 
  
  /** 
   * Payload:
   * {
   *   aerosyncSDKPayload: { ...widgetResponse }
   * }
   */
  
  auth.currentUser.getIdToken(/* no need to force refresh */ false).then(idToken => {
    fetch(aeropayCredentialsEndpoint, { 
      method: 'POST',
      headers: {
        'Authorization': idToken,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(widgetResponse),
      signal: controller.signal
    }).then(response => {
      if (response.status === 200) {
        response.json().then(json => {
          onSuccess(json);
        }); 
      } else {
        response.json().then(json => {
          onError(json);
        });
      }
    }).catch(error => { 
      onError({ error: error.message });
    });
  });
};

export const buildPaymentDetails = (method, bank_account_id) => {
  if (method === 'aeropay') {
    return {
      processor: method,
      aeropay_payment_data: {
        bank_account_id
      }
    }
  } else {
    return {
      processor: method // "on-site-debit" etc.
    }
  }
};
