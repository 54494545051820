import React from "react";

import { auth } from '../../firebase';
import { trackEvent } from "../analytics/tracking";
import { signInWithCustomToken } from "firebase/auth";
import Button from "../styleguide/Button";
import Spinner from "../common/Spinner";

import styles from '../registration/SignUpInline.module.css';

const signInWithToken = (auth, token, onSuccess, onFailure) => {
  signInWithCustomToken(auth, token)
    .then((userCredential) => {
      // Signed in
      trackEvent('phone_signin_success');
      onSuccess();
    })
    .catch((error) => {
      // const errorCode = error.code;
      trackEvent('phone_signin_error');
      onFailure(error);
    });
}

const CompleteSignIn = ({loginState, onSuccess, onFailure}) => {
  
  /**
   * We could to auto-login here but we need some success messaging
   * 
   * React.useEffect(() => {
   *   if (loginState.custom_token) {
   *     signInWithToken(auth, loginState.custom_token, onSuccess, onFailure);
   *   }
   * },[loginState, onSuccess, onFailure])
  */
  return (
    <div className={`${styles.inlineRegFlow} ${styles.phoneSignInField}`}>
      { loginState.custom_token
        ? <>
            <div>Account Verified! Click the button below to complete your Login</div>    
            <Button isCentered text="Complete Login" 
              handleClick={()=> {
                signInWithToken(auth, loginState.custom_token, onSuccess, onFailure)
              }} />
          </>
        : <Spinner />
      }  
    </div>
  );
};

export default CompleteSignIn;
