import * as React from 'react';

import BrandProductsProvider from './BrandProductsProvider';
import FilteredProductsProvider from '../products/FilteredProductsProvider';
import BrandProducts from './BrandProducts';
import Spinner from '../common/Spinner';
import { PropTypes } from 'prop-types';

const PartnerProductsPage = ({
  vendorId, 
  category, 
  subCategory,
  location
}) => {

  // For return to partner page etc. on Category Filter back link
  const [backLink, setBackLink] = React.useState();
  React.useEffect(() => {
    const { returnToPath } = location?.state || {};
    if (returnToPath) {
      setBackLink(returnToPath);
    }
  }, [location])

  // This is required by the child brand components
  // any problems are handled later  
  const default_category = 'Flower';

  // From path or fallback partner default
  const [initialCategory, setInitialCategory] = React.useState();
  
  React.useEffect(() => {
    if(category && category !== initialCategory) {
      setInitialCategory(category);
    } else if (!initialCategory && default_category) {
      setInitialCategory(default_category);
    }
  }, [initialCategory, category])

  return ( initialCategory
    ? <BrandProductsProvider brandName={vendorId}>
        <FilteredProductsProvider brandName={vendorId}>
          <BrandProducts
            brand={vendorId}
            pathName='partner' 
            category={initialCategory}
            subCategory={subCategory}
            backLink={backLink} />
        </FilteredProductsProvider> 
      </BrandProductsProvider>
    : <Spinner />
  )
}

PartnerProductsPage.propTypes = {
  vendorId: PropTypes.string, /* via router */
  category: PropTypes.string, /* via router */
  subCategory: PropTypes.string, /* via router */
  location: PropTypes.object /* via router */
}

export default PartnerProductsPage;
