import React, { useState } from 'react';

import { useAnalyticsStore } from '../../App';
import CheckMark from '../common/CheckMark';
import copyIcon from  '../../assets/copy_icon_black.png';
import shareIcon from '../../assets/share_icon_black.png';
import PropTypes from 'prop-types';

const ShareLink = ({
  ctaText,
  shareText,
  shareLink,
  trackingLabel="product_link"
}) => {

    const { trackEvent  } = useAnalyticsStore();
  
    // Determine whether to upgrade COPY BUTTON to SHARE BUTTON (mobile/tablet)
    const [canShare, setCanShare] = useState();
    const [copySuccess, setCopySuccess] = useState();
    
    const shareOrCopyLink = React.useCallback((text, url) => {
      if (canShare) {
        /* title is not supported by many targets */
        navigator.share({ 
          title: 'Zyp Run: Cannabis Delivered',
          text, 
          url 
        })
          .then(() => trackEvent(`share_${trackingLabel}_clk`))
          .catch((error) => trackEvent(`share_${trackingLabel}_error_${error.substring(0,12)}`));
      } else {
        navigator.clipboard.writeText(url);
        // show the success message
        setCopySuccess(true);
      }
    }, [canShare, trackEvent, trackingLabel]);

    React.useEffect(() => {
      setCanShare(window.navigator.share && window.innerWidth <= 800);
    }, [canShare]);
  
    return ( shareLink 
      ? <span style={{fontStyle:'italic'}}>
          <span style={{cursor:'pointer', marginRight:8}} 
                onClick={() => shareOrCopyLink(shareText, shareLink)}>
            { canShare
              ? <img alt="share" width="18" style={{verticalAlign:-5}} src={shareIcon} />   
              : <img alt="copy" width="18"  style={{verticalAlign:-5}} src={copyIcon} />
            }
            <span style={{marginLeft:6,fontSize:'15px',textDecoration:'underline',fontWeight:600}}>
              {ctaText}
            </span>
          </span>        
          { copySuccess &&
            <span style={{fontSize:'15px'}}>
              <CheckMark size={20} withStyle={{color:'#000'}}/>Link&nbsp;copied!
            </span>
          } 
        </span>
      : null    
    );
};
  
ShareLink.propTypes = {
  ctaText: PropTypes.string,
  shareText: PropTypes.string.isRequired,
  shareLink: PropTypes.string.isRequired,
  trackingLabel: PropTypes.string
}

export default ShareLink;
