import * as React from 'react';

import { encode } from "./refer-util";

// Referral link containing encoded userId and userName
const useReferralLink = (path, userId, userName) => {

  const [referralLink, setReferralLink] = React.useState();
  
  React.useEffect(() => {
    if (userId && userName) {
      const protocol = window.location.host.indexOf('localhost') === -1 ? 'https://' : 'http://';
      const userToken = encode(userId, userName);
      setReferralLink(`${protocol}${window.location.host}/${path}/${userToken}`);
    }
  }, [path, userId, userName]);

  return referralLink;
}

export default useReferralLink;
