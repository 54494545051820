import React from 'react';

import { MAX_MA_FLOWER_GRAMS } from './QuantityWarning';
import PropTypes from 'prop-types';

import styles from './QuantityIcon.module.css';

const QuantityIcon = ({cartCount, productFlowerGrams, totalFlowerGrams}) => {
  
  // We'll allow a single decimal point precision for display
  const productGrams = parseFloat(cartCount * productFlowerGrams).toFixed(1);

  return ( 
    totalFlowerGrams > MAX_MA_FLOWER_GRAMS
    ? <div className={styles.weight}>
        <img className={styles.icon} alt={`${productGrams} grams`} src='/img/weightIconOrangeSm.png' />
        <span className={styles.gramCount}>{productGrams}</span>
        <span className={styles.gramLabel}>grams</span>  
      </div>
    : <></>
  )
}

QuantityIcon.propTypes = {
  cartCount: PropTypes.number.isRequired,
  productFlowerGrams: PropTypes.number.isRequired,
  totalFlowerGrams: PropTypes.number.isRequired
}

export default QuantityIcon;
