import * as React from 'react';

import { dollarDisplay } from '../providers/OrderPricingProvider';

// Suppress cents if they are 0.
const getPriceDisplay = (price_in_cents) => {
  const inDollars = price_in_cents % 100 === 0;
  return dollarDisplay(price_in_cents, inDollars);
};

/**
* 2 => {
*   price_cents: 4000,
*   price_display: $40,
*   savings_percent: 25,
*   is_threshold: true,
* },
*/  
const useBundledPriceMap = (cost_usa_cents, bundle_deal_data) => {
  const [pricingMap, setPricingMap] = React.useState();
  
  React.useEffect(() => {
    // Also .id
    const { values } = bundle_deal_data || {};
    if (values?.length) {
      const pricing = new Map();
      let previousPrice;
      values.forEach(pricingLevel => {
        pricing.set(pricingLevel.quantity, {
          price_cents: pricingLevel.unit_price,
          price_display: getPriceDisplay(pricingLevel.unit_price),
          savings_percent: 100 - Math.floor((pricingLevel.unit_price/cost_usa_cents) * 100),
          is_threshold: pricingLevel.unit_price !== previousPrice
        });
        previousPrice = pricingLevel.unit_price;
      });
      setPricingMap(pricing);
    }
  }, [cost_usa_cents, bundle_deal_data]);

  return pricingMap;
}

export default useBundledPriceMap;
