import React from "react";

import AddressUpdaterInline from "../registration/AddressUpdaterInline";
import TextField from '@material-ui/core/TextField';
import DeliveryBlacklistMessage  from '../address/DeliveryBlacklistMessage';
import BasicButton from '../styleguide/BasicButton';
import PropTypes from 'prop-types';

/* Material UI overrides */
import '../registration/MaterialUI.module.css';
import styles from '../account/YourAccount.module.css';

const AddressForm = ({
  location,
  handleSubmit,
  sectionRef,
  msgHandler,
  updateMsg,
 }) => {
  
  const [editLocation, setEditLocation] = React.useState();
  const [aptNumber, setAptNumber] = React.useState();
  // Toggle typeahead display
  const [enableEdit, setEnableEdit] = React.useState();

  React.useEffect(() => {
    if (location) {
      setEditLocation(location);
      setAptNumber(location.apartment_number);
    }
  }, [location])
 
  const onApartmentChange = event => {
    const { value } = event.currentTarget;
    setAptNumber(value?.trim());
  } 
   
  /**
   * Collect the location data and run the provided handleSubmit script  
   */
  const handleSubmitClick = (event) => {
    event.preventDefault();
    const updatedAddress = { ...editLocation };
    // API endpoint will not accept an undefined/blank value
    if (aptNumber) {
      updatedAddress.apartment_number = aptNumber;  
    }
    handleSubmit({ location:updatedAddress }, sectionRef, msgHandler);
  };

  /* Material UI TextField Variant */
  const tF = 'filled'; 

  return ( editLocation  
    ? <form>
        <AddressUpdaterInline
          location={editLocation}
          editAddress={enableEdit}
          setEditAddress={setEnableEdit} 
          onUpdate={setEditLocation} />
        {/* Apartment */}
        <div>
          <TextField id="apartment_number" value={aptNumber} label="Apt, Suite, Bldg" variant={tF} onChange={onApartmentChange} />   
        </div>
        {/* alert if undeliverable */}
        <DeliveryBlacklistMessage 
          location={editLocation}
        />
        <div className={styles.updateBtnWrap}>
          <BasicButton text="Update Address" handleClick={handleSubmitClick} /> 
          { updateMsg && 
            <span className={styles.updateMsg}>{updateMsg}</span>
          }
        </div>
      </form>
    : <></>
  );
};

AddressForm.propTypes = {
  location: PropTypes.object.isRequired,
  apartment_number: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  sectionRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  msgHandler: PropTypes.func,
  updateMsg: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired
};

export default AddressForm;
