import * as React from 'react';

import { devLog } from '../util/util';
import useInterval from '../util/useInterval';
import usePageVisibility from '../util/usePageVisibility';
import { getPriorityDeliveryETA, PRIORITY_UPDATE_MINUTES } from '../timeSlots/useCurrentTimeSlots';
import { UserContext } from '../providers/UserProvider';
import { DeliveryOptionsContext } from '../providers/DeliveryOptionsProvider';
import useNextAvailableTimeSlot from '../timeSlots/useNextAvailableTimeSlot';

// For customer messaging that requires updating every few minutes
// Priority Delivery by 2:15pm!
const useDeliveryETAMessaging = (priorityDeliveryConfigs, todaysHours=[0,0]) => {

  // All these determine if Priority Delivery is available for the user
  const { currentLocation } = React.useContext(UserContext);
  const { timeSlotsByDay } = React.useContext(DeliveryOptionsContext);
  // Determine if regular same-day slots are available
  const { defaultSlot } = useNextAvailableTimeSlot(timeSlotsByDay);

  // Update state when the tab is active only
  const [tabVisible, setTabVisible] = React.useState();
  // The priority delivery ETA: "2:15pm"
  const [priorityETA, setPriorityETA] = React.useState();

  // Monitor page visibility so we can sleep/restart updates
  usePageVisibility(visibilityState => {
    setTabVisible(visibilityState === "visible");
    if (visibilityState === "visible") {
      setCurrentTimeMS(new Date().getTime());
    }
  });

  // Update the state every 5 mins when browser tab is active
  const intervalMS = PRIORITY_UPDATE_MINUTES * 60 * 1000;
  const [currentTimeMS, setCurrentTimeMS] = React.useState(new Date().getTime());
  useInterval(() => {
    devLog(`Priority ETA update at ${currentTimeMS}`);
    setCurrentTimeMS(new Date().getTime());
  }, intervalMS, tabVisible); // pause when tab is hidden

  // Priority delivery settings for zip code
  const { zip_code } = currentLocation || {};
  const zipCodePriorityConfig = priorityDeliveryConfigs?.find(config => config.zip_code === zip_code);
  const currentHour = new Date(currentTimeMS).getHours();

  React.useEffect(() => {
    if ( zipCodePriorityConfig?.is_enabled &&
         currentHour >= todaysHours[0] &&
         currentHour < todaysHours[1]
    ) {
      const deliveryETA = getPriorityDeliveryETA(new Date(currentTimeMS));
      setPriorityETA(deliveryETA);
    } else {
      setPriorityETA(undefined);
    }
  }, [defaultSlot, currentLocation, currentTimeMS,
      currentHour, todaysHours, zipCodePriorityConfig]);

  return ({ priorityETA });
}

export default useDeliveryETAMessaging;
