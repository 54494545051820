import * as React from 'react';
import { dollarDisplay } from '../providers/OrderPricingProvider';
import { getDeliveryWindowLabel } from '../deliveryWindows/scheduled-delivery-utils';
import PropTypes from 'prop-types';

import styles from './SelectTimeSlot.module.css';

// For debug visibility
const timeAttr = (timestamp) => {
  const d = new Date(timestamp);
  return d.toTimeString().split(" ")[0];
};

const StandardTimeSlot = ({
  idx,
  radioId,
  slot,
  isDisabled,
  isChecked,
  slotCloseMS,
  discountCents,
  isExcludedZipCode,
  onSelect,
}) => {

  const slotLabel = getDeliveryWindowLabel(slot.hours);
  const selectedStyle = isChecked ? styles.selected : '';
  const disabledStyle = isDisabled ? styles.disabledFldSet : '';

  return (
    <fieldset key={radioId}
              className={`${styles.fldSet} ${selectedStyle} ${disabledStyle}`}
              data-closes-at={timeAttr(slotCloseMS)}
              data-is-expired={slot.isExpired}>
      <input type="radio" name="timeSlot" id={radioId} value={idx}
             onChange={onSelect} checked={isChecked}
             disabled={isDisabled} />
      <label htmlFor={radioId}>
        {slotLabel}
        { isChecked
          ? <span className={styles.selected}>Selected</span>
          : <span className={styles.chooseButton}>Choose</span>
        }
        <span className={styles.notAvailable}>
          { isExcludedZipCode
            ? <>Sorry... <span className={styles.soldOut}>Sold Out!</span></>
            : <>No Longer Available</>
          }
        </span>
        { discountCents > 0 &&
          <span className={styles.discount}>
            {`Save ${dollarDisplay(discountCents, true)}!`}
          </span>
        }
      </label>
    </fieldset>
  )
}

StandardTimeSlot.propTypes = {
  idx: PropTypes.number.isRequired,
  radioId: PropTypes.string.isRequired,
  slot: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool.isRequired,
  slotCloseMS: PropTypes.number.isRequired,
  discountCents: PropTypes.number.isRequired,
  isExcludedZipCode: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default StandardTimeSlot;
