import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import { freezeBody, unfreezeBody } from '../modal/modal-utils';
import { CartItemsContext } from './CartItemsProvider';
import { ModalContext } from '../modal/ModalProvider';
import { SHOPPING_MILESTONES } from '../analytics/analytics-utils';
import { CommerceLoggingContext } from '../analytics/CommerceLoggingProvider';
import CartIcon from './CartIcon';
import FooterCartIcon from '../footer/FooterCartIcon';
import FooterCartButton from '../footer/FooterCartButton';
import SlideInCart from './SlideInCart';
import BagIcon from './BagIcon';
import PropTypes from 'prop-types';

export const CartIconType = {
  HEADER: 'header',
  FOOTER: 'footer',
  FOOTER_BUTTON: 'footer_button', /* above footer */
  BASIC: 'basic'
}

const CartToggle  = ({
  iconType=CartIconType.BASIC,
  forceClose, /* e.g. footer navigation away from cart */
  withCartStyle={},
}) => {

  const { trackEvent } = useAnalyticsStore();

  const { cartItems, itemTotalDisplay } = React.useContext(CartItemsContext);
  const { logCommerceAction } = React.useContext(CommerceLoggingContext);
  const { triggerCartOpen, setTriggerCartOpen } = React.useContext(ModalContext);
  const [isOpen, setOpen] = React.useState(false);
  
  const toggleOpen = () => {
    // Only freeze body on mobile 
    if (window.innerWidth <= 480) {
      if (isOpen) {
        unfreezeBody();
      } else {
        freezeBody();
      }
    }
  
    // For footer icon styling... 
    document.body.setAttribute('zr-cart-open', !isOpen);

    // Make sure that a header/footer slide-in cart is not already open.
    if (!isOpen) {
      const winWidth = window.innerWidth;
      // Make sure all ~3 carts are offscreen
      const carts = document.querySelectorAll('div[data-cart-element="true"]');
      const [alreadyOpen] = [ ...carts].filter(cart => {
        // Most are > winWidth but the footer button returns 0
        try {
          const cartX = cart.getBoundingClientRect().x || 0;
          return cartX > 0 && cartX <= winWidth; 
        } catch(e) {
          return false;
        }
      }); 
    
      // User has attempted to view cart
      logCommerceAction(SHOPPING_MILESTONES.CART_VIEWED); 
      // Track open click only
      trackEvent(`cart_icon_${iconType}_click`);

      if (alreadyOpen) {
        return;
      }
    }
    setOpen(!isOpen);
  };

  // Enable Header cart (only) to be opened via ModalProvider
  React.useEffect(() => {
    if (iconType === CartIconType.HEADER && triggerCartOpen) {
      setOpen(true);
      document.body.setAttribute('zr-cart-open', true);
      setTriggerCartOpen(false);
    }
  }, [iconType, triggerCartOpen, setTriggerCartOpen])

  // If we are forcing cart closed via prop (FooterNav)
  if (forceClose) {
    if (isOpen) {
      setOpen(false);
    }
    unfreezeBody();
  }

  const renderCartIcon = () => {
    switch (iconType) {
      case CartIconType.HEADER:
        return <CartIcon items={cartItems} />
      case CartIconType.FOOTER:
        return <FooterCartIcon items={cartItems} displayTotal={itemTotalDisplay} />
      case CartIconType.FOOTER_BUTTON:
        return <FooterCartButton items={cartItems} displayTotal={itemTotalDisplay} />
      default:
        return <BagIcon fillColor="#fff" hoverFillColor="#fff" />
    }
  };

  const iconStyle = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  }

  return (
    <>
      <span style={iconStyle} onClick={toggleOpen}>
        {
          renderCartIcon()
        }
      </span>
      <SlideInCart
        isOpen={isOpen} 
        toggleOpen={toggleOpen}
        cartType={iconType}
        withStyle={withCartStyle} />
    </>
  );
}

CartToggle.propTypes = {
  iconType: PropTypes.string,
  forceClose: PropTypes.bool,
  withCartStyle: PropTypes.object,
};

export default CartToggle;
