import * as React from "react";

import useProductSubCategories, { ALL_OTHER_PREFIX } from "../products/useProductSubCategories";
import { ProductPreviewContext } from '../products/ProductPreviewProvider';

/**
 * Custom hook used to provide products by sub category for carousel display
 */
const useProductsBySubCategory = (categoryName) => {

  const productSubCategories = useProductSubCategories(categoryName);
  const { productsByCategory } = React.useContext(ProductPreviewContext);
  const [subCatProducts, setSubCatProducts] = React.useState();

  const createSubCategoryMap = React.useCallback((products) => {
    // Map products by sub-category
    const mappedProducts = new Map();
    products.forEach(itm => {
      const subCat = itm.display_info.sub_category?.display_name || `${ALL_OTHER_PREFIX} ${categoryName}`;
      const subCatItems = mappedProducts.get(subCat) || [];
      subCatItems.push(itm);
      mappedProducts.set(subCat, subCatItems);
    });

    // Create NEW map with sorted key insertion order
    const sortedKeyProductMap = new Map(); 
    productSubCategories.forEach(subCat => {
      const { display_name } = subCat; 
      if (mappedProducts.has(display_name)) {
        sortedKeyProductMap.set(display_name, mappedProducts.get(display_name));
      }
    });
    return sortedKeyProductMap;
  }, [categoryName, productSubCategories]);
  
  React.useEffect(() => {
    if (categoryName &&
        productSubCategories && 
        productsByCategory &&
        productsByCategory.has(categoryName)) {
      setSubCatProducts(createSubCategoryMap(productsByCategory.get(categoryName)));
    }
  }, [categoryName, productSubCategories, productsByCategory, createSubCategoryMap]);

  return { 
    productsBySubCategory: subCatProducts
  };
};

export default useProductsBySubCategory;
