import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import { ZR_PRIMARY_DARK } from '../../constants/colors';

/**
 * In dev, displays the differences between front-end and
 * back-end price calcs ( The total, fees, taxes etc.)
 *
 * @param {array} costCalcDiffs - Array of line-item diffs
 * @returns
 */

const ValidateOrderResults = ({
  costCalcDiffs=[]
}) => {

  const isDev = process.env.NODE_ENV === 'development';

  const { trackError } = useAnalyticsStore();

  // Skip modules for debug stuff
  // Line item styling, bold last item (total diffs)
  const itemStyle = {fontWeight:'normal', marginBottom: '.25rem'};
  const lastItemStyle = Object.assign({font:'var(--zrFontSemi)'}, itemStyle);

  /**
   * Log an error for diffs
   * Diffs should be less than $1 as orders are rejected
   * when total diff is > $1.00
   */
  const errorLoggedRef = React.useRef();
  if (isDev && costCalcDiffs.length && !errorLoggedRef.current) {
    if (costCalcDiffs[costCalcDiffs.length - 1] > 1) {
      trackError('price_calc_error_major');
    } else {
      trackError('price_calc_error_minor');
    }
    errorLoggedRef.current = true;
  }

  return (
    <>
      { isDev
        ? <div style={{width:'400px', maxWidth:'95vw', margin:'.5rem auto', font:'var(--zrFontSemi)', textAlign:'center'}}>
            { costCalcDiffs.length
              ? <>
                  <div style={{color:'darkRed'}}>DEBUG: Price calculation validation: Errors found!</div>
                  <ul style={{color:'darkRed'}}>
                    { costCalcDiffs.map((calcDiff, idx) => {
                        const diffStyle = idx === costCalcDiffs.length - 1
                          ? lastItemStyle
                          : itemStyle;
                        return <li key={btoa(calcDiff)} style={diffStyle}>{calcDiff}</li>;
                      })
                    }
                  </ul>
                </>
              : <div style={{color:ZR_PRIMARY_DARK}}>
                  DEBUG: Price calculation validation: OK!
                </div>
            }
          </div>
        : null
      }
    </>
  )
}

export default ValidateOrderResults
