import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import { UserContext } from '../providers/UserProvider';
import { AttributionContext } from '../providers/AttributionProvider';
import CartReminderProducts from './CartReminderProducts';
import CartLoginPrompt from './CartLoginPrompt';
import { getSessionVal, setSessionVal, hideCartReminderMsgKey } from '../util/storage-utils';
import closeX from '../../assets/closeX.png';

import styles from '../common/Alert.module.css';

/**
 * For Cart Reminder email traffic, provide a cart link and let
 * customers know what's still available and what's sold out...
 */
const VerifyCartReminderProducts = () => {

  const { user } = React.useContext(UserContext);
  const { isAbandonCartSession } = React.useContext(AttributionContext);

  const [shouldDisplay, setShouldDisplay] = React.useState();

  // Allow user to close
  const handleClose = () => {
    setShouldDisplay(false);
    setSessionVal(hideCartReminderMsgKey, true);
  }

  React.useEffect(() => {
    if (user && isAbandonCartSession &&
        !getSessionVal(hideCartReminderMsgKey)) {
      setShouldDisplay(true);
      trackEvent('verify_cart_reminder_display');
    }
  }, [user, isAbandonCartSession])

  return ( shouldDisplay
    ? <div className={styles.cartNotice}>
        <img alt="close" height="24" className={styles.closeIcon}
             src={closeX} onClick={handleClose} />
        <div className={styles.cartNoticeHdr}>Welcome Back!</div>
        { user?.isAnonymous
          ? <CartLoginPrompt />
          : <CartReminderProducts />
        }
      </div>
    : null
  );
};

export default VerifyCartReminderProducts;
