import * as React from "react";

import ResetPasswordController from '../resetPassword/ResetPasswordController';
import EmailVerified from '../registration/EmailVerified';
import PageNotFoundRedirect from '../common/PageNotFoundRedirect';

// Modes supported by Firebase, passed as mode=
const EmailModes = {
  RESET_PASSWORD: 'resetPassword',
  RECOVER_EMAIL: 'recoverEmail',
  VERIFY_EMAIL: 'verifyEmail'
};

/**
 * Firebase uses a single /path for all email responses
 * Currently we have this set to /emailResponse?mode=
 * This controller uses the mode param to render the correct response
 */
const EmailResponseController = () => {
  
  // grab the mode from the url
  const params = (new URL(document.location)).searchParams;
  const mode = params.get('mode');
  const code = params.get('oobCode'); 

  const renderView = (mode) => {
    switch(mode) {
      case EmailModes.RESET_PASSWORD:
        return <ResetPasswordController />
      case EmailModes.VERIFY_EMAIL:
        return <EmailVerified code={code} />
      default:
        return <PageNotFoundRedirect /> 
    }
  }

  return (
    <>{ renderView(mode) }</>
  );
};

export default EmailResponseController;