import * as React from 'react';

import { devLog } from '../util/util';
import { DeliveryOptionsContext } from '../providers/DeliveryOptionsProvider';
import { NO_SAME_DAY_SLOTS_IDX } from './useCurrentTimeSlots';
import useNextAvailableTimeSlot from './useNextAvailableTimeSlot';
import { getFormattedTimeLabel } from '../deliveryWindows/scheduled-delivery-utils';
import BoltIcon from '../../assets/boltIconBlack.png';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  deliveryByWrap: {
    font: 'var(--zrFontSemi)',
    whiteSpace: 'nowrap',
    minHeight: 20,
    color: '#000',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  icon: {
    height: 20,
    verticalAlign: -4,
    [theme.breakpoints.down('xs')]: {
      height: 16,
      verticalAlign: -3,
    }
  },
  clockIcon: {
    fontSize: '20px',
    verticalAlign: -4,
    marginRight: 3,
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      verticalAlign: -3,
      marginRight: 2,
    }
  }
}));

/**
 * Slot ETA Callout specific to the marketing site iframe
 */
const DisplayCurrentSlotETAOverSearch = () => {

  const classes = useStyles();

  const { timeSlotsByDay } = React.useContext(DeliveryOptionsContext);
  const { defaultSlot, isBostonDefault, displayTimeSlots } = useNextAvailableTimeSlot(timeSlotsByDay);
  const [slotLabel, setSlotLabel] = React.useState();
  // When current slot is next day
  const [isNextDay, setIsNextDay] = React.useState();

  React.useEffect(() => {
    if (typeof defaultSlot === 'number' && displayTimeSlots?.length) {
      // Adjust when next slot is tomorrow (-1)
      const dayIdx = defaultSlot === NO_SAME_DAY_SLOTS_IDX ? 1 : 0;
      const slotIdx = defaultSlot === NO_SAME_DAY_SLOTS_IDX ? 0 : defaultSlot;
      const slotsForDay = displayTimeSlots[dayIdx];
      const { hours } = slotsForDay[slotIdx];
      setSlotLabel(getFormattedTimeLabel(hours.end_hour, hours.end_minute));
      setIsNextDay(defaultSlot === NO_SAME_DAY_SLOTS_IDX);
      devLog(`Current Slot ETA: ${getFormattedTimeLabel(hours.end_hour, hours.end_minute)}`);
    }
  }, [defaultSlot, displayTimeSlots]);

  return (
    <div className={classes.deliveryByWrap}>
      { slotLabel
        ? <>
            { isNextDay
              ? <AccessTimeRoundedIcon className={classes.clockIcon} />
              : <img alt="" height="20" className={classes.icon} src={BoltIcon} />
            }
            { isBostonDefault
              ? 'Boston '
              : null
            }
            Delivery by {slotLabel}
          </>
        : null
      }
    </div>
  )
}

export default DisplayCurrentSlotETAOverSearch;
