import { isSuperDeal } from "./useSaleLabelDetails";
import DisplayPrice from './DisplayPrice';
import BulkPriceDetailsForModal from '../bundles/BulkPriceDetailsForModal';
import SuperDealDetailsForModal from '../deals/SuperDealDetailsForModal';
import OrderLimitPillForModal from './OrderLimitPillForModal';

import styles from './SaleLabel.module.css';

const SaleLabelModal = ({
  product,
  itemCount
}) => {

  const {
    bundle_deal_data,
    per_order_max_quantity,
    discount_data
  } = product;

  return (
    bundle_deal_data
    ? <BulkPriceDetailsForModal product={product} />
    : isSuperDeal(product)
      ? <SuperDealDetailsForModal product={product} />
      : per_order_max_quantity
        ? <OrderLimitPillForModal per_order_max_quantity={per_order_max_quantity} />
          : discount_data
            ? <div className={styles.salePrice}>
                On Sale!
                {' '}
                <DisplayPrice
                  product={product}
                  quantity={itemCount} />
              </div>
            : null
  )
}

export default SaleLabelModal;
