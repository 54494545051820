import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { useAnalyticsStore } from '../../App';
import { ModalType, ModalContext } from '../modal/ModalProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { getCartItemTotalDisplay } from './product-utils';
import AdjustQuantity from './AdjustQuantity';
import { flattenedProduct } from './product-utils';
import Button from '../styleguide/Button';
import DisabledButtonWithReason from '../styleguide/DisabledButtonWithReason';
import SaleLabelModal from './SaleLabelModal';
import useSoldOutStatus from './useSoldOutStatus';
import { makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';

const useStyles = makeStyles({
  addToCartButton: {
    width:'100%',
    margin:'1rem 0',
  },
  soldOut: {
    margin: '0 0 .5rem',
  },
  soldOutMsg: {
    width: '100%',
    margin: '0 0 .5rem',
  },
  soldOutHdr: {
    font: 'var(--zrFontSemiItalic)',
  },
  chatMsg: {
    margin: '.25rem 0',
  }
});

/**
 * Add to Cart, show quantity price on button
 *
 * Currently used only on the product detail card/modal
 */
const AddToCartButton = ({
  product,
  onAddToCart,
  isMobile,
}) => {

  const classes = useStyles();

  const {
    id: itemId,
    name,
    category,
    quantity_available,
    display_sold_out_status,
  } = flattenedProduct(product);

  // This is actually Qty = 1, disabled for purchase, but available for display
  const isSoldOut = useSoldOutStatus(
    quantity_available,
    display_sold_out_status);

  const { trackEvent } = useAnalyticsStore();
  const { hasNoLocation } = React.useContext(UserContext);
  const { displayModal } = React.useContext(ModalContext);
  const { cartItems, itemTotal, addItem } = React.useContext(CartItemsContext);

  // Handle quantity limited products: Limit 1 etc.
  const [addToCartMax, setAddToCartMax] = React.useState();

  React.useEffect(() => {
    // Determine if this item is carted
    let [inCart] = cartItems?.filter(item => item.id === product.id) || [undefined];
    if (product.per_order_max_quantity) {
      setAddToCartMax(inCart
        ? product.per_order_max_quantity - inCart.quantity
        : product.per_order_max_quantity);
    } else {
      setAddToCartMax(undefined);
    }
    // itemTotal is the rerender trigger as cartItems is a ref.
  }, [cartItems, itemTotal, product]);

  const [itemCount, setItemCount] = React.useState(1);
  const [displayTotal, setDisplayTotal] = React.useState();

  const addToCartClick = () => {
    trackEvent('product_modal_add_to_cart_click');
    if (hasNoLocation && !window.Cypress) {
      // Require an address to cart items
      displayModal(
        ModalType.ADDRESS, {
          trackContext: 'address_from_cart_button',
          continueModal: ModalType.PRODUCT,
          itemId,
          itemCount,
          title: name,
          categoryName: category.display_name
          /**
           * TODO FIXME: switch to useReducer for flow
           * relatedProducts: data missing for suggested product carousel
           */
        });
    } else {
      addItem(itemId, product, itemCount);
      if (onAddToCart) {
        onAddToCart();
      }
    }
  };

  // Handle button price
  React.useEffect(() => {
    setDisplayTotal(
      getCartItemTotalDisplay(product, itemCount)
    );
  }, [product, itemCount]);

  return (
    isSoldOut
      ? null
      : <>
          <div>
            <AdjustQuantity
              addToCartMax={addToCartMax}
              count={itemCount}
              setCount={setItemCount}
              isStacked={!isMobile} />
          </div>
          <div style={{flex:1}}>
            { addToCartMax < 1 // generally: undefined < 1 = false
              ? isMobile
                ? <Button
                    withClass={classes.addToCartButton}
                    withStyle={{color:"#000"}}
                    disabled>
                    You've got {product.per_order_max_quantity}
                    {' '}
                    in your cart! <span role="img" aria-label="check">✅</span>
                  </Button>
                : <DisabledButtonWithReason
                    text="Add to Cart"
                    withAlert
                    withClass={classes.addToCartButton}
                    reason={
                      `Your cart has reached the quantity limit
                      for this product.`} />
              : <Button text={`Add to Cart - ${displayTotal}`}
                  handleClick={addToCartClick}
                  withClass={classes.addToCartButton} />
            }
          </div>
          <SaleLabelModal
            product={product}
            itemCount={itemCount} />
        </>
  )
}

AddToCartButton.propTypes = {
  product: PropTypes.object.isRequired,
  onAddToCart: PropTypes.func.isRequired, /* runs after carting */
  isMobile: PropTypes.bool,
}

export default AddToCartButton;
