import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import useCustomerOrders from '../orders/useCustomerOrders';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import Header, {HeaderSize} from '../styleguide/Header';
import BackLink from '../common/BackLink';
import PageTitle from '../common/PageTitle';
import ReOrderProvider from './ReOrderProvider';
import ReOrderDetails from '../orders/ReOrderDetails';
import PropTypes from 'prop-types';

import styles from '../orders/ReOrder.module.css';

/**
 * Display prior order details for use in reordering
 */
const ReOrderPage = ({
  orderId
}) => {

  // Get the user's order history
  const { user } = React.useContext(UserContext);
  const { customerOrders } = useCustomerOrders(user);

  // The order we are reordering
  const [priorOrder, setPriorOrder] = React.useState();
  const [orderDate, setOrderDate] = React.useState();
  
  // Add order info + dispensary to state
  React.useEffect(() => {
    if (customerOrders?.length) {
      const requestedOrder = customerOrders.find(order => order.id === orderId);
      if (requestedOrder) {
        setPriorOrder(requestedOrder);
        // Set the order display date 
        const rawDate = new Date(requestedOrder.created_at * 1000).toDateString();  
        const dateItems = rawDate.split(' ');
        setOrderDate(`${dateItems[1]} ${dateItems[2]}, ${dateItems[3]}`); 
      }
    }
  }, [orderId, customerOrders]);

  /* NOTE: padding here is reduced via negative margin for mobile in CSS ---if changing, update both places */
  return (
    <BodyWrapper withStyle={{padding:'2rem 0 4rem',backgroundColor:'#eee'}} pageLayout={LayoutTypes.FullBleed}>
      <PageTitle title="ReOrder" />
      <div className={styles.reorderPage}>
        <BackLink withClass={styles.backToOrders} backToPath="/orders" backToText="Back to Your Orders" />
        <div className={styles.pageHeaderWrap}>
          <div className={styles.pageHeader}>
            <Header size={HeaderSize.Large} text="Reorder" isCentered withStyle={{marginBottom:0}} />
            { orderDate &&
              <div className={styles.headerNote}>
                Here's the products from your <b>{orderDate}</b> order.
                You can cart currently-stocked items and request any items that we don't have on hand.
              </div>
            }
          </div>
        </div>
        { priorOrder
          ? <ReOrderProvider order={priorOrder}>
              <ReOrderDetails />
            </ReOrderProvider>
          : null
        }
      </div>
    </BodyWrapper>
  )
}

ReOrderPage.propTypes = {
  orderId: PropTypes.string /* via router, undefined initially */
};

export default ReOrderPage;
