
// Currently used for session tracking
export const bostonNeighborhoodsByZipCode = new Map([
  ["02130", {display_name:"Jamaica Plain", trackingLabel:"jamaica_plain"}],
  ["02134", {display_name:"Brighton", trackingLabel:"brighton"}],
  ["02135", {display_name:"Brighton", trackingLabel:"brighton"}],
  ["02163", {display_name:"Brighton", trackingLabel:"brighton"}],
  ["02123", {display_name:"Fenway", trackingLabel:"fenway"}],
  ["02215", {display_name:"Fenway", trackingLabel:"fenway"}],
  ["02115", {display_name:"Fenway", trackingLabel:"fenway"}],
  ["02116", {display_name:"Back Bay", trackingLabel:"back_bay"}],
  ["02117", {display_name:"Back Bay", trackingLabel:"back_bay"}],
  ["02199", {display_name:"Back Bay", trackingLabel:"back_bay"}],
  ["02217", {display_name:"Back Bay", trackingLabel:"back_bay"}],
  ["02122", {display_name:"Dorchester", trackingLabel:"dorchester"}],
  ["02124", {display_name:"Dorchester", trackingLabel:"dorchester"}],
  ["02125", {display_name:"Dorchester", trackingLabel:"dorchester"}],
  ["02131", {display_name:"Roslindale", trackingLabel:"roslindale"}],
  ["02126", {display_name:"Mattapan", trackingLabel:"mattapan"}],
  ["02132", {display_name:"West Roxbury", trackingLabel:"west_roxbury"}],
  ["02136", {display_name:"Hyde Park", trackingLabel:"hyde_park"}],
  ["02137", {display_name:"Hyde Park", trackingLabel:"hyde_park"}],
  ["02108", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02109", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02110", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02111", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02112", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02113", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02114", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02133", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02196", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02201", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02203", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02204", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02205", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02206", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02211", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02212", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02222", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02241", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02266", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02283", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02284", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02293", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02297", {display_name:"North End", trackingLabel:"north_end_dt"}],
  ["02119", {display_name:"Roxbury", trackingLabel:"roxbury"}],
  ["02120", {display_name:"Roxbury", trackingLabel:"roxbury"}],
  ["02121", {display_name:"Roxbury", trackingLabel:"roxbury"}],
  ["02210", {display_name:"Seaport", trackingLabel:"seaport"}],
  ["02127", {display_name:"South Boston", trackingLabel:"southie"}],
  ["02298", {display_name:"South Boston", trackingLabel:"southie"}],
  ["02118", {display_name:"South End", trackingLabel:"south_end"}],
]);
