import React from 'react';

import CheckOut from '../checkout/CheckOut';
import PropTypes from 'prop-types';

/**
 * This controller for /checkOut (from the Menu app) doesn't really have a purpose anymore
 * Other than parsing some router props
 */
const PlaceOrderController = ({
  location={}   /* state from router */
}) => {

  const [returnToPath, setReturnToPath] = React.useState("/");
  const [showRegFlowSuccess, setShowRegFlowSuccess] = React.useState();

    React.useEffect(() => {
      const {
        returnToPath:backPath,
        showRegistrationSuccess // New Reg Flow
      } = location?.state || {};
      if (backPath) {
        setReturnToPath(backPath);
      }
      setShowRegFlowSuccess(!!showRegistrationSuccess);
    }, [location]);

  return (
    <CheckOut
      returnToPath={returnToPath}
      showRegistrationSuccess={showRegFlowSuccess} />
  );
}

PlaceOrderController.propTypes = {
  location: PropTypes.object,
};

export default PlaceOrderController;
