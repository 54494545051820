import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

// SEO Page Title
const PageTitle = ({
  title
}) => {
  return (
    title 
      ? <Helmet>
          <title>Zyp Run - {title}</title>
        </Helmet>
      : null  
  )
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export default PageTitle;
