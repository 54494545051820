import React from 'react';

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from './Button.module.css';

const useStyles = makeStyles({
  'themeButton': {
    color: '#fff',
    backgroundColor: 'var(--zrPrimaryDark)',
    '&:hover': {
      backgroundColor: 'var(--zrPrimaryVeryDark)'  
    }
  }
});

const ButtonWithPrice = ({
  text, 
  price,
  disabled,
  isFullWidth,
  withClass,
  handleClick}) => {

  /* ignore click function if disabled */
  const buttonClick = disabled ? event => event.preventDefault() : handleClick;
  const classes = useStyles();

  const buttonStyle = isFullWidth ? { width:'100%',paddingLeft:'1rem',paddingRight:'1rem' } : {};

  const buttonClasses = disabled 
    ? `${styles.button} ${classes.themeButton} ${styles.disabledButton}`
    : `${styles.button} ${classes.themeButton}`;

  return (
    <button 
      className={`${buttonClasses} ${styles.priceButton} ${withClass ? withClass : ''}`}
      style={buttonStyle}
      onClick={buttonClick}
    >
      <span className={styles.buttonText}>{text}</span>
      <span className={styles.buttonPrice}>{price}</span>
    </button>
  );
};

ButtonWithPrice.propTypes = {
  text: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  withClass: PropTypes.string,  
  handleClick: PropTypes.func
}

export default ButtonWithPrice;
