import React from 'react';

import useMinOrderForLocation from '../dispensary/useMinOrderForLocation';
import { dollarDisplay } from '../providers/OrderPricingProvider';
import Alert from '@material-ui/lab/Alert';
import styles from '../common/Alert.module.css'; 

/**
 * Determine Min Order based on Zip Code (or Default Min Order)
 */
const MinimumOrderWarning = () => {

  const {minOrderCents, currentLocation, isDefaultMinOrder} = useMinOrderForLocation();
  const [minOrderDisplay, setMinOrderDisaplay] = React.useState();
  
  React.useEffect(() => {
    if (minOrderCents) {
      setMinOrderDisaplay(dollarDisplay(minOrderCents, true))
    }    
  }, [minOrderCents]); 
  
  return ( minOrderDisplay
    ? <Alert severity="warning" className={styles.minimumWarning}>
        <div className={styles.miminumBody}>
          <div className={styles.minimumMsg}>
            Minimum Order: 
            <span className={styles.minimumAmount}>
              {minOrderDisplay}
              { isDefaultMinOrder
                ? ` (for Boston)` // Shouldn't really ever happen, address comes before cart view
                : ` (for ${currentLocation.zip_code})`
              }
            </span>
          </div>
          <div className={styles.minimumFootNote}>
            The Minimum Order is the product total after discounts, 
            before taxes and fees.
          </div>
        </div>
      </Alert>  
    : null
  );
} 

export default MinimumOrderWarning;
