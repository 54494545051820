import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import useProductsBySubCategory from './useProductsBySubCategory';
import ProductCarousel from './ProductCarousel';
import Spinner from '../common/Spinner';

import PropTypes from 'prop-types';

const SubCategoryCarousels = ({
  categoryName='Flower'
}) => {
  
  const { productsBySubCategory } = useProductsBySubCategory(categoryName);

  return ( productsBySubCategory
    ? <>
        {[...productsBySubCategory.keys()].map(subCategoryName => {
          const subCatProducts = productsBySubCategory.get(subCategoryName);  
          return <ProductCarousel
                   key={subCategoryName}
                   /* Handle "See All All Other Vaporizers" etc. */
                   carouselTitle={subCategoryName.replace('All ', '')}
                   categoryCount={subCatProducts.length}
                   carouselProducts={subCatProducts}
                   withMoreCard
                   seeAllClick={() => {
                    navigateTop(`/products/${categoryName}/${subCategoryName}`);
                   }} />
        })}
      </>
    : <Spinner />
  )
}

SubCategoryCarousels.propTypes = {
  categoryName: PropTypes.string
};

export default SubCategoryCarousels;
