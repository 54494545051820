import * as React from 'react';

import PropTypes from 'prop-types';

/**
 * Displays Image Coming Soon, etc.
 */
const MissingImageLabel = ({
  withClass='', 
  withStyle={}
}) => ( 
  <div className={withClass} style={withStyle}>
    Image Coming Soon
  </div>
)

MissingImageLabel.propTypes = {
  withStyle: PropTypes.object
}

export default MissingImageLabel;
