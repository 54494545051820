import { useReducer } from "react";
// TODO: track down useAnalyticsStore re-render issue
import { trackEvent, trackError } from "../analytics/tracking";

/**
 * Bank linking is a 2 step process. Using a reducer to handle messaging 
 * and state across both steps.
 */
const useAerosyncReducer = () => {
  
  const initialState = {
    widgetSuccess: undefined,   // widget success (bank selected)
    widgetError: undefined,     // widget error
    postbackSuccess: undefined, // ZR data post success
    updatedBankName: undefined, // Bank name returned in postback response
    postbackError: undefined,   // ZR data post error
    widgetLoading: false,       // e.g. show Spinner during widget load
    postbackInProgress: false,  // e.g. show spinner during postback
  };

  const responseReducer = (state, action) => {
    switch(action.type) {
      case 'widgetLoading':
        trackEvent('aerosync_widget_requested');
        return {
          ...initialState, // Reset flow!
          widgetLoading: true,
        }
      case 'widgetLoadComplete':
        trackEvent('aerosync_widget_loaded');
        return {
          ...state,
          widgetLoading: false,
        }
      case 'widgetSuccess':
        trackEvent('aerosync_linking_success');
        return {
            ...state,
            widgetSuccess: action.message,
            postbackInProgress: true,
        }
      case 'widgetError':
        trackError('aerosync_linking_error');
        return {
            ...state,
            widgetError: action.message,
            postbackInProgress: false,
        }
      case 'postbackSuccess':
        trackEvent('aerosync_postback_success');
        return {
            ...state,
            postbackSuccess: action.message,
            updatedBankName: action.bankName,
            postbackInProgress: false,
        }
      case 'postbackError':
        trackError('aerosync_postback_error');
        return {
            ...state,
            postbackError: action.message,
            postbackInProgress: false,
        }
      default: 
        return state
    }
  }

  // ensure state is defined!
  const [state={}, dispatch] = useReducer(responseReducer, initialState);
  
  return [state, dispatch];
}

export default useAerosyncReducer;
