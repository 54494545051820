import * as React from 'react';

import { STACKING } from './stackingConstants';
import CartToggle, { CartIconType } from '../checkout/CartToggle';
import ErrorBoundary from '../common/ErrorBoundary';
import PropTypes from 'prop-types';

import styles from './Layout.module.css';

// Various page layouts
export const LayoutTypes = {
  Default: "Default",
  Narrow: "Narrow",
  HomeGrid: "HomeGrid", 
  Registration: "Registration",
  FullBleed: "FullBleed"
};

/**
 * Set the content width
 * 
 * @param pageLayout {string}  sets content width etc.
 */
const BodyWrapper = ({
  pageLayout=LayoutTypes.Default,
  withStyle={}, 
  children
}) => {

  const overrideStyle = pageLayout === LayoutTypes.FullBleed ? styles.fullBleedWrapper : 
                        pageLayout === LayoutTypes.Narrow ? styles.narrowWrapper : 
                        pageLayout === LayoutTypes.HomeGrid ? styles.homeGridWrapper : '';
  return (
    <div className={`${styles.defaultWrapper} ${overrideStyle}`}
         style={withStyle}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
      {/* need to enable/disable based on page */}
      <CartToggle iconType={CartIconType.FOOTER_BUTTON} 
                  withCartStyle={{zIndex:STACKING.FOOTER_CART_BUTTON}} />
    </div>
  );
}

BodyWrapper.propTypes = {
  pageLayout: PropTypes.string,
  withStyle: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array])
}

export default BodyWrapper;
