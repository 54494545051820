import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import { optimizedImage } from './CroppedProductImage';
import { handleMissingProductImage } from './product-utils';
import MissingImageLabel from './MissingImageLabel';
import PropTypes from 'prop-types';

import addlStyles from '../products/PreviewProductImage.module.css';

/**
 * Component that optimizes product images for use in the Shopping Cart, etc.
 *
 * @param {string} imgUrl - image src
 * @param {string} imgAlt - product name
 * @param {string} imgClass - for styling
 * @param {string} imgCategory - for use in fallback image
 */
const CartProductImage = ({
  imgUrl,
  imgAlt,
  imgClass,
  imgCategory 
  }) => { 

    const { trackError } = useAnalyticsStore();

  // Sometimes CloudFlare returns 503 or even a 404 during outtages...  
  const handleError = (event) => {
    trackError('img_load_cloudflare_error');
    // Switch to non-CDN image:
    setImgSrc(imgSrcNoCDN);
    setIsFallbackImage(true);
    // we only need to log once
    event.currentTarget.onError = null;
  };
  
  const [imgSrc, setImgSrc] = React.useState();
  const [imgSrcNoCDN, setImgSrcNoCDN] = React.useState();
  const [isFallbackImage, setIsFallbackImage] = React.useState();
 
  // Specific to CartItem.jsx context
  const placeholderMessageStyle = {
    position: 'absolute',
    top: '68%',
    left: '50%',
    transform: 'translateX(-50%)',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    borderRadius: '12px',
    font: 'var(--zrFontSemi)',
    fontSize: '.675rem',
    lineHeight: '1.5rem',
    overflow: 'hidden'
  } 

  React.useEffect(() => {
    // Handle fallback image, and store fallback in case CDN fails (onError)
    const { imgUrl:imgOrFallbackUrl, fallbackUrl, isFallback } = handleMissingProductImage(imgUrl, imgCategory);
    if (!imgSrc) {
      setImgSrc(optimizedImage(imgOrFallbackUrl));
      setImgSrcNoCDN(fallbackUrl);
      setIsFallbackImage(isFallback); 
    }
  },[imgUrl, imgSrc, imgCategory]);

  return ( 
    imgSrc
      ? <div style={{position:'relative'}}>
          <img
            src={imgSrc}
            alt={imgAlt}
            className={imgClass}
            onError={(event) => handleError(event)}
            />
          { isFallbackImage &&
            <div style={placeholderMessageStyle}> 
              <MissingImageLabel withClass={addlStyles.missingImageLabel} withStyle={{padding:'0 1rem'}} />
            </div>
          }
        </div>  
      : null 
  )
}

CartProductImage.propTypes = {
  imgSrc: PropTypes.string, /* we handle null */
  imgAlt: PropTypes.string.isRequired,
  imgClass: PropTypes.string,
  imgCategory: PropTypes.string.isRequired
}

export default CartProductImage;
