import * as React from 'react';

import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded';

const CheckMark  = ({
  size=24,
  withStyle={}
}) => {

  const defaultStyle = {
    height: size,
    color: 'var(--zrPrimaryDark)',
    verticalAlign: -5
  }
  
  const checkStyle = Object.assign(defaultStyle, withStyle);

  return (
    <CheckCircleRounded style={checkStyle} />        
  ) 
}

export default CheckMark;
