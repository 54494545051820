import * as React from "react";
import { ModalType } from './ModalProvider';

/**
 * Custom hook used with ModalProvider to display modal content
 *
 * @returns 
 *   @param {ModalType} modalTye - Map, Login etc.
 *   @paran {object} modalProps - Props for use in modal component(s)
 *   @param {func} displayModal - Function used to display/hide modal
 */
const useModal = () => {
  
  const [modalType, setModalType] = React.useState(ModalType.NONE);
  const [modalProps, setModalProps] = React.useState({}); 

  // Modals are hidden/closed via ModalType.NONE
  const displayModal = React.useCallback((newModalType, newModalProps={}) => {
    if (modalType !== newModalType ||
        // handle suggested product modal-to-modal rerender 
        (modalProps.itemId !== newModalProps.itemId)) {
      setModalType(newModalType);
      setModalProps(newModalProps);
    }
  }, [modalType, modalProps]);

  return { 
    modalType,
    modalProps, 
    displayModal 
  };
};

export default useModal;
