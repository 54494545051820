import * as React from 'react';
import { ModalContext, ModalType } from '../modal/ModalProvider';
import closeX from '../../assets/closeX.png';
import styles from './ProductDetailsCard.module.css';

/**
 * An easy-to-reach modal close button for mobile
 */
const CloseModalButton = () => { 
  
  const { displayModal } = React.useContext(ModalContext); 

  return (
    <div className={styles.closeButtonWrap}>
      <span className={styles.closeButton} onClick={() => displayModal(ModalType.NONE)}>
        <img alt="Close Modal" className={styles.closeIcon} src={closeX} height="24" />
        <span className={styles.closeLabel}>Close</span>
      </span>
    </div>
  )
}  

export default CloseModalButton;
