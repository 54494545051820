import * as React from 'react';

import { makeStyles } from '@material-ui/core';
import BagIcon from './BagIcon';
import PropTypes from 'prop-types';

import styles from './CartIcon.module.css';

const useStyles = makeStyles({
  'themeIcon': {
    color: '#fff',
    backgroundColor: 'var(--zrPrimaryDark)',
    '&:hover': {
      backgroundColor:  'var(--zrPrimaryVeryDark)'  
    }
  }
});

/**
 * The Shopping Bag (cart) Icon for display in Footer Nav 
 * 
 * Doesn't always know whether the cart is in the layout so we can scrape the page
 * for the #LAYOUT_CART element.
 * 
 * @param {array} items - to display cart count 
 */

const CartIcon = ({items=[]}) => {
  
  const [iconStyle, setIconStyle] = React.useState({});
  const classes = useStyles(); 

  React.useEffect(() => {
    if (items.length === 0) { 
      setIconStyle({ backgroundColor: '#bccad2' });
    } else {
      setIconStyle({});
    }
  }, [items]);
  
  return (
    <div className={`${styles.cartIconWrapper} ${classes.themeIcon}`} style={iconStyle}>
      <BagIcon fillColor="#fff" hoverFillColor="#fff" />
      <span className={styles.itemCount}>{items.length}</span>
    </div>
  );
};

CartIcon.propTypes = {
  items: PropTypes.array
};

export default CartIcon;
