import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import { trackingString } from '../analytics/tracking';
import { FilteredProductsContext, SHOW_ALL, SALE_ONLY} from '../products/FilteredProductsProvider';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import styles from './ProductInfoFilter.module.css';

/**
 * This component will provide a Sale Item filter for both Desktop and Mobile layouts
 */ 
const PricingFilter = ({
  startOpen=false,
  inModal=false
}) => {
  
  const { trackEvent } = useAnalyticsStore();

  // Current filters context
  const { pricing:selectedPricing, hasSubCategorySaleItems, setFilters } = React.useContext(FilteredProductsContext);

  // Add the 'all' option 
  const pricingWithAll = [SHOW_ALL, SALE_ONLY];
  
  // Toggle the display
  const [showPricing, setShowPricing] = React.useState(startOpen);

  const toggleShowPricing = () => {
    if (labelRef.current) {
      labelRef.current.setAttribute('data-show-content', !showPricing);
    } 
    setShowPricing(!showPricing);
  };

  const pricingClick = (pricing) => {
    trackEvent(`filter_pricing_${trackingString(pricing)}_click`); 
    setFilters({pricing});
  };
  
  const labelRef = React.useRef();
  
  const addlClass = inModal ? styles.inModal : '';

  // This is a check for the existence of sale items
  return ( hasSubCategorySaleItems  
    ? <div className={`${styles.productInfoFilter} ${addlClass}`}> 
        <div ref={labelRef} data-show-content={showPricing} className={styles.sectionHdr} onClick={toggleShowPricing}>
          PRICING <ChevronRightRoundedIcon style={{verticalAlign:'-7px'}}/>
        </div>
        { showPricing &&
          <ul className={styles.filterLinks}>
            { pricingWithAll.map(pricing => {
              // On Sale link is red...
              const selectedClass = pricing === selectedPricing
                ? pricing === SHOW_ALL ? styles.selected : ` ${styles.salePricing} ${styles.selected}`
                : pricing === SHOW_ALL ? '' : styles.salePricing;
              const label = pricing === SHOW_ALL
                          ? 'Show All'
                          : 'On Sale';
              return <li key={pricing} 
                         className={`${styles.filterLink} ${selectedClass}`} 
                         onClick={() => {pricingClick(pricing)}}>
                       {label}
                       <span className={styles.selectedIcon}>
                         <CheckCircleRoundedIcon style={{color: '#054052', verticalAlign:'-6px'}}/>
                       </span>
                     </li>
            })}
          </ul>
        }  
      </div>
    : null 
  );
}

export default PricingFilter;
