import React, { useCallback, useRef } from 'react';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import PropTypes from 'prop-types';

import styles from '../registration/SignUpInline.module.css';

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_LEFT_ARROW = 37;
const KEY_RIGHT_ARROW = 39;

const ResetInputIcon = ({onReset}) => (
  <AddCircleRoundedIcon  
    className={styles.clearInput} 
    onClick={onReset} />
);

// References to each of the 5 inputs
// external so we don't have hook dependencies
const useInputRefs = () => {
  return [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];
};

// This set of inputs is for ZR FIVE DIGIT verfication codes
const EnterCode = ({code='', setCode=()=>{}}) => {
  
  // 5 Refs to control each digit input element
  const inputRefs = useInputRefs();

  // Reset all inputs and clear state
  const resetCode = () => {
    inputRefs.forEach(ref => {
      ref.current.value = '';
    });
    inputRefs[0].current.focus();
    setCode('');
  }

  // Handle input
  const handleInput = useCallback((e, index) => {
    const input = e.target;
    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];
    // Assign the input value to the corresponding single-digit ref
    inputRefs[index].current.value = input.value.length ? input.value.substring(input.value.length - 1) : '';
    // Build new char array from refs
    const newCode = inputRefs.reduce((acc, ref) => {
      if(ref.current.value) {
        acc.push(ref.current.value);   
      }
      return acc;
    }, []); 

    setCode(newCode.join(''));  
    input.select();  
      
    // Select previous input while clearing inputs
    // But only if next input is blank (allow middle edits)
    if (input.value === '' && 
        previousInput &&
        (!nextInput || (nextInput && nextInput.current.value === ''))) {
      previousInput.current.focus();
    // Select next input after entry, if current input has a value
    } else if (input.value !== '') {
      if (nextInput) {
        nextInput.current.select();
      } else {
        // Last digit entered, so blur
        input.blur();    
      }
    }
  }, [setCode, inputRefs]);

  // Select the contents on focus for easy delete
  const handleFocus = (e) => e.target.select();

  const handleKeyDown = (e, index) => {
    const input = e.target;
    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1]; 
    // Handle backspace key
    if ([KEY_BACKSPACE, KEY_DELETE].includes(e.keyCode) && 
         input.value === '') {
      e.preventDefault();
      setCode((prevCode) => prevCode.slice(0, index) + prevCode.slice(index + 1));
      if (previousInput) {
        previousInput.current.focus();
      }
    // Handle arrow navigation  
    } else if ([KEY_LEFT_ARROW].includes(e.keyCode)) {
        e.preventDefault();
        if (previousInput) {
          previousInput.current.focus();
        } else {
          input.select();  
        }  
    } else if ([KEY_RIGHT_ARROW].includes(e.keyCode)) {
        e.preventDefault();
        if (nextInput) {
          nextInput.current.focus();
        } else {
          input.select();    
        }
    }
  }

  // Handle pasted code
  const handlePaste = (e) => {
    const clipboardText = e.clipboardData.getData('text');
    const pastedCode = clipboardText?.length ? clipboardText.substring(0,5) : '';

    if (pastedCode?.length) {
      // assign 1 char per input
      for (let i = 0; i < pastedCode.length; i++) {
        inputRefs[i].current.value = pastedCode.charAt(i);
      }
      // Select last input which somehow advances to next available if available
      inputRefs[pastedCode.length - 1].current.select();
      setCode(pastedCode);
    }
  };

  return (
    <div className={styles.digitInputs}>
      {[0, 1, 2, 3, 4].map((index) => (
        <input
          key={`code${index}`}
          ref={inputRefs[index]}
          autoFocus={index === 0}
          onFocus={handleFocus}
          className={styles.digitInput}
          type="number"
          inputMode="numeric"
          maxLength={1}
          onChange={(e) => handleInput(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onContextMenu={(e) => e.preventDefault()}
          onPaste={handlePaste} />
      ))}
      { code?.length
        ? <ResetInputIcon onReset={resetCode} />
        : null
      }
    </div>
  );
}

EnterCode.propTypes = {
  code: PropTypes.string,
  setCode: PropTypes.func.isRequired,    
}

export default EnterCode;
