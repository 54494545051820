import * as React from 'react';

import ErrorMessage from '../styleguide/ErrorMessage';

import { makeStyles } from '@material-ui/core';

// default size is 195x195
const useStyles = makeStyles(theme => ({
  canvas: {
    width: '250px !important',
    height: '250px !important',
    display: 'block',
    margin: '0 auto'
  }
}));

const DisplayQRCode = ({url}) => {
  
  const classes = useStyles();
  const [error, setError] = React.useState();
  const canvasRef = React.useRef();

  React.useEffect(() => {
    const opts = {
      width: 253,
      color: {
        light:"#daebfc"  /* Zyp Run blue-ish */
      }
    };
    if (url && canvasRef.current) {
      // Defer load (reduce bundle size)
      import('qrcode').then(QRCode => {
        QRCode.toCanvas(canvasRef.current, url, opts, function (error) {
          if (error) {
            setError(error);
          }
        })
      })
    }
  }, [url]) 

  return ( error
    ? <ErrorMessage text={error} />
    : url 
      ? <div style={{minHeight:250}}>
          <canvas className={classes.canvas} ref={canvasRef}></canvas>
        </div>
      : null  
  )
}

export default DisplayQRCode;  
