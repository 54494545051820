import React from 'react';

import config from '../../config';
import { UserContext } from '../providers/UserProvider';
import { AeropayContext } from '../payment/AeropayProvider';
import OrderValidityProvider from './OrderValidityProvider';
import { DiscountCreditAndFeeContext, AEROPAY_SIGNUP_CREDIT_CENTS  } from '../providers/DiscountCreditAndFeeProvider';
import useCustomerOrders from '../orders/useCustomerOrders';
import { SHOPPING_MILESTONES } from '../analytics/analytics-utils';
import { CommerceLoggingContext } from '../analytics/CommerceLoggingProvider';
import usePaymentOptionReducer, { aeropayPaymentTypeValue } from './usePaymentOptionReducer';
import { formatPhoneNumber } from 'react-phone-number-input'
import CurrentTimeProvider from '../timeSlots/CurrentTimeProvider';
import DisplayDeliverySlots from '../timeSlots/DisplayDeliverySlots';
import PlaceOrderItems from './PlaceOrderItems';
import SelectPayment from './SelectPayment';
import EnterDiscountCode from '../discounts/EnterDiscountCode';
import Header, { HeaderSize } from '../styleguide/Header';
import DisplayCreditAtCheckout from '../refer/DisplayCreditAtCheckout';
import FeesTaxesAndTotal from './FeesTaxesAndTotal';
import OrderDeliveryButton from './OrderDeliveryButton';
import useOrderDetailsReducer from './useOrderDetailsReducer';
import AddDeliveryNote from './AddDeliveryNote';
import RegistrationSuccessOneLine from '../registration/RegistrationSuccessOneLine';
import ErrorBoundary from '../common/ErrorBoundary';
import BackLink from '../common/BackLink';
import PropTypes from 'prop-types';

import styles from './PlaceOrder.module.css';

const createLabelId = (title) => {
  return `label_for_${title?.toLowerCase().replace(/\W+/g, '_')}`;
}

/**
 * For accessibility we'll add an id to the title for use in the form:
 * aria-labelledby={id}
 */
export const OrderSection = ({title, children}) => {
  return (
    <div className={styles.section}>
      { title && (
        <div className={styles.sectionTitle}
             id={createLabelId(title)}>
          {title}
        </div>
      )}
      <div className={styles.sectionInner}>
        {children}
      </div>
    </div>
  );
};

/**
 * Place Order - Delivery
 *
 * NOTE: Avoid adding custom hooks here which may result
 *       in additional jumpy re-renders, use the hooks in the sub-components
 */
const PlaceOrder = ({
  returnToPath,
  showRegistrationSuccess
}) => {

  // Enabled payment options and current selection
  const [paymentMethodState, dispatch] = usePaymentOptionReducer();
  const [orderDetailsState, detailsDispatch] = useOrderDetailsReducer();

  /* Additional order details */
  const [selectedDeliverySlot, setSelectedDeliverySlot] = React.useState();

  const { user, location:delivery_address,
          userPhone, hasCompletedFirstAeropayOrder } = React.useContext(UserContext);
  const { street_address, apartment_number, city, state, zip_code } = delivery_address || {};
  const displayApartmentNumber = apartment_number ? `${' '}${apartment_number}`: '';

  // Check for existing same-day orders
  const { activeOrderDates, activeOrderCount } = useCustomerOrders(user);
  // Show submit errors at bottom of page (within OrderDeliveryButton)
  const [submitError, setSubmitError] = React.useState();

  // Commerce Logging
  const { logCommerceAction } = React.useContext(CommerceLoggingContext);
  const { linkedAccountId } = React.useContext(AeropayContext);
  const { setAeropayCreditCents } = React.useContext(DiscountCreditAndFeeContext);

  // TODO: This is not 100% accurate now that we allow multiple active orders
  const isAeropayCreditEligible = React.useCallback(() => {
    return config.ENABLE_AEROPAY_CREDIT &&
      !hasCompletedFirstAeropayOrder;
  }, [hasCompletedFirstAeropayOrder]);

  React.useEffect(() => {
    // User has attempted to view checkout
    logCommerceAction(SHOPPING_MILESTONES.CHECKOUT_VIEWED);
  }, [logCommerceAction]);

  const handlePaymentUpdate = React.useCallback((selectedMethod) => {
    dispatch({type:'selectedMethod', selectedMethod })
    if (selectedMethod === aeropayPaymentTypeValue) {
      setAeropayCreditCents(isAeropayCreditEligible()
        ? AEROPAY_SIGNUP_CREDIT_CENTS
        : 0
      );
    }
  }, [dispatch, isAeropayCreditEligible, setAeropayCreditCents]);

  // Set the initial/default value
  React.useEffect(() => {
    if (linkedAccountId) {
      dispatch({
        type:'aeropayLinked',
        enableAeropayCredit: isAeropayCreditEligible()
      });
    }
  },[dispatch, linkedAccountId, isAeropayCreditEligible])

  return (
    <OrderValidityProvider
      selectedDeliverySlot={selectedDeliverySlot}
      activeOrderDates={activeOrderDates}>
      <div className={styles.placeOrderWrapper}>
        <OrderSection>
          { returnToPath &&
            <BackLink backToPath={returnToPath} trackLabel="checkout" />
          }
          <Header
            withClass={styles.header}
            size={HeaderSize.Large}
            isCentered text='Checkout' />
          {/* New Reg Flow */}
          { showRegistrationSuccess &&
            <RegistrationSuccessOneLine />
          }
        </OrderSection>

        <OrderSection title="DELIVERY TIME">
          <CurrentTimeProvider>
            <ErrorBoundary>
              <DisplayDeliverySlots
                selectedDeliverySlot={selectedDeliverySlot}
                setSelectedDeliverySlot={setSelectedDeliverySlot} />
            </ErrorBoundary>
          </CurrentTimeProvider>
        </OrderSection>

        <OrderSection title="ADDRESS">
          { street_address &&
            <span>
              {`${street_address}${displayApartmentNumber}, ${city}, ${state}, ${zip_code}`}
            </span>
          }
        </OrderSection>

        <OrderSection title="PHONE NUMBER">
          { userPhone &&
            <span>{formatPhoneNumber(userPhone)}</span>
          }
        </OrderSection>

        <OrderSection title="PAYMENT">
          <SelectPayment
            formLabelId={createLabelId('PAYMENT')}
            selectedMethod={paymentMethodState.selectedMethod}
            paymentMethodState={paymentMethodState}
            updatePaymentMethod={handlePaymentUpdate}
            isAeropayCreditEligible={isAeropayCreditEligible()} />
        </OrderSection>

        <OrderSection title="SUMMARY">
          <PlaceOrderItems />
        </OrderSection>

        <DisplayCreditAtCheckout />

        <OrderSection title="PROMO CODE">
          <EnterDiscountCode
            setSubmitError={setSubmitError} />
        </OrderSection>

        <OrderSection title="DELIVERY NOTE">
          <AddDeliveryNote
            orderDetailsState={orderDetailsState}
            onNoteEntered={(note) => {
              // Handle update to empty: \n etc.
              const updatedNote = /[a-zA-Z]/.test(note)
                ? note
                : '';
              detailsDispatch({
                type: 'deliveryNote',
                deliveryNote: updatedNote
              })
            }} />
        </OrderSection>

        <OrderSection>
          <FeesTaxesAndTotal
            selectedDeliverySlot={selectedDeliverySlot}
            activeOrderCount={activeOrderCount} />
          <OrderDeliveryButton
            selectedDeliverySlot={selectedDeliverySlot}
            paymentMethod={paymentMethodState.selectedMethod}
            orderDetailsState={orderDetailsState}
            submitError={submitError}
            setSubmitError={setSubmitError} />
        </OrderSection>
      </div>
    </OrderValidityProvider>
  )
}

PlaceOrder.propTypes = {
  returnToPath: PropTypes.string,
  showRegistrationSuccess: PropTypes.bool,
};

export default PlaceOrder;
