import * as React from 'react';

import { dispensaryProductsEndpoint } from '../../constants/api-endpoints'; 

// A fetch hook for use in the brand/product provider - vendorId required
function useBrandProductsFetch(user, vendorId) {

  const [data, setData] = React.useState();
  const [brands, setBrands] = React.useState();
  const [loading, setLoading] = React.useState();
  const [error, setError] = React.useState();

  const controllerRef = React.useRef(new AbortController());
  // Alpha sort partner brands 
  const uniqueBrands = (products) => {
    const brandSet = new Set();
    products.forEach(product => brandSet.add(product.display_info.brand));
    return Array.from(brandSet).sort((a,b) => a > b ? 1: -1);
  }
 
  React.useEffect(() => {
    
    const controller = controllerRef.current.signal.aborted 
      ? new AbortController()
      : controllerRef.current;

    if (user && vendorId) {
      user.getIdToken().then(idToken => {
        setLoading(true);
        setData(null);
        setError(null);
        fetch(`${dispensaryProductsEndpoint}${vendorId ? `?vendor_id=${vendorId}` : ''}`, 
          { 
            method: 'GET',
            headers: {
              'Authorization': idToken
            },
            Accept: 'application/json',
            signal: controller.signal
          })
        .then(response => response.json())
        .then(json => {
          setLoading(false);
          if (json) {
            setData(json.data);
            setBrands(uniqueBrands(json.data));
          }  
        })
        .catch(err => {
          setLoading(false)
          setError('An error occurred...')
        })
      })
    }
    return () => {
      controller.abort();
    }
  }, [user, vendorId])

  return { 
    data,
    brands, 
    loading, 
    error 
  }
}

export default useBrandProductsFetch;
