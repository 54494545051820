import * as React from 'react';

import ToolTip from '../common/ToolTip';
import { formattedHours } from './StoreInfo';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  inner: {
    padding: '0 0 .5rem',
    fontSize: '1rem',
  },
  key: {
    fontWeight: 600,
    flex: 1,
    paddingRight: 12
  },
  value: {
    flex: 0,
    whiteSpace: 'nowrap'
  }
})

const StoreHoursToolTip = ({hours_of_operation=[]}) => {
  
  const classes = useStyles();

  // 9:00am - 5:00pm, etc.
  return ( hours_of_operation?.length
    ? <>
        <ToolTip
          buttonTextBefore="Zyp Run Delivery Schedule" 
          tipTitle="Zyp Run Delivery Hours"
          placement='bottom'
          toolTipStyle={{zIndex:2}}>
          <div className={classes.inner}>
            { hours_of_operation?.map(day => {
              return  <div key={day.weekday} style={{display:'flex'}}>
                        <span className={classes.key}>
                          {day.weekday.substring(0,3).toUpperCase()}: 
                        </span>
                        <span className={classes.value}>{formattedHours(day)}</span>
                      </div>
            })}
          </div>
        </ToolTip>
      </>
    : null    
  )
}

export default StoreHoursToolTip;
