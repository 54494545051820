import config from '../../config';
import { devLog } from './util';

// Assume that users on this device likely have an account already
export const regFlowSuccessKey = 'regFlowSuccess';
// Stored address from typeahead
export const userAddressKey = 'userAddress';
// NOTE: This key is also used on the Marketing Site, so don't modify
export const userIs21Key = 'zrIs21';
// Delivery not available, show mail chimp subscribe
export const emailSubscribedKey = 'emailSubscribed';
// MailChimp Email Notify form shown
export const emailSubscribeFormShownKey = 'emailSubscribeForm';
// Referring User Key ( for refer credits at registration )
export const referringUserKey = 'referringUser';
// Hide header referral callout
export const hideHeaderReferralCalloutKey = 'hideHeaderReferralCallout';
// Hide store referring user details (fraud detect)
export const referrerFromDeviceKey = 'referrerFromDevice';
// Hide Cart reminder product availabilty message
export const hideCartReminderMsgKey = 'hideCartReminderMsg';

export const isStorageSupported = (isSimpleCheck) => {
  var storage = window.localStorage;
  if (isSimpleCheck) {
    return storage && !!storage.getItem;
  }
  try {
    storage.setItem('test', 'test');
    storage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
};

// Check for a stored value
export const hasStoredValue = (key) => {
  if (isStorageSupported(true)) {
    const storedVal = window.localStorage.getItem(key);
    return !!storedVal;
  }
  return false;
};

// Store a value for use in storage
export const storeValue = (key, value) => {
  try {
    if (isStorageSupported()) {
      window.localStorage.setItem(key, value);
    }
  } catch(e) {
    devLog(`storage set error ${e}`);
  }
};

// Store a value for use in storage
export const storeValueStringified = (key, obj) => {
  try {
    if (isStorageSupported()) {
      window.localStorage.setItem(key, JSON.stringify(obj));
    }
  } catch(e) {
    devLog(`storage set error ${e}`);
  }
};

// Get a stored value
export const getStoredValue = (key) => {
  try {
    if (isStorageSupported(true)) {
      return window.localStorage.getItem(key);
    }
  } catch(e) {
    devLog(`storage get error ${e}`);
  }
  return null;
};

// Get a stored value as JSON
export const getStoredValueJson = (key) => {
  try {
    if (isStorageSupported(true)) {
      const storedString = window.localStorage.getItem(key);
      return storedString ? JSON.parse(storedString) : undefined;
    }
  } catch(e) {
    devLog(`storage get error ${e}`);
  }
  return null;
};

// Remove a stored value
export const removeStoredValue = (key) => {
  try {
    if (isStorageSupported(true)) {
      window.localStorage.removeItem(key);
    }
  } catch(e) {
    devLog(`storage remove error ${e}`);
  }
};

// Get a url hashed value ( zip code etc.)
export const getUrlHashedValue = (key) => {
  // app.ziprun.com#zip.12345+showlogin.true
  const hashParams = window.location.hash.substring(1);
  if (hashParams.indexOf(`${key}.`) > -1) {
    const keyRegex = new RegExp(`${key}.(\\w+)`);
    return hashParams.match(keyRegex)[1];
  }
  return null;
};

// Get a url hashed value ( zip code etc.) else return stored value
export const getUrlHashedOrStoredValue = (hashKey, storageKey) => {
  // any hashed value should be stored (overwrite existing)
  const hashedVal = getUrlHashedValue(hashKey);
  if (hashedVal) {
    storeValue(storageKey, hashedVal);
    return hashedVal;
  } else {
    return getStoredValue(storageKey);
  }
};

export const setSessionVal = (key, val) => {
  try {
    window.sessionStorage.setItem(key, val);
  } catch (e) {
    // too bad.
  }
};

export const removeSessionVal = (key) => {
  try {
    window.sessionStorage.removeItem(key);
  } catch (e) {
    // too bad.
  }
};

export const getSessionVal = (key) => {
  try {
    return window.sessionStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

// The default test group is 'sessionTest' but this
// supports addition 50/50 test groups: sessionTest2, etc.
export const isTestSession = (testLabel='zr_test') => {
  devLog('Running isTestSession!');
  if (!config.ENABLE_TESTS) {
    return false;
  }
  // Handle URL Hash enable: #test for 'zr_test'
  if (window.location.hash.substring(1) === testLabel.substring(3)) {
    setSessionVal(testLabel, true);
    return true;
  // Handle URL Hash disable: #testoff
  } else if (window.location.hash.substring(1) === `${testLabel.substring(3)}off`) {
    setSessionVal(testLabel, false);
    return false;
  }
  // Handle existing session value
  const previouslySet = getSessionVal(testLabel);
  if (typeof previouslySet === "string") {
    return previouslySet === "true"
  }
  // Else: 50/50 coin toss
  const isTest = Math.random() < 0.5;
  // stored value is string!
  setSessionVal(testLabel, isTest);
  return isTest;
}
