import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import useFetch from '../util/useFetch';
import { dispensaryProductBySkuEndpoint } from '../../constants/api-endpoints';

import PropTypes from 'prop-types';

/**
 * Hook that returns a product by Dutchie Sku
 * 
 * NOTE: The bySku fetch can return multiple products (same product, different batch)
 * so we have to sort by quantity_available after fetching 
 */
const useProductDetailsBySku = (itemSku) => {

  const { user } = React.useContext(UserContext);

  const productDetailEndpoint = dispensaryProductBySkuEndpoint(itemSku);
  const isPaused = typeof itemSku !== "string"; 
  const { data:products, status, error } = useFetch(user, productDetailEndpoint, true, '', isPaused);

  const [productToUse, setProductToUse] = React.useState();
  React.useEffect(() => {
    if (products?.length >= 0) {
      // highest to lowest
      products.sort((a,b) => b.quantity_available - a.quantity_available);
      setProductToUse(products[0]);
    } 
  }, [products]);

  // Load product
  return {
    productId: productToUse?.id,
    product: productToUse,
    status,
    error
  }
}  

useProductDetailsBySku.propTypes = {
  itemSku: PropTypes.string.isRequired,
}

export default useProductDetailsBySku;
