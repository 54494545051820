import * as React from 'react';
import { navigateTop } from '../routing/router-utils';
import { trackEvent } from '../analytics/tracking';
import styles from '../common/Alert.module.css';

const CartLoginPrompt = () => {
  
  React.useEffect(() => {
    trackEvent('verify_cart_login_display');
  }, [])

  const loginClick = () => {
    trackEvent('verify_cart_login_click');
    navigateTop('/signIn', { state: { 
      returnToPath: `${window.location.pathname+window.location.search}`,
    }})
  }
  
  return (
    <div  className={styles.cartLoginPrompt}>
      Please
      {' '}
      <span className={styles.cartLoginLink} onClick={loginClick}>
        Log In to your account
      </span> 
      {' '}
      so we can retrieve your <b>shopping bag items</b>!
    </div>
  )
}

export default CartLoginPrompt;
