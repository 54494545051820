import * as React from 'react';

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  wrapper: {
    display: 'inline-block',
    minWidth: '4.5rem',
    padding: '3px 0',
    textAlign: 'center',
    lineHeight: '20px',
    font: 'var(--zrFontBold)',
    color: '#000',
    border: '2px solid #000',
    borderRadius: '.25rem'
  },
  weight: {
    fontWeight: 'normal'
  }  
});

export const GRAMS_TO_DISPLAY_OUNCES = new Map([
  ['3.5', '1/8 oz'],
  ['7', '1/4 oz'],
  ['14', '1/2 oz'],
  ['28', '1 oz'] 
]);

export const WEIGHT_ENABLED_CATEGORIES = [
  'flower',
  'vaporizers',
  'concentrates'
];

const ProductWeightPill = ({grams, price, category}) => {

  const classes = useStyles();
  const [displayWeight, setDisplayWeight] = React.useState();

  React.useEffect(() => {
    if (grams && typeof displayWeight === 'undefined') {
      if (GRAMS_TO_DISPLAY_OUNCES.has(grams.toString())) {
        setDisplayWeight(GRAMS_TO_DISPLAY_OUNCES.get(grams.toString()));
      } else {
        setDisplayWeight(`${grams}g`);
      }  
    }  
  }, [grams, displayWeight]); 
  
  return (
    <div className={classes.wrapper}>
      { displayWeight && WEIGHT_ENABLED_CATEGORIES.includes(category.name)
        ? <div className={classes.weight}>{displayWeight}</div>
        : null
      }
      { price && 
        <div>${price}</div>
      }
    </div>
  )
}

ProductWeightPill.propTypes = {
  grams: PropTypes.number, 
  price: PropTypes.string.isRequired,
  category: PropTypes.object.isRequired
}

export default  ProductWeightPill;
