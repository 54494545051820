import * as React from "react";

import config from "../../config";
import { UserContext } from '../providers/UserProvider';
import { PartnerContext } from "./PartnerProvider";
import { ProductCategoryContext } from "../providers/ProductCategoryProvider";
import useBrandProductsFetch from "./useBrandProductsFetch"; 
import { groupByStandardCategories } from '../products/product-utils';
export const BrandProductsContext = React.createContext({});

/**
 * This product provider is used for the brand and partner pages
 */
const BrandProductsProvider = ({brandName, children}) => {

  const { user } = React.useContext(UserContext);
  const { vendorIdByName } = React.useContext(PartnerContext);
  const { productCategories } = React.useContext(ProductCategoryContext);
  // All dispensary products keyed by category name
  const [productsByCategory, setProductsByCategory] = React.useState();
  // Supply only brand-relevant categories
  const [brandProductCategories, setBrandProductCategories] = React.useState();
  
  /**
   * Here we support both:
   *   /brand/mission (vendor Id from static partner data)
   *   /partner/41052 (vendor Id from path)
   */
  const vendorId = /^\d+$/.test(brandName)
    ? parseInt(brandName, 10)
    : vendorIdByName?.size
      ? vendorIdByName.get(brandName?.toLowerCase().replaceAll(/\W+/g,''))
      : config.IS_DEV && vendorIdByName?.size === 0
        ? 41040 // BR for dev when nothing's setup
        : undefined;
  
  // fetch aborted on null vendorId
  const { data, brands, loading } = useBrandProductsFetch(user, vendorId);

  React.useEffect(() => {
    if (data && productCategories) {
      // INITIAL PRODUCT FETCH
      console.log('BrandProductsProvider setup!');
      const byCategory = groupByStandardCategories(data);
      setProductsByCategory(byCategory);
      // category subset for the partner
      setBrandProductCategories(
        productCategories.filter(cat => 
          byCategory.has(cat.display_name)
        )
      );
    }
  }, [data, brandName, productCategories]);

  return (
    <BrandProductsContext.Provider value={{
      productsByCategory,
      brandProductCategories,
      brands,
      loading
    }}>
      {children}
    </BrandProductsContext.Provider>
  );
};

export default BrandProductsProvider;
