import * as React from 'react';

/**
 * Currently used for:
 * 1) Displaying "1 Per Order" warning when placing order
 */
const useCartItemWarnings = (
    id, 
    productName, 
    quantity,
    perOrderMaxQty,
  ) => {
  
  const [itemWarnings, setItemWarnings] = React.useState([]);

  React.useEffect(() => {
    const warnings = [];
    if (perOrderMaxQty && quantity > perOrderMaxQty) {
      warnings.push({
        id,
        message: `Limit: ${perOrderMaxQty} Per Order!`,
        recommendation: `Reduce the quantity of ${productName} before submitting your order.`, 
      });
    }
    setItemWarnings(warnings);
  },[id, productName, quantity, perOrderMaxQty])
  
  return itemWarnings;
}

export default useCartItemWarnings;
