import * as React from 'react';

import { CartItemsContext } from './CartItemsProvider';
import CartHeaderDelivery from './CartHeaderDelivery';
import CartSuggestedProductsProvider from '../suggestedProducts/CartSuggestedProductsProvider';
import CartSuggestedProductsCarousel from '../suggestedProducts/CartSuggestedProductsCarousel';
import EmptyCart from './EmptyCart';
import CartItem from './CartItem';
import ErrorBoundary from '../common/ErrorBoundary';
import PropTypes from 'prop-types';

import styles from './Cart.module.css';

// cart fingerprint
export const getCartDetails = (cartItems) => {
  if (!cartItems.length) {
    return 'none';
  }
  const prodIds = cartItems.map(item => item.id);
  const prodQuantities = cartItems.map(item => item.quantity);
  return prodIds.concat(prodQuantities).join('|'); 
}

const Cart = ({ 
  cartType="", 
  closeMenuFn,
}) => {
  
  // The id is used for tests 
  const elemId = `CART_${cartType.toUpperCase()}`;
 
  const { cartItems, flowerGrams, removeItem, increaseQuantity, decreaseQuantity } = React.useContext(CartItemsContext);

  return (
    <div id={elemId} data-cart-element="true" className={styles.cart}>
      { cartItems.length
        ? <div className={styles.cartInner}>
            <CartHeaderDelivery 
              flowerGrams={flowerGrams}
              closeMenuFn={closeMenuFn} /> 

            <ErrorBoundary>
              <ul className={styles.cartItems}>
                { cartItems.map(item => {
                  return <CartItem
                    key={item.id}
                    product={item}
                    quantity={item.quantity}
                    totalFlowerGrams={flowerGrams}
                    handleRemove = {() => removeItem(item.id)}
                    handleIncrease = {() => increaseQuantity(item.id,1)}
                    handleDecrease = {() => decreaseQuantity(item.id,1)} 
                  />
                })}
              </ul>
              <div className={styles.suggestWrap} >          
                <CartSuggestedProductsProvider>
                  <CartSuggestedProductsCarousel /> 
                </CartSuggestedProductsProvider>
              </div>
            </ErrorBoundary>
          </div>
        : <EmptyCart />
      }   
    </div>           
  )
}

Cart.propTypes = {
  cartType: PropTypes.string.isRequired,
  closeMenuFn: PropTypes.func,
};

export default Cart;
