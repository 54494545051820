import * as React from "react";

import { isSuperDeal } from "../products/useSaleLabelDetails";
import Alert from "@material-ui/lab/Alert";
import StrongText from "../common/StrongText";

import PropTypes from 'prop-types';

const SuperDealCartWarning = ({
  discountCode,
  cartItems,
  itemTotal,
  withClass}) => {

  // Super deal products
  const [dealProducts, setDealProducts] = React.useState();

  React.useEffect(() => {
    const dealItems = cartItems?.filter(item => isSuperDeal(item));
    setDealProducts(dealItems);
  }, [discountCode, cartItems, itemTotal]); // cartItems is a ref!

  return ( discountCode && dealProducts?.length
    ? <Alert severity="warning" className={withClass ? withClass : ''}>
        <div>
          <StrongText noWrap>Discount Codes</StrongText> cannot be combined with
          <StrongText noWrap>Super Deal</StrongText> products.
          To place your order, remove the discount code OR remove the
          following <StrongText noWrap>Super Deal</StrongText> products from your cart:
        </div>
        <table style={{ marginTop:6, width: '100%'}}>
          <tbody>
            <tr>
              <th style={{textAlign:'left'}}>Super Deal</th>
            </tr>
            { dealProducts.map(product => (
              <tr key={product.id}>
                <td>{product.display_info.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Alert>
    : null
  );
};

SuperDealCartWarning.propTypes = {
  discountCode: PropTypes.string,
  cartItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
  })),
  withClass: PropTypes.string,
}

export default SuperDealCartWarning;
