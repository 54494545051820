import * as React from 'react';

import styles from '../orderLimit/OrderLimit.module.css';
import PropTypes from 'prop-types';

/**
 * Display order quantity limits if specified via Dutchie tag
 */
const OrderLimitPillForModal = ({
  per_order_max_quantity, // Via Dutchie tag: "per-order-limit-1"
  withStyle={}
}) => {

  return ( per_order_max_quantity > 0
    ? <div className={styles.qtyLimit} style={withStyle}>
        <sup className={styles.sup}>*</sup>
        Limit: {per_order_max_quantity} Per Order
        <sup className={styles.sup}>*</sup>
      </div>
    : null
  )
}

OrderLimitPillForModal.propTypes = {
  per_order_max_quantity: PropTypes.number,
  withStyle: PropTypes.object,
}

export default OrderLimitPillForModal;
