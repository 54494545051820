import config from '../config';

/** CUSTOMER **/

// registration
export const newCustomerEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/customer`;
// customer info
export const customerInfoEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/customer/me`;
// email availability check
export const emailAvailabilityEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/email_availability?email=`;
// password strength
export const passwordStrengthEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/password_strength?password=`;
// delivery address blacklist
export const addressBlackListEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/delivery_address_blacklist`;
// customer cart save/restore
export const customerCartEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/customer/cart`;
// notifications read
export const notificationsReadEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/customer/notifications_read`;
// id verification
export const idVerificationEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/customer/id_verification`;
// telephone verification
export const telephoneVerificationEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/telephone_verification`;

/* Log in via phone */
export const requestLoginCodeEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/login_code`;
export const debugCustomTokenEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/custom_token`; 
/* End Log in via phone */

/** DISPENSARIES **/
// all partners/vendors
export const partnersEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/partners`;
// all dispensaries
export const dispensariesEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/dispensary`;
// single dispensary ( includes delivery slots )
export const dispensaryDetailEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/dispensary/[dispId]`;
// dispensary pickup options/timeslots 
export const pickupOptionsEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/dispensary/[dispId]/pickup_options`;
// dispensary delivery options/timeslots 
export const deliveryOptionsEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/dispensary/[dispId]/delivery_options`;
// meta data ( total price calcs / fee structure )
export const metadataEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/metadata`;
// promo text
export const promoTextEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/dispensary/[dispId]/promo_text`;
// commerce analytics ( not Firebase )
export const commerceAnalyticsEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/session_tracking/dispensary/[dispId]`;
// menu theme JSON
export const menuThemeEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/dispensary/[dispId]/menu_configuration`;

/** PRODUCTS **/

// all dispensary products
export const dispensaryProductsEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/inventory`;

// single dispensary product details used with ?product_id=id
export const dispensaryProductDetailEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/inventory/[productId]?include_zero_quantity=true`;

// dispensary products retrieved by Dutchie SKU (product_id is short-lived)
// NOTE: Response products may not be in stock (quantity=0)
export const dispensaryProductBySkuEndpoint = (sku) => 
  `${config.REACT_APP_API_DOMAIN}/api/v1/public/inventory/sku/${sku}`;
// products by categories across all dispensaries
export const productsByCategoryEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/products`; 
// Retrieve all valid/configured categories
export const productCategoriesEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/product_categories`;
// Retrieve all valid/configured sub-categories
export const productSubCategoriesEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/product_sub_categories`;

/** ORDERS **/

// Validate order pre-payment (aeropay)
export const validateOrderEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/order/dispensary/[dispId]/validity`;
// Submit Order: append dispensary id
export const newOrderEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/order/dispensary/`;
// Submit Guest Pickup order: append dispensary id
export const guestOrderEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/order/guest/dispensary/`;
// Retrieve Orders: append user's uid
export const retreiveOrdersEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/order/customer`;
// Retrieve a users AeroPay account info (if exists) or empty array
export const aeropayLinkedAccountEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/customer/linked_bank_accounts/`;
// GET tokens for AeroPay account selection widget / POST selected account(s) bank to Zyp Run 
export const aeropayCredentialsEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/customer/bank_account_linking_credentials/`;

/** TOOLS ENDPOINTS  */

// Database flush
export const databaseTestingEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/admin/database`;
// Store Out of Service Area "Notify ME" email+location
export const outOfServiceAreaEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/unserviced_area_signup`;

/** PROMOS **/

// Get all promos
export const promosEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/public/promo_content`;

/** CUSTOMER CREDITS **/

// Get customer credits ( append user id! )
export const customerCreditEndpoint = `${config.REACT_APP_API_DOMAIN}/api/v1/customer/credits`;
