import * as React from "react";

import { auth } from '../../firebase';
import { verifyPasswordResetCode } from "firebase/auth";
import BodyWrapper from '../layout/BodyWrapper';
import Header from "../styleguide/Header";
import Spinner from '../common/Spinner';
import NewPasswordForm from './NewPasswordForm';
import SendResetEmailForm from './SendResetEmailForm';

import styles from './ResetPassword.module.css';

/**
 * This component verifies the PW reset code in the URL and then displays either
 * the reset for or an error messaage with resend link
 */
const ResetPasswordController = () => {
  
  const [codeVerified, setCodeVerified] = React.useState();
  const [validCode, setValidCode] = React.useState();
  const [code, setCode] = React.useState('');

  React.useEffect(() => {
    // verify that the reset code in url (from email) is still valid/unused
    const params = (new URL(document.location)).searchParams;
    const code = params.get('oobCode'); 
    
    if (code) {
      verifyPasswordResetCode(auth, code)
        .then((email) => {
          console.log(`email ${email}`);
          setCode(code);
          setValidCode(true);
          setCodeVerified(true);
        })
        .catch(() => {
          setValidCode(false);
          setCodeVerified(true);
        });
    } else {
      // invalid/missing code in url
      setValidCode(false);
      setCodeVerified(true);
    }
  }, [setCode, setCodeVerified, setValidCode]);   

  return (
    <BodyWrapper>
      <div className={styles.resetWrap}>
        <Header
          isCentered
          text="Reset Password" />
        { codeVerified 
          ? validCode 
              ? <NewPasswordForm resetCode={code}/>  
              : <>
                  <div className={styles.invalidCode}>
                    This password reset link has expired or has already been used.
                    Please request a new password reset email.
                  </div>
                  <SendResetEmailForm isInline />
                </> 
          : <Spinner />
        }
        </div>  
    </BodyWrapper>
  );
};

export default ResetPasswordController;
