import { useReducer } from "react";
import { trackEvent, trackError } from "../analytics/tracking";

/**
 * Reducer to consolidate the state of the name edit form
 */
const useEditNameReducer = (currentName) => {
  const initialState = {
    showForm: false,
    name: currentName,
    updateSuccess: undefined,   // update success (bank selected)
    updateError: undefined,     // update error
    updating: false,            // e.g. show Spinner during submit
  };

  const editNameReducer = (state, action) => {
    switch(action.type) {
      case 'showForm':
        trackEvent('updating_name_display_form');
        return {
          ...initialState,
          updateError: undefined,
          name: action.showForm ? state.name : currentName,
          showForm: action.showForm,
        }
      case 'onInputChange':
        return {
          ...state,
          updateSuccess: undefined,
          updateError: undefined,
          name: action.name,
        }
      case 'updating':
        return {
          ...state,
          updateSuccess: undefined,
          updateError: undefined,
          updating: true,
        }   
      case 'updateSuccess':
        trackEvent(`success_updating_name`);
        return {
          ...state,
          updateError: undefined,
          updateSuccess: true,
          showForm: false,
          updating: false,
        }
      case 'updateError':
        trackError('error_updating_name');
        return {
          ...state,
          updateError: action.error,
          updateSuccess: undefined,
          updating: false,
        }
      default: 
        return state
    }
  }

  // ensure state is defined!
  const [state={}, dispatch] = useReducer(editNameReducer, initialState);  
  return [state, dispatch];
}

export default useEditNameReducer;
