import * as React from "react";

import { UserContext } from "../providers/UserProvider";
import useCustomerCredits from "./useCustomerCredits";

export const CustomerCreditContext = React.createContext({});

/**
 * Referral Credit Provider
 */
const CustomerCreditProvider = ({children}) => {

  const { user } = React.useContext(UserContext);
  const { activeCredits, activeCreditDisplay, invalidCredits } = useCustomerCredits(user);

  return (
    <CustomerCreditContext.Provider value={{
      activeCredits, 
      activeCreditDisplay,
      invalidCredits, 
    }}>
      {children}
    </CustomerCreditContext.Provider>
  );
};

export default CustomerCreditProvider;
