import * as React from 'react';

import { trackEvent, trackingString as trkStr} from '../analytics/tracking';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Header, { HeaderSize } from '../styleguide/Header';
import { TextInput } from './ProductRequestSurvey';
import Button from '../styleguide/Button';
import PropTypes from 'prop-types';

import styles from './Survey.module.css';

const SOURCES = {
  SEARCH: { label: 'Web Search (Google etc.)', value: 'search' },
  FRIEND: { label: 'Recommended by a Friend', value: 'friend' },
  BILLBOARD: { label: 'Billboard', value: 'billboard' },
  TRASH_RECEPTACLE: { label: 'Trash Receptacle', value: 'trash' },
  MAILER: { label: 'I received a Mailer', value: 'mailer'},
  SOCIAL_MEDIA: { label: 'Social Media', value: 'social_media'},
  OTHER: { label: 'Other', value: 'other' },
}

/**
 * Survey new customers on how they found Zyp Run
 * e.g. "shop_survey_referby_billboard"
 */
const HowDidYouFindUsSurvey = ({
  customer,
  onSubmit,
}) => {

  // Source Dropdown
  const unselected = 'none';
  const [firstOrder, setFirstOrder] = React.useState();
  const [source, setSource] = React.useState(unselected);
  const [other, setOther] = React.useState(''); // '' = Prevent uncontrolled lint errors
  const [submitted, setSubmitted] = React.useState();

  React.useEffect(() => {
    setFirstOrder(customer && !customer?.has_completed_first_order)
  },[customer])

  const validData = React.useCallback(() => {
    return source !== unselected &&
    !(source === SOURCES.OTHER.value && !other)
  }, [source, other]);

  const resetRef = React.useRef();
  const trackProductRequest = React.useCallback(() => {
    if (validData()) {
      const sourceLower = trkStr(source.substring(0,16));
      trackEvent(`user_source_${sourceLower}${other ? `_${trkStr(other)}`: ''}`);
      setSubmitted(true);
    }
    // Reset form
    resetRef.current = window.setTimeout(() => {
      setSource(unselected);
      setSubmitted(false);
      if (onSubmit) {
        onSubmit();
      }
    }, 3000);

    return () => window.clearTimeout(resetRef.current);
  },[validData, source, other, onSubmit]);

  const handleSelect = (event) => {
    const { value } = event.target;
    if (value) {
      setSource(value);
      if (value !== SOURCES.OTHER.value) {
        setOther('');
      }
    }
  }

  return ( firstOrder
    ? <div className={`${styles.surveyWrap} ${styles.howHeardSurvey}`}> 
        <div className={styles.surveyForm}>
          <Header withClass={styles.formHeader} size={HeaderSize.Medium_Static}>
            <span role="img" aria-label="celebrate">🎉</span>
            {' '}Congrats on your first delivery order, Nicely&nbsp;done!
          </Header>
          <div className={styles.formLegend}>How did you discover <b>Zyp Run?</b></div>
          { submitted 
            ? <Alert severity="success" className={styles.successAlert}>
                <span>
                  Thanks for sharing!
                  {' '}<span role="img" aria-label="praise">🙏</span>
                </span>
              </Alert>
            : <Grid container className={styles.gridItems} spacing={4}>
                <Grid item className={styles.gridItem}>
                  <FormControl className={styles.selectFieldset}>
                    <InputLabel>Source</InputLabel>
                    <Select
                      className={styles.select}                      
                      label='Source'
                      placeholder='Source'
                      value={source}
                      onChange={handleSelect}>
                        <MenuItem disabled value={unselected}>Source</MenuItem>
                        { Object.values(SOURCES).map(source => (
                          <MenuItem
                            key={source.value}
                            className={styles.option}
                            value={source.value}>
                              {source.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                
                { source === SOURCES.OTHER.value &&
                  <Grid item className={styles.gridItem}>
                    <TextInput
                      label="Other"
                      value={other}
                      setValue={setOther} />
                  </Grid>
                }  
    
                <Grid item className={styles.gridItem}>
                  <Button
                    withClass={styles.submitButton}
                    disabled={!validData()}
                    text='Submit'
                    handleClick={trackProductRequest} />
                </Grid>
              </Grid>
          }    
        </div>
      </div>
    : null
  )
}

HowDidYouFindUsSurvey.propTypes = {
  customer: PropTypes.object,
  onSubmit: PropTypes.func,
}

export default HowDidYouFindUsSurvey;
