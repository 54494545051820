import { commerceAnalyticsEndpoint } from '../../constants/api-endpoints';
import { trackError } from './tracking';
import { devLog } from '../util/util';

// CART_VIEWED would be a nice addition to the API
export const SHOPPING_MILESTONES = {
  MENU_VISIBLE: "menu_visible",
  MENU_INTERACTED: "menu_interacted", 
  ITEM_CARTED: "item_carted",
  CHECKOUT_VIEWED: "checkout_viewed",
  ORDER_PLACED: "order_placed",
};

// All possible milestone data, in order, for slicing to create post body
const bodyVals = [
  { menu_interacted:true },
  { item_carted:true }, 
  { checkout_viewed:true }, 
  { order_placed:true }
];

// API requires "ziprun_web" not "zyprun_web" here
const analytics_context = 'ziprun_web';
const order_type = 'delivery';

// Updates sent to the API will replace existing user data for session.
export const UPDATE_BODY = {
  "menu_visible" : {analytics_context, order_type},
  "menu_interacted" : Object.assign({order_type}, ...bodyVals.slice(0,1)),
  "item_carted" : Object.assign({order_type}, ...bodyVals.slice(0,2)),
  "checkout_viewed" : Object.assign({order_type}, ...bodyVals.slice(0,3)),
  "order_placed" : Object.assign({order_type}, ...bodyVals.slice(0,4))
};

// Log analytics for use in dispensary app (eCommerce)
export const commerceLoggingRequest = (user, dispensaryId, body, callback, controller) => {
  if (user) {
    user.getIdToken(/* no need to force refresh */ false).then(idToken => {
      const endpoint = commerceAnalyticsEndpoint.replace(/\[dispId\]/,dispensaryId); 
      // Once MENU_INTERACTED is included method is PATCH
      const method = body[SHOPPING_MILESTONES.MENU_INTERACTED] ? 'PATCH' : 'POST'; 
      fetch(endpoint, {
        method,
        headers: {
          'Authorization': idToken,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
        signal: controller.signal
      }).then(response => {
        if (![201,204].includes(response.status)) {
          callback(false);
          trackError(`commerce_logging_error_${response.status}`);
          devLog(`commerce_logging_error_${response.status}`);
        } else {
          callback(true);
          devLog(`commerce_logging_success_${Object.keys(body).join('+')}`); 
        }  
      }).catch(error => devLog(error.message)); 
    });
  }
};
