import React from 'react';

import { auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { AttributionContext } from '../providers/AttributionProvider';
import { PartnerContext } from '../brand/PartnerProvider';
import useBrandPartnerData from '../brand/useBrandPartnerData';
import PartnerAge21Message from '../brand/PartnerAge21Message';
import { userIs21Key, storeValue, getStoredValue } from '../util/storage-utils';
import verify21 from '../../assets/verify21-new.png';

import styles from './Verify21Modal.module.css';

/**
 * Verify that user coming direct to app.ziprun.com are at least 21
 */
const Verify21Modal = () => {

  // Customize message when we have a partner referrer
  const { partnerReferralDomain, loading } = React.useContext(PartnerContext);
  const { referralDomain } = React.useContext(AttributionContext);
  const partnerData = useBrandPartnerData(partnerReferralDomain);

  const ageVerified = React.useRef(false);
  const [showModal, setShowModal] = React.useState(false);

  // We will check anonymous users every 2 weeks
  const is21Click = () => {
    storeValue(userIs21Key, new Date().getTime());
    setShowModal(false);
    ageVerified.current = true;
  }

  // Redirect to info on Mass.gov
  const isNot21Click = () => {
    window.location.href = 'https://masscannabiscontrol.com/know-the-laws/#recreational-marijuana';
  }

  React.useEffect(() => {
    // Last response is a week old
    const storedResponseIsExpired = (timeStamp) => {
      return new Date().getTime() - timeStamp > (86400000 * 7)
    };
    // Show modal to anonymous users every week
    const shouldShowModal = () => {
      // Skip iframed context on marketing site
      if (window.top !== window.self) {
        return false;
      }
      // Skip if we've alreadt shown an age 21 modal on marketing site
      if (referralDomain && referralDomain.startsWith("https://zyprun.com")) {
        return false;
      }
      const storedResponse = getStoredValue(userIs21Key);
      return !storedResponse || storedResponseIsExpired(storedResponse);
    };

    // Check for redirect
    const urlParams = new URLSearchParams(window.location.search);
    const isRedirect = !!urlParams.get('redirect');    
    // eslint-disable-next-line no-unused-vars
    let authListener;

    // handle redirect from marketing site ( modal previously shown ) 
    if (isRedirect) {
      console.log('redirect');
      storeValue(userIs21Key, new Date().getTime());
      setShowModal(false);  
    } else { 
      // handle initial page load pre auth
      // eslint-disable-next-line no-unused-vars
      authListener = onAuthStateChanged(auth, async user => {
        if(!ageVerified.current && user?.isAnonymous) {
          setShowModal(shouldShowModal());
        }
      });
      // handle post-auth render
      if(!ageVerified.current && auth.currentUser?.isAnonymous) {
        setShowModal(shouldShowModal());
      }
    }
    // remove auth listener  
    return () => { authListener = null }     
  }, [showModal, referralDomain]);
  
  return (
    showModal && !loading
      ? <>
          <div className={styles.modalBg}></div>
          <div className={styles.modalMsg}>
            { partnerData.data?.name
              ? <PartnerAge21Message 
                  partnerData={partnerData.data}
                  on21Click={is21Click} 
                  onNot21Click={isNot21Click} /> 
              : <>
                  <img className={styles.graphic} alt="Are you 21?" src={verify21} />
                  <div className={styles.modalInner}>
                    <span onClick={is21Click} className={styles.is21}>I AM 21 OR OLDER</span>
                    <span onClick={isNot21Click} className={styles.not21}>I AM NOT 21 YET</span>
                  </div>
                </>
            }
          </div>      
        </>
      : null
  );  
}

export default Verify21Modal
