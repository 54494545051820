import * as React from 'react';

import typeahead from 'typeahead-standalone';
import { ModalContext, ModalType } from '../modal/ModalProvider';
import { useAnalyticsStore } from '../../App.js';
import useOutsideClick from './useOutsideClick.js';
import { ProductPreviewContext } from '../products/ProductPreviewProvider.jsx';
import SearchToolTip from './SearchTooltip.jsx';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import searchIconLeft from '../../assets/searchIconLeft.png';

import styles from './ProductSearch.module.css';

const SearchTypeAhead = ({
  placeholder="Product name, brand, category...",
  autoFocus=false
}) => {

  const { allProducts, getCachedProductById } = React.useContext(ProductPreviewContext);
  const { displayModal } = React.useContext(ModalContext);
  const { trackEvent } = useAnalyticsStore();
  // The focus state of the widget as a whole (the wrapper)
  const [hasFocus, setHasFocus] = React.useState();
  const wrapperRef = useOutsideClick(() => setHasFocus(false));

  // Track usage
  const interactionRef = React.useRef();

  // Text input ref and value
  const inputRef = React.useRef();
  const [searchText, setSearchText] = React.useState('');
  // typeahead instance
  const typeaheadRef = React.useRef();

  // Get current suggestions for use in product modal
  const getResultSet = React.useCallback(() => {
    const suggested = [];
    const listItems = document.querySelectorAll('.tt-list .suggestion-label');
    Array.from(listItems).forEach(element => {
      const product = getCachedProductById(element.getAttribute('data-item-id'));
      if (product) {
        suggested.push(product);
      }
    });
    return suggested;
  }, [getCachedProductById]);

  const handleSelect = React.useCallback((product) => {
    const resultSet = getResultSet();
    if (product?.id) {
      trackEvent('search_result_clk');
      // Mobile Safari will display cursor under modal if not blurred...
      inputRef.current.blur();
      displayModal(ModalType.PRODUCT, {
        itemId: product.id, 
        title: product.display_info.name,
        categoryName: product.display_info.category.display_name,
        trackingLabel: 'search_product',
        relatedProductsCarouselTitle: 'More Search Results',
        relatedProducts: resultSet,
      });
    }
  }, [getResultSet, displayModal, trackEvent]);
  
  // The typeahead-standalone package does not support mobile
  // This is the workaround 
  const mobileSelect = (event) => {
    // This is setting the focus state of the widget as a whole
    // false when user clicks outside the search wrapper
    setHasFocus(true);
    const wrapperElem = event.target.closest('div');
    const itemId = wrapperElem.getAttribute('data-item-id');
    if (itemId) {
      // Clear previous selection
      if ( inputRef.current) {
        const suggests = inputRef.current.parentNode.querySelectorAll('.tt-suggestion');
        Array.from(suggests).forEach(suggest => suggest.className = 'tt-suggestion');
      }
      wrapperElem.parentNode.className = 'tt-suggestion tt-selected';
      const product = getCachedProductById(itemId); 
      if (product) {
        handleSelect(product);
      }
    } else {
      if (!interactionRef.current) {
        trackEvent('search_interacted');
        interactionRef.current = true;
      }
    }
  }

  React.useEffect(() => {
    if (inputRef.current && allProducts?.length && !typeaheadRef.current) {
      typeaheadRef.current = typeahead({
        input: inputRef.current,
        preventSubmit: true, // No Enter w/o selection
        onSubmit: (event, product) => {
          // onSubmit handles Desktop Enter key only
          event.stopPropagation();
          handleSelect(product);
        },
        source: {
          local: allProducts,
          identifier: "search_data",
        },
        templates: {
          suggestion: (item, resultSet) => {
            // type for styling
            const { cannabis_type="" } = item.display_info;
            // No addl divs inside this div! I'm using .closest('div') to get data-item-id
            return `<div data-item-id="${item.id}"
                         class="suggestion-label ${styles[cannabis_type.toLowerCase()]}">
                      ${item.display_info.name} :
                      <span class="${styles.resultBrand}">
                        ${' '}${item.display_info.brand}
                        ${item.discount_data
                          ? `<span class="${styles.saleLabel}">Sale!</span>`
                          : ''
                        }
                      </span>
                    </div>`;
          },
        },
        limit: 10,
        hint: false,      
        // input field display after selection 
        display: (item, event) => item.display_info.name,
      });
      if (autoFocus) {
        inputRef.current.focus();
      }
    }
    // This wasn't a good pattern, resulted in multiple instances of typeahead
    // return () => typeaheadRef.current = null;
  },[ allProducts, autoFocus, handleSelect])

  const clearInput = (event) => {
    // required in mobile safari to properly focus input
    event.target.blur();
    setSearchText('');
    inputRef.current.focus();
    // clear suggestions as package isn't catching the input clear
    const list = document.querySelector('.tt-list');
    if (list) {
      list.innerHTML = '';
    }
  };

  return (    
    <div ref={wrapperRef} 
         className={ `${hasFocus ? styles.focused : ''} ${searchText ? '' : styles.empty}`}
         onClick={mobileSelect}>
      <span>
        <img alt="" className={styles.searchIcon} src={searchIconLeft} />
        { searchText
          ? <AddCircleRoundedIcon  className={styles.clear} onClick={clearInput} />
          : <SearchToolTip />
        }
      </span>
      <input type="text"
        className={styles.searchInput} 
        ref={inputRef}
        autoFocus={autoFocus}
        autoComplete='new-password'          
        placeholder={placeholder}
        name="product_search"
        value={searchText} 
        onChange={(e) => setSearchText(e.target.value)}
      />
    </div>
  );
};

export default SearchTypeAhead;
