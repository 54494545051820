import * as React from 'react';
import { dollarDisplay } from '../providers/OrderPricingProvider';
import { discountedItemTotalCents, bundleItemTotalCents } from './product-utils';
import { CartItemsContext } from '../checkout/CartItemsProvider';

/**
 * This pricing hook considers currently carted bundle-priced products
 * when includeCartedItems is TRUE
 */
const useProductPricing = (product, quantity, includeCartedItems) => {
  // Regular price
  const [priceCents, setPriceCents] = React.useState();
  // Bundle or Sale price
  const [salePriceCents, setSalePriceCents] = React.useState();
  // Incremental bundle pricing based on carted items
  const { bundlePricingMap } = React.useContext(CartItemsContext);

  React.useEffect(() => {
    // Regular price
    const { cost_usa_cents } = product?.display_info || {};
    if (cost_usa_cents) {
      setPriceCents(cost_usa_cents * quantity);
    }
    // Bundle price
    const { bundle_deal_data } = product;
    if (bundle_deal_data) {
      const bundlePrice = includeCartedItems
        ? bundlePricingMap
          ? (bundlePricingMap.get(bundle_deal_data.id) || 0) * quantity
          : undefined
        // bundle/bulk price ignoring carted items
        : bundleItemTotalCents(product, quantity)
      setSalePriceCents(bundlePrice);
    // Sale price
    } else {
      const salePrice = discountedItemTotalCents(product, quantity);
      setSalePriceCents(salePrice);
    }
  }, [product, quantity, bundlePricingMap, includeCartedItems]);

  return {
    priceCents,
    priceDisplay: dollarDisplay(priceCents),
    salePriceCents,
    salePriceDisplay: salePriceCents && dollarDisplay(salePriceCents), // suppress 'N/A'
  }
}

export default useProductPricing;
