import React from 'react';

import useCartItemWarnings from './useCartItemWarnings';
import CartWarnings from './CartWarnings';
import SpecialPricingLabel from './SpecialPricingLabel';
import DisplayCartedPrice from '../products/DisplayCartedPrice';
import CartQtyControls from './CartQtyControls';
import { flattenedProduct } from '../products/product-utils';
import increaseIcon from '../../assets/increaseIcon.png';
import decreaseIcon from '../../assets/decreaseIcon.png';
import PropTypes from 'prop-types';
import styles from './PlaceOrder.module.css';

const PlaceOrderItem = ({
  product,
  quantity,
  increaseQuantity,
  decreaseQuantity,
  removeFn
}) => {

  const { id, name, per_order_max_quantity } = flattenedProduct(product);

  // Limit 1 per order...
  const itemWarnings = useCartItemWarnings(id, name, quantity, per_order_max_quantity);

  return (
    <li className={styles.cartItem}>
      <CartQtyControls
        product={product}
        quantity={quantity}
        increaseIcon={increaseIcon}
        increaseQuantity={increaseQuantity}
        decreaseIcon={decreaseIcon}
        decreaseQuantity={decreaseQuantity}
        styleModule={styles} />
      <div className={styles.itemCount}>x{quantity}</div>
      <div className={styles.itemTitle}>
        {name}
        <span className={styles.removeFromOrder} onClick={removeFn}>REMOVE</span>
      </div>
      <div className={styles.itemPrice}>
        <DisplayCartedPrice
          product={product}
          quantity={quantity} />
        <SpecialPricingLabel
          product={product}
          withStyle={{top:24}} />
      </div>
      <div style={{flexBasis:'100%'}}>
        <CartWarnings
          warnings={itemWarnings} />
      </div>
    </li>
  )
}

PlaceOrderItem.propTypes = {
  product: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  increaseQuantity: PropTypes.func.isRequired,
  decreaseQuantity: PropTypes.func.isRequired,
  removeFn: PropTypes.func.isRequired
};

export default PlaceOrderItem;
