import * as React from "react";

import { navigateTop, navigateTopWithState } from "../routing/router-utils";
import SignUpInline from "./SignUpInline";
import BodyWrapper, { LayoutTypes } from "../layout/BodyWrapper";
import PageTitle from "../common/PageTitle";
import PropTypes from "prop-types";
import ProcessRegistrationInline from "./ProcessRegistrationInline";
import ErrorBoundary from "../common/ErrorBoundary";
import Header, { HeaderSize } from "../styleguide/Header";
import ErrorMessage from "../styleguide/ErrorMessage";
import Button from "../styleguide/Button";
import RegistrationComplete from "./RegistrationComplete";
import { ModalContext, ModalType } from "../modal/ModalProvider";

const RegFlowSteps = {
  SIGNUP: "",  // unset, default
  SUBMIT: "submit",
  SHOW_SUCCESS: "success",
  SHOW_SUBMIT_ERROR: "submit_error",
  CONTINUE: "continue"
}

// Show Submission error
const ShowSubmissionError = ({error, onContinue}) => {
  return (
    <div style={{maxWidth:500, margin:'2rem auto'}}>
      <Header size={HeaderSize.Small} isCentered>
        Sorry, your registration failed...
      </Header>
      <ErrorMessage text={error} />
      <Button text="Return to Sign Up Page" handleClick={onContinue} isCentered />
      <div style={{ margin:'1rem 0', textAlign:'center'}}>
        Please reach out to us using the chat icon for assistance (11am&nbsp;-&nbsp;9pm).
      </div>
    </div>
  )
}

/**
 * Single page sign up, replaces old reg flow
 */
const SignUpPage = ({location}) => {
  
  // This component controls the signup flow
  const [returnToPath, setReturnToPath] = React.useState("/");
  const [forwardToPath, setForwardToPath] = React.useState("/");
  const [continuePath, setContinuePath] = React.useState("/");
  const [nextStep, setNextStep] = React.useState();
  const [submitError, setSubmitError] = React.useState();
  
  const { displayModal } = React.useContext(ModalContext);

  React.useEffect(() => {
    // Set paths from router state/props
    const { returnToPath:backPath, continuePath:fwdPath } = location?.state || {};
    if ( backPath ) {
      setReturnToPath(backPath);
    }
    if ( fwdPath ) {
      // Registration provider
      setContinuePath(fwdPath);
      // Switch to SignIn etc.
      setForwardToPath(fwdPath);
    }
  }, [location]);

  // Handle switch to Log In page 
  const handleSignIn = () => {
    navigateTopWithState('signIn', { 
      returnToPath: '/signUp',
      forwardToPath,  // e.g. /checkOut 
      continuePath: forwardToPath
    });
  }

  // Return to shopping 
  const handleCancel = () => {
    navigateTop(returnToPath);
  };

  return (
    <BodyWrapper pageLayout={LayoutTypes.Narrow}>
      <PageTitle title="Sign Up" />
      <ErrorBoundary>
        { nextStep
          ? nextStep === RegFlowSteps.SUBMIT
            ? <ProcessRegistrationInline 
                onSuccess={() => {
                  // Close phone verify modal
                  displayModal(ModalType.NONE);
                  setNextStep(RegFlowSteps.SHOW_SUCCESS);
                }}
                onFailure={(error) => {
                  // Close phone verify modal
                  displayModal(ModalType.NONE);
                  setNextStep(RegFlowSteps.SHOW_SUBMIT_ERROR);
                  setSubmitError(error)
                }}
                continuePath={forwardToPath} />
            : nextStep === RegFlowSteps.SHOW_SUCCESS
              ? <RegistrationComplete 
                  continuePath={continuePath} />
              : nextStep === RegFlowSteps.SHOW_SUBMIT_ERROR
                ?  <ShowSubmissionError
                     error={submitError}
                     onContinue={() => {
                      setSubmitError(undefined);
                      setNextStep(RegFlowSteps.SIGNUP);
                    }} />
                : <></>
          : <SignUpInline 
              onSubmit={() => {
                setNextStep(RegFlowSteps.SUBMIT)
              }}
              onCancel={handleCancel}
              onSwitchToSignIn={handleSignIn} />
        }
      </ErrorBoundary>
    </BodyWrapper>
  );
}

SignUpPage.props = {
  location: PropTypes.object /* from router */
}

export default SignUpPage;
