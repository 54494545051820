import React from 'react';

import { makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';

const useStyles = makeStyles({
  warning: {
    font: 'var(--zrFontSemiItalic)',
    fontSize: '1rem',
    lineHeight: '32px', /* for wrapping */
    color: 'var(--zrTextRed)',
    whiteSpace: 'nowrap',
  }
});    

export const WARNING_THRESHHOLD = 10;

const LowStockWarning = ({quantityAvailable}) => {
  const classes = useStyles();
  return ( quantityAvailable < WARNING_THRESHHOLD
    ? <div className={classes.warning}>
        { quantityAvailable === 0
          ? `Sold out!`
          : `Only ${quantityAvailable} left!`
        }
      </div>
    : <></>
  )
}

LowStockWarning.propTypes = {
  quantityAvailable: PropTypes.number.isRequired
}

export default LowStockWarning;
