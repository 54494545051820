import * as React from "react";

import { ModalContext, ModalType } from "../modal/ModalProvider";
import { RegistrationContext } from "./RegistrationProvider";
import { useAnalyticsStore } from '../../App';
import { parsePhoneNumber } from 'react-phone-number-input';
import { sendVerificationCode } from './registration-utils';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '../styleguide/Button';
import ErrorMessage from "../styleguide/ErrorMessage";
import HavingTroubleCallout from "./HavingTroubleCallout";
import Alert from "@material-ui/lab/Alert";
import Spinner from "../common/Spinner";
import PropTypes from 'prop-types';

import styles from './SignUpInline.module.css';

export const VoiceDefaultAlert = () => (
  <Alert severity="info">
    NOTE: We currently recommend using <b>voice call</b> verification for&nbsp;registration.
  </Alert>
)

/**
 * Validate Phone Number (via SMS)
 * AND run validateAll to validate all registration values in the RegistrationProvider
 *
 * Ask for and validate phone number (RFC3966)
 */
const VerifyPhoneAndComplete = ({
  onSuccess,
  isVoiceDefault,
}) => {

  const {
    phoneNumber,
    validateAll
  } = React.useContext(RegistrationContext);

  const { trackEvent, trackError } = useAnalyticsStore();

  const [sendCodeError, setSendCodeError] = React.useState();
  const [verifyCodeError, setVerifyCodeError] = React.useState();
  const { displayModal } = React.useContext(ModalContext);

  // Show spinner while waiting on API to send code
  const [codeRequested, setCodeRequested] = React.useState();

  const [isVoiceCall, setIsVoiceCall] = React.useState(false);

  // Set default verify method on render
  React.useEffect(() => {
    setIsVoiceCall(isVoiceDefault);
  }, [isVoiceDefault])

  // A reference to the number being verified
  const codeRecipientNumber = React.useRef();
  const countryCodeRef = React.useRef('US');

  // Validate Registration data and send verification code if validation passes
  const handleVerify = () => {
    setVerifyCodeError(false);

    // VALIDATE ALL REGISTRATION DATA - and abort phone verification if validation fails
    if (!validateAll()) {
      // Errors have been noted and will display
      return;
    }

    if (phoneNumber) {
      // Parse out country code, etc.n
      const phoneNumberInfo = parsePhoneNumber(phoneNumber);
      // (non-inline defensive code) The country code has a tendency to get unset on back navigation:
      countryCodeRef.current = phoneNumberInfo.country;

      // Handle verification response
      const sendCodeCallback = ((response) => {
        setCodeRequested(false);
        // success
        if(response.recipient_tel_number) {
          trackEvent('registration_send_code_success');
          displayModal(ModalType.VERIFY_PHONE, {
            trackContext:'verify_phone',
            phoneNumber,
            onVerified:onNumberVerified
          })
          // failure
        } else {
          trackError('registration_send_code_error');
          setSendCodeError(response.error || 'Error sending verification code');
        }
      });

      codeRecipientNumber.current = phoneNumber;
      const verificationInfo = {
        delivery_method: 'sms-text',
        tel_number: codeRecipientNumber.current
      };

      // Adding a spinner in case there are sms vendor issues.
      setCodeRequested(true);
      const controller = new AbortController();
      // Send the SMS message or voice and show modal for code
      sendVerificationCode(verificationInfo, isVoiceCall, sendCodeCallback, controller);
    }
  }

  // The callback for the verification modal
  const onNumberVerified = (response) => {
    if (response.success) {
      displayModal(ModalType.NONE);
      onSuccess();
    } else {
      // something happened that was not success or error
      setVerifyCodeError(response.error || 'Invalid code entered');
    }
  };

  // Handle voice call toggle
  const onVoiceCallClick = (event) => {
    const { checked:value } = event.currentTarget;
    setIsVoiceCall(value);
  }

  return (
    <div className={styles.verifyButton}>
      <Button
        isCentered
        handleClick={handleVerify}
        text={ isVoiceCall
          ? "Verify Phone (Voice Call) to Complete"
          : "Verify Phone (via SMS) to Complete"
        } />
      <div className={styles.verifyMethod}>
        <FormControlLabel
          control={<Checkbox
            color="default"
            id="accept_terms"
            checked={isVoiceCall || false}
            onChange={onVoiceCallClick} />}
          label="Send my verification code via voice call"
        />
      </div>
      { isVoiceDefault &&
        <VoiceDefaultAlert />
      }
      { codeRequested &&
        <>
          <Spinner inSmallLayout />
          <HavingTroubleCallout trackingLabel={isVoiceCall ? 'voice_verify' : 'sms_verify'} />
        </>
      }
      { sendCodeError &&
        <ErrorMessage text={sendCodeError} />
      }
      { verifyCodeError &&
        <ErrorMessage text={verifyCodeError} />
      }
    </div>
  );
}

VerifyPhoneAndComplete.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  isVoiceDefault: PropTypes.bool,
}

export default VerifyPhoneAndComplete;
