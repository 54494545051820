import * as React from 'react';

import PageTitle from './PageTitle';
import { useAnalyticsStore } from '../../App';
import { STACKING } from '../layout/stackingConstants';
import { ModalContext, ModalType } from '../modal/ModalProvider';
import { freezeBody, unfreezeBody } from '../modal/modal-utils';
import closeX from '../../assets/closeX.png';
import PropTypes from 'prop-types';

import styles from './DisplayModal.module.css';

const DisplayModal = ({
  title, 
  trackContext,
  isShrinkToFit,
  scrollingInner, 
  noWrapper,
  closeFn,
  children
}) => {

  const { trackEvent } = useAnalyticsStore();
  const { displayModal } = React.useContext(ModalContext); 

  /**
   * The Cart Provider will force a rerender of modals so use a Ref
   * to prevent duplicate logging/race conditions
   */ 
  const modalRef = React.useRef();
  const trackModalDisplay = React.useCallback(() => {
    if (trackContext && trackContext !== modalRef.current) {
      trackEvent(`mdl_${trackContext}_disp`);
      modalRef.current = trackContext;
    }
  }, [trackEvent, trackContext]);

  /**
   * closeFn: When we need to do something more than
   * just close the modal (e.g. navigate to a new page)
   */
  const handleClose = () => {
    closeFn
      ? closeFn()
      : displayModal(ModalType.NONE);
  }

  React.useEffect(() => {
    // track if desired
    trackModalDisplay(trackContext);
    // fix/hack via css for location search in modal
    document.body.setAttribute('data-modal-open', true);
    // prevent body scroll under modal
    freezeBody(); 

    // unfreeze document scroll on unmount
    return () => {
      document.body.setAttribute('data-modal-open', false);
      unfreezeBody();
    };

  }, [trackModalDisplay, trackContext]);

  const modalStyle = { zIndex:STACKING.MODAL }
  if (isShrinkToFit) {
    modalStyle.width = 'auto';
  }
  if (noWrapper) {
    modalStyle.padding = 0;  
  }

  // For .modalInner element
  const scrollClass = scrollingInner ? styles.scrollingInner : '';
  const innerClass = noWrapper ? '' : styles.modalInner;

  return (
    <>
      {/* optional page title for SEO/UX */}
      { title &&
        <PageTitle title={title} /> 
      }
      <div className={styles.modalBGElem} style={{zIndex:STACKING.MODAL}}></div>
      <div id={trackContext ? trackContext.toUpperCase() : 'modal'}
        className={styles.modalContent}
        style={modalStyle}>
        <img alt="" className={styles.closeIcon} src={closeX} height="24" onClick={handleClose} />
        <div className={`${innerClass} ${scrollClass}`}>
          {children}
        </div>
      </div>  
    </> 
  );
};

DisplayModal.propTypes = {
  title: PropTypes.string.isRequired,  // used to update page title
  trackContext: PropTypes.string.isRequired,
  isShrinkToFit: PropTypes.bool,
  scrollingInner: PropTypes.bool,
  noWrapper: PropTypes.bool,
  closeFn: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
};

export default DisplayModal;
