import * as React from 'react';

import { FilteredProductsContext, SHOW_ALL } from '../products/FilteredProductsProvider';
import { Chip } from '@material-ui/core';

// common styles for the the pill set
import styles from '../styleguide/Stylesheet.module.css'

const ActiveFilterPills = ({inMobileNav}) => {

  const { filterState, setFilters } = React.useContext(FilteredProductsContext); 

  return (
    <div className={`${styles.filterPills} ${inMobileNav ? styles.filterPillsNav : ''}`}>
      { Object.keys(filterState).map(key => (
        typeof filterState[key] === 'string' &&
               filterState[key] !== SHOW_ALL
          ? <Chip
              className={styles.filterPill}
              label={filterState[key]}
              onDelete={() => setFilters({[key]:SHOW_ALL })} />
          : null
      ))}        
    </div>
  )
}

export default ActiveFilterPills;
