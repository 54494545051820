import * as React from 'react';

import config from '../../config';
import isbot from 'isbot';
import { devLog } from '../util/util';
import { UserContext } from '../providers/UserProvider';
import { UPDATE_BODY, SHOPPING_MILESTONES, commerceLoggingRequest } from './analytics-utils';

const useCommerceLogging = (
  commerceTracking={},
  dispatch=()=>{},
) => {

  const { user } = React.useContext(UserContext);
  const dispensaryId  = config.ZYP_RUN_DISPENSARY_ID;

  /**
   * Each action should only be logged once per session (one order/day max)
   */ 
  const logCommerceAction = React.useCallback((milestone, order_value_cents) => {
    
    // The MENU_VISIBLE POST has to happen first, or logging will fail
    if (milestone !== SHOPPING_MILESTONES.MENU_VISIBLE && 
        !commerceTracking.commerceEvents.includes(SHOPPING_MILESTONES.MENU_VISIBLE)) {
      return;
    }

    const callback = (isSuccess) => {
      if (isSuccess) {
        // This is for tracking state and debug
        dispatch({
          type: 'trackCommerceEvent',
          commerceEvent: milestone
        });
      }
    };

    if (user &&
        commerceTracking.commerceId && 
        !commerceTracking.commerceEvents.includes(milestone) && 
        !isbot(window.navigator.userAgent)) {

      const requestBody = UPDATE_BODY[milestone];
      // Add order value when placing order
      if (requestBody) {
        requestBody.id = commerceTracking.commerceId;
        if (milestone === SHOPPING_MILESTONES.ORDER_PLACED && order_value_cents) {
          requestBody.order_value_cents = order_value_cents;
        }
        commerceLoggingRequest(user, dispensaryId, requestBody, callback, new AbortController()); 
        devLog(`ANALYTICS (${milestone}): ${JSON.stringify(requestBody)}`);
      }
    }
  }, [user, dispensaryId, commerceTracking, dispatch]); 

  return {
    logCommerceAction,
  };
}

export default useCommerceLogging;
