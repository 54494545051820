import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import { trackingString } from '../analytics/tracking';
import { FilteredProductsContext, SHOW_ALL} from '../products/FilteredProductsProvider';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import styles from './ProductInfoFilter.module.css';

/**
 * This component will provide sub-category BRAND filters for both Desktop and Mobile layouts
 */ 
const BrandFilter = ({
  startOpen=false,
  inModal=false
}) => {
  
  const { trackEvent } = useAnalyticsStore();

  // Current filters context
  const { brand:selectedBrand, subCategoryBrands, setFilters } = React.useContext(FilteredProductsContext);

  // Add the 'All {category}' option 
  const subCategoryBrandsWithAll = [SHOW_ALL, ...subCategoryBrands];
  
  // Toggle the display
  const [showBrands, setShowBrands] = React.useState(startOpen);

  const toggleShowBrands = () => {
    if (labelRef.current) {
      labelRef.current.setAttribute('data-show-content', !showBrands);
    } 
    setShowBrands(!showBrands);
  };

  const brandClick = (brand) => {
    trackEvent(`filter_brand_${trackingString(brand)}_click`); 
    setFilters({brand});
  };
  
  const labelRef = React.useRef();
  
  const addlClass = inModal ? styles.inModal : '';

  return (subCategoryBrandsWithAll.length > 2  // Suppress filter when there's only 1 brand!
    ? <div className={`${styles.productInfoFilter} ${addlClass}`}> 
        <div ref={labelRef} data-show-content={showBrands} className={styles.sectionHdr} onClick={toggleShowBrands}>
          BRANDS <ChevronRightRoundedIcon style={{verticalAlign:'-7px'}}/>
        </div>
        { showBrands &&
          <ul className={styles.filterLinks}>
            { subCategoryBrandsWithAll.map(brand => {
              const selectedClass = brand === selectedBrand
                ? styles.selected 
                : '';
              const label = brand === SHOW_ALL
                          ? `All Brands`
                          : brand;
              return <li key={brand} 
                         className={`${styles.filterLink} ${selectedClass}`} 
                         onClick={() => {brandClick(brand)}}>
                       {label}
                       <span className={styles.selectedIcon}>
                         <CheckCircleRoundedIcon style={{color: '#054052', verticalAlign:'-6px'}}/>
                       </span>
                     </li>
            })}
          </ul>
        }  
      </div>
    : null 
  );
}

export default BrandFilter;
