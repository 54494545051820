import * as React from 'react';

import useBundledPriceMap from './useBundledPriceMap';
import styles from './BundlePricing.module.css';

import PropTypes from 'prop-types';

/**
 * Display bundle pricing thresholds
 */
const BundlePricingThresholds = ({
  cost_usa_cents,
  bundle_deal_data,
  includeSavingsPct,
}) => {

  const pricingMap = useBundledPriceMap(cost_usa_cents, bundle_deal_data);

  return ( pricingMap
    ? <div className={styles.thresholds}>
        { Array.from(pricingMap).map(pair => (
            pair[1].is_threshold
              ? <div key={`qty${pair[0]}`} className={styles.threshold}>
                  Buy
                  {' '}
                  <strong>{pair[0]}</strong>
                  {' '}
                  for
                  {' '}
                  <strong>{pair[1].price_display}</strong>
                  {' '}
                  each.
                  { includeSavingsPct &&
                    <span className={styles.savings}>Save {pair[1].savings_percent}%!</span>
                  }
                </div>
              : null
        ))}
      </div>
    : null
  )
}

BundlePricingThresholds.propTypes = {
  cost_usa_cents: PropTypes.number.isRequired,
  bundle_deal_data: PropTypes.object,
}

export default  BundlePricingThresholds;
