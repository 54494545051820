import * as React from 'react';

import { flattenedProduct } from './product-utils';
import DisplayPrice from './DisplayPrice';
import PropTypes from 'prop-types';

import styles from './ProductCardForGrid.module.css';

const GRAMS_TO_DISPLAY_OUNCES = new Map([
  ['3.5', '1/8 oz'],
  ['7', '1/4 oz'],
  ['14', '1/2 oz'],
  ['28', '1 oz'] 
]);

const WEIGHT_ENABLED_CATEGORIES = [
  'flower',
  'vaporizers',
  'concentrates'
];

const PriceWithWeightButton = ({
  product,
  handleClick
}) => {

  const {
    grams, 
    category
  } = flattenedProduct(product);

  const [displayWeight, setDisplayWeight] = React.useState();

  const buttonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleClick(1);
  }

  // Set the display weight: "1/8 oz" etc. 
  React.useEffect(() => {
    if (grams) {
      if (GRAMS_TO_DISPLAY_OUNCES.has(grams.toString())) {
        setDisplayWeight(GRAMS_TO_DISPLAY_OUNCES.get(grams.toString()));
      } else {
        setDisplayWeight(`${grams}g`);
      }  
    }  
  }, [grams]);
  
  return (
    <div className={styles.productPrice} onClick={buttonClick}>
      { displayWeight && WEIGHT_ENABLED_CATEGORIES.includes(category.name)
        ? <>
            <div className={styles.productWeight}>{displayWeight}</div>
            <div className={styles.productWeightDash}>-</div>
          </>
        : null
      }
      <DisplayPrice 
        product={product} 
        quantity={1} />
    </div>
  )
}

PriceWithWeightButton.propTypes = {
  product: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired 
}

export default  PriceWithWeightButton;
