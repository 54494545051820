import React from 'react';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

export const MAX_MA_FLOWER_GRAMS = 28.3;

const QuantityWarning = ({gramsInCart=0}) => {
  return ( gramsInCart > MAX_MA_FLOWER_GRAMS
    ? <Alert severity="warning" style={{margin:'1rem 0 0'}}>
        Sorry, it looks like your cart exceeds the 28.3gram (1oz) MA state limit for Cannabis. 
        You'll need to reduce product quantities prior to placing your order.
      </Alert>    
    : null
  )
}

QuantityWarning.propTypes = {
  gramsInCart: PropTypes.number.isRequired
}

export default QuantityWarning;
