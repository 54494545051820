import * as React from "react";

import { storeValue, userAddressKey } from '../util/storage-utils';
import { useAnalyticsStore } from '../../App';
import { errMsg } from './registration-errors';
import SearchLocationInput from '../googlemaps/SearchLocationInput';
import AddressUpdaterInline from "./AddressUpdaterInline";
import DeliveryBlacklistMessage from "../address/DeliveryBlacklistMessage";
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';

import PropTypes from 'prop-types';

import '../registration/MaterialUI.module.css';
import styles from './AddressSearch.module.css';

/**
 * Provide a form to edit the location-search selected address ( minus apartment number ) 
 * 
 * @param {object} location -- The address JSON
 * @param {addressErr} boolean -- Validation error: required field etc.
 * @param {function} setLocation -- update loocation in the RegistrationProvider
 */
const AddressSearchAndUpdate = ({
  location,
  addressErr, 
  setLocation
}) => {
  
  const { trackEvent, trackError } = useAnalyticsStore();

  // Allow the location search to control display/edit state
  const [enableEdit, setEnableEdit] = React.useState(); 

  const handleAddressSelection = (response) => {
    if (response?.address) {
      const { 
        address: street_address,
        city,
        stateShort: state,
        zipCode: zip_code,
        geo_coordinates,
      } = response;
      
      const selectedAddress = {
        street_address,
        city,
        state,
        zip_code,
        geo_coordinates,
      };
      // Update Context
      setLocation(selectedAddress); 
      
      // Store address for future use. Use session maybe?
      const addrString  = JSON.stringify(selectedAddress);
      storeValue( userAddressKey, addrString);
      trackEvent('registration_address_search_success'); 
    } else {
      trackError('registration_address_search_error');
    }
  };

  return ( location.street_address 
    // If we have an address, show it
    ? <>
        <AddressUpdaterInline 
          location={location}
          editAddress={enableEdit}
          setEditAddress={setEnableEdit} 
          onUpdate={(location) => {
            setLocation(location)
            setEnableEdit(false);
           }} />
        <DeliveryBlacklistMessage location={location} />   
      </>   
    // else show location search   
    : <div className={styles.inputWrap}>
        <div className={styles.pinIcon}>
          <LocationOnRoundedIcon fontSize={'inherit'} style={{color:'#0000008a'}} />
        </div>
        <SearchLocationInput disableAutoFocus onSelection={handleAddressSelection} />
        {/* Address required etc. */}
        { addressErr &&
           <div className={styles.errorMsg}>
             {errMsg('address', addressErr)}
           </div>
        }
      </div>
  );
}

AddressSearchAndUpdate.propTypes = {
  location: PropTypes.object,
  addressErr: PropTypes.bool, 
  setLocation: PropTypes.func.isRequired,
}

export default AddressSearchAndUpdate;
