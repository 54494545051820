import * as React from 'react';

import { FilteredProductsContext, subCategoryAll } from '../products/FilteredProductsProvider';
import PropTypes from 'prop-types';

import styles from './FilterWithinCategory.module.css';

/**
 * This component will provide sub-category filters for both Desktop and Mobile layouts
 */ 
const FilterWithinCategory = ({onSubCategoryClick}) => {

  const { categoryName, subCategory, subCategories } = React.useContext(FilteredProductsContext);

  // Add the 'All Edibles' option 
  const subCategoriesWithAll = [subCategoryAll, ...subCategories];
  
  // Changing sub-categories will trigger navigation: /Edibles/Gummies
  const subCategoryClick = (subCategory) => {
    if (onSubCategoryClick) {
      onSubCategoryClick(subCategory);
    }
  };

  return (subCategoriesWithAll.length > 1
    ? <div className={styles.subCategoryFilter}> 
        <div className={styles.sectionHdr}>
          SUBCATEGORIES
        </div>
        <ul className={styles.filterLinks}>
          { subCategoriesWithAll.map(subCat => {
            const selectedClass = subCat.name === subCategory.name ? styles.selected :'';
            const categoryLabel = subCat.name === subCategoryAll.name
                           ? `All ${categoryName}`
                           : subCat.display_name;
            return <li key={subCat.name} 
                       className={`${styles.filterLink} ${selectedClass}`} 
                       onClick={() => {
                         subCategoryClick(subCat);
                       }}>
                     {categoryLabel}
                   </li>
          })}
        </ul>
        <div className={styles.rightEdgeMask}></div>
      </div>
    : null
  );
}

FilterWithinCategory.propTypes = {
  onSubCategoryClick: PropTypes.func.isRequired
}

export default FilterWithinCategory;
