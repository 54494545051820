import * as React from 'react';

import ToolTip from '../common/ToolTip';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  inner: {
    fontSize: '.875rem',
  },
  block: {
    marginBottom: 8
  },
  search: {
    fontWeight: 600,
    fontStyle: 'italic',
  },
  explained: {
   margin: '4px 0',
   paddingLeft: 8, 
  }
})

const SearchToolTip = () => {
  
  const classes = useStyles();

  const btnStyle = {
    position: 'absolute',
    top: 3,
    right: 3,
    zIndex: 3,
  }

  return (
    <ToolTip
      buttonStyle={btnStyle}
      iconSize={27}
      iconOpacity='0.5'
      toolTipStyle={{zIndex:3}}
      tipTitle="Product Search"
      trackingLabel="product_search_tooltip"
      placement='bottom-end'>
      <div className={classes.inner}>
        <div className={classes.block}>
          Returns the first <b>10</b> matching products. Search by name, brand, strain or sale.
        </div>
        <div className={classes.block}>
          For example:
        </div>
        <div className={classes.search}>
          "28g flo sale"
        </div>
        <div className={classes.explained}>
          for 28g Flower on Sale
        </div>
        <div className={classes.search}>
          "edi ind apple"
        </div>
        <div className={classes.explained}>
          for Apple-flavored Indica Edibles
        </div>
        <div className={classes.search}>
          "hyb pre 5pk"
        </div>
        <div className={classes.explained}>
          for Hybrid Pre-Roll 5 Packs
        </div>
      </div>
    </ToolTip>  
  )
}

export default SearchToolTip;
