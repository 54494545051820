import * as React from 'react';

import { activeController, devLog } from '../util/util';
import { UserContext } from '../providers/UserProvider';
import { useAnalyticsStore } from '../../App';
import { fetchProductDetail } from '../dispensary/dispensary-utils';

import PropTypes from 'prop-types';

/**
 * Hook that returns a product
 */
const useProductDetails = (itemId) => {

  const { user } = React.useContext(UserContext);
  const { trackError } = useAnalyticsStore();
  const [ product, setProduct ] = React.useState();
  const [ error, setError ] = React.useState();
  const [ status, setStatus ] = React.useState();
  const controllerRef = React.useRef(new AbortController());

  // Load product
  React.useEffect(() => {
    const controller = activeController(controllerRef);
    if (user && 
        product?.id !== itemId) {

      const callback = (response) => {
        if (response.id) {
          setProduct(response);
          setStatus(200);
        } else if (response.error) {
          setError(response.error);
          setStatus(response.status);
          if (response.status === 404) {
            trackError('useproductdetails_sold_out_product');
          } else {
            trackError('useproductdetails_response_error');
          }
        } 
      };
      try {
        devLog('fetching product data');
        fetchProductDetail(itemId, callback, controller);
      } catch(error) {
        devLog(`useProductDetails fetch error ${error}`);
        trackError('useproductdetails_fetch_error');
      }
    }
    return () => controller.abort(); 
  }, [user, itemId, product, trackError]);

  return {
    productId: product?.id,
    product,
    error,
    status
  }
}  

useProductDetails.propTypes = {
  itemId: PropTypes.string.isRequired,
}

export default useProductDetails;
