import * as React from 'react';

import closeX from '../../assets/closeX.png';
import Cart from './Cart';
import PropTypes from 'prop-types';

import styles from './SlideInCart.module.css'; 

const SlideInCart = ({
  isOpen,
  toggleOpen,
  cartType,
  withStyle={},
}) => {
  
  const openStyle = isOpen ? styles.open : ''; 
  
  return (
    <div className={`${styles.slideInCart} ${openStyle}`}
         style={withStyle}>
      <div className={styles.cropShadow}>
        <button className={styles.closeIcon} onClick={toggleOpen} type="button" size="16" kind="BUTTON/PLAIN" aria-label="Close">
          <img alt="" className={styles.closeX} src={closeX} />
          <span className={styles.closeText}>Close</span>
        </button>
        <Cart
          cartType={cartType}
          closeMenuFn={toggleOpen} />
      </div>
    </div>
  );
}

SlideInCart.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  cartType: PropTypes.string.isRequired,
  withStyle: PropTypes.object,
};

export default SlideInCart;
