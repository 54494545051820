import * as React from 'react';
import { devLog } from '../util/util';
import { UserContext } from '../providers/UserProvider';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { validateSignUpEntry } from './validation-utils';
import useStoredReferringUser from '../refer/useStoredReferringUser';

import PropTypes from 'prop-types';

export const RegistrationContext = React.createContext();

// Avoid uncontrolled lint errors
const EMPTY_STRING = '';

const RegistrationProvider = ({children}) => {
  // Monitor available location changes
  const { searchLocation } = React.useContext(UserContext);
  // Address via typeahead or storage
  const [location, setLocation] = React.useState({})
  const [addressErr, setAddressErr] = React.useState();
  // Inline SignUp form
  const [name, setName] = React.useState(EMPTY_STRING);
  const [nameErr, setNameErr] = React.useState();
  const [email, setEmail] = React.useState(EMPTY_STRING);
  const [emailErr, setEmailErr] = React.useState();
  const [password, setPassword] = React.useState(EMPTY_STRING);
  const [passwordErr, setPasswordErr] = React.useState();
  const [apartmentNumber, setApartmentNumber] = React.useState(EMPTY_STRING);
  const [birthDate, setBirthDate] = React.useState(EMPTY_STRING);
  const [birthDateErr, setBirthDateErr] = React.useState();
  const [age21Err, setAge21Err] = React.useState();
  const [acceptTerms, setAcceptTerms] = React.useState(false);
  const [acceptTermsErr, setAcceptTermsErr] = React.useState();
  const [acceptMarketing, setAcceptMarketing] = React.useState(true);
  // PhoneNumberInline component
  const [phoneNumber, setPhoneNumber] = React.useState(EMPTY_STRING);
  const [phoneNumberErr, setPhoneNumberErr] = React.useState();
  // PhotoIdInline component - We only need front ID image - 8/2022
  const [primaryPhotoId, setPrimaryPhotoId] = React.useState();
  const [primaryPhotoIdErr, setPrimaryPhotoIdErr] = React.useState();
  // Referrer Info for Refer Credit promo
  const [referrerId, setReferrerId] = React.useState();
  const [referrerName, setReferrerName] = React.useState();
  // Whether we have validation errors or not - clear on input change
  const [validationErrors, setValidationErrors] = React.useState();
  // Continue path after registration complete
  const [continuePath, setContinuePath] = React.useState('/');
  // Stored referring user from referral links
  const storedReferringUser = useStoredReferringUser();

  // Monitor available location changes
  React.useEffect(() => {
    if (searchLocation) {
      setLocation(searchLocation);
    }
  }, [searchLocation])

  React.useEffect(() => {
    if (storedReferringUser?.id) {
      setReferrerId(storedReferringUser.id);
      setReferrerName(storedReferringUser.name);
    }
  }, [storedReferringUser])

  /**
   * Validate a single form input.
   *
   * @param {string} inputId - input id
   * @param {string} value - input value
   */
  const isValidEntry = (inputId, value, errorSetter) => {
    const isValid = validateSignUpEntry(inputId, value);
    if (errorSetter) {
      errorSetter(!isValid);
    }
    return isValid;
  };

  // Create a Date from the registration date input
  const dateFromDOBInput = (value) => {
    const dateItems = typeof value === "undefined" ? [] : value.split('/');
    if (dateItems.length === 3 && dateItems[2].length === 4) {
      return new Date(`${dateItems[2]}/${dateItems[0]}/${dateItems[1]}`);
    } else {
      return undefined;
    }
  }

  // Verify age is at least 21 on submit/validateAll
  const isAtLeast21 = (value) =>  {
    const birthDate = dateFromDOBInput(value);
    if (birthDate) {
      const today = new Date();
      // Subtract 21 years from the current year
      const minus21Years = today.getFullYear() - 21;
      // Create the today - 21 years date object
      const date21YearsAgo = new Date(minus21Years, today.getMonth(), today.getDate());
      setAge21Err(birthDate > date21YearsAgo);
      return birthDate <= date21YearsAgo;
    }
    setAge21Err(false);
    return true; // DOB is invalid, handle via input validation
  }

  /**
   * Validate a BirthDate
   *
   * @param {string} value - MM/DD/YYYY
   * @returns boolean
   */
  const isValidBirthDate = (value) => {
    /**
     * Validate the date by comparing the Month/Year after setting the values
     * (An invalid Feb 30 date will resolve to March 2)
     */
    const dateItems = typeof value === "undefined" ? [] : value.split('/');
    let isValid = false;
    if (dateItems.length === 3 && dateItems[2].length === 4) {
      const bDay = new Date(`${dateItems[2]}/${dateItems[0]}/${dateItems[1]}`);
      const birthYear = bDay.getFullYear();
      const currentYear = new Date().getFullYear();
      isValid = birthYear === parseInt(dateItems[2], 10) &&
                birthYear > 1900 && birthYear < currentYear &&
                bDay.getMonth() === parseInt(dateItems[0], 10) - 1;
                // The bDay.getMonth() check ensures the user entered a valid day
    }
    setBirthDateErr(!isValid);
    return isValid;
  };

  const isValidAddress = (value) => {
    const isValid = !!(value.street_address && value.city && value.state && value.zip_code);
    setAddressErr(!isValid);
    return isValid;
  }

  const isValidPhoneNumber = (value) => {
    // This is the less strict validation to reduce false negatives
    // See: https://gitlab.com/catamphetamine/react-phone-number-input#isvalidphonenumbervalue-string-boolean
    const isValid = typeof value === "string" && isPossiblePhoneNumber(value);
    setPhoneNumberErr(!isValid);
    return isValid;
  }

  /**
   * Validate all required form inputs
   */
  const validateAll = () => {
    const validName = isValidEntry('name', name, setNameErr);
    const validEmail = isValidEntry('email', email, setEmailErr);
    const validPassword = isValidEntry('password', password, setPasswordErr);
    const validAddress = isValidAddress(location);
    const validDOB = isValidBirthDate(birthDate);
    const isAge21 = isAtLeast21(birthDate);
    const validPhone = isValidPhoneNumber(phoneNumber);
    const validPhotoIdImage = isValidEntry('photoIdPrimary', primaryPhotoId, setPrimaryPhotoIdErr);
    const validTerms = isValidEntry('acceptTerms', acceptTerms, setAcceptTermsErr);

    devLog(`Validating...`);
    devLog(`valid name: ${validName}`);
    devLog(`valid email: ${validEmail}`);
    devLog(`valid password: ${validPassword}`);
    devLog(`valid address: ${validAddress}`);
    devLog(`apartment is ${apartmentNumber}`);
    devLog(`valid phone is ${validPhone}`);
    devLog(`valid photo ID image is ${validPhotoIdImage}`);
    devLog(`valid DOB: ${validDOB}`);
    devLog(`is at least 21?: ${isAge21}`);
    devLog(`valid terms: ${validTerms}`);
    devLog(`accept marketing is ${acceptMarketing}`);

    const isValid = validName && validEmail && validPassword && validAddress &&
                    validDOB && isAge21 && validPhone && validPhotoIdImage && validTerms;
    setValidationErrors(!isValid);
    return isValid;
  };

  return <RegistrationContext.Provider value={{
      location,
      setLocation,
      addressErr,
      name,
      setName,
      nameErr,
      setNameErr,
      email,
      setEmail,
      emailErr,
      setEmailErr,     /* also used in for onBlur email-exists check */
      password,
      setPassword,
      passwordErr,
      setPasswordErr,  /* also used for onBlur strong-password check */
      apartmentNumber,
      setApartmentNumber,
      birthDate,
      setBirthDate,
      birthDateErr,
      setBirthDateErr,
      age21Err,
      setAge21Err,
      acceptTerms,
      setAcceptTerms,
      acceptTermsErr,
      setAcceptTermsErr,
      acceptMarketing,
      setAcceptMarketing,
      phoneNumber,
      setPhoneNumber,
      phoneNumberErr,
      setPhoneNumberErr,
      primaryPhotoId,
      setPrimaryPhotoId,
      primaryPhotoIdErr,
      setPrimaryPhotoIdErr,
      referrerId,
      setReferrerId,
      referrerName,
      setReferrerName,
      validateAll,
      validationErrors,
      setValidationErrors,
      continuePath,
      setContinuePath
    }}>
    {children}
  </RegistrationContext.Provider>
}

RegistrationProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default RegistrationProvider;
