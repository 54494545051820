import * as React from 'react';

import useBundledPriceMap from './useBundledPriceMap';
import { getBundlePriceTeaser } from "../products/product-utils";
import StrongText from '../common/StrongText';
import PropTypes from 'prop-types';

import styles from './BundlePricing.module.css';

/**
 * Display bundle pricing thresholds
 */
const MixAndMatchMsg = ({
  cost_usa_cents,
  bundle_deal_data,
}) => {

  const pricingMap = useBundledPriceMap(cost_usa_cents, bundle_deal_data);

  return ( pricingMap
    ? <div className={styles.mixMatchMsg}>
        <StrongText firstWord>Mix & Match:</StrongText>
        Quantity minimum can be met by mixing with other
        <StrongText noWrap>{getBundlePriceTeaser(bundle_deal_data)}</StrongText> products!
      </div>
    : null
  )
}

MixAndMatchMsg.propTypes = {
  cost_usa_cents: PropTypes.number.isRequired,
  bundle_deal_data: PropTypes.object.isRequired,
}

export default MixAndMatchMsg;
