import * as React from 'react';
import { TextField, makeStyles }from '@material-ui/core';
import BasicButton from '../styleguide/BasicButton';
import WriteIcon from '@material-ui/icons/CreateRounded';
import EnterKeyIcon from '@material-ui/icons/KeyboardReturnRounded';

const useStyles = makeStyles({
  addNoteButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    maxWidth: 'calc(100vw - 2rem)',
    padding: '8px 12px 8px 10px',
    border: '2px dashed #ccc',
    borderRadius: 4,
    fontWeight: 400,
    fontSize: '.875rem',
  },
  noteInput: {
    boxSizing: 'border-box',
    border: '1px solid #ccc',
    margin: '0 !important',
    padding: '7px 8px',
    fontSize: '1.125rem',
    borderRadius: 4,
    backgroundColor: '#62b0f411',
    '&>div': {
      border: 'none'
    },
    '&>div:after': {
      display: 'none'
    },
    '&>div:before': {
      display: 'none'
    }
  },
  writeIcon: {
    verticalAlign: -5,
    marginRight: 4,
  },
  editGrid: {
    display: 'flex',
    marginTop: -8,
  },
  editButton: {
    padding:'6px 8px', 
    minHeight: 37, 
    minWidth: 'auto'
  },
  whenDone: {
    fontSize: '.875rem',
    fontWeight: 400,
  }
});

const AddDeliveryNote = ({
  onNoteEntered,
  orderDetailsState={}
}) => {

  const classes = useStyles();
  const [expand, setExpand] = React.useState();
  const [note, setNote] = React.useState('');
  const inputRef = React.useRef();

  const toggleExpand = React.useCallback(() => {
    setExpand(!expand);
  }, [expand])

  // This helps with the mobile keyboard: 'Done'
  const onBlurFn = React.useCallback(() => {
    toggleExpand();
    onNoteEntered(note);
  }, [note, toggleExpand, onNoteEntered]);

  // Focus input on expand
  React.useEffect(() => {
    if (expand) {
      inputRef.current.focus();
    }
  }, [expand, note]);


  const onKeyPress = React.useCallback((event) => {
    if (event.key === 'Enter') {
      inputRef.current.blur();
      toggleExpand();
      onNoteEntered(event.target.value);
    }
  }, [toggleExpand, onNoteEntered])

  return (
    <div>
      { expand
        ? <div>
            <TextField
              className={classes.noteInput}
              multiline
              minRows={2}
              maxRows={4}
              inputRef={inputRef}
              inputProps={{ maxLength:135 /* API Max:256 */}}
              value={note}
              onChange={(event) => setNote(event.currentTarget.value)}
              onBlur={onBlurFn}
              onKeyUp={onKeyPress} />
            <div className={classes.whenDone}>
              <EnterKeyIcon style={{verticalAlign:-7}} /> 
              Hit <b>'DONE' or 'ENTER'</b> to save note.
            </div>    
          </div>
        :  orderDetailsState.customer_delivery_notes
          ? <div className={classes.editGrid}>
              <div style={{flex:1, paddingTop:8}}>
                "{ orderDetailsState.customer_delivery_notes.trim() }"
              </div>
              <div>
                <BasicButton 
                  text='Edit Note'
                  withClass={classes.editButton}
                  handleClick={toggleExpand} />
              </div>
            </div>
          : <div className={classes.addNoteButton} onClick={() => toggleExpand()}>
              <span><WriteIcon className={classes.writeIcon} /></span>
              <span>Anything the driver should know? (optional)</span>
            </div>
      }
    </div>
  )
}

export default AddDeliveryNote;
