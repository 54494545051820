import React from 'react';

import { UserContext } from '../providers/UserProvider';
import { RegistrationContext } from '../registration/RegistrationProvider';
import GetReferCreditDetails from './GetReferCreditDetails';

/**
 * Render show referral credit banner when user has used a referral link
 */
const ReferredSessionBanner = () => {

  const { user } = React.useContext(UserContext); 
  // Refer Credit referrer, e.g. from session
  const { referrerName } = React.useContext(RegistrationContext);

  // Show referral link content only while user is unregistered  
  const [showReferPromo, setShowReferPromo] = React.useState();
  React.useEffect(() => {
    setShowReferPromo(referrerName && user?.isAnonymous);
  }, [user, referrerName])

  return ( showReferPromo
    ? <GetReferCreditDetails referrerName={referrerName} />
    : null
  );
} 

export default ReferredSessionBanner;
