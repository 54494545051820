import React from 'react';

import { navigateTop } from '../routing/router-utils';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { useAnalyticsStore } from '../../App'; 
import ButtonWithPrice from '../styleguide/ButtonWithPrice';

/**
 * Display REORDER Checkout button with price for delivery orders
 * 
 * NOTE: Since customers may have other non-reorder products in their cart
 * we should NOT enforce cart minimums here or display cart warnings like:
 * "You've exceeded the 1oz limit etc." We'll handle these issues on
 * the checkout page.
 */
const ReOrderDeliveryButton = () => {

  const { trackEvent } = useAnalyticsStore();
  const { itemTotalDisplay } = React.useContext(CartItemsContext);

  // Proceed to /placeOrder
  const proceedToCheckout = () => {
    navigateTop(`/checkOut`);
    trackEvent('reorder_delivery_checkout_click');
  };
 
  return(
    <ButtonWithPrice
      isFullWidth 
      text="Proceed to Checkout" 
      price={itemTotalDisplay} 
      handleClick={proceedToCheckout} />
  )
}

export default ReOrderDeliveryButton;
