import * as React from "react";

import ToolTip from "../common/ToolTip";
import BundlePricingThresholds from "./BundlePricingThresholds";
import MixAndMatchMsg from "./MixAndMatchMsg";
import discountRedIcon from "../../assets/discountRedIcon.png";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  wrapper: {
    whiteSpace: 'nowrap',
    marginTop: 4,
  },
});

const BundlePricingTooltip = ({
  cost_usa_cents,
  bundle_deal_data,
  withStyle={},
  inModal,
}) => {

  const classes = useStyles();
  return ( bundle_deal_data
    ? <div className={classes.wrapper} style={withStyle}>
        <ToolTip
          buttonText="Show Qty Prices"
          buttonIcon={discountRedIcon}
          buttonStyle={{padding:4, margin:'0 0 -4px -4px'}}
          tipTitle="Bulk Discounts*"
          toolTipStyle={{maxWidth:250}}
          footerText="*NOTE: Bulk Discounts cannot be combined with promotional discount codes."
          placement="top"
          inModal={inModal}
          enableFlip
          trackingLabel="bundle_pricing_tooltip">
            <>
              <BundlePricingThresholds
                cost_usa_cents={cost_usa_cents}
                bundle_deal_data={bundle_deal_data}
                includeSavingsPct />
              <MixAndMatchMsg
                cost_usa_cents={cost_usa_cents}
                bundle_deal_data={bundle_deal_data} />
            </>
        </ToolTip>
      </div>
    : null
  );
};

export default BundlePricingTooltip;
