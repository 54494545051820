import * as React from 'react';

import { ModalContext, ModalType } from '../modal/ModalProvider';
import useReferralLink from './useReferralLink';
import { useAnalyticsStore } from '../../App';
import { UserContext } from '../providers/UserProvider';
import { CREDIT_DETAILS } from './GetReferCreditDetails';
import CheckMark from '../common/CheckMark';
import Button from '../styleguide/Button';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  shareOptions: {
    display: 'block',
    margin: '.5rem 0 0',
    '& button': {
      minWidth: '100%',
      marginBottom: 8,
      margin: 0,
      padding: '12px 0'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
      '& button': {
        flex: '0 0  calc(50% - 4px)',
        minWidth: 'auto',
        margin: 0,        
      }
    }
  },
  referUrl: {
    font: 'var(--zrFontSemi)',
    color: '#000',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    boxSizing: 'border-box',
    fontSize: '1rem',
    padding: '.625rem 1.25rem',
    lineHeight: '150%',
    border: '1px solid #e6e7e8',
    borderRadius: 8,
    boxShadow: 'rgb(214, 214, 214) 0px 0px 0px 1px inset'
  },
  qrIcon: {
    verticalAlign: -6,
    height: 20,
    margin: '2px 8px',
  },
  shareIcon: {
    verticalAlign: -6,
    height: 24,
    margin: '0 8px',
  },
  copySuccess: {
    display: 'none',
    textAlign: 'center',
    font: 'var(--zrFontSemi)',
    fontSize: '1rem',
    color: 'var(--zrPrimaryDark)',
    margin: '.75rem 0 0'
  },   
  showSuccess: {
    display: 'block'  
  }
}));

const ReferralLinkInput = () => {

  const classes = useStyles();
  const { trackEvent  } = useAnalyticsStore();
  const { userId, userName } = React.useContext(UserContext);

  // These could be props if we need re-use
  const shareTitle = `Here's $${CREDIT_DETAILS.total} to use on Zyp Run, Get all your cannabis needs delivered fast! Register here:`;
  const sharePage = 'getCredit';
  const referralLink = useReferralLink(sharePage, userId, userName);

  const { displayModal } = React.useContext(ModalContext);

  /**
   * Copy referral code url to the clipboard
   */
  const copyCodeUrl = () => {
    const linkElem = inputRef.current;
    if (linkElem.value) {
      navigator.clipboard.writeText(linkElem.value);
      trackEvent('refer_copy_link');
      // show the message
      if (msgRef.current) {
        msgRef.current.classList.add(classes.showSuccess);
      }
    }
  };  

  const displayQRModal = (codeUrl) => {
    displayModal(ModalType.QR_CODE, { 
      codeUrl,
      trackContext: 'refer_qrcode'
    });  
  }

  // Determine whether to upgrade COPY BUTTON to SHARE BUTTON (mobile/tablet)
  const [canShare, setCanShare] = React.useState();
  React.useEffect(() => {
    setCanShare(window.navigator.share && window.innerWidth <= 800);
  }, [canShare]);
  
  const inputRef =  React.useRef();
  const msgRef = React.useRef();

  return ( referralLink 
    ? <>
        <div>
          <input type="text" ref={inputRef} className={classes.referUrl} 
                 value={referralLink} 
                 onChange={(/*suppress warning*/)=>{}} />
        </div>
        <div className={classes.shareOptions}>
          { canShare
            ? <Button 
              text="Share Your Link"
              handleClick={() => {
                navigator.share({
                  title: 'Zyp Run Cannabis Delivery', /* not supported by all targets */
                  text: shareTitle,
                  url: referralLink,
                })
                .then(() => trackEvent(`share_${sharePage}_click`))
                .catch((error) => trackEvent(`share_${sharePage}_error_${error.substring(0,12)}`));
              }}>
                <img alt="" className={classes.shareIcon} height="20" src="/img/share_icon_white.png" />
              </Button>    
            : <Button
                text="Copy Your Link"
                withStyle={{padding:'10px 0 9px'}} 
                handleClick={copyCodeUrl}>
                <img alt="" className={classes.shareIcon} height="20" src="/img/copy_icon_white.png" />
              </Button>    
          } 
          <Button 
            withStyle={{padding:'10px 0 9px'}}
            text="Display QR Code" 
            handleClick={() => displayQRModal(referralLink)}>
            <img alt="" className={classes.qrIcon} height="20" src="/img/qr_code_white.png" />
          </Button>
        </div>

        {/* Success msg for Desktop copy option */}
        <div ref={msgRef} className={classes.copySuccess}>
          <CheckMark /> Refer link copied to clipboard
        </div> 
      </>
    : null    
  );
};

export default ReferralLinkInput;
