import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import { handleMissingProductImage } from './product-utils';
import { optimizedImage } from './CroppedProductImage';
import MissingImageLabel from './MissingImageLabel';
import PropTypes from 'prop-types';

import styles from './PreviewProductImage.module.css';


/**
 * Displays product image or fallback
 *
 * @param {string} productName - name for alt attribute
 * @param {string} src - image src
 * @param {string} category - for use with fallback image
 * @param {boolean} isSuggestedProduct - for the smaller suggested product card
 **/
const PreviewProductImage = ({
  productName, 
  src,
  category,
  isSuggestedProduct
}) => { 
 
  const { trackError } = useAnalyticsStore();

  // Sometimes CloudFlare returns 503 or even a 404 during outtages...  
  const handleError = (event) => {
    trackError('preview_img_load_error');
    // Switch to non-CDN image:
    setImgSrc(imgSrcNoCDN);
    setIsFallbackImage(true);
    // we only need to log once
    event.currentTarget.onError = null;
  };
  
  const [imgSrc, setImgSrc] = React.useState();
  const [imgSrcNoCDN, setImgSrcNoCDN] = React.useState();
  const [isFallbackImage, setIsFallbackImage] = React.useState();

  React.useEffect(() => {
    // Handle fallback image, and store fallback in case CDN fails (onError)
    if (!imgSrc) {
      const { imgUrl, fallbackUrl, isFallback } = handleMissingProductImage(src, category);
      setImgSrc(optimizedImage(imgUrl, 300));
      setImgSrcNoCDN(fallbackUrl);
      setIsFallbackImage(isFallback); 
    }
  },[src, category, imgSrc]);

  const suggestedStyle = isSuggestedProduct ? styles.missingImageSuggested : '';

  return ( 
    <div className={styles.imageOuter}>
      <div className={styles.imageWrap}>
        { imgSrc
          ? <>
              <img
                alt={productName}
                className={styles.productImage}
                src={imgSrc}
                onError={(event) => handleError(event)}
                />
          </>
          : null 
        }   
      </div>
      { isFallbackImage &&
        <div className={`${styles.placeholderMessage} ${suggestedStyle}`}> 
          <MissingImageLabel withClass={styles.missingImageLabel} />
        </div>
      }
    </div>
  )
}

PreviewProductImage.propTypes = {
  productName: PropTypes.string.isRequired,
  src: PropTypes.string,
  category: PropTypes.string,
  isSuggestedProduct: PropTypes.bool
}

export default PreviewProductImage;
