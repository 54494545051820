import * as React from "react";

import { trackEvent, trackError } from "../analytics/tracking";
import { updateUserInfo } from '../registration/registration-utils';
import { activeController } from "../util/util";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import OrderNotifications from "./OrderNotifications";
import SuccessMessage from "../styleguide/SuccessMessage";
import ErrorMessage from "../styleguide/ErrorMessage";
import styles from './YourAccount.module.css';

import PropTypes from 'prop-types';

/**
 * Update email/message preferences 
 */
const SubscribeForm = ({
  emailEnabled,        /* current email setting returend by API */
  textEnabled,         /* current text setting returned by API */
  deliveryTextEnabled, /* current state from API - Read Only */
}) => {

  const [emailChecked, setEmailChecked] = React.useState(true);
  const [messageChecked, setMessageChecked] = React.useState(true);

  const [updating, setUpdating] = React.useState(false);
  const [updateError, setUpdateError] = React.useState();
  const [updateSuccess, setUpdateSuccess] = React.useState();

  // Initial state via props
  React.useEffect(() => {
    setEmailChecked(emailEnabled);
    setMessageChecked(textEnabled);
  }, [emailEnabled, textEnabled]);

  const controllerRef = React.useRef(new AbortController());

  const onChange = (event) => {
    const controller = activeController(controllerRef);
    const updateInfo = {};
    
    if (event.currentTarget.type === "checkbox") {
      const { id, checked } = event.currentTarget;
      if (id === 'subEmail') {
        setEmailChecked(checked);
        updateInfo.marketing_email_opted_in = checked;
        trackEvent(`subscribe_email_toggle_${checked ? 'on' : 'off'}`);
      } else {
        setMessageChecked(checked);
        updateInfo.marketing_sms_text_opted_in = checked;
        trackEvent(`subscribe_sms_toggle_${checked ? 'on' : 'off'}`);
      }
      
      setUpdateSuccess(undefined);
      setUpdateError(undefined);
      setUpdating(true);
      // Make API call
      updateUserInfo(
        updateInfo, 
        (responseOk, response) => {
          setUpdateSuccess(responseOk);
          setUpdateError(responseOk ? undefined : response.error);
          setUpdating(false);          
          // on error, revert toggle change
          if (!responseOk) {
            if (id === 'subEmail') {
              setEmailChecked(!checked);
            } else {
              setMessageChecked(!checked);
            }
            trackError(`error_subscribe_toggle_${id === 'subEmail' ? 'email' : 'sms'}`);
          }
        },
        controller
      );
    }
  }
  
  return (
    <div id="SUBSCRIBE" className={styles.subscribe}>
      <div className={styles.subscribeItem}>
        <FormControlLabel
          label={`Enable promotions and announcements via email (${emailChecked ? 'ENABLED' : 'DISABLED' })`}
          control={<Switch disabled={updating} checked={emailChecked || false} onChange={onChange} id="subEmail" />}
        />
      </div>
      <div className={styles.subscribeItem}>
        <FormControlLabel
          label={`Enable promotions and announcements via text message (${messageChecked ? 'ENABLED' : 'DISABLED' })`}
          control={<Switch disabled={updating} checked={messageChecked || false} onChange={onChange} id="subMessage" />}
        />
      </div>
      {/* success / failure */}
      { updateError &&   
        <ErrorMessage isInline text={updateError} />
      }
      { updateSuccess &&   
        <SuccessMessage isInline text="Updated!" />
      }
      <OrderNotifications deliveryTextEnabled={deliveryTextEnabled} />
    </div>
  );
}

SubscribeForm.propTypes = {
  emailEnabled: PropTypes.bool,
  textEnabled: PropTypes.bool,
  deliveryTextEnabled: PropTypes.bool,
};

export default SubscribeForm;
