import * as React from 'react';

/**
  "bundle_deal_data": {
    "values": [{ "quantity": 2, "unit_price": 3500 }],
    "id": "2FOR70",
    "minimum_quantity": 2
  },
*/

// Adjusted for multi-product bundling
const taggedItemPriceCents = (item, taggedItemCount) => {
  const { bundle_deal_data } = item;
  // Bundle quantity price trumps ALL
  let bundleMatch;
  if (bundle_deal_data && taggedItemCount >= bundle_deal_data.minimum_quantity) {
    bundleMatch = [ ...bundle_deal_data.values]
                      .sort((a,b) => b.quantity - a.quantity) // sort descending
                      .find(val => taggedItemCount >= val.quantity);
  }
  return bundleMatch?.unit_price;
}

/**
 * PRIMARILY FOR CARTED ITEMS
 *
 * Returns a map with the current item price for each
 * bundled product keyed by bundle id
 *
 * { '2FOR40' : 2000 }
 *
 * @param {*} cartItems
 * @returns Map<string,number>
 */
const useCartItemBundledPricingMap = (cartItems, itemTotal /* trigger rerender */) => {

  const [bundlePricingMap, setBundlePricingMap] = React.useState();

  React.useEffect(() => {
    if (cartItems?.length) {
      // Build a map of all the bundle tags that exist in the cart
      // Calculate the item counts for each tag
      const tagUseCount = new Map();
      cartItems.forEach(item => {
        const { bundle_deal_data } = item;
        if (bundle_deal_data) {
          const count = tagUseCount.get(bundle_deal_data.id);
          const addlQty = item.quantity;
          tagUseCount.set(bundle_deal_data.id, count ? count + addlQty : addlQty);
        }
      })
      // Build a map of the current bundle item price based on current item counts
      const taggedItemPrice = new Map();
      cartItems.forEach(item => {
        const { bundle_deal_data } = item;
        if (bundle_deal_data) {
          const { id } = bundle_deal_data;
          const count = tagUseCount.get(id);
          const bundlePrice = taggedItemPriceCents(item, count);
          // Price if minimum is met
          if (bundlePrice) {
            taggedItemPrice.set(id, bundlePrice);
          }
        }
      })
      setBundlePricingMap(taggedItemPrice.size > 0 ? taggedItemPrice : undefined);
    } else {
      setBundlePricingMap(undefined);
    }

  }, [cartItems, itemTotal]);

  return bundlePricingMap;
}

export default useCartItemBundledPricingMap;
