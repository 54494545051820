import React from 'react';

import { trackEvent } from '../analytics/tracking';
import { handleMissingProductImage, flattenedProduct } from '../products/product-utils';
import { dollarDisplay } from '../providers/OrderPricingProvider';
import DisplayPrice from '../products/DisplayPrice';
import ButtonWithPrice from '../styleguide/ButtonWithPrice';
import ProductRequestButton from '../survey/ProductRequestButton'; 
import QuantityIcon from '../checkout/QuantityIcon';
import { Alert } from '@material-ui/lab';

import PropTypes from 'prop-types';

import styles from './ReOrder.module.css';

const ReOrderItem = ({
  product,
  totalFlowerGrams, // trigger weight display when cart is over 1 OZ
  addToCart,
}) => {

  const {
    name,
    image_url,
    category,
    cost_usa_cents,
    flower_equivalent_grams,
  } = flattenedProduct(product);

  // Handle missing product image
  const { imgUrl:productImage } = handleMissingProductImage(image_url, category.display_name);
  const [reorderQuantity, setReorderQuantity] = React.useState(1);

  const adjustQuantity = React.useCallback(adjustCount => {
    // But not below 1
    setReorderQuantity(reorderQuantity + adjustCount || 1);
  }, [reorderQuantity]);
  
  return (
    <li className={styles.reorderItem}>
      <div className={styles.itemDetails}>
        <span className={styles.itemCount}>x{reorderQuantity}</span>
        <span className={styles.itemTitle}>
          {name} 
        </span>
        <span className={styles.itemPriceCol}>
          <span className={styles.itemPrice}>
            <DisplayPrice
              product={product}
              quantity={reorderQuantity} />
          </span>
        </span>
      </div>
      
      <div className={styles.imageWrap}>
        <img src={productImage} alt={name} className={styles.itemImg}/>
        { product.notAvailable 
          ? null
          : <>
              { reorderQuantity > 0 &&
                <QuantityIcon cartCount={reorderQuantity} productFlowerGrams={flower_equivalent_grams} totalFlowerGrams={totalFlowerGrams} />
              } 
              <span className={`${styles.itemControl} ${styles.increase}`} 
                onClick={ () => adjustQuantity(1) }>+</span>
              { reorderQuantity > 1 &&  
                <span className={`${styles.itemControl} ${styles.decrease}`} 
                  onClick={ () => adjustQuantity(-1) }>-</span>
              }
            </>
        }      
      </div>
      <div className={styles.btmRow}>
        { product.notAvailable 
          ? <>
              <Alert severity="warning" className={styles.inventoryAlert}>
                This product is not currently available
              </Alert>
              <ProductRequestButton product={product} /> 
            </>  
          : <ButtonWithPrice text="Add To Cart" price={dollarDisplay(cost_usa_cents * reorderQuantity)} 
              withClass={styles.cartButton}
              handleClick={() => {
                addToCart(product, reorderQuantity);
                trackEvent('reorder_add_to_cart_clk');
              }} /> 
        }
      </div>
    </li>
  )
}

ReOrderItem.propTypes = {
  product: PropTypes.object.isRequired,
  totalFlowerGrams: PropTypes.number.isRequired,
  addToCart: PropTypes.func.isRequired
};

export default ReOrderItem;
