import React from 'react';

import PropTypes from 'prop-types';

import styles from './Button.module.css';

const DetourButton = ({text, isCentered, isSmall, withStyle={}, withClass, handleClick}) => {

  const smallStyle = isSmall ? { fontSize: '.875rem', padding: '.25rem .75rem'} : {}
  const centerStyle = isCentered ? { display:'block', marginLeft:'auto', marginRight: 'auto' } : {};
  const optionalStyle =  Object.assign(centerStyle, smallStyle);

  const propsStyle = Object.assign(optionalStyle, withStyle);
  
  return (
    <button
      className={`${styles.detourButton} ${withClass ? withClass : ''}`}
      style={propsStyle}
      onClick={handleClick}
    >
      {text} 
    </button>
  );
};

DetourButton.propTypes = {
  text: PropTypes.string.isRequired,
  isCentered: PropTypes.bool,
  isSmall: PropTypes.bool,
  withStyle: PropTypes.object,
  withClass: PropTypes.string, 
  handleClick: PropTypes.func
}

export default DetourButton;
