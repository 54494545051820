import * as React from 'react';

import useSpecialSaleProducts from '../specialSale/useSpecialSaleProducts';
import SaleItemCarousels from './SaleItemCarousels';
import SpecialSaleCarousel from '../specialSale/SpecialSaleCarousel';

const DealsPage = () => {

  // Products with various special sale items: limit 1, bundle pricing etc.
  const { specialSaleProducts } = useSpecialSaleProducts();

  return (
    <>
      {/* 420 Deals */}
      <SpecialSaleCarousel specialSaleProducts={specialSaleProducts} />
      <SaleItemCarousels otherSaleItemsCount={specialSaleProducts?.length} />
    </>
  )
}

export default DealsPage;
