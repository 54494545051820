import * as React from 'react';

import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';

export const BoltIcon = ({className=''}) => {
  return (
    <span className={className}>
      <svg fill="currentColor"
           style={{height:20}}
           focusable="false"
           aria-hidden="true" viewBox="6 0 12 24">
        <path d="M10.67 21c-.35 0-.62-.31-.57-.66L11 14H7.5c-.88 0-.33-.75-.31-.78 1.26-2.23 3.15-5.53 5.65-9.93.1-.18.3-.29.5-.29.35 0 .62.31.57.66l-.9 6.34h3.51c.4 0 .62.19.4.66-3.29 5.74-5.2 9.09-5.75 10.05-.1.18-.29.29-.5.29z"></path>
      </svg>
    </span>
  )
}
// ETA label: "Delivery by 3pm" styled via classes prop
const CurrentSlotETAText = ({
  slotLabel,
  isBostonDefault,
  isNextDay,
  classes={},
}) => {
  return ( slotLabel
    ? <div className={classes.deliveryByWrap}>
        { isNextDay
          ? <AccessTimeRoundedIcon className={classes.clockIcon} />
          : <BoltIcon className={classes.boltIcon} />
        }
        <span className={classes.deliveryText}>
          { isBostonDefault
            ? 'Boston '
            : null
          }
          Delivery by
        </span>
        {' '}
        <span className={classes.timeLabel}>
          {slotLabel}
        </span>
      </div>
    : null
  )
}

export default CurrentSlotETAText;
