import * as React from 'react';

import config from '../../config';
import { useAnalyticsStore } from '../../App';
import { MAX_CAROUSEL_PRODUCTS } from '../home/ProductCarousel';
import { ProductPreviewContext } from '../products/ProductPreviewProvider';
import { NotificationsContext } from '../notifications/NotificationsProvider';

// NOTE:  It's useful to sanity check display using actual customer order history
// by copying the response data for Order Search by customer email in the Admin App.
// import { kesslerOrders, carolineOrders, auzhaneOrders } from './sample_order_data';

// Don't show a carousel with only 1 item.
export const MIN_REORDER_ITEM_DISPLAY_COUNT = 2;

export const ReOrderItemsContext = React.createContext();

/**
 * Provides currently stocked products from a user's order history 
 */
const ReOrderItemsProvider = ({children}) => {

  const { trackEvent } = useAnalyticsStore();

  // For efficiency, focus on recent orders
  const MAX_ORDER_HISTORY = 12; 
  const { allProducts } = React.useContext(ProductPreviewContext);
  const { priorOrders } = React.useContext(NotificationsContext);

  // Previously ordered and available items
  const [reOrderItems, setReOrderItems] = React.useState();

  React.useEffect(() => {
    if (allProducts && priorOrders?.length) {
      // Build a set of prior order Skus
      const orderItemSkus = priorOrders.slice(0, MAX_ORDER_HISTORY).reduce((acc,order) => {
        // Be more liberal with display in dev
        if (config.IS_DEV || order.status === 'completed') {
          order.items.forEach(item => acc.add(item.dutchie_sku));
        }
        return acc;
      }, new Set());
      // Select the in-stock items
      const inStockItems = allProducts.filter(product => orderItemSkus.has(product.dutchie_sku));
      if (inStockItems?.length >= MIN_REORDER_ITEM_DISPLAY_COUNT) {
        setReOrderItems(inStockItems.slice(0, MAX_CAROUSEL_PRODUCTS));
        trackEvent('reorder_min_items_found');
      } else {
        trackEvent('reorder_min_items_not_found');
      }
    }
  }, [allProducts, priorOrders, trackEvent]);

  return (
    <ReOrderItemsContext.Provider value={{
      reOrderItems,    
    }}>
      {children}
    </ReOrderItemsContext.Provider>
  );
};

export default ReOrderItemsProvider;
