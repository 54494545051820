import * as React from 'react';

import useInterval from '../util/useInterval';

const DELAY = 5000;

/**
 * Rotate promo banners, used in mobile only currently
 */
const useCarouselAnimation = (count, isPaused) => {

  const [scrollToPromo, setScrollToPromo] = React.useState(0);

  const nextPromo = React.useCallback(() => {
    if (scrollToPromo + 1 >= count) {
       setScrollToPromo(0); 
     } else {
       setScrollToPromo(scrollToPromo + 1);
     }
  }, [count, scrollToPromo]);

  useInterval(nextPromo, DELAY, isPaused);

  return scrollToPromo;
}

export default useCarouselAnimation;
