import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(props => ({
  icon: {
    padding: '1px 3px 2px;',
    '&:hover path:nth-of-type(2)': {
      fill: (props) => props.hoverFillColor
    }
  },
	menuIcon: {
    verticalAlign: -1,
		padding: '0 1px'
  },
}));

/* Converted PNG to SVG using Adobe site */
/* Active state handled in FixedFooterNav.module.css */
const NewHomeIcon = ({
	fillColor='var(--zrPrimaryVeryDark)', 
  hoverFillColor='var(--zrPrimaryDark)', 
  size='28px',
  inMenu
}) => {
  
	const classes = useStyles({ hoverFillColor });
  
  const iconClass = inMenu ? classes.menuIcon : classes.icon;

  return (
    <svg className={iconClass}
        version="1.1" xmlns="http://www.w3.org/2000/svg"
    	  width={size} viewBox="0 0 134 143">
      <path fill="transparent" opacity="1.000000" stroke="none" 
      	d="M80.000000,144.000000 
        	C53.356964,144.000000 27.213930,144.000000 1.035448,144.000000 
        	C1.035448,96.400040 1.035448,48.800041 1.035448,1.100022 
        	C45.559448,1.100022 90.119194,1.100022 134.839478,1.100022 
        	C134.839478,48.666229 134.839478,96.333069 134.839478,144.000000 
        	C116.800446,144.000000 98.650223,144.000000 80.000000,144.000000 
          M132.153717,70.498680 
        	C132.092346,70.335266 132.030975,70.171844 132.131561,69.263489 
        	C131.203613,67.169861 130.753571,64.628273 129.274933,63.050854 
        	C111.525169,44.115398 93.699799,25.248949 75.709602,6.541977 
        	C70.878273,1.518151 65.011353,1.418630 60.254623,6.354182 
        	C42.244652,25.041210 24.406374,43.895302 6.684039,62.855305 
        	C5.050427,64.603004 4.695485,67.545898 3.455333,70.499619 
        	C3.516658,70.702713 3.577984,70.905807 3.355254,71.995903 
        	C3.450134,90.965958 3.545014,109.936012 3.463709,129.515381 
        	C3.570413,129.697906 3.677116,129.880432 3.609833,130.851776 
        	C4.818793,138.313522 8.504427,141.779572 15.872082,141.959488 
        	C22.523701,142.121902 29.182341,141.998108 35.837883,141.998184 
        	C49.468605,141.998322 52.796478,138.615997 52.798820,124.760124 
        	C52.800423,115.276985 52.744404,105.793404 52.819660,96.310860 
        	C52.872265,89.681755 55.597454,87.076859 62.309319,87.005028 
        	C65.636719,86.969414 68.964821,86.992920 72.292587,87.000053 
        	C80.525101,87.017700 82.991615,89.447601 83.015228,97.677956 
        	C83.045296,108.159538 82.906075,118.643105 83.066772,129.122330 
        	C83.183418,136.728226 87.322998,141.580566 94.274300,141.831406 
        	C103.245232,142.155136 112.246719,142.156799 121.216576,141.813934 
        	C127.956902,141.556305 131.748672,136.982941 132.145996,129.504883 
        	C132.102371,129.335190 132.058762,129.165512 132.239914,128.082306 
        	C132.165771,109.056313 132.091629,90.030319 132.153717,70.498680 
        z"/>
      <path fill={fillColor} opacity="1.000000" stroke="none" 
    	  d="M3.639895,128.906067 
        	C3.545014,109.936012 3.450134,90.965958 3.711172,71.451859 
        	C4.171737,70.794014 4.379241,70.595848 4.365516,70.579117 
        	C4.177118,70.349380 3.958214,70.144676 3.746805,69.933815 
        	C4.695485,67.545898 5.050427,64.603004 6.684039,62.855305 
        	C24.406374,43.895302 42.244652,25.041210 60.254623,6.354182 
        	C65.011353,1.418630 70.878273,1.518151 75.709602,6.541977 
        	C93.699799,25.248949 111.525169,44.115398 129.274933,63.050854 
        	C130.753571,64.628273 131.203613,67.169861 131.889420,69.734245 
        	C131.770676,70.471443 131.894073,70.737885 132.017487,71.004326 
        	C132.091629,90.030319 132.165771,109.056313 131.961365,128.621796 
        	C131.606781,129.257156 131.447388,129.408432 131.468277,129.439850 
        	C131.598404,129.635559 131.768860,129.804428 131.925949,129.982193 
        	C131.748672,136.982941 127.956902,141.556305 121.216576,141.813934 
        	C112.246719,142.156799 103.245232,142.155136 94.274300,141.831406 
        	C87.322998,141.580566 83.183418,136.728226 83.066772,129.122330 
        	C82.906075,118.643105 83.045296,108.159538 83.015228,97.677956 
        	C82.991615,89.447601 80.525101,87.017700 72.292587,87.000053 
        	C68.964821,86.992920 65.636719,86.969414 62.309319,87.005028 
        	C55.597454,87.076859 52.872265,89.681755 52.819660,96.310860 
        	C52.744404,105.793404 52.800423,115.276985 52.798820,124.760124 
        	C52.796478,138.615997 49.468605,141.998322 35.837883,141.998184 
        	C29.182341,141.998108 22.523701,142.121902 15.872082,141.959488 
        	C8.504427,141.779572 4.818793,138.313522 3.893590,130.338135 
        	C3.998197,129.518341 3.819046,129.212204 3.639895,128.906067 
        z"/>
      <path fill={fillColor} opacity="1.000000" stroke="none" 
      	d="M3.601069,70.216721 
        	C3.958214,70.144676 4.177118,70.349380 4.365516,70.579117 
        	C4.379241,70.595848 4.171737,70.794014 3.853200,71.008362 
        	C3.577984,70.905807 3.516658,70.702713 3.601069,70.216721 
        z"/>
      <path fill={fillColor} opacity="1.000000" stroke="none" 
      	d="M132.085602,70.751503 
        	C131.894073,70.737885 131.770676,70.471443 131.808441,70.106712 
        	C132.030975,70.171844 132.092346,70.335266 132.085602,70.751503 
        z"/>
      <path fill={fillColor} opacity="1.000000" stroke="none" 
      	d="M3.551803,129.210724 
        	C3.819046,129.212204 3.998197,129.518341 3.980583,129.943726 
        	C3.677116,129.880432 3.570413,129.697906 3.551803,129.210724 
        z"/>
      <path fill={fillColor} opacity="1.000000" stroke="none" 
      	d="M132.035980,129.743530 
        	C131.768860,129.804428 131.598404,129.635559 131.468277,129.439850 
        	C131.447388,129.408432 131.606781,129.257156 131.848984,129.078552 
        	C132.058762,129.165512 132.102371,129.335190 132.035980,129.743530 
        z"/>
    </svg>
	);
}
		
export default NewHomeIcon;
