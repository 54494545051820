import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { isStorageSupported, getStoredValue, storeValue, emailSubscribedKey, emailSubscribeFormShownKey } from '../util/storage-utils';
import { ModalType } from './ModalProvider';
import DisplayModal from '../common/DisplayModal';
import Header, { HeaderSize } from '../styleguide/Header';
import NotifyMeForm from '../address/NotifyMeForm';
import CheckMark from '../common/CheckMark';
import PropTypes from 'prop-types';

import styles from './NoDeliveryModal.module.css';

// Show the No Delivery modal only once per address
export const shouldShowNoDeliveryModal = () => {
  if (isStorageSupported()) {
    return !getStoredValue(emailSubscribedKey);
  }    
  return false;
}

const NoDeliveryModal = ({ displayModal, modalProps }) => {
  
  const { user, currentLocation } = React.useContext(UserContext);
  const { getNoDeliveryLocation } = React.useContext(DispensaryContext);
  const [showSubscribe, setShowSubscribe] = React.useState();
  const [noServiceLocation, setNoServiceLocation] = React.useState('');
  const { trackContext, continueModal } = modalProps;

  const closeModal = () => {
    storeValue(emailSubscribeFormShownKey, true);
    displayModal(ModalType.NONE);
    if (ModalType.PRODUCT === continueModal) {
      displayModal(ModalType.PRODUCT, modalProps);
    }
  };
  
  React.useEffect(() => {
    /* wait for session */
    if (user) {
      if (user.isAnonymous && shouldShowNoDeliveryModal()) {
        setShowSubscribe(true);
      } else {
        setShowSubscribe(false);
      }
      setNoServiceLocation(getNoDeliveryLocation(currentLocation));
    } 
  }, [user, currentLocation, showSubscribe, getNoDeliveryLocation]);  

  return (
    <DisplayModal title="Not in Your Area Yet"
                  trackContext={trackContext}
                  isShrinkToFit 
                  closeFn={closeModal}>
      
      <div className={styles.noDeliveryModal}>
        <Header isCentered size={HeaderSize.Medium_Static} withStyle={{marginBottom:'.5rem'}}>
          Not in Your Area Yet
        </Header>

        { showSubscribe
          ? <>
              <div className={styles.emailHeader}>
                Drop us your email and we'll let you know
                when Zyp Run is available in&nbsp;{noServiceLocation}.
              </div>
              <NotifyMeForm noDeliveryLocation={noServiceLocation} />
            </>
          : <div className={styles.subscribedHeader}>
              <div className={styles.subscribedIcon}>
                <CheckMark /> 
              </div>
              <div className={styles.subscribedMsg}>
                You previously signed up for email notifications (from this device). We'll
                let you know when delivery is available in&nbsp;{noServiceLocation}.
              </div>
            </div>
        }
        
        <div className={styles.continueMsg}>
          <span className={styles.browseLink}
                onClick={closeModal}>
            Continue Browsing
          </span>
        </div>  
      </div>
    </DisplayModal>
  );
};

NoDeliveryModal.propTypes = {
  displayModal: PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired 
}

export default NoDeliveryModal;
