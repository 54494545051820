import * as React from 'react';

import Header, { HeaderSize } from '../../styleguide/Header';
import { useAnalyticsStore } from '../../../App';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';

const useStyles = makeStyles({
  card: {
    cursor: 'pointer',
    color: 'var(--zrPrimaryDark)',
    backgroundColor: '#a9d6fcaa',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    minHeight: '100%',
    borderRadius: 8,
    font: 'var(--zrFontSemi)',
    fontSize: '1.25rem',
    textAlign: 'center',
    padding: '0 8px',
  },
  hdr: {
    flex: 1,
    font: 'var(--zrFontBoldItalic);',
    lineHeight: '133%',
    color: 'var(--zrPrimaryVeryDark)',
    textShadow: '-1px 1px 1px #fff',
    margin: '1rem 0 0 !important',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
  },
  beforeText: {
    fontSize: '18px',
  },
  labelPill: {
    color: '#000',
    backgroundColor: '#fff', 
    borderRadius: 8,
    padding: '12px 4px',
  },
  labelWrap: {
    flex: 1,
  },  
  button: {
    borderRadius: 8,
    margin: '0 0 1rem',
    padding: '4px 0px',
    display: 'flex',
    minWidth: '100%',
    alignItems: 'center',
    backgroundColor: 'var(--zrPrimaryDark)',
    color: '#fff',
    fontSize: '1rem',
  },
  buttonText: {
    flex: 1,
    marginRight: 0,
    fontSize: '18px',
    lineHeight: '130%',
  },
  iconWrap: {
    overflow: 'hidden',
    width: 20,
    paddingTop: 6,
    textAlign: 'center',
  }
})

const MoreButton = ({
  btnText
}) => {
  const classes = useStyles();
  return (
    <div className={classes.button}>
      <div className={classes.buttonText}>
        <div>{btnText}</div>
      </div>
      <div className={classes.iconWrap}> 
        <ArrowForwardIosRoundedIcon style={{fontSize:22, marginLeft:-12}} />
      </div>
    </div>
  );
}

const SeeMoreCard = ({
  moreLabel,
  moreCount,
  totalCount,
  onClick,
}) => {

  const classes = useStyles();
  const { trackEvent } = useAnalyticsStore();

  const handleClick = () => {
    trackEvent('see_more_card_click');
    onClick();  
  }

  return (
    <div className={classes.card} onClick={handleClick}>
      <Header size={HeaderSize.Medium_Static} withClass={classes.hdr}>
        <div className={classes.beforeText}>We've got</div>
        <div>{moreCount} MORE!</div>
      </Header>
      <div className={classes.labelWrap}>
        <div className={classes.labelPill}>{moreLabel}</div>
      </div>
      <MoreButton
        btnText={`Shop All ${totalCount}`} />
    </div>
  )
}

SeeMoreCard.propTypes = {
  moreLabel: PropTypes.string.isRequired,
  moreCount: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default SeeMoreCard;
