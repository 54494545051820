import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import { FilteredProductsContext, SHOW_ALL} from '../products/FilteredProductsProvider';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import styles from './ProductInfoFilter.module.css';

// This is for price+weight display
export const WEIGHT_ENABLED_CATEGORIES = ['Concentrates','Flower', 'Vaporizers'];
// Filtered is disabled
const DISABLED_CATEGORIES = [
  'Accessories',
  'Edibles',
  'Munchies',
  'Topicals',
  'Tinctures',
];

const GRAMS_TO_DISPLAY_OUNCES = new Map([
  ['3.5', '1/8 oz'],
  ['7', '1/4 oz'],
  ['14', '1/2 oz'],
  ['28', '1 oz'] 
]);

/**
 * This component will provide sub-category WEIGHT filters for both Desktop and Mobile layouts
 */ 
const WeightFilter = ({
  startOpen=false,
  inModal=false
}) => {
  
  const { trackEvent } = useAnalyticsStore();

  // Current filters context
  const { categoryName, grams:selectedWeight, subCategoryWeights, setFilters } = React.useContext(FilteredProductsContext);

  // Add the 'All Weights' option 
  const subCategoryWeightsWithAll = [SHOW_ALL, ...subCategoryWeights];
  
  // Toggle the display
  const [showFilter, setShowFilter] = React.useState(startOpen);

  const toggleShowFilter = () => {
    if (labelRef.current) {
      labelRef.current.setAttribute('data-show-content', !showFilter);
    } 
    setShowFilter(!showFilter);
  };

  const filterClick = (grams) => {
    trackEvent(`filtr_weight_${grams}g_clk`);
    setFilters({grams});
  };

  // 3.5 => 1/8 oz
  const formatLabel = (grams) => {
    if (GRAMS_TO_DISPLAY_OUNCES.has(grams.toString())) {
      return GRAMS_TO_DISPLAY_OUNCES.get(grams.toString());
    } else {
      return `${grams}g`;
    }  
  }  

  const labelRef = React.useRef();
  const addlClass = inModal ? styles.inModal : '';
  return (subCategoryWeightsWithAll.length > 1 && !DISABLED_CATEGORIES.includes(categoryName)
    ? <div className={`${styles.productInfoFilter} ${addlClass}`}> 
        <div ref={labelRef} data-show-content={showFilter} className={styles.sectionHdr} onClick={toggleShowFilter}>
          WEIGHTS <ChevronRightRoundedIcon style={{verticalAlign:'-7px'}}/>
        </div>
        { showFilter &&
          <ul className={styles.filterLinks}>
            { subCategoryWeightsWithAll.map(grams => {
              const selectedClass = grams === selectedWeight
                      ? styles.selected 
                      : '';
              const label = grams === SHOW_ALL
                          ? `All Weights`
                          : formatLabel(grams);
              return <li key={grams} 
                         className={`${styles.filterLink} ${selectedClass}`} 
                         onClick={() => {filterClick(grams)}}>
                       {label}
                       <span className={styles.selectedIcon}>
                         <CheckCircleRoundedIcon style={{color: '#054052', verticalAlign:'-6px'}}/>
                       </span>
                     </li>
            })}
          </ul>
        }  
      </div>
    : null 
  );
}

export default WeightFilter;
