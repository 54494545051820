import * as React from "react";

import { auth } from "../../firebase";
import { activeController, devLog } from "../util/util";
import { fetchUserInfo } from '../registration/registration-utils';

/**
 * Because the UserProvider is not reliable for customer deets
 */
const useCustomerDetails = (user) => {

  const [zypRunUser, setZypRunUser] = React.useState();
  // 404 on fetch etc.
  const [fetchError, setFetchError] = React.useState();
  const controllerRef = React.useRef(new AbortController());

  const userInfoCallback = React.useCallback((response) => {
    devLog('useCustomerDetails callback');
    if (response.error) {
      devLog('error fetching registered user data');
      setFetchError(
        `(${response.status}):${response.error},${auth.currentUser?.email}`
      );
      setZypRunUser(undefined);
    } else {
      devLog('Setting registered user info...');
      setFetchError(null);
      setZypRunUser(response);
    } 
  }, []);
  
  // Fetch current customer data
  React.useEffect(() => {
    const controller = activeController(controllerRef);
    if (user && !user.isAnonymous) {
      devLog('Fetching registered user info');
      fetchUserInfo(userInfoCallback, controller);
    } else {
      setZypRunUser(undefined);
    }
    // remove on unmount
    return () => controller.abort();
  }, [user, userInfoCallback ]);

  return {
    zypRunUser,
    emailOptIn: !!zypRunUser?.marketing_communications?.email_opted_in,
    textOptIn: !!zypRunUser?.marketing_communications?.sms_text_opted_in,
    error: fetchError
  }
}

export default useCustomerDetails;
