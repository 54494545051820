import * as React from 'react';

import Header, {HeaderSize} from '../styleguide/Header';
import styles from './YourOrders.module.css';

const OrderStatusHelp = () => {
  return (
    <>
      <Header size={HeaderSize.Large} isCentered text="Order Statuses" />
      <p>Here's what each of our statuses mean:</p>
      <dl className={styles.statusHelp}>
        <dt>Requested</dt> 
        <dd>Order submitted (order can be canceled prior to acceptance).</dd>
        <dt>Accepted</dt>
        <dd>Your order is in process.</dd> 
        <dt>Ready for Delivery</dt>
        <dd>Awaiting driver pickup.</dd> 
        <dt>In-transit / En Route</dt>
        <dd>Out for delivery.</dd> 
        <dt>Completed</dt>
        <dd>Your order was delivered.</dd> 
        <dt className={styles.cancelTerm}>Canceled</dt>
        <dd>Your ordered was canceled.</dd>
      </dl>
    </>
  )
}

export default OrderStatusHelp;
