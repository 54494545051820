import * as React from 'react';

import { dollarDisplay } from '../providers/OrderPricingProvider';
import { makeStyles } from '@material-ui/core';
import Spinner from '../common/Spinner';

const useStyles = makeStyles({
  credits: {
    margin: '.5rem 0 0',
    fontSize: '.875rem',
    border: '1px solid #ccc',
  },
  hdr: {
    display: 'flex',
    font: 'var(--zrFontSemi)',
    fontSize: '.875rem',
    backgroundColor: 'var(--zrPrimaryBackground)',
  },
  body: {
    maxHeight: '160px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  row: {
    display: 'flex',
    borderBottom: '1px solid #e6e7e8'
  },
  col: {
    fontSize: '.875rem',
    textAlign: 'left',
    padding: '4px 6px',
  },
  type: {
    flex: '0 0 25%',
    whiteSpace: 'nowrap',
  },
  user: {
    flex: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  amount: {
    flex: '0 0 15%',
    textAlign: 'center',
  },
});

// We display the related user name fo credit
export const CREDIT_LABELS = {
  referral: 'Referrer:',
  referrer: 'Referral:'
}

const CreditDetails = ({credit, classes}) => {
  return (
    <div className={classes.row}>
      <div className={`${classes.col} ${classes.type}`}>
        {CREDIT_LABELS[credit.reason]}
      </div>
      <div className={`${classes.col} ${classes.user}`}>
        { credit.reason === 'referrer'
          ? credit.referral_name /* you received from */
          : credit.referrer_name /* you referred and got from */
        }
      </div>
      <div className={`${classes.col} ${classes.amount}`}>
        {dollarDisplay(credit.value.usa_cents, true)}
      </div>
    </div>
  )
};

const DisplayCreditDetails = ({activeCredits, loading}) => {
  const classes = useStyles();
  return ( loading
    ? <Spinner inSmallLayout size={48} />
    : activeCredits?.length
      ? <div className={classes.credits}>
          <div className={classes.hdr}>
            <span className={`${classes.col} ${classes.type}`}>TYPE</span>
            <span className={`${classes.col} ${classes.user}`}>USER</span>
            <span className={`${classes.col} ${classes.amount}`}>CREDIT</span> 
          </div>
          <div className={classes.body}> 
            { activeCredits.map(credit =>
              <CreditDetails credit={credit} classes={classes} />
            )}
          </div>
        </div>            
      : null
  );
};

export default DisplayCreditDetails;
