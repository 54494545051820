import * as React from 'react';

const useSoldOutStatus = (
  quantity_available,
  display_sold_out_status) => {

  const [isSoldOut, setIsSoldOut] = React.useState();
  React.useEffect(() => {
    setIsSoldOut(display_sold_out_status && quantity_available === 1);
  }, [quantity_available, display_sold_out_status,]);

  return isSoldOut;
}

export default useSoldOutStatus;
