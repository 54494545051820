import * as React from 'react';

import { GRAMS_TO_DISPLAY_OUNCES, WEIGHT_ENABLED_CATEGORIES } from './ProductWeightPill';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '.25rem',
    margin: '.75rem 0',
    font: 'var(--zrFontSemi)',
    lineHeight: '100%',
    fontStyle: 'italic',
    color: '#000'
  }
});

const SubCategoryAndWeight = ({grams, category, subCategory}) => {

  const classes = useStyles();
  const [displayWeight, setDisplayWeight] = React.useState();
  const [displaySubCategory, setDisplaySubcategory] = React.useState();

  React.useEffect(() => {
    if (grams && typeof displayWeight === 'undefined') {
      if (GRAMS_TO_DISPLAY_OUNCES.has(grams.toString())) {
        setDisplayWeight(GRAMS_TO_DISPLAY_OUNCES.get(grams.toString()));
      } else {
        setDisplayWeight(`${grams}g`);
      }  
    }
    if (!displaySubCategory) {
      setDisplaySubcategory(subCategory?.display_name || category?.display_name);
    }  
  }, [category, subCategory, grams, displayWeight, displaySubCategory]); 
  
  return ( WEIGHT_ENABLED_CATEGORIES.includes(category?.name) && 
           displaySubCategory && 
           displayWeight
            ? <div className={classes.wrapper}>
                <span>{ displaySubCategory }</span>
                <span>-</span>
                <span>{displayWeight}</span>
              </div>
            : null
  )
}

SubCategoryAndWeight.propTypes = {
  grams: PropTypes.number, 
  category: PropTypes.object,
  subCategory: PropTypes.object
}

export default  SubCategoryAndWeight;
