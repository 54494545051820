import * as React from "react";

import { getFeaturedProducts } from './featuredBrands';
import { ProductPreviewContext } from '../products/ProductPreviewProvider';

/**
 * Custom hook used to provide brand products by subCategory (for carousel display)
 */
const useBrandProductsBySubCategory = (categoryName) => {

  const { productsByCategory } = React.useContext(ProductPreviewContext);
  const [brandSubCatProducts, setBrandSubCatProducts] = React.useState();

  const createSubCategoryMap = React.useCallback((products) => {
    return getFeaturedProducts(categoryName, products);
  }, [categoryName]);
  
  React.useEffect(() => {
    if (productsByCategory && 
        productsByCategory.has(categoryName)) {
      setBrandSubCatProducts(new Map(createSubCategoryMap(productsByCategory.get(categoryName))));
    }
  }, [categoryName, productsByCategory, createSubCategoryMap]);

  return { 
    productsBySubCategory: brandSubCatProducts
  };
};

export default useBrandProductsBySubCategory;
