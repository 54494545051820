import * as React from 'react';

import { DeliveryOptionsContext } from '../providers/DeliveryOptionsProvider';

const PaymentItem = ({label, icon}) => {
  return (
    <div key={label} style={{marginBottom:6}}>
      {icon} {label}
    </div>
  )
}

const PaymentMethods = () => {

  const { paymentMethods } = React.useContext(DeliveryOptionsContext);

  const { 
    aeropay: enableAeropay=true,
    on_site_cash: enableCash=true,
    on_site_debit:enableDebit=true, 
  } = paymentMethods;

  const imageStyle = {
    height: '18px',
    verticalAlign: '-3px'
  };

  const iconStyle = {
    height: '24px',
    verticalAlign: '-6px'
  };

  /* We want the payment methods in a specific order */
  return (
    paymentMethods
      ? <div>
          { enableCash &&
            <PaymentItem key="Cash" label="Cash"
              icon={<img alt="" src="/img/localAtmIcon.png" style={iconStyle} />} />
          }
          { enableDebit &&
            <PaymentItem key="Debit" label="Debit&nbsp;Card"
              icon={<img alt="" src="/img/creditCardIcon.png" style={iconStyle} />} />  
          }
          { enableAeropay &&
            <PaymentItem key="Aeropay" label="Aeropay&nbsp;(PrePay)"
              icon={<img alt="" src="/img/AeropayIconBlack.jpg" style={imageStyle} />} />
          }
        </div>
      : null 
  );
}

export default PaymentMethods;
