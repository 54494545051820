import * as React from 'react';

import { getOrderDate, daysOfWeekAbbrev } from './timeslot-utils';
import PropTypes from 'prop-types';

import styles from '../deliveryWindows/SelectDayCarousel.module.css';

/**
 * This day selection carousel will re-render when viewing a different day
 */
const SelectDayCarousel = ({
  displayDayIdx,
  displayDayLabelIdx,
  timeSlotsByDay=[],
  currentTimeMS,
  handleDayClick  // update day for view
}) => {

  const themeStyleSelected = {
    color: '#fff',
    backgroundColor: 'var(--zrPrimaryDark)'
  };

  const getDayLabel = (offset) => {
    if (offset === 0) {
      return 'TODAY';
    }
    const weekdayNum = (displayDayLabelIdx + offset) % 7;
    return daysOfWeekAbbrev[weekdayNum];
  };

  return ( currentTimeMS && timeSlotsByDay.length 
    ? <ol className={styles.selectDay}>
        { timeSlotsByDay.map((day, idx) => {
          const selectedStyle = idx === displayDayIdx ? themeStyleSelected : {};
          return  <li className={styles.dayItem}
                      style={selectedStyle}
                      key={`day_${idx}`}
                      onClick={() => handleDayClick(idx)}>
                    {getDayLabel(idx)}
                    <span>{getOrderDate(idx, currentTimeMS)}</span>
                  </li>
        })}   
      </ol>
    : null
  )
}

SelectDayCarousel.propTypes = {
  displayDayIdx: PropTypes.number.isRequired,
  displayDayLabelIdx: PropTypes.number.isRequired,
  timeSlotsByDay: PropTypes.array.isRequired,
  currentTimeMS: PropTypes.number.isRequired, 
  handleDayClick: PropTypes.func.isRequired
};

export default SelectDayCarousel;
