import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { dollarDisplay } from '../providers/OrderPricingProvider';
import { NUM_FREE_DELIVERIES } from '../orders/useCustomerOrders';
import PropTypes from 'prop-types';
import styles from './OrderButton.module.css';

/**
 * Display delivery fee, accounting for priority delivery
 */
const DisplayDeliveryFee = ({
  activeOrderCount,
  deliveryFeeApplied,
  deliveryFeeDisplay,
  selectedDeliverySlot,
}) => {

  // First 2 orders are free, but we need to account for active orders
  const { location, completedOrderCount } = React.useContext(UserContext);
  const { freeDeliveryZipCodes=[] } = React.useContext(DispensaryContext);

  return ( selectedDeliverySlot?.on_demand
    ? <>
        <span className={styles.label}>Priority Delivery:</span>
        <span className={styles.amount}>
          {dollarDisplay(selectedDeliverySlot.on_demand.fee_cents)}
        </span>
      </>
    : <>
        <span className={styles.label}>Delivery:</span>
        <span className={styles.amount}>
          { deliveryFeeApplied === 0
            ? <>
                <span className={styles.priceComment}>
                  { completedOrderCount + activeOrderCount < NUM_FREE_DELIVERIES
                    ? `First ${NUM_FREE_DELIVERIES} orders: Free!`
                    : 'Free!'
                  }
                </span>
                { freeDeliveryZipCodes.includes(location?.zip_code)
                  ? null
                  : <span className={styles.noCharge}>{deliveryFeeDisplay}</span>
                }
              </>
            : <span>{deliveryFeeDisplay}</span>
          }
        </span>
      </>
  );
}

DisplayDeliveryFee.propTypes = {
  activeOrderCount: PropTypes.number.isRequired,
  deliveryFeeApplied: PropTypes.number.isRequired,
  deliveryFeeDisplay: PropTypes.string.isRequired,
  selectedDeliverySlot: PropTypes.object,
}

export default DisplayDeliveryFee;
