import * as React from "react";

import { AeropayContext } from "../payment/AeropayProvider";
import useAerosyncReducer from "./useAerosyncReducer";
import LinkAccountsWithAerosync from "../payment/LinkAccountsWithAerosync";
import AerosyncFlowMessaging from "../payment/AerosyncFlowMessaging";
import ErrorBoundary from '../common/ErrorBoundary';
import BasicButton from '../styleguide/BasicButton';
import aeroPayLogo from '../../assets/aeroPayLogo.png';
import { makeStyles } from "@material-ui/core";

import styles from './YourAccount.module.css';

/* Checkout link styles */
const useStyles = makeStyles({
  apLink: {
    cursor: 'pointer',
    fontWeight: 400,
    textDecoration: 'underline',
  },
  apLinkAfter: {
    color: '#555',
    fontWeight: 600,
    fontStyle: 'italic',
    marginLeft: 5,
  },
  strong: {
    fontWeight: 600,
  }
})

// Account page
const LaunchWidgetButton = ({bankName, onClick}) => {
  const lblText = bankName
    ? (<>Aeropay is currently linked to your account at <b>{bankName}</b></>)
    : "Delivery is even better with Aeropay payment!";
  const btnText = bankName
    ? "Update Linked Bank Account"
    : "Link Bank Account";
  return (
    <>
      <div>{lblText}</div>
      <BasicButton withStyle={{marginTop:16}} text={btnText} handleClick={onClick} />
    </>
  )
}

// e.g. Checkout page
const LaunchWidgetLink = ({bankName, onClick}) => {
  const classes = useStyles();
  const lblText = bankName
    ? (<>Update linked account at <b>{bankName}</b></>)
    : (<span className={classes.strong}>
        Link your checking account now to use Aeropay
       </span>);
  return (
    <div>
      <span className={classes.apLink} onClick={onClick}>
        {lblText}
      </span>
      { !bankName &&
        <span className={classes.apLinkAfter}>
          It's&nbsp;easy!
        </span>
      }
    </div>
  )
}

/**
 * Set/Update Aeropay account linkage from Account page
 */
const PaymentFormAerosync = ({isLinkOnly}) => {

  // Current bank if linked
  const { bankName, refetch } = React.useContext(AeropayContext);
  const [linkingFlow, dispatch] = useAerosyncReducer();
  const [launchWidget, setLaunchWidget] = React.useState();

  const handleWidgetLoad = React.useCallback(() => {
    dispatch({message:'', type:'widgetLoadComplete'});
  }, [dispatch]);

  // Widget must be unmounted prior to relaunching
  const handleWidgetClose = React.useCallback(() => {
    setLaunchWidget(false);
  }, []);

  const handleWidgetSuccess = React.useCallback((response) => {
    const message = `Widget Success: ${JSON.stringify(response, null, 4)}`
    dispatch({ message, type:'widgetSuccess'});
    // DO NOT unmount widget while postback is in progress
    // setLaunchWidget(false);
  }, [dispatch]);

  const handleWidgetError = React.useCallback((response) => {
    const message = `Widget Error: ${JSON.stringify(response, null, 4)}`
    dispatch({ message, type:'widgetError'});
    setLaunchWidget(false);
  }, [dispatch]);

  const handlePostbackSuccess = React.useCallback((response) => {
    const { message, bankName } = response;
    dispatch({ message, bankName, type:'postbackSuccess'});
    setLaunchWidget(false);
    // Update Aeropay Provider for accurate bank display 
    refetch(new AbortController());
  }, [dispatch, refetch]);

  const handlePostbackError = React.useCallback((response) => {
    const { message } = response;
    dispatch({ message, type:'postbackError'});
    setLaunchWidget(false);
  }, [dispatch]);

  const showWidget = React.useCallback(() => {
    dispatch({message:'', type:'widgetLoading'});
    setLaunchWidget(true);
  }, [dispatch]);

  return (
    <ErrorBoundary>
      { isLinkOnly
        ? <LaunchWidgetLink
            bankName={linkingFlow.updatedBankName || bankName}
            onClick={showWidget} />
        : <>
            <div className={styles.withBtmMargin}>
              <img alt="Aeropay" height="24" src={aeroPayLogo} />
            </div>
            <LaunchWidgetButton
              bankName={linkingFlow.updatedBankName || bankName}
              onClick={showWidget} />
          </>
      }

      { launchWidget &&
        <LinkAccountsWithAerosync
          launchWidget={launchWidget}
          onWidgetLoad={handleWidgetLoad}
          onWidgetClose={handleWidgetClose}
          onWidgetSuccess={handleWidgetSuccess}
          onWidgetError={handleWidgetError}
          onPostbackSuccess={handlePostbackSuccess}
          onPostbackError={handlePostbackError} />
      }
      {/* success/failure messages */}
      <AerosyncFlowMessaging flowState={linkingFlow} />
    </ErrorBoundary>
  );
}

export default PaymentFormAerosync;
