
/**
 * This is the temporary check until the endpoint is operational
 * The endpoint always returns true currently
 * Chrome auto-generate doesn't use special characters so we're going with Upper+Lower+Number, 8 char minimum
 *
 * @param string password
 */
export const isValidPassword = (password) => {
  return password &&
         password.length > 7 &&     // 8 char minimum
         /\d+/.test(password) &&    // one digit
         /[A-Z]/.test(password) &&  // one uppercase
         /[a-z]/.test(password)     // one lowercase
};

export const isValidEmail = (email) => {
  return /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(email);
};

const VALID_STATE_ABBREVS = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'];

/**
 * Validate a single form input.
 *
 * @param string inputName
 */
export const validateSignUpEntry = (inputName, inputValue) => {
  switch (inputName) {
    case 'name':
      // currently 'full name' so at least 'a bc'
      return /\w+\s+([A-Za-z]{2})/.test(inputValue);
    case 'email':
      // test for *@*.*
      return /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(inputValue);
    case 'password':
      return isValidPassword(inputValue);
    case 'street_address':
      // number + wordchar + space + wordchar: 1b Main
      return /\d+\w*\s+\w*/.test(inputValue);
    case 'city':
      // at least two characters
      return /([A-Za-z]{2})/.test(inputValue);
    case 'state':
      // Pickup could be from any state
      return VALID_STATE_ABBREVS.includes(inputValue.toUpperCase());
    case 'zip_code':
      return inputValue.length === 5;
    case 'month':
      return parseInt(inputValue,10) < 13;
    case 'day':
      return parseInt(inputValue,10) < 32;
    case 'year':
      return /^\d{4}$/.test(inputValue);
    case 'photoIdPrimary':
        // required hosted Id image when not using Berbix
        return inputValue && inputValue.url?.indexOf('firebasestorage') > -1;
    case 'acceptTerms':
        // must be true - inline reg flow
        return !!inputValue;
    case 'accept_terms':
      // must be true
      return !!inputValue;
    default:
      return false;
  }
};
