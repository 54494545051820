import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import { trackEvent } from '../analytics/tracking';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { DeliveryOptionsContext } from '../providers/DeliveryOptionsProvider';
import { ModalContext } from '../modal/ModalProvider';
import Alert from '@material-ui/lab/Alert';
import BagIcon from '../checkout/BagIcon';
import Spinner from '../common/Spinner';
import ArrowCircleRightRoundedIcon from '@material-ui/icons/ArrowDropDownCircleRounded';
import styles from '../common/Alert.module.css';

const CartReminderProducts = () => {

  const { availableSavedItems, unavailableSavedItems, itemTotal } = React.useContext(CartItemsContext);
  const { minOrderDefaultCents } = React.useContext(DeliveryOptionsContext);
  const { setTriggerCartOpen } = React.useContext(ModalContext);
  
  const cartClick = React.useCallback(() => {
    trackEvent('verify_cart_cart_click');
    setTriggerCartOpen(true)
  }, [setTriggerCartOpen])

  const checkoutClick = () => {
    trackEvent('verify_cart_checkout_click');
    navigateTop('/checkOut/', { state: { 
      returnToPath: `${window.location.pathname+window.location.search}`,
      continuePath: '/checkOut'
    }})
  }

  const [ shouldDisplay, setShouldDisplay ] = React.useState(); 

  React.useEffect(() => {
    if (availableSavedItems?.length || unavailableSavedItems?.length) {     
      setShouldDisplay(true);
      trackEvent('verify_cart_items_displayed');
      if (availableSavedItems?.length) {
        trackEvent('verify_cart_available_items_displayed');
      }
      if (unavailableSavedItems?.length) {
        trackEvent('verify_cart_unavailable_items_displayed');
      }
    } else {
      setShouldDisplay(false);
      if (availableSavedItems?.length === 0 && 
          unavailableSavedItems?.length === 0) {
        trackEvent('verify_cart_items_not_found');
      }
    } 
  }, [availableSavedItems, unavailableSavedItems])

  return ( 
    <>
      <div className={styles.cartListHdr}>Your bag ({availableSavedItems?.length || 0} products):</div>
      { shouldDisplay
        ? <>
            <ul className={styles.cartList}>
              { availableSavedItems?.length
                ? availableSavedItems.map(item => (
                    <li key={`${item.id}-avail`} className={styles.itemAvailable}>
                      <span className={styles.cartItem}>{item.display_info.name}</span>
                      <span className={styles.itemStatus}>Bagged</span>          
                    </li>
                  ))
                : null
              }
              { unavailableSavedItems?.length
                ? unavailableSavedItems.map(item => (
                    <li key={`${item.id}-unavail`} className={styles.itemUnavailable}>
                      <span className={styles.cartItem}>{item.display_info.name}</span>
                      <span className={styles.itemStatus}>Sold Out!</span>          
                    </li>
                  ))
                : null
              }
            </ul>
            <div className={styles.cartLinks}>
              <div className={styles.cartLink} onClick={cartClick}>
                VIEW BAG&thinsp;<BagIcon size={24} />
              </div>
              { itemTotal >= minOrderDefaultCents
                ? <div className={styles.cartLink} onClick={checkoutClick}>
                    CHECKOUT&thinsp;<ArrowCircleRightRoundedIcon 
                      style={{fontSize:'24px', transform:'rotate(-90deg'}}/>
                  </div>
                : null  
              }
            </div>
          </>  
        : !availableSavedItems && !unavailableSavedItems // fetching
          ? <Spinner inSmallLayout size={32} />        
          : <div className={styles.cartAlertWrap}>
            <Alert severity='warning'>
              <div>
                <b>Sorry!</b> We can't find any "left in bag" products for your account.
              </div>
              <div>
                This can happen if you've already placed your order (thanks!) or
                have recently removed the product(s) from your bag.
              </div>
            </Alert>
          </div>
      }
    </>   
  )        
}  

export default CartReminderProducts;
