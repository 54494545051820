import { useCallback, useEffect } from 'react';

const useKeyDown = (callback, keys) => {
  
  const onKeyDown = useCallback((event) => {
    const wasTargetKeyPressed = keys.some((key) => event.key === key);
    if (wasTargetKeyPressed) {
      event.preventDefault();
      callback();
    }
  }, [callback, keys]);
  
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);
};

export default useKeyDown;

/**
* Sample usage:
*   useKeyDown(() => {
*     someCallback();
*   }, ["Enter","Escape"]);
*/
