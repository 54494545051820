import React from 'react';

import { useTheme, useMediaQuery } from '@material-ui/core';

// e.g. Below 600px width
const MobileOnlyDisplay  = ({children}) => {

  const theme = useTheme();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down('sm'));

  return ( isMobileWidth
    ? <>{children}</>
    : null   
  ) 
}

export default MobileOnlyDisplay;
