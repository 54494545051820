import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { ModalContext, ModalType } from '../modal/ModalProvider';

import Button from '../styleguide/Button';

/**
 * If the user is seeing the "Sold Out" message and their product data is old,
 * prompt them to refresh the app (if their cart is empty)
 */
const RefreshAppPrompt = () => {

  // If the session start is > 1 hour ago, prompt an app refresh 
  const REFRESH_THRESHOLD_MINUTES = 60;

  const { sessionStartMS, trackEvent } = useAnalyticsStore();
  const { isEmptyCart } = React.useContext(CartItemsContext);
  const { displayModal } = React.useContext(ModalContext);

  const [staleMinutes, setStaleMinutes] = React.useState();
  React.useEffect(() => {
    const staleMins = 
      Math.floor((new Date().getTime() - sessionStartMS) / 60000);
    setStaleMinutes(staleMins);
    if (isEmptyCart && staleMins > REFRESH_THRESHOLD_MINUTES) {
      trackEvent('refresh_app_prompt_view');
    }
  }, [isEmptyCart, sessionStartMS, trackEvent])

  return ( 
    <div>
      { isEmptyCart && staleMinutes > REFRESH_THRESHOLD_MINUTES
        ? <div>
            Hey, your product selection is <b>{staleMinutes} minutes old</b>, let's get you all the latest products!
            <Button isCentered text="Refresh Products" handleClick={() => window.location.reload()} />
          </div>
        : <Button isCentered text="Continue Shopping" handleClick={() => displayModal(ModalType.NONE)} />
      }
    </div>
  )
}

export default  RefreshAppPrompt;
