import React from 'react';

import config from '../../config';
import Alert from "@material-ui/lab/Alert";
import Spinner from "../common/Spinner";
import PropTypes from 'prop-types';

import styles from '../account/YourAccount.module.css';

const SpinnerWithMsg = ({message}) => (
  <div style={{padding:'1rem 0'}}>
   <Spinner isInline inSmallLayout size={30} />
   <span className={styles.spinnerMsg}>{message}</span>
  </div>
);

const AerosyncFlowMessaging = ({flowState={}}) => {
  return (
    <div>
      { config.IS_AEROSYNC_DEV && flowState.widgetSuccess &&
        <Alert className={styles.aerosyncMsg} severity="success">
          {flowState.widgetSuccess}
        </Alert>
      }
      { config.IS_AEROSYNC_DEV && flowState.widgetError &&
        <Alert className={styles.aerosyncMsg} severity="error">
          {flowState.widgetError}
        </Alert>
      }
      { flowState.widgetLoading &&
        <SpinnerWithMsg message="Loading Aeropay..." />
      }
      { flowState.postbackInProgress &&
        <SpinnerWithMsg message="Saving your account selection..." />
      }
      { flowState.postbackSuccess &&
        <Alert className={styles.aerosyncMsg} severity="success">
          {flowState.postbackSuccess}
        </Alert>
      }
      { flowState.postbackError &&
        <Alert className={styles.aerosyncMsg} severity="error">
          {flowState.postbackError}
        </Alert>
      }
    </div>
  )
}

AerosyncFlowMessaging.propTypes = {
  flowState: PropTypes.object.isRequired
}

export default AerosyncFlowMessaging;
