import React, { useState } from "react";

import { auth } from '../../firebase';
import { activeController } from "../util/util";
import { requestLoginCodeEndpoint } from "../../constants/api-endpoints";
import useDefaultPhoneVerifyMethod from "../registration/useDefaultPhoneVerifyMethod";
import { VoiceDefaultAlert } from "../registration/VerifyPhoneAndComplete";
import { Checkbox, FormControlLabel }from '@material-ui/core';
import PhoneInput from 'react-phone-number-input';
import Button from "../styleguide/Button";
import Spinner from "../common/Spinner";
import HavingTroubleCallout from "../registration/HavingTroubleCallout";
import { DEFAULT_ERROR } from "./PhoneSignIn";

import styles from '../registration/SignUpInline.module.css';

// Default to US +1
const COUNTRY_CODE = 'US';

const loginCodeRequest = (phoneNumber, voiceCall, successCb, failureCb, controller) => {
  if (auth.currentUser) {
    const method = voiceCall ? 'voice-call' : 'sms-text';
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
      fetch(`${requestLoginCodeEndpoint}?delivery_method=${method}&tel_number=${phoneNumber}`, {
        method: 'GET',
        headers: {
          'Authorization': idToken
        },
        signal: controller.signal
      }).then(response => response.json())
        .then(data => {
          if (data.recipient_tel_number) {
            successCb(data);
          } else {
            const { error=DEFAULT_ERROR } = data;
            failureCb({message:error});
          }  
        })  
        .catch(error => failureCb(error));
    });
  }
};

const RequestLoginCode = ({loginState, dispatch, onSuccess, onFailure}) => {

  const { isVoiceDefault } = useDefaultPhoneVerifyMethod();
  const [phoneNumber, setPhoneNumber] = useState(loginState.phone_number || '');
  const [voiceCall, setVoiceCall] = useState(false);
  const [codeRequestPending, setCodeRequestPending] = React.useState();

  // Set default verify method on render
  React.useEffect(() => {
    setVoiceCall(isVoiceDefault);
  }, [isVoiceDefault])

  // Focus input
  const inputRef = React.useRef();
  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  },[])

  // Request verification code
  const controllerRef = React.useRef(new AbortController());
  const requestCode = React.useCallback(() => {
    const handleSuccess = (data) => {
      setCodeRequestPending(false);
      onSuccess(data);
    }
  
    const handleFailure = (error) => {
      setCodeRequestPending(false);
      onFailure(error);
    }

    const controller = activeController(controllerRef);
    if (phoneNumber.length < 12) {
      onFailure({message:'Invalid phone number...'});
      return;
    }  
    setCodeRequestPending(true);
    dispatch({type:'clearMessages'});
    loginCodeRequest(phoneNumber, voiceCall, handleSuccess, handleFailure, controller);
  }, [phoneNumber, voiceCall, dispatch, onSuccess, onFailure]);

  return (
    <div className={`${styles.inlineRegFlow} ${styles.phoneSignInField}`}>
      <PhoneInput
        ref={inputRef}
        id="tel_number"
        initialValueFormat="national"
        defaultCountry={COUNTRY_CODE}
        placeholder="Your Phone Number"
        value={phoneNumber}
        onChange={setPhoneNumber} />    
      <Button isCentered button handleClick={requestCode} text="Send Verification Code" />
      <div style={{textAlign:'center', marginTop:6}}>
        <FormControlLabel 
          control={<Checkbox 
            color="default" 
            id="voice_call" 
            checked={voiceCall || false}
            onChange={() => setVoiceCall(!voiceCall)} />}
          label="Send code via Voice Call"
        />
      </div>
      { isVoiceDefault &&
        <VoiceDefaultAlert />
      }
      { codeRequestPending &&
        <>
          <Spinner inSmallLayout />
          <HavingTroubleCallout trackingLabel={`${voiceCall ? 'voice' : 'sms'}_signin`} />
        </>
      }
    </div>
  );
};

export default RequestLoginCode;
