import React from 'react';

import DisplayModal from '../common/DisplayModal';
import ZypRunTermsOfService from '../../docs/ZypRunTermsOfService';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  linkWrapper: {
    margin: '2rem auto',
    fontSize: '.875rem',
    fontWeight: 400,
    maxWidth: 600,
  },
  termLink: {
    font: 'var(--zrFontSemi)',
    fontSize: 'inherit',
    color: 'var(--zrPrimaryDark)',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    }
  },
  modalBody: {
    fontWeight: 400,
  },
  modalTitle: {
    textAlign: 'center',
    font: 'var(--zrFontBold)',
    fontSize: '1.375rem',
    margin: '0 0 .75rem'
  },
  regItems: {
    margin: '1.5rem 0 0 1rem',
    listStyleType: 'disc',
    maxWidth: 400,
  },
  regItem: {
    margin: '.75rem 0',
  }
}))

/**
 * Provide T&C links containing MA details (body cam) required by the CCC 
 */
const DeliveryTermsLinks = () => {

  const classes = useStyles();

  const[showTerms, setShowTerms] = React.useState();
  const[showBodyCamWarning, setshowBodyCamWarning] = React.useState();
  
  return( 
    <>
      <div className={classes.linkWrapper}>
        By clicking "<b>Place Order</b>" you agree to the
        {' '} 
        <span className={classes.termLink} onClick={() => setShowTerms(true)}>
          Zyp Run Terms &amp; Conditions,
        </span>
        {' '}
        terms mandated by state and local 
        {' '}
        <span className={classes.termLink} onClick={() => setshowBodyCamWarning(true)}> 
          Cannabis Delivery Regulations,
        </span>  
        {' '} 
        and consent to receiving text or email messages about your order.
      </div>
      { showTerms &&
        <DisplayModal scrollingInner title="Terms of Service" closeFn={() => setShowTerms(false)}>
          <ZypRunTermsOfService />
        </DisplayModal>
      }
      { showBodyCamWarning &&
        <DisplayModal isShrinkToFit title="Terms of Service" closeFn={() => setshowBodyCamWarning(false)}>
          <div className={classes.modalBody}>
            <div className={classes.modalTitle}>Cannabis Delivery Regulations</div> 
              <ul className={classes.regItems}>
                <li className={classes.regItem}>
                  Massachusetts state law requires video surveillance of the delivery event.
                </li>
                <li className={classes.regItem}>
                  Have your ID ready for verification by our driver.
                </li>  
              </ul>
          </div>
        </DisplayModal>
      }
    </>
  )
}

export default DeliveryTermsLinks;
