import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import { trackingString } from '../analytics/tracking';
import { FilteredProductsContext, SHOW_ALL} from '../products/FilteredProductsProvider';

import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import styles from './ProductInfoFilter.module.css';

/**
 * This component will provide sub-category Cannabis Type filters for both Desktop and Mobile layouts
 */ 
const TypeFilter = ({
  startOpen=false,
  inModal=false
}) => {
  
  const { trackEvent } = useAnalyticsStore();

  // Current filters context
  const { type:selectedType, subCategoryTypes, setFilters } = React.useContext(FilteredProductsContext);

  // Add the 'All {category}' option 
  const subCategoryTypesWithAll = [SHOW_ALL, ...subCategoryTypes];
  // Toggle the display
  const [showTypes, setShowTypes] = React.useState(startOpen);

  const toggleShowTypes = () => {
    if (typeRef.current) {
      typeRef.current.setAttribute('data-show-content', !showTypes);
    } 
    setShowTypes(!showTypes);
  };

  const typeClick = (type) => {
    trackEvent(`filter_type_${trackingString(type)}_click`); 
    setFilters({type});
  };
  
  const typeRef = React.useRef();
  
  const addlClass = inModal ? styles.inModal : '';

  return (subCategoryTypesWithAll.length > 1
    ? <div className={`${styles.productInfoFilter} ${addlClass}`}> 
        <div ref={typeRef} data-show-content={showTypes} className={styles.sectionHdr} onClick={toggleShowTypes}>
          TYPES <ChevronRightRoundedIcon style={{verticalAlign:'-7px'}}/>
        </div>
        { showTypes &&
          <ul className={styles.filterLinks}>
            { subCategoryTypesWithAll.map(type => {
              const selectedClass = type === selectedType
                ? styles.selected
                : '';
              const label = type === SHOW_ALL
                          ? `All Types`
                          : type;
              return <li key={`t${type}`} 
                         className={`${styles.filterLink} ${selectedClass}`} 
                         onClick={() => {typeClick(type)}}>
                       {label}
                       <span className={styles.selectedIcon}>
                         <CheckCircleRoundedIcon style={{color: '#054052', verticalAlign:'-6px'}}/>
                       </span>
                     </li>
            })}
          </ul>
        }  
      </div>
    : null 
  );
}

export default TypeFilter;
