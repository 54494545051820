import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import { SUGGESTED_PRODUCT_CAROUSEL_HEIGHT } from '../products/SuggestedProductsCarousel';
import DisplayModal from '../common/DisplayModal';
import ProductDetailsCard from '../products/ProductDetailsCard';
import AddedToCartCheck from '../products/AddedToCartCheck';
import Spinner from '../common/Spinner';
import useProductDetails from '../products/useProductDetails';
import ProductNotFound from '../products/ProductNotFound';
import PropTypes from 'prop-types';

import cardStyles from '../products/ProductDetailsCard.module.css';

const ProductDetailModal = ({ modalProps }) => {

  const [showAddedCheck, setShowAddedCheck] = React.useState();

  const { 
    itemId,
    title="", /* undefined initially */
    trackingLabel="product",
    itemCount /* re-order */, 
    cartItemOnRender /*re-order */,
    relatedProducts,
    relatedProductsCarouselTitle,
  } = modalProps;

  const { trackEvent } = useAnalyticsStore();
  const { product, status } = useProductDetails(itemId);

  /**
   * NOTE: Address prompt on cart-click complexity!!
   * 
   * We're using a ref in this add-to-cart callback to prevent
   * the cartItemOnRender functionality from
   * running more than once (when returning to modal
   * from the address prompt).
   */
  const cartedOnRenderRef = React.useRef();
  const onAddToCart = React.useCallback(() => {
    cartedOnRenderRef.current = true;
    // Show the "added" check
    setShowAddedCheck(true);
    trackEvent(`mdl_${trackingLabel}_cart_clk`);
  }, [trackEvent, trackingLabel]);
 
  const notFoundStyle = {
    width: 600,
    padding:'1rem 1rem 2rem'
  };

  return (
    <DisplayModal title={title}
                  trackContext={trackingLabel} // track display
                  isShrinkToFit
                  noWrapper>
      { product
        ? <ProductDetailsCard
            product={product}
            onAddToCart={onAddToCart}
            /* Related to address prompt */
            cartItemOnRender={ cartedOnRenderRef.current 
              ? false  // only run once!
              : cartItemOnRender
            }
            cartOnRenderQuantity={itemCount}
            relatedProducts={relatedProducts}
            relatedProductsCarouselTitle={relatedProductsCarouselTitle}
          />
        : status === 404
          ? <div className={cardStyles.card} style={notFoundStyle}>
              <ProductNotFound />
            </div>
          : // Prevent vertical collapse when navigating product -> product
            <div className={cardStyles.card} 
              style={{ paddingBottom:SUGGESTED_PRODUCT_CAROUSEL_HEIGHT }}>
              <Spinner />
            </div>
      }
      { showAddedCheck &&
        <AddedToCartCheck closeFn={() => { setShowAddedCheck(false)} } />
      }
    </DisplayModal>
  );
};

ProductDetailModal.propTypes = {
  modalProps: PropTypes.object.isRequired 
}

export default ProductDetailModal;
