import * as React from 'react';

import './SuppressNav.module.css';

/**
 * Suppress normal Top / Bottom nav ( e.g. for iframing on marketing site )
 */
const SuppressNav = () => {
  React.useEffect(() => {
    document.body.setAttribute('data-minimal-nav', true);     
    // remove on unmount
    return () => { document.body.removeAttribute('data-minimal-nav'); };  
  });  
  return null;
};

export default SuppressNav;
