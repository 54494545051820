import * as React from 'react';

import { makeStyles } from '@material-ui/core';
import BagIcon from '../checkout/BagIcon';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  button: {
    display: 'flex',
    position: 'fixed',
    zIndex: 3,
    bottom: '4.25rem',
    width: '95vw',
    boxSizing: 'border-box',
    left: '2.5vw',
    padding: '.25rem',
    borderRadius: '.25rem',
    backgroundColor: 'var(--zrPrimaryDark)',
    color: '#fff',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    '&:hover': {
      backgroundColor: 'var(--zrPrimaryVeryDark)',
    }
  },
  iconWrap: {
    flex: 0,
    margin: '1px 0 -2px'
  },
  middle: {
    textAlign: 'center'
  },
  buttonLabel: {
    font: 'var(--zrFontSemi)',
    fontSize: '10px',
  },
  buttonValue: {
    font: 'var(--zrFontBold)'
  },
  count: {
    flex: 0,
    lineHeight: '22px',
    font: 'var(--zrFontBold)',
    textAlign: 'center',
    minWidth: '24px',
    height: '24px',
    margin: '4px 3px',
    borderRadius: '50%',
    backgroundColor: '#fff3'
  }   
}));

const CART_BUTTON_PAGES = [
  'products',
  'popular',
  '' /* home */
];

const FooterCartButton = ({
  items=[],
  displayTotal
}) => {

  const classes = useStyles();
  const location = useLocation(); 
  const [showBar, setShowBar] = React.useState(); 
  
  React.useEffect(() => {
    if (items.length && location) {
     const pathItems = location.pathname.split('/');
     setShowBar(CART_BUTTON_PAGES.includes(pathItems[1]));
    } else {
      setShowBar(false);
    }
  }, [items, location]);

  return ( showBar
    ? <div className={classes.button}>
        <div className={classes.iconWrap}>
          <BagIcon fillColor='#fff' hoverFillColor='#fff' />
        </div>
        <div className={classes.middle} style={{flex:1}}>
          <div className={classes.buttonLabel}>VIEW BAG</div>
          <div className={classes.buttonValue}>{displayTotal}</div>
        </div>
        <div className={classes.count}>
          {items.length}
        </div>
      </div>
    : null
  );
};

FooterCartButton.propTypes = {
  items: PropTypes.array.isRequired,
  displayTotal: PropTypes.string.isRequired
}

export default FooterCartButton;
