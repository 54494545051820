import * as React from "react";

import { auth } from "../../firebase";
import { confirmPasswordReset } from "firebase/auth";
import { useAnalyticsStore } from '../../App';
import { navigateTop } from '../routing/router-utils';
import { isValidPassword } from '../registration/validation-utils';
import TextField from '@material-ui/core/TextField';
import { errMsg } from '../registration/registration-errors';
import Button from '../styleguide/Button';
import ErrorMessage from "../styleguide/ErrorMessage";

import styles from './ResetPassword.module.css';

const NewPasswordForm = ({resetCode=''}) => {
  
  const { trackEvent, trackError } = useAnalyticsStore();
  const [newPassword, setNewPassword] = React.useState();
  // display errors related to password strength 
  const [passwordErr, setPasswordErr] = React.useState(false);
  // password update success
  const [updateSuccessful, setUpdateSuccessful] = React.useState(false);
  // password update failure
  const [passwordUpdateError, setPasswordUpdateError] = React.useState();
  
  const handlePasswordOnFocus = () => {
    setPasswordErr(false);      
  };

  const handlePasswordOnBlur = () => {
    setPasswordErr(!isValidPassword(newPassword));      
  };

  /** 
   * Sample email link with oobCode
   * https://ziprun-qa1.firebaseapp.com/__/auth/action?mode=resetPassword&oobCode=fL2d6_tDpcx1wRfQDgQSYQOmmVh5aOn6QRM6IPXY5SgAAAF1JzzzdA&apiKey=AIzaSyA1RWr8entMmdizW433pWG5V0gN5UMHLcA&lang=en
   */
  const resetPassword = (event) => {
    event.preventDefault();
 
    confirmPasswordReset(
      auth,
      resetCode,
      newPassword
    ).then(() => {
      setUpdateSuccessful(true);
      setPasswordUpdateError(undefined);
      trackEvent('reset_password_success');
      // Log them in? 
      // signInWithEmailAndPassword(
      //  auth,
      //  auth.currentUser.email, 
      //  newPassword);
      
      //.catch(error => {
      //  trackError('signin_failure');
      //  setError('Invalid email or password');
      //});
    }).catch((error) => {
      setUpdateSuccessful(false);
      setPasswordUpdateError(`Password update error: ${error.message || 'No details provided'}`);
      trackError('reset_password_error');
    })
  }
   
  /**
   * Update password as it's entered
   * @param {event} event 
   */
  const onChange = event => {
    const { name, value:password } = event.currentTarget;
    if (name === "newPassword") {
      setNewPassword(password);
    } 
  };     

  const SuccessMessage = () => {
    return (
      <div div className={styles.successMsgWrap}>
        <div className={styles.successMsg}>All set! your password has been updated.</div>
        <Button text="Shop for Cannabis Products" handleClick={() => navigateTop('/')} />
      </div>
    );
  } 

  return (
    <div className={styles.plainForm}>
      { updateSuccessful 
        ? <SuccessMessage />
        : <form action="" className={styles.plainInputs}>
            <TextField id="newPassword" name="newPassword" type="password" value={newPassword} label="New Password" error={passwordErr} helperText={errMsg('password', passwordErr)} 
                       onBlur={handlePasswordOnBlur}
                       onFocus={handlePasswordOnFocus} 
                       variant={'filled'}  onChange={onChange} />
            { passwordUpdateError && 
              <ErrorMessage text={passwordUpdateError} />
            }
            <Button isCentered text="Reset password" handleClick={resetPassword} />
          </form>
      }
    </div>
  );
}

export default NewPasswordForm;
