import * as React from 'react';

import { activeController } from '../util/util';
import { navigateTop } from '../routing/router-utils';
import useAddlContextTracking, { TRACK_CONTEXT } from '../analytics/useAddlContextTracking';
import useOrderPricingCalcs from './useOrderPricingCalcs';
import { AeropayContext } from '../payment/AeropayProvider';
import AeropayOrderButton from './AeropayOrderButton';
import { AttributionContext } from '../providers/AttributionProvider';
import { PartnerContext } from '../brand/PartnerProvider';
import { OrderValidityContext } from './OrderValidityProvider';
import { DiscountCreditAndFeeContext } from '../providers/DiscountCreditAndFeeProvider';
import { OrderPricingContext } from '../providers/OrderPricingProvider';
import { CartItemsContext } from './CartItemsProvider';
import { buildPaymentDetails } from '../payment/payment-utils';
import { OrderType, submitDispensaryOrder, validateAndPlaceOrder, validateCostCalculation } from './order-utils';
import Button from '../styleguide/Button';
import DisabledButtonWithReason from '../styleguide/DisabledButtonWithReason';
import MinimumOrderWarning from '../dispensary/MinimumOrderWarning';
import MinimumOrderForCodeWarning from '../dispensary/MinimumOrderForCodeWarning';
import OrderSubmissionErrors from './OrderSubmissionErrors';
import ProgressBar from '../common/ProgressBar';
import CartWarnings from './CartWarnings';
import DeliveryTermsLinks from './DeliveryTermsLinks';
import PropTypes from 'prop-types';

import styles from './OrderButton.module.css';

/**
 * We are gating the "place order" button based on:
 *  1) User must be logged in
 *  2) Minimum product order met
 *  3) Delivery available for user's zip code
 *  4) Product availability and total price pre-validated
 *
 *  All cost components validated on submit.
 */
const OrderDeliveryButton = ({
  selectedDeliverySlot,
  paymentMethod,
  orderDetailsState,
  submitError, // e.g. "3 items in your order are currently unavailable. ID: [00023, 00021, 00022]",
  setSubmitError
}) => {

  const { trackEventWithContext } = useAddlContextTracking();
  const { cartItems, emptyCart } = React.useContext(CartItemsContext);
  const { discountCode } = React.useContext(DiscountCreditAndFeeContext);
  const { minOrderOrDiscountMinCents, minOrderOrDiscountMinDisplay } = React.useContext(OrderPricingContext);

  // Attribution
  const { referralDomain } = React.useContext(AttributionContext);
  const { partnerReferralDomain, partnerReferralVendorId } = React.useContext(PartnerContext);

  // State of ID verify and Aeropay linking
  const { linkedAccountId, bankName } = React.useContext(AeropayContext);
  const [minOrderMet, setMinOrderMet] = React.useState();
  const [orderProcessing, setOrderProcessing] = React.useState();

  const {
    taxCents,
    serviceFeeCents,
    deliveryFeeApplied,
    smallOrderFeeApplied,
    activeCredit,
    discountCodeCreditCents,
    aeropayCreditCents,
    windowDiscountCents,
    preDiscountSubTotalCents,
    subTotalCents,
    totalPriceCents } = useOrderPricingCalcs();

  const abortControllerRef = React.useRef(new AbortController());
  // Exceeding "Limit 1 per Order" quantity etc.
  const { cartWarnings } = React.useContext(OrderValidityContext);

  // Handle errors from orders that are rejected by the validity endpoint (dispensary closed etc.)
  const handleValidityErrors = (responseMessage) => {
    // We'll get an empty string if there's no errors
    if (responseMessage) {
      // Cancel progress bar
      setOrderProcessing(false);
      trackEventWithContext('order_validition_error', TRACK_CONTEXT.NEW_USER);
      setSubmitError(responseMessage);
    }
  };

  const placeOrderCallback = React.useCallback((response) => {
    if (response.error) {
      // hide progress bar
      setOrderProcessing(false);
      setSubmitError(response.error);
      trackEventWithContext('checkout_order_submission_error', TRACK_CONTEXT.NEW_USER);
    } else {
      const {
        id,
        dispensary_id,
        type,
        customer,
        payment_details,
        items,
        costs: backEndCosts,
        fulfillment_time_slot,
        on_demand,
        status } = response;
      // Move to confirmation page?
      // Empty the cart once the order is placed
      emptyCart();

      // Does not include item sale prices
      const totalDiscountsAndCreditsCents =
        (activeCredit?.value.usa_cents || 0) +
        (discountCodeCreditCents || 0) +
        windowDiscountCents +
        aeropayCreditCents;

      const totalDiscountsOnly =
        (discountCodeCreditCents || 0) +
        windowDiscountCents;

      // Validate costs using rounded penny amounts
      const frontEndCosts  = {
        total: totalPriceCents,
        subtotal: preDiscountSubTotalCents, // subTotalCents,
        tax: taxCents,
        service_fee: serviceFeeCents,
        delivery_fee: deliveryFeeApplied,
        small_bag_fee: smallOrderFeeApplied,
        credits_applied: activeCredit?.value.usa_cents || 0,
        timeslot_discount: windowDiscountCents,
        total_cents_discounted: totalDiscountsAndCreditsCents,  // This should be the total of all discounts/credits
        aeropay_use_discount: aeropayCreditCents,
        large_order_discount: 0, // TODO: Should be removed from back end
        discount: totalDiscountsOnly, // No idea why we need this
        special_discount: discountCodeCreditCents || 0,  // This is discount code only per Mike
      };

      // Compare API computed price components with Web App calcs
      const costCalcDiffs = validateCostCalculation(frontEndCosts, backEndCosts, OrderType.DELIVERY);
      if ( costCalcDiffs.length ) {
        trackEventWithContext('order_delivery_pricing_error', TRACK_CONTEXT.NEW_USER);
      }

      // Order details will be cleared from provider, so we'll pass data in props
      navigateTop(`/orderConfirmation`, {
        state: {
          id,
          dispensary_id,
          customer,
          type,
          payment_details,
          items,
          fulfillment_time_slot,
          on_demand,
          costs: backEndCosts,
          costCalcDiffs,
          referralDomain: partnerReferralDomain || referralDomain,
          status
        }
      });
    }
  }, [ activeCredit, deliveryFeeApplied, windowDiscountCents, aeropayCreditCents,
       discountCodeCreditCents, emptyCart, partnerReferralDomain,
       preDiscountSubTotalCents, referralDomain, serviceFeeCents,
       setSubmitError, smallOrderFeeApplied, taxCents, totalPriceCents,
       trackEventWithContext ]);

  /**
   * Validate and place order if valid
   * This takes ~10 seconds with Aeropay
   */
  const placeOrderClick = (event) => {
    event.preventDefault();
    // This will take ~8 seconds or so
    setSubmitError(undefined);
    // show progress bar
    setOrderProcessing(true);

    validateAndPlaceOrder(
      cartItems,
      selectedDeliverySlot,  // to remove slotIdx data:  Object.keys({ a:1, b:3}).reduce((a, key) => ({ ...a, [key]: b[key]}), {});
      buildPaymentDetails(paymentMethod, linkedAccountId),
      discountCode,
      activeCredit,
      totalPriceCents,
      placeOrder,
      handleValidityErrors,
      activeController(abortControllerRef)  /* do not cancel */
    );
  }

  // Place order
  const placeOrder = () => {
    submitDispensaryOrder(
      cartItems,
      selectedDeliverySlot,
      buildPaymentDetails(paymentMethod, linkedAccountId),
      discountCode,
      activeCredit,
      {
        partner_id: partnerReferralVendorId,
        partner_domain : partnerReferralDomain || referralDomain
      },  // attributions
      orderDetailsState.customer_delivery_notes,
      placeOrderCallback,
      activeController(abortControllerRef)  /* do not cancel */
    );
  }

  React.useEffect(() => {
    if (typeof subTotalCents === "number" &&
        typeof minOrderOrDiscountMinCents === "number") {
      setMinOrderMet(subTotalCents >= minOrderOrDiscountMinCents);
    }
  }, [subTotalCents, minOrderOrDiscountMinCents]);

  return (
    <div className={styles.toCheckoutForm}>
      <div className={styles.buttonWrap}>
        { orderProcessing
          ? <ProgressBar />
          : minOrderMet
            ? cartWarnings?.length
              ? <DisabledButtonWithReason text="Place Order" reason={(
                  <CartWarnings severity="warning" warnings={cartWarnings} />
                )} />
              : <>
                  { paymentMethod === 'aeropay'
                    ? <AeropayOrderButton
                         linkedAccountId={linkedAccountId}
                         bankName={bankName}
                         placeOrderClick={placeOrderClick} />
                    : <Button isCentered text="Place Order" handleClick={placeOrderClick} />
                  }
                  <DeliveryTermsLinks />
                </>
            : <DisabledButtonWithReason text="Place Order" reason={
                discountCode
                  ? <MinimumOrderForCodeWarning
                      discountCode={discountCode}
                      minOrderDisplay={minOrderOrDiscountMinDisplay} />
                  : <MinimumOrderWarning />
              } />
        }
      </div>
      { submitError &&
        <OrderSubmissionErrors errorMsg={submitError} />
      }
    </div>
  );
}

OrderDeliveryButton.propTypes = {
  selectedDeliverySlot: PropTypes.object,
  paymentMethod: PropTypes.string,
  orderDetailsState: PropTypes.shape({
    customer_delivery_notes: PropTypes.string,
  }),
  submitError: PropTypes.string,
  setSubmitError: PropTypes.func.isRequired
};

export default OrderDeliveryButton;
