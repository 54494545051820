import * as React from 'react';

import { CartItemsContext } from '../checkout/CartItemsProvider';
// import { hideHeaderReferralCalloutKey, storeValue, getStoredValue } from '../util/storage-utils';
import styles from '../common/PageHeader.module.css';

// Removing "close/hide" for MVP testing
const PriorityCalloutInHeader = ({
  priorityETA
}) => {

  const { cartItems } = React.useContext(CartItemsContext);
  const [ showCallout, setShowCallout ] = React.useState();

  /*
  const hideCallout = () => {
    setShowCallout(false);
    trackEvent('refer_hdr_callout_hide');
    // We may want to re-enable the callout so we'll store the dismiss Date
    const dismissDateMS = new Date().getTime();
    storeValue(hideHeaderReferralCalloutKey, dismissDateMS);
  }
  */

  React.useEffect(() => {
    // const closedByUser = getStoredValue(hideHeaderReferralCalloutKey);
    setShowCallout(cartItems.length < 1);
  }, [cartItems])

  return (showCallout
      ? <div className={`${styles.referFriendsOverlay} ${styles.priorityDeliveryOverlay}`}>
          <img alt="" src="/img/boltWithGlow.png" className={styles.priorityBoltIcon} />
          Priority Delivery by {priorityETA} Available!
          {/*
          <span className={styles.referFriendsClose} onClick={hideCallout}>
            <HighlightOffRoundedIcon style={{fontSize:'25px'}} />
          </span>
          */}
        </div>
      : null
  );
};

export default PriorityCalloutInHeader;
