import * as React from 'react';

import config from '../../config';
import { trackError } from '../analytics/tracking';
import { handleMissingProductImage } from './product-utils';
import MissingImageLabel from './MissingImageLabel';
import PropTypes from 'prop-types';

import styles from './CroppedProductImage.module.css';
import addlStyles from './PreviewProductImage.module.css';

// sample cdnPrefix = 'https://zyprun.com/cdn-cgi/image/width=300,height=300/';
export const optimizedImage = (src, maxWidth) => {
  // handle data/uri
  if (src && src.toLowerCase().startsWith('data')) {
    return src;
  }
  const imageWidth = maxWidth || 300;
  // Handle localhost/dev mode: don't try to CDN from http localhost
  if (src?.startsWith('https') && config.ENABLE_CLOUDFLARE_CDN) {
    return `${config.IMAGE_CDN_BASE}/width=${imageWidth}/${src}`;
  } else {
    return src;
  }
};

/**
 * Component that crops product images to provide aspect ratio needed for view
 *
 * @param {string} productName - name for alt attribute
 * @param {string} src - image src
 * @param {string} category - for fallback image
 * @param {boolean} isModal - style used in modal
 */
const CroppedProductImage = ({
  productName, 
  src,
  category,
  isModal
}) => {
  
  // Fallback assigned when src is null
  const { fallbackUrl, isFallback } =  handleMissingProductImage(src, category);
  const [imgSrc, setImgSrc] = React.useState();
  const [isFallbackImage, setIsFallbackImage]= React.useState();

  const errorLogged = React.useRef();
  // Sometimes CloudFlare returns 503 or even a 404 during outtages...  
  const handleError = React.useCallback(() => {
    imgRef.current.src = fallbackUrl;
    setIsFallbackImage(true);
    if (!errorLogged.current) {
      // TODO: useAnalyticsStore trackError triggers infinite rerender loop!
      trackError('img_load_error');
      errorLogged.current = true;
    }
  }, [fallbackUrl]);

  React.useEffect(() => {
    if (!imgSrc) {
      setIsFallbackImage(isFallback);
      if (isFallback) {
        setImgSrc(optimizedImage(fallbackUrl, 300));
      } else {
        setImgSrc(optimizedImage(src, 300));
      }
    }
  },[src, imgSrc, fallbackUrl, isFallback]);

  const wideStyle = isModal ? styles.modalImage : '';
  const imgRef = React.useRef();

  return ( 
    <div className={`${styles.imageOuter} ${wideStyle}`} >
      <div className={styles.imageWrap}>
        { imgSrc
          ? <img
              ref={imgRef}
              alt={productName}
              className={styles.productImage}
              src={imgSrc}
              onError={handleError} />
           : <></> 
        }   
      </div>
      { isFallbackImage && 
        <div className={styles.placeholderMessage}>
          <MissingImageLabel withClass={addlStyles.missingImageLabel} />
        </div>
      }
    </div>
  )
}

CroppedProductImage.propTypes = {
  productName: PropTypes.string.isRequired,
  src: PropTypes.string,
  category: PropTypes.string,
  isModal: PropTypes.bool
}

export default CroppedProductImage;
