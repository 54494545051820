import React from 'react';

import useDeliverability from '../dispensary/useDeliverability';
import Alert from '@material-ui/lab/Alert';
import styles from '../common/Alert.module.css';

import PropTypes from 'prop-types';

/**
 * Display warnings related to cart items when placing an order
 */
const DeliverabilityWarnings = ({
  severity='warning'
}) => {

  const { isDeliveryPaused, isUndeliverableAddress, noDeliveryLocation } = useDeliverability();

  return (
    <Alert severity={severity} className={styles.cartWarnings}>
      { isDeliveryPaused   
        ? <div>
            <div className={styles.warningMsg}>
              Sorry, delivery service is not currently available.
            </div>  
            <div>We're working on it, please check back later.</div>
          </div>
        : isUndeliverableAddress
          ? <>{`Sorry, Delivery is not currently available 
                in ${noDeliveryLocation}.`}</>
          : null  
      }
    </Alert>
  )
}

DeliverabilityWarnings.propTypes = {
  severity: PropTypes.string,
}

export default DeliverabilityWarnings;
