
// Slot start/end time for with the given day offset: 0,1,2,etc.
// Nov 2: 1730602800000 (240 offset) Nov 3: 300 offset
const getSlotTimeMS = (hour, minute, dayOffset) => {
  const slotStart = new Date(new Date().setHours(hour,minute,0,0));
  const slotStartWithDayOffset = new Date(slotStart.setDate(slotStart.getDate() + dayOffset));
  return slotStartWithDayOffset.getTime();
};

export const getLatestOrderTimeMS = (dayOffset, slot) => {
  const { end_hour, end_minute } = slot.hours;
  const slotStartMS = getSlotTimeMS(end_hour, end_minute, dayOffset);
  return slotStartMS - (slot.allows_orders_up_until_minutes_before_end * 60000);
};

export const getEarliestOrderTimeMS = (dayOffset, slot) => {
  const { start_hour, start_minute } = slot.hours;
  const slotStartMS = getSlotTimeMS(start_hour, start_minute, dayOffset);
  return slotStartMS - (slot.max_minutes_can_order_in_advance * 60000);
};

/**
 * The method allows altering the timeStamp for testing purposes
 *
 * @param {number} timeStamp
 */
 const getCompareDate = (timeStamp) => {
  // adjust for testing
  const timeShiftMS = 0; // 6*60*60*1000; // 6 hours
  return new Date(timeStamp + timeShiftMS);
};

// Display: Dec 2, Dec 3, etc.
export const getOrderDate = (offset, timeStamp) => {
  const today = getCompareDate(timeStamp);
  today.setDate(today.getDate() + offset);
  const dateInfo = today.toDateString().split(' ');
  return `${dateInfo[1]} ${parseInt(dateInfo[2])}`;
};

// Determine how many days out to show timeslots, based on max advance order time
export const getMaxDaysOut = (dailyTimeSlots, timeStamp) => {
  const enabledDailySlots = dailyTimeSlots.map((day=[], idx) => {
    return day.filter(slot => {
      const earliestMS = getEarliestOrderTimeMS(idx, slot);
      return timeStamp >= earliestMS;
    })
  });
  // Find the index of the first empty array (We won't show options)
  const [firstDisabledDay] = enabledDailySlots.reduce((array, slots, idx) => {
    if (slots.length === 0) {
      array.push(idx);
    }
    return array;
  }, []);
  return firstDisabledDay;
};

// Is the slot currently available for the user's location
const isAvailableSlotForLocation = (slot, timeStamp, user_zip_code="-1") => {
  const earliestMS = getEarliestOrderTimeMS(0, slot);
  const latestMS = getLatestOrderTimeMS(0, slot);
  return timeStamp > earliestMS &&
         timeStamp < latestMS &&
         !slot.exclude_zip_codes?.includes(user_zip_code);
}

// Find the first available same-day pickup slot idx or -1, adjusted for user_zip_code exclusions
export const getDefaultSlotForToday = (slotsForToday=[], timeStamp, user_zip_code="-1") => {
  const [firstEnabledIdx] = slotsForToday.reduce((array, slot, idx) => {
    if (isAvailableSlotForLocation(slot, timeStamp, user_zip_code)) {
      array.push(idx);
    }
    return array;
  }, []);
  return typeof firstEnabledIdx === "number" ? firstEnabledIdx : -1;
};

// Find the first discounted same-day pickup slot or undefined, based on user_zip_code
export const getNextDiscountSlotForToday = (slotsForToday=[], timeStamp, user_zip_code="-1") => {
  const [firstEnabledSlot] = slotsForToday.reduce((array, slot) => {
    const { usa_cents_discount, exclusive_zip_codes=[] } = slot.incentive || {};
    if (isAvailableSlotForLocation(slot, timeStamp, user_zip_code) &&
        usa_cents_discount > 0 &&
        (exclusive_zip_codes.length === 0 ||
        exclusive_zip_codes.includes(user_zip_code))) {
      array.push(slot);
    }
    return array;
  }, []);
  return firstEnabledSlot;
};

// Find the first available pickup slot for a specific day or null
// This is used when dispensary doesn't accept orders outside of biz hours
export const getEarliestOrderTime = (slotsForDay=[], dayOffset, timeStamp) => {
  const [firstEnabledTime] = slotsForDay.reduce((array, slot) => {
    const latestMS = getLatestOrderTimeMS(dayOffset, slot);
    // if slot is available
    if ( timeStamp < latestMS ) {
      // console.log(`calced latest ${ new Date(latestMS).toLocaleTimeString()}`);
      array.push(latestMS);
    }
    // Sort the timestamps lowest to highest
    return array.sort((a, b) => a - b);
  }, []);
  return typeof firstEnabledTime === "number" ? firstEnabledTime : null;
};

export const daysOfWeekAbbrev = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
export const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const daysOfWeekDisplay = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

// Reorder the timeslots starting with the current day of the week
export const getSlotsStartingToday = (todayNum, slotsByDay) => {
  // todayNum: e.g. 2 for Tuesday
  return [
    slotsByDay[daysOfWeek[todayNum]],
    slotsByDay[daysOfWeek[(todayNum + 1) % 7]],
    slotsByDay[daysOfWeek[(todayNum + 2) % 7]],
    slotsByDay[daysOfWeek[(todayNum + 3) % 7]],
    slotsByDay[daysOfWeek[(todayNum + 4) % 7]],
    slotsByDay[daysOfWeek[(todayNum + 5) % 7]],
    slotsByDay[daysOfWeek[(todayNum + 6) % 7]]
  ];
};

export const getDayLabel = (currentTimeMS, offset) => {
  const dayOfWeek = new Date(currentTimeMS).getDay();
  if (offset === 0) {
    return 'TODAY';
  } else if (offset === 1) {
    return 'TOMORROW';
  }
  // Use Monday, Tuesday, etc.
  const weekdayNum = (dayOfWeek + offset) % 6;
  return daysOfWeekDisplay[weekdayNum];
};

export const isExpiredSlot = (slot, currentTimeMS) => {
  try {
    // Time stamp for slot end hour/minute
    const slotEndMS = new Date(
      slot.date.year,
      slot.date.month - 1,
      slot.date.day,
      slot.hours.end_hour,
      slot.hours.end_minute
    ).getTime();

    return currentTimeMS
           + (slot.allows_orders_up_until_minutes_before_end * 60000)
           > slotEndMS;
  } catch(e) {
    return false;
  }
};

// Slot discounts can be targeted to a zip_code
export const slotDiscountForLocation = (incentive, location) => {
  const { exclusive_zip_codes, usa_cents_discount } = incentive || {};
  const { zip_code } = location || {};
  if (exclusive_zip_codes && zip_code) {
    // Everyone gets the discount if there are no exclusive zip codes
    if (exclusive_zip_codes.length === 0 ||
        exclusive_zip_codes.includes(zip_code)){
      return usa_cents_discount;
    }
  }
  return 0;
}
