import * as React from 'react';

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

// Styles for the ProductCardForGrid component 
export const imageOverlayStyle = {
  position: 'absolute',
  zIndex: 1,
  bottom: 4,
  left: '50%',
  transform: 'translateX(-50%)',
  fontSize: '14px',
  margin: 0,
};

const useStyles = makeStyles({
  qtyLimit: {
    color: '#fff',
    fontWeight: 600,
    fontStyle: 'italic',
    lineHeight: '100%',
    whiteSpace: 'nowrap',
    backgroundColor: '#000',
    padding: '8px 12px',
    borderRadius: 20,
    margin: '0 4px',
  }  
})

/**
 * Display order quantity limits if specified via Dutchie tag
 */
const OrderLimitPill = ({
  per_order_max_quantity, // Via Dutchie tag: "per-order-limit-1"
  withStyle={}
}) => { 

  const classes = useStyles();
  
  return ( per_order_max_quantity > 0 
    ? <div className={classes.qtyLimit} style={withStyle}>
        Limit: {per_order_max_quantity} Per Order
      </div>
    : null
  )
}

OrderLimitPill.propTypes = {
  per_order_max_quantity: PropTypes.number,
  withStyle: PropTypes.object,
}

export default OrderLimitPill;
