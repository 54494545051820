import * as React from 'react';

import useBrandPartnerData from '../brand/useBrandPartnerData';
import DisplayModal from '../common/DisplayModal';
import Header, {HeaderSize} from '../styleguide/Header';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  wrapper: {
    boxSizing: 'border-box',
    width: 450,
    maxWidth: '100%',
  },
  body: {
   padding: '.25rem .75rem 1rem',
   fontSize: '1.125rem' 
  },
  overview: {
    fontSize:'1rem',
    '& a': {
      fontWeight: 600,
      color: 'var(--zrPrimaryDark)'
    }
  },
  logoWrap: {
    margin: '1rem 0 0',
    textAlign: 'center'
  },
  logo: {
    borderRadius: 6,
    display: 'inline-block'   
  }
})

const PartnerInfoModal = ({ modalProps }) => {

  const classes = useStyles(); 
  const { partnerName, trackContext } = modalProps;
  const { data } = useBrandPartnerData(partnerName); 
  
  const { name, logo, overview } = data || {};

  return (
    <DisplayModal title={`About ${name}`}
                  trackContext={trackContext}
                  scrollingInner
                  isShrinkToFit >
      <div className={classes.wrapper}>
        { name
          ? <div>
              <div className={classes.body}>
                <Header 
                  size={HeaderSize.Medium_Static} 
                  isCentered 
                  text={`About ${name}`} />
                <div className={classes.overview}>
                  {overview}  
                </div>
                <div className={classes.logoWrap}>
                  <img className={classes.logo} height="75" alt="" src={logo} />
                </div>
              </div>
            </div>
          : <></>  
        }
      </div>
    </DisplayModal>
  );
};

PartnerInfoModal.propTypes = {
  modalProps: PropTypes.object.isRequired 
}

export default PartnerInfoModal;
