import * as React from 'react';

import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { PromoBannerContext } from './PromoBannerProvider';
import PromoBannerCarousel from './PromoBannerCarousel';
import Spinner from '../common/Spinner';
import styles from '../home/Home.module.css';

const PromoBannerSection = () => {
  // For targeting Priority Delivery sessions
  const { priorityETA } = React.useContext(DispensaryContext);
  const { promoBannerData } = React.useContext(PromoBannerContext);

  // Suppress certain banners depending on session
  const [filteredBanners, setFilteredBanners] = React.useState();

  React.useEffect(() => {
    if (promoBannerData) {
      const banners = [];
      promoBannerData.forEach(banner => {
        // Priority delivery banners only for relevant sessions
        if (banner.go_to_url === 'priority' && !priorityETA) {
          // Suppress
        } else {
          banners.push(banner);
        }
      })
      setFilteredBanners(banners);
    }
  }, [promoBannerData, priorityETA])

  return (
    filteredBanners?.length
    ? <div className={styles.promoCarouselWrapper}>
        <PromoBannerCarousel promoBanners={filteredBanners} />
      </div>
    : filteredBanners?.length === 0
      ? null
      : <Spinner inSmallLayout />
  )
}

export default PromoBannerSection;
