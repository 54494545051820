import * as React from 'react';

import config from '../../config';
import { UserContext } from './UserProvider';
import useBlockedAddressCheck from '../address/useBlockedAddressCheck';
import { activeController } from '../util/util';
import { INTERCOM_MILESTONES } from '../intercom/useIntercomLogging';
import { aeropayPaymentTypeValue, cashPaymentTypeValue, debitPaymentTypeValue } from '../checkout/usePaymentOptionReducer';
import { fetchDeliveryOptions } from '../dispensary/dispensary-utils';
import { dollarDisplay } from './OrderPricingProvider';
import ToolTip from '../common/ToolTip';

export const DeliveryOptionsContext = React.createContext({});

// ToolTip for Debit Card
export const DebitTip = () => {
  return (
    <ToolTip
      enableFlip
      buttonStyle={{verticalAlign:-3, padding:0, marginLeft:-3}}
      tipTitle="Debit Card Payment"
      footerText="*Due to the federal status of cannabis, we can't accept credit cards">
      <>
        Standard debit card transaction fees apply and will be added to your order total.
        Traditional bank-issued debit card required.
      </>
    </ToolTip>
  )
};

// Track usage of the various payment types in Intercom
export const getIntercomPaymentAction = (order) => {
  const { processor } = order?.payment_details || {};
  switch (processor) {
    case cashPaymentTypeValue:
      return INTERCOM_MILESTONES.PAID_WITH_CASH;
    case debitPaymentTypeValue:
      return INTERCOM_MILESTONES.PAID_WITH_DEBIT;
    case aeropayPaymentTypeValue:
      return INTERCOM_MILESTONES.PAID_WITH_AEROPAY;
    default:
      return undefined;
  }
}

/**
 * TODO: awkward!
 * on_site_cash becomes on-site-cash for order submission
 * online_aeropay needs to be just aeropay
 */
const fixPaymentNames = (methods) => {
  const {
    online_aeropay:aeropay,
    on_site_cash,
    on_site_debit
  } = methods;
  /**
   * Hack for Aeropay Outtage 5/18/24
   * Suppress payment option for new users
   * let enabled = true;
   * // 1716060182000 = 5/18, 3.23PM
   * if ( !user.isAnonymous &&
   *      parseInt(user.metadata.createdAt, 10) >= 1716060182000) {
   *   // alert(new Date(parseInt(user.metadata.createdAt, 10)));
   *   enabled = false;
   * }
   *
   * Then: aeropay: aeropay && enabled,
   */
  return { aeropay, on_site_cash, on_site_debit };
}

// Timeslots are provided in creation order :(
const sortedTimeSlots = (slotsByDay={}) => {
  Object.values(slotsByDay).forEach(timeSlots => {
    if (timeSlots?.length && timeSlots[0].hours) {
      timeSlots.sort((a,b) => {
        return a.hours.start_hour - b.hours.start_hour;
      });
    }
  })
  return slotsByDay;
}

/**
 * TODO: This provider needs to be refreshed every ~5 minutes or so due
 * to frequent timeslot config changes.
 */
const DeliveryOptionsProvider = ({children}) => {

  const { user, currentLocation } = React.useContext(UserContext);

  // We've moved this up out of the DeliverTo component to reduce requests
  const isBlockedAddress = useBlockedAddressCheck(currentLocation);

  const [deliveryEnabled, setDeliveryEnabled] = React.useState();
  // For use ONLY in useMinOrderForLocation
  const [minOrderDefaultCents, setMinOrderDefaultCents] = React.useState();
  const [timeSlotsByDay, setTimeSlotsByDay] = React.useState();
  const [paymentMethods, setPaymentMethods] = React.useState();

  const deliveryOptionsCallback = React.useCallback((response) => {
    const {
      allows_delivery_orders,
      minimum_delivery_value_usa_cents,
      delivery_time_slots_for_week,
      payment_methods
    } = response;
    setDeliveryEnabled(allows_delivery_orders);
    setMinOrderDefaultCents(minimum_delivery_value_usa_cents);
    setTimeSlotsByDay(sortedTimeSlots(delivery_time_slots_for_week));
    setPaymentMethods(fixPaymentNames(payment_methods));
  }, []);

  const controllerRef = React.useRef(new AbortController());

  // Fetch Delivery Options after timezone offset is available
  React.useEffect(() => {
    const dispensaryId = config.ZYP_RUN_DISPENSARY_ID;
    const controller = activeController(controllerRef);
    if (user && typeof minOrderDefaultCents === 'undefined') {
      fetchDeliveryOptions(dispensaryId, deliveryOptionsCallback, controller);
    }
    // cancel fetch on unmount
    return () => controller.abort();
  }, [user, deliveryEnabled, minOrderDefaultCents, deliveryOptionsCallback]);

  return (
    <DeliveryOptionsContext.Provider value={{
      deliveryEnabled,
      minOrderDefaultCents, // For use ONLY in useMinOrderForLocation
      minDeliveryOrderDisplay: dollarDisplay(minOrderDefaultCents),
      timeSlotsByDay,
      paymentMethods,
      isBlockedAddress,
    }}>
      {children}
    </DeliveryOptionsContext.Provider>
  );
};

export default DeliveryOptionsProvider;
