import * as React from 'react';

import { RegistrationContext } from '../registration/RegistrationProvider';
import useReferralFraudReducer from './useReferralFraudReducer.js';
import ReferFraudMessage from './ReferFraudMessage.jsx';
import { CREDIT_DETAILS } from './GetReferCreditDetails';
import MoneyBagIcon from '../icons/MoneyBagIcon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: '1rem 0',
    borderRadius: '.25rem',
    backgroundColor: 'var(--zrPrimaryBackground)'
  },
  wrapper: {
    flex: 1,
    padding: '.5rem 1rem .5rem 0',
  },
  highlight: {
    font: 'var(--zrFontSemi)',
    color: 'var(--zrPrimaryDark)'
  },
  footnote: {
    flexBasis: '100%',
    textAlign: 'center',
    fontSize: 12,
    fontStyle: 'italic',
    margin: '0 8px 8px',
  } 
});

const DisplaySignUpReferrer = () => {

  const classes = useStyles();
  const { referrerId, referrerName } = React.useContext(RegistrationContext);
  const { isLikelyFraud } = useReferralFraudReducer();

  return ( referrerId
    ? isLikelyFraud
      ? <ReferFraudMessage />
      : <div className={classes.grid}>
          <MoneyBagIcon fillColor="var(--zrPrimaryDark)" size='36'  withStyle={{margin:'0 10px'}} />
          <div className={classes.wrapper}>
            You were referred
            by <span className={classes.highlight}>{referrerName}</span> and
            will receive <span className={classes.highlight}>
              ${CREDIT_DETAILS.total}
            </span> in
            {' '}<span className={classes.highlight}>Zyp&nbsp;Run Credits</span> to 
            use on your delivery orders.*
          </div>
          <div className={classes.footnote}>
            * One ${CREDIT_DETAILS.per_order} credit per order, subject to delivery order minimum.
          </div>
        </div>
    : null
  );
};

export default DisplaySignUpReferrer;
