import * as React from 'react';

/**
 * Zyp Run Terms of Service as of 1/2/23
 */
const ZypRunTermsOfService = () => {

  const iframeStyle = {
    border: 'none',
    height: '60vh',
    marginBottom: 16,
  }

  // Document from /public folder
  return (
    <iframe
      title="Zyp Run Terms of Service"  
      style={iframeStyle}
      width="100%" 
      src="/TermsOfService.html">
    </iframe>
  ) 
}

export default ZypRunTermsOfService;
