import * as React from 'react';

import useBrandProductsBySubCategory from './useBrandProductsBySubCategory';
import ProductCarousel from '../home/ProductCarousel';
import Spinner from '../common/Spinner';

import PropTypes from 'prop-types';

/**
 * Currently we have hard-coded brands by product category
 * in featuredBrands.js
 */
const BrandCarousels = ({
  categoryName
}) => {
  
  const { productsBySubCategory } = useBrandProductsBySubCategory(categoryName);

  return ( productsBySubCategory
    ? <>
        {[...productsBySubCategory.keys()].map((brand) => {
          const subCatProducts = productsBySubCategory.get(brand);  
          return <ProductCarousel
                   key={brand}
                   carouselTitle={`Featured Brand: ${brand} ${categoryName}`}
                   /* superset count */
                   categoryCount={subCatProducts.length}
                   carouselProducts={subCatProducts} 
                   seeAllClick={ null /* for LC Square */}
                   isSponsored // is a brand carousel
                 />
        })}
      </>
    : <Spinner />
  )
}

BrandCarousels.propTypes = {
  categoryName: PropTypes.string.isRequired
};

export default BrandCarousels;
