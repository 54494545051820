import * as React from "react";

import { ProductPreviewContext } from '../products/ProductPreviewProvider';

const NEW_PRODUCT_BRANDS_BY_CATEGORY = {
  'Pre-Rolls' : ['Jeeter', 'Dogwalkers'],
  'Vaporizers' : ['RYTHM'],
};

/**
 * Custom hook used to provide new/brand products by category (for carousel display)
 */
const useNewProductsByCategory = (categoryName) => {

  const { productsByCategory } = React.useContext(ProductPreviewContext);
  const [newProducts, setNewProducts] = React.useState();

  /**
   * Return featured brands for a given product category by Brand
   */
  const getNewProductsByCategory = React.useCallback((categoryName) => {
    const newBrands = NEW_PRODUCT_BRANDS_BY_CATEGORY[categoryName];
    if (newBrands) {
      const categoryProducts = productsByCategory?.get(categoryName) || [];
      return categoryProducts.filter(itm =>
        itm.is_new_product || newBrands.includes(itm.display_info.brand));
    }
    return [];
  }, [productsByCategory]);

  React.useEffect(() => {
    if (categoryName) {
      const newProducts = getNewProductsByCategory(categoryName);
      setNewProducts(newProducts);
    }
  }, [categoryName, getNewProductsByCategory]);

  return {
    newProducts
  };
};

export default useNewProductsByCategory;
