import * as React from 'react';

import { navigateTop } from '../routing/router-utils';
import { useAnalyticsStore } from '../../App';
import { STACKING } from '../layout/stackingConstants';
import { Squash as Hamburger } from 'hamburger-react';
import MenuItems from '../hamburgerMenu/MenuItems';
import CartToggle, { CartIconType } from '../checkout/CartToggle';
import ErrorBoundary from './ErrorBoundary';
import DeliverTo from '../address/DeliverTo';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import zypRunLogo from '../../assets/zyprun_logo_200w.png';

import styles from './PageHeader.module.css';

const PageHeader = () => {

  const { trackEvent } = useAnalyticsStore();

  // Hamburger menu state
  const [menuOpen, setMenuOpen] = React.useState(false);

  const storeInfoClick = () => {
    navigateTop(
      `/storeInfo`,
      { state: { returnToPath: `${window.location.pathname}` }}
    );
  }

  const navigateHome = () => {
    if (window.location.pathname.startsWith('/checkOut')) {
      trackEvent('header_logo_checkout_click');
    }
    navigateTop(`/`);
  }

  return (
    <ErrorBoundary>
      <div id="header" className={styles.pageHeader} style={{zIndex:STACKING.HEADER_AND_FOOTER}}>
        <div className={styles.burgerWrap}>
          <div id="burgerIcon" className={styles.burgerIcon} 
               style={{zIndex: STACKING.HAMBURGER_MENU_ICON}}>
            <Hamburger
              rounded
              distance="sm"
              label="Toggle menu"
              size={23}
              toggled={menuOpen}
              onToggle={() => {
                if (!menuOpen) {
                  trackEvent('hamburger_menu_open');
                  if (window.location.pathname.startsWith('/checkOut')) {
                    trackEvent('hamburger_menu_open_checkout');
                  }
                }
                setMenuOpen(!menuOpen);
              }} />
          </div>
          <MenuItems open={menuOpen} setOpen={setMenuOpen} /> 
        </div>
        <div className={styles.navLinks}>
          <DeliverTo isInline />
        </div>      
        <div className={styles.centerArea}>
          {/* logo is positioned absolutely to be centered, the wrapper div just clears some space */}
          <div className={styles.logoWrap}>
            <div className={styles.logoBackground}>
              <img alt="logo" onClick={navigateHome} className={styles.zrLogo} src={zypRunLogo} />
            </div>
          </div>
        </div>
        <div className={styles.infoWrap} onClick={storeInfoClick}>
          <span className={styles.navLink}>
            Store Info&thinsp;<InfoIcon style={{fontSize: 22, verticalAlign:'-6px'}}/>
          </span>   
        </div>
        <div className={styles.cartWrap}>
          <CartToggle iconType={CartIconType.HEADER} />
        </div>
      </div>
      <DeliverTo />
    </ErrorBoundary>
  );  
}

export default PageHeader;
