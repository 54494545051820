import * as React from 'react';

const NewSuperscript  = () => {
  const supStyle = {
    fontSize: '13px',
    fontWeight: 600,
    fontStyle: 'italic',
    color: 'var(--zrTextRed)',
    verticalAlign: 5,
    marginTop: -5,
    paddingLeft: 2,
  };
  return (
    <span style={supStyle}>NEW!</span>        
  ) 
}

export default NewSuperscript;
