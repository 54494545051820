import * as React from 'react';

import { DispensaryContext } from './DispensaryProvider';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import Header, { HeaderSize } from '../styleguide/Header';
import GoogleMap from '../googlemaps/GoogleMap';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid } from '@material-ui/core';
import { getFormattedTimeLabel } from '../deliveryWindows/scheduled-delivery-utils';
import { getFormattedAddress } from '../googlemaps/google-maps-utils';
import StoreInfoSidebar from './StoreInfoSidebar';
import CurrentTimeProvider from '../timeSlots/CurrentTimeProvider';
import ErrorBoundary from '../common/ErrorBoundary';
import Spinner from '../common/Spinner';
import BackLink from '../common/BackLink';
import ChatLink from '../intercom/ChatLink';
import PageTitle from '../common/PageTitle';
import { capitalize } from '@material-ui/core';
import styles from './StoreInfo.module.css';

export const formattedHours = (day) => {
  const hours = day.hours;
  return  `${ getFormattedTimeLabel(hours.start_hour, hours.start_minute)} -
            ${ getFormattedTimeLabel(hours.end_hour, hours.end_minute)}`;
}

/**
 * This component displays store hours, payment methods, address etc.
 *
 * The location prop is from the router
 **/
const StoreInfo = ({location}) => {

  const { dispensary } = React.useContext(DispensaryContext);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  // Set path for back link
  const [returnToPath, setReturnToPath] = React.useState();

  React.useEffect(() => {
    if (location?.state) {
      const { returnToPath:backPath } = location?.state;
      if ( backPath !== returnToPath) {
        setReturnToPath(backPath);
      }
    }
  }, [location, returnToPath]);

  return (
    <BodyWrapper pageLayout={LayoutTypes.Narrow} withStyle={{paddingBottom:'4rem'}}>
      { dispensary?.id
        ? <>
            <PageTitle title="Hours" />
            <Header size={HeaderSize.Medium} isCentered withStyle={{margin:'2rem 0 0'}}>
              <BackLink backToPath={returnToPath} inHeader />
              <>{dispensary.name}</>
            </Header>
            <Grid container spacing={isSmall ? 2 : 4}>
              <Grid item sm={12} md={4}>
                <ErrorBoundary>
                  <CurrentTimeProvider>
                    <StoreInfoSidebar hours_of_operation={dispensary.hours_of_operation}/>
                  </CurrentTimeProvider>
                </ErrorBoundary>
              </Grid>
              <Grid item sm={12} md={8}>
                { dispensary.location &&
                  <div className={styles.mapWrapper}>
                    <GoogleMap addressObj={dispensary.location} />
                  </div>
                }
                <Grid container justifyContent='center' spacing={6}>
                  <Grid item xs={12} md={6}>
                    <Header size={HeaderSize.Small} withClass={styles.infoHdr} text="Business Hours" />
                    <ul className={styles.weeklyHours}>
                      { dispensary.hours_of_operation?.map(day => {
                        return  <li key={day.weekday}>
                                  <span className={styles.bizDay}>{capitalize(day.weekday)}</span>
                                  <span className={styles.bizHours}>{formattedHours(day)}</span>
                                </li>
                      })}
                    </ul>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Header size={HeaderSize.Small} withClass={styles.infoHdr} text="Location" />
                    { dispensary.location &&
                      <div>{ getFormattedAddress(dispensary.location) }</div>
                    }
                    <Header size={HeaderSize.Small} withClass={styles.infoHdr} text="Contact" />
                    <div className={styles.contactItem}>
                      <span className={styles.contactLbl}>Email:</span>
                      <a className={styles.contactLnk} href="mailto:help@zyprun.com?subject=Shop.ZypRun Help Question">help@zyprun.com</a>
                    </div>
                    <div className={styles.contactItem}>
                      <span className={styles.contactLbl}>Phone:</span>
                      <a className={styles.contactLnk} href="tel:+16174050429">(617) 405-0429</a>
                    </div>
                    <div className={styles.contactItem}>
                      <span className={styles.contactLbl}>Chat:</span>
                      <ChatLink unstyled>
                         <span className={styles.contactLnk}>Open Chat</span>
                      </ChatLink>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        : <Spinner />
      }
    </BodyWrapper>
  );
};

export default StoreInfo;
