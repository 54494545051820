import * as React from 'react';

import config from '../../config';
import Button from '../styleguide/Button';
import AccountTipImage from '../../assets/YourAccountTip.png';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import LightbulbIcon from '@material-ui/icons/EmojiObjects';
import ReferralLinkForm from '../refer/ReferralLinkForm';
import { makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  successWrap: {
    maxWidth: 600,
    margin: '0 auto',
  },
  successInner: {
    padding: '2rem 0 2.5rem',
    [theme.breakpoints.up('sm')]: {
      padding: '2rem 1rem 3rem',
    }
  },
  successMsg: {
    textAlign: 'center',
    font: 'var(--zrFontBold)',
    fontSize: '1.675rem',
    marginBottom: '2rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
    }   
  },
  exclaim: { 
    display: 'block',
    marginBottom: '.5rem',
    color: 'var(--zrPrimaryDark)',
    [theme.breakpoints.up('sm')]: {
      display: 'inline',
      marginBottom: 0,
    } 
  },
  accountTip:  {
    margin: '2rem 0.5rem 1.5rem',
    padding: '1rem .5rem',
    textAlign: 'center',
    font: 'var(--zrFontSemi)',
    fontSize: '.875rem',
    backgroundColor: '#f1f1f1',
    color: 'var(--zrPrimaryDark)',
    borderRadius: '.5rem',
    [theme.breakpoints.up('sm')]: {
      margin: '3rem .5rem 3rem',
    },
    '& img': {
      display: 'block',
      margin: '.5rem auto 0',
    }
  },
  referWrap: {
    padding: '1rem',
    margin: '.25rem .5rem 0',
    borderRadius: '.5rem',
    backgroundColor: '#dfedfe'  
  }
}));

const RegistrationSuccessMsg = ({
  isCheckout,
  onContinue
}) => {
  
  const classes = useStyles();

  const handleContinue = React.useCallback(() => {
    if (onContinue) {
      // Continue to Shopping/Checkout
      onContinue(); 
    }
  }, [onContinue])

  return (
    <div className={classes.successWrap}>
      <div className={classes.successInner}>
        <div className={classes.successMsg}>
          <span className={classes.exclaim}>
            <CheckCircleRoundedIcon style={{fontSize:'inherit', verticalAlign:'-4px'}} />
            {'  '}<span>Success!</span>
          </span>
          {'  '}You're registered. 
          {'  '}<span role="img" aria-label="celebrate">🎉</span>
        </div>
        <Button 
          isCentered 
          text={ isCheckout
            ? 'Continue to Checkout'
            : 'Continue Shopping'
          }
          handleClick={handleContinue} />
      </div>

      { config.ENABLE_CREDITS &&
        <div className={classes.referWrap}>
          <ReferralLinkForm />
        </div>
      }

      <div className={classes.accountTip}>
        <LightbulbIcon style={{color:'inherit', fontSize: 24, verticalAlign:-6}} /> Manage your account using the <i>Your Account</i> menu option
        <img height="48" alt="Your Account Menu Option" src={AccountTipImage} />
      </div>
    </div>
  );
};

RegistrationSuccessMsg.propTypes = {
  isCheckout: PropTypes.bool, // for messaging
  onContinue: PropTypes.func.isRequired
};

export default RegistrationSuccessMsg;
