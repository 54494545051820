import * as React from 'react';

import { CarouselStyle } from '../common/SimpleCarousel';
import ProductCarousel from '../home/ProductCarousel';

import PropTypes from 'prop-types';

export const SUGGESTED_PRODUCT_CAROUSEL_HEIGHT = 375;

const SuggestedProductsCarousel = ({
  currentProductId,
  carouselTitle,
  relatedProducts,
}) => {

  const [otherSubCatProducts, setOtherSubCatProducts] = React.useState();

  // Remove the current product from the suggested carousel
  React.useEffect(() => {
    if (relatedProducts) {
      setOtherSubCatProducts(relatedProducts.filter(product =>
        product.id !== currentProductId)
      );
    }
  }, [relatedProducts, currentProductId]);

  return ( otherSubCatProducts
    ? <ProductCarousel
        carouselTitle={carouselTitle}
        /* caregoryCount is for the "See All" link which we don't use here */
        categoryCount={otherSubCatProducts.length}
        carouselProducts={otherSubCatProducts}
        /* Restore full product list during modal to modal viewing  */
        allRelatedProducts={relatedProducts}
        carouselStyle={CarouselStyle.SUGGESTED_PRODUCTS}
        isSuggestedProduct />
    : <></>
  )
}

SuggestedProductsCarousel.propTypes = {
  currentProductId: PropTypes.string.isRequired,
  carouselTitle: PropTypes.string.isRequired,
  relatedProducts: PropTypes.array.isRequired,
};

export default SuggestedProductsCarousel;
