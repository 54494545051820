import * as React from 'react';

import { DispensaryContext } from './DispensaryProvider';
import { DeliveryOptionsContext } from '../providers/DeliveryOptionsProvider';
import { UserContext } from '../providers/UserProvider';

/**
 * Returns the minimum order (cents) by user zip code (or the default min order)
 */
const useMinOrderForLocation = () => {

  const { currentLocation } = React.useContext(UserContext);
  const { minOrderByZipCode } = React.useContext(DispensaryContext);
  const { minOrderDefaultCents } = React.useContext(DeliveryOptionsContext);
  
  const [minOrderCents, setMinOrderCents] = React.useState();
  const [isDefaultMinOrder, setIsDefaultMinOrder] = React.useState();

  React.useEffect(() => {
    if (minOrderByZipCode && 
        currentLocation?.zip_code &&
        minOrderDefaultCents) {
      const minOrderCents = minOrderByZipCode[currentLocation?.zip_code];
      if (minOrderCents) {
        setMinOrderCents(minOrderCents);
        setIsDefaultMinOrder(false);
      } else {
        setMinOrderCents(minOrderDefaultCents);
        setIsDefaultMinOrder(true);
      }
    }  
  }, [minOrderByZipCode, minOrderDefaultCents, currentLocation]);

  return ({
    currentLocation,
    minOrderCents,
    isDefaultMinOrder,
  });
};

export default useMinOrderForLocation;
