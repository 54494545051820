// detect mobile safari for end-of-scroll hacks, etc.
export const isMobile = /iPad|iPhone|iPod/.test(navigator.userAgent);

export const addMobileScrollHandler = (scrollHandler) => {
  if (isMobile) {
    document.addEventListener('touchstart', scrollHandler);
    document.addEventListener('touchmove', scrollHandler);
    document.addEventListener('touchend', scrollHandler);
  }
};

export const removeMobileScrollHandler = (scrollHandler) => {
  if (isMobile) {
    document.removeEventListener('touchstart', scrollHandler);
    document.removeEventListener('touchmove', scrollHandler);
    document.removeEventListener('touchend', scrollHandler);  
  }
};
