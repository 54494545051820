import * as React from 'react';

import { DispensaryContext } from './DispensaryProvider';
import { daysOfWeek } from '../util/date-utils';
import { formattedHours } from './StoreInfo';
import { Grid } from '@material-ui/core';
import Header, { HeaderSize } from '../styleguide/Header';
import PaymentMethods from '../payment/PaymentMethods';
import PropTypes from 'prop-types';

import styles from './StoreInfo.module.css';

/**
 * This component displays store hours, payment methods, location etc.
 **/
const StoreInfoSidebar = ({hours_of_operation}) => {

  const { getTodaysBusinessHours } = React.useContext(DispensaryContext);
  // [ start_hour, end_hour ]
  const businessHours = getTodaysBusinessHours() || [0,0];
  const currentHour = new Date().getHours();

  const getTodaysHours = () => {
    const dayOfWeek = daysOfWeek[new Date().getUTCDay()];
    const today = hours_of_operation.find(day => day.weekday === dayOfWeek);
    return formattedHours(today);
  }

  const Pill = ({text, withStyle={}}) => {
    return (
      <span className={styles.pill} style={withStyle}>
        {text}
      </span>
    );
  };

  const InfoItem = ({title, content, icon}) => {
    return (
      <div className={styles.infoItem}>
        <Grid container justifyContent='center' spacing={2}>
          <Grid item xs={2}>{icon}</Grid>
          <Grid item xs={10}>
            <div className={styles.gridText}>
              <div className={styles.gridInfoHdr}>{title}</div>
              <div>{content}</div>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }

  return (
    <div className={styles.sideBarCard}>
      { hours_of_operation?.length > 0
        ? <>
            <Header size={HeaderSize.Small} text="Today" withClass={styles.infoHdr}>
              { currentHour >= businessHours[0] &&
                currentHour < businessHours[1]
                  ? <Pill text="OPEN" withStyle={{color:'#fff', backgroundColor:'var(--zrPrimaryDark)'}} />
                  : null
              }
            </Header>

            <Grid container>
              <Grid item xs={4}>Hours:</Grid>
              <Grid item xs={8}>{getTodaysHours()}</Grid>
            </Grid>

            <Header size={HeaderSize.Small} withStyle={{marginTop:'2rem'}} text="Store Info" />

            <InfoItem title="PAYMENT"
                      icon={<img alt="" src="/img/localAtmIcon.png" style={{height:40}} />}
                      content={<PaymentMethods />} />
            <InfoItem title="MENUS"
                      icon={<img alt="" src="/img/computerIcon.png" style={{height:40}} />}
                      content="Recreational" />
          </>
        : null
      }
    </div>
  );
};

StoreInfoSidebar.propTypes = {
  hours_of_operation: PropTypes.array.isRequired
}

export default StoreInfoSidebar;
