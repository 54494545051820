import * as React from 'react';

import { auth } from '../../firebase';
import { onAuthStateChanged, applyActionCode } from 'firebase/auth';
import { navigateTop } from '../routing/router-utils';
import { makeStyles } from '@material-ui/core/styles';
import { SIGN_IN_TEXT } from './SignIn';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import DisplayModal from '../common/DisplayModal';
import SignIn from './SignIn';
import Header, { HeaderSize } from '../styleguide/Header';
import Button from '../styleguide/Button';
import SuccessMessage from '../styleguide/SuccessMessage';
import ErrorMessage from '../styleguide/ErrorMessage';
import PageTitle from '../common/PageTitle';
import PropTypes from 'prop-types';

/* Not enough CSS to warrant a module */
const useStyles = makeStyles({
  contentWrapper: {
    padding:'4rem 0', 
    textAlign:'center', 
    maxWidth: '600px', 
    margin:'0 auto'
  },
  textBlock: {
    fontSize:'1.125rem', 
    lineHeight:'150%', 
    margin:'1rem 0'
  }
});

/** 
 * Page used for "verify email" link in Firebase verify email template 
 **/
const EmailVerified = ({code /* oobCode from email link */}) => {
  
  const classes= useStyles();

  // Link back to the app
  const suggestedPage = '/';
 
  // Handle Firebase verify error ( code expired, etc.)
  const [verifyError, setVerifyError] = React.useState();

  // In case user we need the user to log in to continue
  const [showLoginModal, setShowLoginModal] = React.useState();
  const [isAuthenticated, setIsAuthenticated] = React.useState(); 

  // Handle resending  email
  const [emailSent, setEmailSent] = React.useState();
  const [sendError, setSendError] = React.useState();

  const sendEmailVerification = () => {
    setEmailSent(false);
    setSendError('');
    try {
      auth.currentUser.sendEmailVerification();
      window.setTimeout(() => {
        setEmailSent(true);
      }, 500);
    } catch(e) {
      window.setTimeout(() => {
        setSendError('Error sending verification email');
      }, 500);
    }            
  }

  /**
   * Pass code to Firebase
   * This can only happen once or: Error, Code Expired 
   */
  React.useEffect(() => {
    applyActionCode(auth, code).then((resp) => {
      // No need to do anything
    }).catch((error) => {
      setVerifyError(error.message);
    });    
  }, [code]);
    
  // Monitor authentication state
  React.useEffect(() => {  
    /* eslint-disable no-unused-vars */
    let stateChangeHandler = onAuthStateChanged(auth, () => {
      if (auth && !auth.currentUser?.isAnonymous) {
        setIsAuthenticated(true);
      }
    });
    return () => { stateChangeHandler = undefined };  
  }, [isAuthenticated])

  return (
    <>
      <PageTitle title="Email Verification" />
    
      <BodyWrapper pageLayout={LayoutTypes.Narrow}>
        <div className={classes.contentWrapper}>
          { verifyError
            ? <>
                <Header size={HeaderSize.Large} isCentered text="Email Verification" />
                <div className={classes.textBlock}>
                  <ErrorMessage text={verifyError} />
                </div>
                <div  className={classes.textBlock}>
                  <Button text="Resend Verification Email" isCentered handleClick={sendEmailVerification} />
                  { sendError &&
                    <div> 
                      <ErrorMessage text={sendError} />
                    </div>
                  }
                  { emailSent &&
                    <div> 
                      <SuccessMessage text="Email sent!" />
                    </div>
                  }
                </div>
              </>
            : <>
                <Header size={HeaderSize.Large} isCentered text="Thanks for confirming your email!" />
                { isAuthenticated
                  ? <Button text="Shop Cannabis Products" isCentered withStyle={{marginTop:'2rem'}} handleClick={() => navigateTop(suggestedPage)} />
                  : <Button text={SIGN_IN_TEXT} isCentered withStyle={{marginTop:'2rem'}} handleClick={() => setShowLoginModal(true)} />
                }
              </>
          }    
        </div> 
      </BodyWrapper>
      {/* Modal for Log In if needed */}
      { showLoginModal && 
        <DisplayModal title={SIGN_IN_TEXT} closeFn={() => setShowLoginModal(false)}>
          <SignIn withHeader={false} closeFn={() => setShowLoginModal(false)} 
            navigateTo={suggestedPage} />
        </DisplayModal>
      }
    </>
  );
};

EmailVerified.propTypes = {
  code: PropTypes.string.isRequired
}

export default EmailVerified;
