import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import { ModalType } from '../modal/ModalProvider';
import DisplayModal from '../common/DisplayModal';
import { FilteredProductsContext } from '../products/FilteredProductsProvider';
import SubCategoryFilter from './SubCategoryFilter';
import WeightFilter from './WeightFilter';
import BrandFilter from './BrandFilter';
import TypeFilter from './TypeFilter';
import FlowerTypeFilter from './FlowerTypeFilter';
import PricingFilter from './PricingFilter';
import MoodFilter from './MoodFilter';
import SortFilter from './SortFilter';
import ActiveFilterPills from './ActiveFilterPills';
import Button from '../styleguide/Button';
import BasicButton from '../styleguide/BasicButton';

import FilterIcon from '../../assets/filterIcon2White.png';

import styles from './SubCategoryFilterModal.module.css';

/**
 * This component will display product filters in a modal
 */ 
const SubCategoryFilterModal = ({
  modalProps,
  displayModal
}) => {
  
  const { onSubCategoryClick, trackContext } = modalProps;
  const { trackEvent } = useAnalyticsStore();

  const { categoryName, filteredProducts, resetFilters } = React.useContext(FilteredProductsContext);

  const closeFilters = () => {
    displayModal(ModalType.NONE);
    trackEvent('filter_modal_apply_filters');
  };

  return (
    <DisplayModal title="Product Filter"
                  trackContext={trackContext}>
      <div className={styles.filterWrap}>
        <div className={styles.hdr}>
          <img className={styles.hdrIcon} alt="" height="24" src={FilterIcon} />
          Filter: {categoryName}
        </div> 
        <div className={styles.scrollingContent}>
          <SubCategoryFilter onSubCategoryClick={onSubCategoryClick} startOpen inModal />
          <MoodFilter startOpen inModal />
          <TypeFilter startOpen inModal />          
          <FlowerTypeFilter startOpen inModal />
          <PricingFilter startOpen inModal />
          <SortFilter startOpen inModal />
          <WeightFilter startOpen inModal />
          <BrandFilter startOpen inModal />
          <div className={styles.resetWrap}>
            <div className={styles.activePills}>
              <ActiveFilterPills />
            </div>
            <BasicButton text="Reset All Filters" handleClick={
              () => { 
                resetFilters();
                trackEvent('filter_modal_reset_filters');
              } 
            } />
          </div>
        </div>       
        <div className={styles.buttonWrap}>
          <Button text={`View ${filteredProducts.length} Product(s)`} handleClick={closeFilters} />
        </div>
      </div>
    </DisplayModal>  
  );
}

export default SubCategoryFilterModal;
