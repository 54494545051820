import * as React from 'react';

import config from '../../config';
import { AttributionContext } from '../providers/AttributionProvider';
import { UserContext } from '../providers/UserProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { OrderPricingContext } from '../providers/OrderPricingProvider';
import { DiscountCreditAndFeeContext } from '../providers/DiscountCreditAndFeeProvider';
import { dollarDisplay } from '../providers/OrderPricingProvider';
import Button from '../styleguide/BasicButton';
import ErrorMessage from '../styleguide/ErrorMessage';
import Alert from '@material-ui/lab/Alert';
import BulkCartWarning from '../bundles/BulkCartWarning';
import SuperDealCartWarning from '../deals/SuperDealCartWarning';
import CurrentCodesTooltip from './CurrentCodesTooltip';
import SuccessMessage from '../styleguide/SuccessMessage';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import Warning from '../styleguide/Warning';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  label: {
    flex: 0,
    whiteSpace:'nowrap',
    textAlign: 'right',
    marginBottom: '8px !important'
  },
  input: {
    flex: 0,
    margin: '0 .5rem 0 0',
    '& input': {
      fontWeight: 600,
      width: 197,
      padding: '7px 8px',
      fontSize: '1.125rem',
      border: '1px solid #ccc',
      borderRadius: 4,
      backgroundColor: '#62b0f411'
    }
  },
  button: {
    flex: 0
  },
  removeDiscount: {
    cursor: 'pointer',
    color: '#850000',
    margin: '0 12px',
    fontSize: '.875rem',
    lineHeight: '100%',
    padding: '2px 8px 4px',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  alert: {
    padding: '0 12px',
    margin: '12px 0'
  }
});

// Remove code
const removeIconStyle = {
  verticalAlign: -5,
  fontSize: 20,
  color: '#850000',
  margin: '0 2px'
};

/**
 * Validate Discount Code and return discount details
 */
const EnterDiscountCode = ({
  setSubmitError,   // Clear submit errors
}) => {

  const classes = useStyles();

  const { user } = React.useContext(UserContext);
  const { itemTotal, cartItems, bulkItems } = React.useContext(CartItemsContext);
  const { siteLinkDiscountCode } = React.useContext(AttributionContext);

  // Shared context
  const {
    discountCode,
    addDiscountCode,
    removeDiscountCode,
    discountCodeValue,
    activeCredit,
  } = React.useContext(DiscountCreditAndFeeContext);
  // Checkout-only context
  const {
    minOrderOrDiscountMinCents,
    minOrderOrDiscountMinDisplay
  } = React.useContext(OrderPricingContext);

  const [error, setError] = React.useState();

  const [inputVal, setInputVal] = React.useState('');

  // codeFromContext is a code via site link: ?zrcode=FOO20
  const validateDiscount = React.useCallback((codeFromContext) => {
    if (user && (inputVal || codeFromContext)) {
      const code = (inputVal || codeFromContext).toLocaleUpperCase();

      const controller = new AbortController();
      user.getIdToken().then(idToken => {
        setError(null);
        fetch(`${config.REACT_APP_API_DOMAIN}/api/v1/order/discount_code/value?discount_code=${code}`,
          {
            method: 'GET',
            headers: {
              'Authorization': idToken
            },
            Accept: 'application/json',
            signal: controller.signal
          })
        .then(response => response.json())
        .then(discountObj => {
          if (discountObj.code) {
            addDiscountCode(discountObj);
          } else if (discountObj.error) {
            setError(discountObj.error);
          }
        })
        .catch(err => {
          setError(`Error: ${err}`);
        })
      })
    }
  }, [user, inputVal, addDiscountCode]);

  // Attempt to use discount code from site url
  const siteLinkCodeAttemptRef = React.useRef();
  React.useEffect(() => {
    if (siteLinkDiscountCode && !siteLinkCodeAttemptRef.current) {
      validateDiscount(siteLinkDiscountCode);
      siteLinkCodeAttemptRef.current = true;
    }
  }, [validateDiscount, siteLinkDiscountCode]);

  // This is for displaying the shortfall message when discount codes are used.
  const [orderShortfall, setOrderShortfall] = React.useState(0);
  React.useEffect(() => {
    if (discountCodeValue && itemTotal && minOrderOrDiscountMinCents &&
        (minOrderOrDiscountMinCents > itemTotal - discountCodeValue)) {
      setOrderShortfall(minOrderOrDiscountMinCents - (itemTotal - discountCodeValue));
    } else {
      setOrderShortfall(0);
    }
  }, [minOrderOrDiscountMinCents, itemTotal, discountCodeValue]);

  return (
    <>
      { discountCode
        ? <div>
            <SuccessMessage text={`${discountCode} discount applied.`} withStyle={{marginTop:0, padding:0}} />
            <span className={classes.removeDiscount} onClick={() => {
              removeDiscountCode();
              setSubmitError(undefined);
            }}>
              Remove
              <HighlightOffRoundedIcon style={removeIconStyle} />
            </span>
            { orderShortfall > 0 &&
              <Alert className={classes.alert} severity="warning">
                Discount Code {discountCode} requires a minimum order (Subtotal)
                of {minOrderOrDiscountMinDisplay} after the discount is applied.
                Your order is {dollarDisplay(orderShortfall)} below
                the required minimum.
              </Alert>
            }
         </div>
        : <div className={classes.wrapper}>
            <label htmlFor="code" className={classes.label}>
              Have a Promo Code? Apply it here:
            </label>
            <div className={classes.input}>
              <input type="text" id="code" onChange={(event) => setInputVal(event.target.value.trim())} />
            </div>
            <div className={classes.button}>
              <Button
                text='Apply Code'
                withStyle={{padding:'6px 8px', minHeight: 37}}
                handleClick={validateDiscount} />
            </div>
            { activeCredit &&
              <Warning severity='info'>
                NOTE: <b>Promo Codes</b> and your <b>Zyp Run Credit</b> cannot be combined, so choose
                the option that works best for you.
              </Warning>
            }
            <CurrentCodesTooltip />
          </div>
      }
      <BulkCartWarning
        discountCode={discountCode}
        bulkItems={bulkItems}
        withClass={classes.alert} />
      <SuperDealCartWarning
        discountCode={discountCode}
        itemTotal={itemTotal}
        cartItems={cartItems}
        withClass={classes.alert} />
      { error &&
        <ErrorMessage text={error} withStyle={{marginBottom:0}}/>
      }
    </>
  );
}

EnterDiscountCode.propTypes = {
  setSubmitError: PropTypes.func.isRequired
}

export default EnterDiscountCode;
