import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { DiscountCreditAndFeeContext } from '../providers/DiscountCreditAndFeeProvider';
import { dollarDisplay } from '../providers/OrderPricingProvider';
import { discountedItemTotalCents } from '../products/product-utils';
import { useAnalyticsStore } from '../../App';
import useCartItemBundledPricingMap from '../bundles/useCartItemBundlePricingMap';
import useAddlContextTracking, { TRACK_CONTEXT } from '../analytics/useAddlContextTracking';
import useSaveCartItems from './useSaveCartItems';
import { SHOPPING_MILESTONES } from '../analytics/analytics-utils';
import { CommerceLoggingContext } from '../analytics/CommerceLoggingProvider';
import PropTypes from 'prop-types';

export const CartItemsContext = React.createContext();

/**
 * Calc the Item total for carted product (original price or sale price/bundled price)
 *
 * NOTE: This is the definitive price calc for the order-related pricing
 * of carted items.
 */
export const cartItemTotalForQuantity = (item, quantity, bundlePricingMap, nonCannabisOnly) => {
  // Related to lower taxes on non-cannabis items/accessories
  if (nonCannabisOnly && item.is_cannabis) {
    return 0;
  }
  // Bundle quantity price trumps ALL
  if (bundlePricingMap && item.bundle_deal_data?.values) {
    const bundlePriceCents = bundlePricingMap.get(item.bundle_deal_data.id);
    if (bundlePriceCents) {
      return bundlePriceCents * quantity;
    }
  }
  // Check for Sale Price next
  const discountedItemTotal = discountedItemTotalCents(item, quantity);
  if (discountedItemTotal) {
    return discountedItemTotal;
  } else {
    // Return regular price
    return item.display_info.cost_usa_cents * quantity;
  }
};

const CartItemsProvider = ({children}) => {

  const { user } = React.useContext(UserContext);
  const { discountDto, windowDiscountCents, aeropayCreditCents } = React.useContext(DiscountCreditAndFeeContext) || {};
  const { trackEvent } = useAnalyticsStore();
  const { trackEventWithContext } = useAddlContextTracking();

  // Commerce Logging
  const { logCommerceAction } = React.useContext(CommerceLoggingContext);
  // Restore/Save Cart - Registered users only
  const { savedCartItems, availableSavedItems, unavailableSavedItems,
          loading:fetchingSavedCart, setSavedCartItems, saveCart } = useSaveCartItems(user);

  // Carted Items
  const cartItems = React.useRef([]);
  const cartItemCount = React.useRef(0);
  const [itemTotal, setItemTotal] = React.useState(0);
  const [nonCannabisItemTotal, setNonCannabisItemTotal] = React.useState(0);
  const [itemDiscountTotal, setItemDiscountTotal] = React.useState(0);
  const [flowerGrams, setFlowerGrams] = React.useState(0);
  const [bulkItems, setBulkItems] = React.useState([]);
  // The current item price for multti-product bundled items
  const bundlePricingMap = useCartItemBundledPricingMap(cartItems.current, itemTotal);

  const updateItemTotal = React.useCallback(() => {

    const items = cartItems.current;

    const itemTotalRegularPrice = items.map(item =>
      item.display_info.cost_usa_cents)
        .reduce((a,b) => a + b, 0);

    const itemTotalCents = items.map(item =>
      cartItemTotalForQuantity(item, item.quantity, bundlePricingMap))
        .reduce((a, b) => {
          return a + b;
        }, 0);

    const nonCannabisItemTotalCents = items.map(item =>
      cartItemTotalForQuantity(item, item.quantity, bundlePricingMap, true))
        .reduce((a, b) => {
          return a + b;
        }, 0);

    const itemDiscountTotalCents = itemTotalRegularPrice - itemTotalCents;

    const itemCount = items.map(item => item.quantity)
                           .reduce((a, b) => a + b, 0);

    const flowerEquivalentGrams = items.reduce((total, item) => {
      return total + (item.quantity * (item.flower_equivalent_grams || 0))
    }, 0);

    return {
      itemTotalCents,
      nonCannabisItemTotalCents,
      itemDiscountTotalCents,
      itemCount,
      flowerEquivalentGrams
    };
  }, [bundlePricingMap]);

  /**
   * THIS CALLBACK IS THE PRIMARY RERENDER TRIGGER FOR CONSUMERS
   * as the cartItems are a Ref and itemTotal .etc are state updates
   */
  const updateCartDetails = React.useCallback((cartItems, isCartRestore) => {
    const {
      itemTotalCents,
      nonCannabisItemTotalCents,
      itemDiscountTotalCents,
      itemCount,
      flowerEquivalentGrams } = updateItemTotal();
    cartItemCount.current = itemCount;
    setItemTotal(itemTotalCents);
    setNonCannabisItemTotal(nonCannabisItemTotalCents);
    setItemDiscountTotal(itemDiscountTotalCents);
    setFlowerGrams(flowerEquivalentGrams);
    setBulkItems(cartItems.filter(item => bundlePricingMap?.has(item.bundle_deal_data?.id)));
    if(!isCartRestore) {
      // Save cart to API - but not during cart restore
      saveCart(cartItems);
    }
  }, [updateItemTotal, saveCart, bundlePricingMap]);

  // For use after placing order
  const emptyCart = () => {
    cartItems.current = [];
    updateCartDetails([]);
  };

  // RECALC DEAL PRICING on changes to discounts/credits
  React.useEffect(() => {
    updateCartDetails(cartItems.current, true);
  }, [discountDto, windowDiscountCents, aeropayCreditCents, updateCartDetails]);

  // Add Item or increase quantity of existing carted item
  // TODO: We don't need to pass the item (we can use getCachedItem... from the provider)
  const addItem = React.useCallback((id, item, quantity, isCartRestore) => {
    const previouslyCarted = cartItems.current.find(item => item.id === id);
    const newCart = [...cartItems.current];
    if ( previouslyCarted ) {
      previouslyCarted.quantity += quantity;
    } else {
      const itemCopy = Object.assign({}, item);
      itemCopy.quantity = quantity || 1;
      newCart.push(itemCopy);
      cartItems.current = newCart;
    }
    updateCartDetails(newCart, isCartRestore);
    if (!isCartRestore) {
      trackEventWithContext('cart_add_item', [TRACK_CONTEXT.PARTNER, TRACK_CONTEXT.SESSION_LENGTH]);
      logCommerceAction(SHOPPING_MILESTONES.ITEM_CARTED);
    }
  }, [trackEventWithContext, logCommerceAction, updateCartDetails]);

  // Add Item or increase quantity of existing carted item
  const removeItem = (id) => {
    const previouslyCarted = cartItems.current.find(item => item.id === id);
    if ( previouslyCarted ) {
       const newCart = cartItems.current.filter(item => item.id !== id);
       cartItems.current = newCart;
       updateCartDetails(newCart);
    }
    trackEvent('cart_remove_item');
  };

  // Increase quantity of an existing carted item
  const increaseQuantity = (id, quantity) => {
    const previouslyCarted = cartItems.current.find(item => item.id === id);
    if ( previouslyCarted ) {
      previouslyCarted.quantity += quantity;
    }
    updateCartDetails(cartItems.current);
    trackEvent('cart_increase_qty');
  };

  // Increase quantity of an existing carted item
  const decreaseQuantity = (id, quantity) => {
    const previouslyCarted = cartItems.current.find(item => item.id === id);
    if ( previouslyCarted ) {
      if ( previouslyCarted.quantity <= quantity) {
        removeItem(id);
      } else {
        previouslyCarted.quantity -= quantity;
        updateCartDetails(cartItems.current);
      }
    }
    trackEvent('cart_decrease_qty');
  };

  /**
   * Registered Users: Restore cart from API
   * But only if we have saved cart items and the local cart is empty
   */
  React.useEffect(() => {
    if (savedCartItems?.length && !cartItems.current.length) {
      savedCartItems.forEach(item => addItem(item.id, item, item.quantity, true /* isCartRestore */));
      setSavedCartItems([]);
    }
  },[savedCartItems, addItem, setSavedCartItems]);

  return <CartItemsContext.Provider value={{
      cartItems: cartItems.current,
      cartItemCount: cartItemCount.current, /* a rerender trigger */
      availableSavedItems,
      unavailableSavedItems,
      fetchingSavedCart,
      bulkItems, // Used to prevent discount code usage
      bundlePricingMap,
      isEmptyCart: cartItems.current.length === 0,
      itemTotal,
      itemTotalDisplay: dollarDisplay(itemTotal),
      nonCannabisItemTotal,
      itemDiscountTotal,
      itemDiscountTotalDisplay: dollarDisplay(itemDiscountTotal),
      flowerGrams,
      addItem,
      removeItem,
      increaseQuantity,
      decreaseQuantity,
      emptyCart
    }}>
    {children}
  </CartItemsContext.Provider>
}

CartItemsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default CartItemsProvider;
