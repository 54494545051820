import * as React from 'react';

import config from '../../config';
import { UserContext } from '../providers/UserProvider';
import { DeliveryOptionsContext } from '../providers/DeliveryOptionsProvider';
import useBankAccountId from './useBankAccountId';
import PropTypes from 'prop-types';

export const AeropayContext = React.createContext({});

/**
 * Aeropay Provider
 * 
 * The user should be in 1 of 2 states:
 *   1) Previously linked via ZypRun or another retailer (in which case they're good to purchase)
 *   2) They need to link via the widget. In this case we will keep the bank details in state
 *      to submit with the order
 */
const AeropayProvider = ({children}) => {
 
  // Current user to fetch bank account linking details for
  const { user } = React.useContext(UserContext);
  // Used to check if Aeropay is enabled
  const { paymentMethods={} } = React.useContext(DeliveryOptionsContext);
  // Initial AeroPay linking tate from API
  const { bankAccountId, bankName, refetch } = useBankAccountId(user);
  // Track status of forced account linking (testing)
  const [forcedLinkingComplete, setForcedLinkingComplete] = React.useState();
  // The proxy bank account set by the AeroPay linking widget (requires refetch)
  const [linkedAccountId, setLinkedAccountId] = React.useState();
  // Display any API response account linking errors
  const [linkingError, setLinkingError] = React.useState(); 

  // Initial bank linking state
  React.useEffect(() => {
    // Only provide linked account when aeropay is enabled
    // ALSO: FORCE_BANK_LINKING will break normal flow (set Aeropay default, etc.) so
    // turn it off for end-to-end testing.
    if (config.AEROPAY_FORCE_BANK_LINKING && !forcedLinkingComplete) {
      // Ignore existing linkage!
    } else if (bankAccountId) {
      setLinkedAccountId(bankAccountId);
    } 
  },[bankAccountId, forcedLinkingComplete]);

  return <AeropayContext.Provider value={{
      aeropayEnabled: !!paymentMethods?.aeropay,
      linkedAccountId, /* the proxy bank account id */
      bankName: linkedAccountId ? bankName : undefined, /* support forced linking for testing */
      forceAccountLinking: config.AEROPAY_FORCE_BANK_LINKING,
      forcedLinkingComplete,
      setForcedLinkingComplete,
      refetch,
      linkingError,
      setLinkingError 
    }}>
    {children}
  </AeropayContext.Provider>
}

AeropayProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default AeropayProvider;
