import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { getFormattedAddress } from '../googlemaps/google-maps-utils';
import { formatPhoneNumber } from 'react-phone-number-input';
// import { getFormattedTimeLabel } from '../deliveryWindows/scheduled-delivery-utils'; 
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';

import PropTypes from 'prop-types';

import styles from './OrderConfirmation.module.css';

const DeliveryCustomerDetails = ({
  order={}
}) => {  
  
  const { userName, location, userPhone } = React.useContext(UserContext);

  // const { fulfillment_time_slot } = order;
  // const timeslot = fulfillment_time_slot.hours;

  let intlNumber = userPhone; 
  if (intlNumber && !intlNumber.startsWith('+')) {
    intlNumber = `+${intlNumber}`;   
  }  
  
  const formattedPhoneNumber = intlNumber ? formatPhoneNumber(intlNumber) : '';
  const formattedAddress = getFormattedAddress(location);

  return ( location?.state 
    ? <div className={styles.dispensaryInfo}> 
        <div>
          {userName}  
        </div>
        <div>{formattedAddress}</div>
        <div>
          <a className={styles.telLink} href={`tel:${intlNumber}`}>
            <span className={styles.mobileCall}>
              <PhoneRoundedIcon fontSize='inherit' /> 
            </span>
            {formattedPhoneNumber}
          </a>
        </div>
      </div>
    : null
  )    
}

DeliveryCustomerDetails.propTypes = {
  order: PropTypes.object    /* for pickup */
}

export default DeliveryCustomerDetails;
