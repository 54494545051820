import * as React from "react";

import Alert from "@material-ui/lab/Alert";
import PropTypes from 'prop-types';

const BulkCartWarning = ({
  discountCode,
  bulkItems,
  withClass}) => {

  // Consolidate Mix & Match deal item data
  const [dealData,setDealData] = React.useState();

  React.useEffect(() => {
    let dealIdMap = new Map();
    if (bulkItems?.length) {
      dealIdMap = bulkItems.reduce((map, item) => {
        const dealEntry = map.get(item.bundle_deal_data.id);
        map.set(item.bundle_deal_data.id,
          dealEntry
            ? {
                quantity: item.quantity +  dealEntry.quantity,
                minimum: item.bundle_deal_data.minimum_quantity,
              }
            : {
                quantity: item.quantity,
                minimum: item.bundle_deal_data.minimum_quantity,
              }
        );
        return map;
      }, new Map());
    }
    setDealData(dealIdMap);
  }, [bulkItems]);

  return ( discountCode && dealData?.size
    ? <Alert severity="warning" className={withClass ? withClass : ''}>
        <div>
          <strong>Discount Codes</strong> cannot be combined with <strong>bulk-pricing deals</strong>.
          To place your order, remove the discount code OR reduce the quantity
          of your bulk-priced item(s) to the maximum listed below:
        </div>
        <table style={{ marginTop:6, width: '100%'}}>
          <tbody>
            <tr>
              <th style={{textAlign:'left'}}>Bulk Deal</th>
              <th style={{textAlign:'center'}}>Your Cart</th>
              <th style={{textAlign:'center'}}>Max</th>
            </tr>
            { Array.from(dealData.entries()).map(([key, data]) => (
              <tr key={key}>
                <td style={{textAlign:'left'}}>{key}</td>
                <td style={{textAlign:'center'}}>{data.quantity}</td>
                <td style={{textAlign:'center'}}>{data.minimum - 1}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Alert>
    : null
  );
};

BulkCartWarning.propTypes = {
  discountCode: PropTypes.string,
  bulkItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    bundle_deal_data: PropTypes.object.isRequired,
  })),
  withClass: PropTypes.string,
}

export default BulkCartWarning;
