import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(props => ({
  icon: {
    padding: '3px 3px 4px;',
    '&:hover path:nth-of-type(2)': {
      fill: (props) => props.hoverFillColor
    }
  }
}));

/* Converted PNG to SVG using Adobe site */
const BackIcon = ({fillColor='var(--zrPrimaryVeryDark)', 
                   hoverFillColor='var(--zrPrimaryDark)', 
                   size='28px'}) => {
  const classes = useStyles({ hoverFillColor });
  
  return (
    <svg className={classes.icon}
         version="1.1" xmlns="http://www.w3.org/2000/svg"
	       width={size} viewBox="0 0 102 124">
      <path fill="transparent" opacity="1.000000" stroke="none" 
	      d="M57.000000,125.000000 
	      C38.000000,125.000000 19.500000,125.000000 1.000000,125.000000 
	      C1.000000,83.666664 1.000000,42.333332 1.000000,1.000000 
	      C35.000000,1.000000 69.000000,1.000000 103.000000,1.000000 
	      C103.000000,42.333332 103.000000,83.666664 103.000000,125.000000 
	      C87.833336,125.000000 72.666664,125.000000 57.000000,125.000000 
        M72.896324,118.630211 
	      C95.917534,105.994797 104.885124,84.697289 99.676201,60.648277 
	      C97.976143,52.799324 93.605476,44.941162 88.615219,38.517349 
	      C78.844666,25.939983 65.257187,19.379011 48.836800,18.704710 
	      C48.836800,13.643799 48.836800,8.894796 48.836800,2.548276 
	      C32.849495,11.681516 17.824520,20.264996 1.698749,29.477339 
	      C18.096016,38.977764 33.180962,47.717838 49.180733,56.987957 
	      C49.180733,50.849869 49.180733,45.958126 49.180733,40.949207 
	      C66.186852,40.356373 77.906967,47.646152 85.012527,62.410828 
	      C88.525627,69.710701 89.498856,77.209801 88.166351,85.462791 
	      C85.530609,101.787682 71.887192,115.812386 56.043854,118.889473 
	      C49.987732,120.065689 43.686028,119.977455 37.496239,120.465439 
	      C40.823128,121.727180 44.328812,122.592934 47.885731,122.954132 
	      C56.313324,123.809937 64.350204,121.920197 72.896324,118.630211 
        z"/>
      <path fill={fillColor} opacity="1.000000" stroke="none" 
	      d="M72.541367,118.802513 
	      C64.350204,121.920197 56.313324,123.809937 47.885731,122.954132 
	      C44.328812,122.592934 40.823128,121.727180 37.496239,120.465439 
	      C43.686028,119.977455 49.987732,120.065689 56.043854,118.889473 
	      C71.887192,115.812386 85.530609,101.787682 88.166351,85.462791 
	      C89.498856,77.209801 88.525627,69.710701 85.012527,62.410828 
	      C77.906967,47.646152 66.186852,40.356373 49.180733,40.949207 
	      C49.180733,45.958126 49.180733,50.849869 49.180733,56.987957 
	      C33.180962,47.717838 18.096016,38.977764 1.698749,29.477339 
	      C17.824520,20.264996 32.849495,11.681516 48.836800,2.548276 
	      C48.836800,8.894796 48.836800,13.643799 48.836800,18.704710 
	      C65.257187,19.379011 78.844666,25.939983 88.615219,38.517349 
	      C93.605476,44.941162 97.976143,52.799324 99.676201,60.648277 
	      C104.885124,84.697289 95.917534,105.994797 72.541367,118.802513 
        z"/>
    </svg>
  )
}

export default BackIcon;
