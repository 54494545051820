import * as React from 'react';

import DisplayModal from '../common/DisplayModal';
import EnterVerificationCode from '../registration/EnterVerificationCode';
import Alert from '@material-ui/lab/Alert';
import MobileOnlyDisplay from '../common/MobileOnlyDisplay';
import Spinner from '../common/Spinner';
import PropTypes from 'prop-types';

const VerifyPhoneModal = ({ modalProps }) => {
  const { phoneNumber, onVerified, trackContext=""} = modalProps;
  return (
    <DisplayModal 
      title="Verify Phone Number" 
      trackContext={trackContext}>
      { phoneNumber  
        ? <>
            <EnterVerificationCode
              phoneNumber={phoneNumber}
              onVerified={onVerified} />
            <MobileOnlyDisplay> 
              <Alert severity="info" style={{margin: '1.5rem 0 .5rem', fontSize:'13px'}}>
                <b>iPhone Tip:</b> Tap the empty field above to enable copying your code <b>From Messages</b>
              </Alert>
            </MobileOnlyDisplay>   
          </>  
        : <Spinner inSmallLayout />  
      }    
    </DisplayModal>
  );
};

VerifyPhoneModal.propTypes = {
  modalProps: PropTypes.object.isRequired 
}

export default VerifyPhoneModal;
