import * as React from 'react';

import { navigateTop } from '../routing/router-utils';

import { DispensaryContext } from '../dispensary/DispensaryProvider';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import RegistrationSuccessMsg from './RegistrationSuccessMsg';
import PageTitle from '../common/PageTitle';

// Note: Success logging currently happens in ProcessRegistrationInline
const RegistrationComplete = ({continuePath}) => {
  // Return to page before Sign Up flow: Checkout etc.  
  const onContinue = React.useCallback(() => {
    navigateTop(continuePath || '/');
  }, [continuePath]);

  // Did the user sign up after clicking "Check Out"?
  const { isCheckingOut } = React.useContext(DispensaryContext);

  return (
    <BodyWrapper pageLayout={LayoutTypes.Default}>
      <PageTitle title="Registration Complete" />
      <RegistrationSuccessMsg
        isCheckout={isCheckingOut}
        onContinue={onContinue} />
    </BodyWrapper>
  );
};

export default RegistrationComplete;
