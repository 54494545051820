import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { ModalType, ModalContext } from '../modal/ModalProvider';
import { getFormattedAddress } from '../googlemaps/google-maps-utils';
import CheckOutButton from './CheckOutButton';
import DeliveryBlacklistMessage from '../address/DeliveryBlacklistMessage';
import QuantityWarning from './QuantityWarning';
import ErrorMessage from '../styleguide/ErrorMessage'; 
import PropTypes from 'prop-types';

import styles from './Cart.module.css';

import ArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

const CartHeaderDelivery = ({
  flowerGrams=0,
  closeMenuFn
}) => {

  const { currentLocation:userLocation, hasNoLocation } = React.useContext(UserContext);

  // display address update modal on pre-registered user/temp address click
  const { displayModal } = React.useContext(ModalContext);

  const arrowStyle = {
    verticalAlign: -4,
    fontSize: 16,
    marginLeft: -4
  };

  return (
    <div className={styles.cartHeader}>
      { userLocation
        ? <>
            <div className={styles.cartDetails}>
              <div className={styles.serviceDetails}>
                <div>DELIVERY TO:</div>
                <div className={styles.serviceAddress}>{getFormattedAddress(userLocation)}</div>  
              </div>
            </div>
            <CheckOutButton closeMenuFn={closeMenuFn} />
            <QuantityWarning gramsInCart={flowerGrams} />
            <DeliveryBlacklistMessage location={userLocation} />
          </>
        : hasNoLocation
          ? <ErrorMessage>
              <div style={{cursor:'pointer'}} 
                   onClick={() => displayModal(ModalType.ADDRESS)}>
                Please provide your <b>Delivery Address</b>
                {' '}<ArrowDownRoundedIcon style={arrowStyle} />
              </div>
            </ErrorMessage>
          : <></>  
      }
    </div>
  )
}

CartHeaderDelivery.propTypes = {
  flowerGrams: PropTypes.number.isRequired,
  closeMenuFn: PropTypes.func.isRequired,
}            

export default CartHeaderDelivery;
