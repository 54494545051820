import * as React from 'react';

import { NotificationsContext } from '../notifications/NotificationsProvider';
import AccessTimeRoundedIcon from '@material-ui/icons/AccessTimeRounded';
import { makeStyles } from '@material-ui/core';

const StatusDisplay = {
  requested: 'Requested',
  accepted: 'Accepted', 
  'ready-for-pickup': 'Bagged',
  'in-transit': 'En Route',
};

export const StatusPercent = {
  requested: 10,
  accepted: 25, 
  'ready-for-pickup': 50,
  'in-transit': 75,
};

const useStyles = makeStyles(theme => ({
  statusPill: {
    '--color':'var(--zrPrimaryVeryDark)',
    '--size': '18px',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    top: -24,
    left: 24,
    padding: '3px 9px 3px 3px',
    border: '2px solid var(--color)',
    color: 'var(--color)',
    backgroundColor: 'var(--zrLightBackground)',
    font: 'var(--zrFontSemiItalic)',
    fontSize: '.875rem',
    lineHeight: '100%',
    whiteSpace: 'nowrap',
    borderRadius: 16,
    [theme.breakpoints.down('xs')]: {
      fontSize: '.75rem',  
    },  
  },
  pie: {
    '--pct': props => props.pct,
    width: 'var(--size)',
    aspectRatio: 1,
    margin: '0 4px 0 0',
    border: '1px solid var(--color)',
    borderRadius: '50%',
    backgroundColor: '#fff',
  },
  pieInner: {
    width: 'var(--size)',
    height: 'var(--size)',
    borderRadius: '50%',
    inset: 0,
    background: 'conic-gradient(var(--color) calc(var(--pct)*1%),#0000 0)',
  },
}));

// Clock icon
const iconStyle = {
  fontSize: '20px',
  marginRight: 4,
  backgroundColor: '#fff',
  borderRadius: '50%',
};

const OrderProgressPill = ({
  order, 
  orderStatus,
  orderEtaLabel
}) => {

  const classes = useStyles({pct:StatusPercent[orderStatus]||10});
  
  const [statusLabel,setStatusLabel] = React.useState();
  const [showClock, setShowClock] = React.useState();

  // Open 10am
  const { afterHoursLabel, storeIsClosed } = React.useContext(NotificationsContext);

  React.useEffect(() => {
    // If we have an ETA, the ditch progress pie and show clock with ETA label
    if (orderEtaLabel) {
      setStatusLabel(orderEtaLabel);
    } else {
      setStatusLabel(storeIsClosed
        ? afterHoursLabel
        : StatusDisplay[orderStatus] || orderStatus);
    }
    setShowClock(!!orderEtaLabel || storeIsClosed);
  }, [order, orderStatus, orderEtaLabel, afterHoursLabel, storeIsClosed]);

  return (
    order && statusLabel 
      ? <div className={classes.statusPill}>
          { showClock 
            ? <AccessTimeRoundedIcon style={iconStyle} />
            : <div className={classes.pie}>
                <div className={classes.pieInner}></div>
              </div>
          }    
          <span>{statusLabel}</span>
        </div>
      : null
  )
}

export default OrderProgressPill
