import React from 'react';

import { flattenedProduct } from '../products/product-utils';
import PropTypes from 'prop-types';

const CartControl = ({icon, handleClick, styleModule, isIncrease, isDisabled}) => {

  const ctrlClass = isIncrease ? styleModule.increase : styleModule.decrease;
  const disabledClass = isDisabled ? styleModule.disabledControl : '';
  const ctrlText = isIncrease ? "+" : "-";
  const altText = isDisabled
    ? "quantity limit reached"
    : isIncrease ? "increase quantity" : "decrease quantity";
  const onClick = isDisabled ? undefined : handleClick;

  return ( icon
    ? <span className={`${styleModule.control} ${ctrlClass} ${disabledClass}`}
            onClick={onClick}>
        <img alt={altText} src={icon} />
      </span>
    : <span className={`${styleModule.plainTextControl} ${ctrlClass} ${disabledClass}`}
            onClick={onClick}>
        {ctrlText}
      </span>
  )
}

// Because 1 > null = true, 1 > undefined = false
const nonNull = (value) => {
  return value === null
    ? undefined
    : value
}

const CartQtyControls = ({
  product,
  quantity,
  increaseIcon,
  increaseQuantity,
  decreaseIcon,
  decreaseQuantity,
  styleModule,
}) => {

  const { per_order_max_quantity } = flattenedProduct(product);

  return (
    <div className={styleModule.controls}>
      { per_order_max_quantity === 1
          ? null
          : <>
              { quantity >= nonNull(per_order_max_quantity)
                ? <CartControl icon={increaseIcon}
                               handleClick={increaseQuantity}
                               styleModule={styleModule}
                               isIncrease isDisabled />
                : <CartControl icon={increaseIcon}
                               handleClick={increaseQuantity}
                               styleModule={styleModule}
                               isIncrease />
              }
              <CartControl icon={decreaseIcon}
                           handleClick={decreaseQuantity}
                           styleModule={styleModule} />
            </>
      }
    </div>
  )
}

CartQtyControls.propTypes = {
  product: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  increaseIcon: PropTypes.node,
  increaseQuantity: PropTypes.func.isRequired,
  decreaseIcon: PropTypes.node,
  decreaseQuantity: PropTypes.func.isRequired,
  styleModule: PropTypes.any.isRequired,
};

export default CartQtyControls;
