import { useReducer } from "react";
import { subCategoryAll, SHOW_ALL } from "../products/FilteredProductsProvider";

// We can skip product filtering if no filters are set
export const inActiveFilterCount = (state) => {
  return Object.values(state).filter(value => value === SHOW_ALL).length;
}

/**
 * The state of the product filters
 */
const useProductFilterReducer = () => {
  
  const initialState = {
    subCategory: subCategoryAll,
    grams: SHOW_ALL,
    brand: SHOW_ALL,
    type: SHOW_ALL,
    flowerType: SHOW_ALL,
    pricing: SHOW_ALL,
    mood: SHOW_ALL,
    sorting: SHOW_ALL,
  }
  
  const productFilterReducer = (state, action) => {
    switch(action.type) {
      case 'subCategory':
        return {
          ...state,
          subCategory: action.value,
        };      
      case 'grams':
        return {
          ...state,
          grams: action.value,
        };
      case 'brand':
        return {
          ...state,
          brand: action.value,
        };
      case 'type':
        return {
          ...state,
          type: action.value,
        };  
      case 'flowerType':
        return {
          ...state,
          flowerType: action.value,
        };
      case 'pricing':
        return {
          ...state,
          pricing: action.value,
        };
      case 'mood':
        return {
          ...state,
          mood: action.value,
        };
      case 'sorting':
        return {
          ...state,
          sorting: action.value,
        };
      case 'resetFilters':
        return initialState;      
      default:
        return state;
    }
  }

  const [filterState={}, dispatch] = useReducer(productFilterReducer, initialState);
  
  return [filterState, dispatch];
}

export default useProductFilterReducer;
