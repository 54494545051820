import React from 'react';

import QuantityIcon from './QuantityIcon';
import { flattenedProduct } from '../products/product-utils';
import DisplayCartedPrice from '../products/DisplayCartedPrice';
import CartQtyControls from './CartQtyControls';
import CartProductImage from '../products/CartProductImage';
import CartItemAvailabilityCheck from './CartItemAvailabilityCheck';
import SpecialPricingLabel from './SpecialPricingLabel';
import PropTypes from 'prop-types';

import styles from './CartItem.module.css';

const CartItem = ({
  product,
  quantity,
  totalFlowerGrams,
  handleRemove,
  handleIncrease,
  handleDecrease
}) => {

  const {
    id,
    name,
    category,
    image_url,
    flower_equivalent_grams,
  } = flattenedProduct(product);

  return (
    <li className={styles.cartItem}>
      <div className={styles.itemDetails}>
        <span className={styles.itemCount}>x{quantity}</span>
        <span className={styles.itemTitle}>
          {name}
        </span>
        <span className={styles.itemPrice}>
          <DisplayCartedPrice
            product={product}
            quantity={quantity} />
          <SpecialPricingLabel
            product={product} />
        </span>
      </div>

      <div className={styles.itemRemoveWrap}>
        <span className={styles.itemRemove}
              style={{color: 'var(--zrPrimaryDark)'}}
              onClick={handleRemove}>
          Remove
        </span>
      </div>

      <div className={styles.imageWrap}>
        <CartProductImage imgUrl={image_url} imgAlt={name} imgClass={styles.itemImg} imgCategory={category?.display_name} />
        <QuantityIcon cartCount={quantity} productFlowerGrams={flower_equivalent_grams} totalFlowerGrams={totalFlowerGrams} />
        <CartQtyControls
          product={product}
          quantity={quantity}
          increaseQuantity={handleIncrease}
          decreaseQuantity={handleDecrease}
          styleModule={styles} />
      </div>
      <CartItemAvailabilityCheck Id={id} handleRemove={handleRemove} />
    </li>
  )
}

CartItem.propTypes = {
  product: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  totalFlowerGrams: PropTypes.number.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleIncrease: PropTypes.func.isRequired,
  handleDecrease: PropTypes.func.isRequired
};

export default CartItem;
