import { useReducer } from "react";
import { trackEvent } from "../analytics/tracking";

/**
 * A reducer is a better way to handle delivery order options
 * versus having numerous setState items.
 * We'll migrate timeslot, payment type, etc. to this reducer.
 * To start, we'll implement the delivery note feature
 */
const useOrderDetailsReducer = () => {
  
  const initialState = {
    customer_delivery_notes: undefined,
  }
  
  const deliveryDetailsReducer = (state, action) => {
    switch(action.type) {
      case 'deliveryNote':
        trackEvent('delivery_note_added');
        return {
          ...state,
          customer_delivery_notes: action.deliveryNote,
        };
      default:
        return state;
    }
  }

  // ensure state is defined!
  const [orderDetailsState={}, dispatch] = useReducer(deliveryDetailsReducer, initialState);
  
  return [orderDetailsState, dispatch];
}

export default useOrderDetailsReducer;
