import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { ModalType, ModalContext } from '../modal/ModalProvider';
import useSoldOutStatus from './useSoldOutStatus';
import Spinner from '../common/Spinner';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import PropTypes from 'prop-types';

import styles from './AddedToCart.module.css';

/**
 * This component renders the quickAdd product card functionality with quantity +/- icons
 *
 * @param {string} itemId - the product Id
 */

const InCartCheck = ({
  itemId,
  product,
  quantity_available,
  display_sold_out_status,
  handleCartClick}) => {

  const isSoldOut = useSoldOutStatus(
    quantity_available,
    display_sold_out_status,
  );

  // The currently carted items
  const { cartItems, cartItemCount, increaseQuantity, decreaseQuantity } = React.useContext(CartItemsContext);
  // display address update modal on pre-registered user/temp address click
  const { displayModal } = React.useContext(ModalContext);
  const { hasNoLocation } = React.useContext(UserContext);

  const [itemQuantity, setItemQuantity] = React.useState(0);

  // For products with Limit1 etc.
  const [limitReached, setLimitReached] = React.useState();
  const [showToolBar, setShowToolBar] = React.useState();
  const [showTransitionBar, setShowTransitionBar] = React.useState();

  const MOUSE_LEAVE_CLOSE_MS = 1000;

  const addItem = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (hasNoLocation && !window.Cypress) { /* disable for tests */
      // Require an address to cart items
      displayModal(ModalType.ADDRESS, {
        trackContext: 'address_from_cart_button',
        continueModal: ModalType.PRODUCT,
          itemId,
          itemCount: itemQuantity,
          /**
           * TODO FIXME: switch to useReducer for flow
           * relatedProducts: data missing for suggested product carousel
           */
      });
    } else {
      handleCartClick(1);
      toggleTransitionBar();
    }
  };

  const adjustCount = (evt, adjustBy) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (adjustBy === 1) {
      increaseQuantity(itemId, 1);
    } else if (adjustBy === -1) {
      decreaseQuantity(itemId, 1);
      if (itemQuantity === 1) {
        toggleTransitionBar();
      }
    }
  };

  const toggleToolBar = (evt, isShow) => {
    evt.preventDefault();
    evt.stopPropagation();
    setShowToolBar(isShow);
  };

  // On Desktop, close toolbar on mouseleave
  let closeTask;
  const delayedClose = () => {
    window.clearTimeout(closeTask);
    closeTask = window.setTimeout(() => {
      setShowToolBar(false);
    }, MOUSE_LEAVE_CLOSE_MS);
  };
  const cancelClose = () => {
    window.clearTimeout(closeTask);
  };

  // When first adding an item, we preview the toolbar with a spinner
  // We also show it when the item has been removed from cart
  const toggleTransitionBar = () => {
    setShowTransitionBar(true);
    window.setTimeout(() => {
      setShowTransitionBar(false);
    }, 1000);
  };

  const TransitionBar = () => {
    return (
      <div className={`${styles.quickAddBarItems} ${styles.transitionBar}`}>
        <div className={styles.barDecrement}>
          <DeleteRoundedIcon style={iconStyle} />
        </div>
        <div className={styles.barCount}>
          <Spinner inSmallLayout size={16} color="#fff" withStyle={{display:'block', margin:'-18px 0'}} />
        </div>
        <div className={styles.barIncrement}>
          <AddRoundedIcon style={iconStyle} />
        </div>
      </div>
    )
  };

  React.useEffect(() => {
    if (cartItems?.length) {
      // Determine if this item is carted
      const [inCart] = cartItems.filter(item => item.id === itemId);
      if (inCart) {
        setItemQuantity(inCart.quantity);
        setLimitReached(inCart.quantity >= product.per_order_max_quantity);
      } else {
        setItemQuantity(0);
        setShowToolBar(false);
      }
    } else {
      setItemQuantity(0);
      setShowToolBar(false);
    }
  }, [itemId, product, cartItems, cartItemCount, itemQuantity, setItemQuantity]);

  // Styles for MUI Icons
  const iconStyle = {
    color: '#fff',
    fontSize: '20px',
    verticalAlign: '-4px'
  }

  const closeIconStyle = {
    color: '#fff',
    fontSize: '20px',
    verticalAlign: '-4px'
  }

  return ( isSoldOut
    ? null
    : showTransitionBar
      ? <TransitionBar />
      : showToolBar // User is interracting with +/-
        ? <div className={styles.quickAddToolBar} onMouseLeave={delayedClose} onMouseEnter={cancelClose}>
            <div className={styles.quickAddBarItems}>
              <div className={styles.mobileClose} onClick={(event) => { toggleToolBar(event, false)}}>
                <HighlightOffRoundedIcon style={closeIconStyle} />
              </div>
              <div className={styles.barDecrement} onClick={(event) => {
                adjustCount(event, -1);
                setItemQuantity(itemQuantity - 1);
              }}>
                { itemQuantity > 1
                  ? <RemoveRoundedIcon style={iconStyle} />
                  : <DeleteRoundedIcon style={iconStyle} />
                }
              </div>
              <div className={styles.barCount}>{itemQuantity}</div>
              <div className={`${styles.barIncrement} ${limitReached ? styles.disabled : ''}`}
                onClick={(event) => {
                  if (limitReached) {
                    event.preventDefault();
                    event.stopPropagation();
                    return;
                  }
                  adjustCount(event, 1);
                  setItemQuantity(itemQuantity + 1);
                }
              }>
                <AddRoundedIcon style={iconStyle} />
              </div>
            </div>
          </div>
      : itemQuantity > 0
        ? <div className={styles.inCartCheck} onClick={(event) => {
            toggleToolBar(event, true);
          }}>
            <span className={styles.text}>
              <span className={styles.tinyX}>x</span>
              {itemQuantity}
            </span>
          </div>
        : <div className={styles.quickAdd}>
            <button className={styles.quickAddButton} onClick={(event) => {
              addItem(event);
            }}>
              <AddRoundedIcon style={iconStyle} /> Add
            </button>
          </div>
  )
}

InCartCheck.propTypes = {
  itemId: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  quantity_available: PropTypes.number.isRequired,
  display_sold_out_status: PropTypes.bool,
  handleCartClick: PropTypes.func.isRequired
};

export default InCartCheck;
