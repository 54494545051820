import * as React from 'react';

import { devLog } from '../util/util';
import { navigateTop } from '../routing/router-utils';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import Header, { HeaderSize } from '../styleguide/Header';
import Button from '../styleguide/Button';
import BackLink from '../common/BackLink';
import PageTitle from '../common/PageTitle';
import useInterval from '../util/useInterval';
import ChatIcon from '../icons/ChatIcon';

/**
 * Chat page that launches chat (useful for linking in email) 
 */ 
const ChatPage = () => { 
  
  const [chatShown, setChatShown] = React.useState();

  // Wait for Intercom to load
  useInterval(
    () => { 
      if (typeof window.Intercom !== "undefined") {
        window.Intercom('show');
        devLog('Start Chat');
        setChatShown(true);
      }
    }, 
    250, 
    chatShown,
  );
 
  const bodyStyle = {
    backgroundColor: 'var( --zrLightBackground)', 
    minHeight: '100vh',
    fontSize: '1.375rem',
    textAlign: 'center',
  }

  const chatBubbleStye = {
    cursor: 'pointer',
    width: 350,
    maxWidth: '90vw',
    boxSizing: 'border-box',
    margin: '0 auto',
    whiteSpace: 'nowrap',
    backgroundColor:'#fff', 
    borderRadius:16, 
    padding: '1.5rem 2rem',
    textDecoration: 'underline',
  }

  const btnWrapStyles = {
    width: 350,
    maxWidth:'90vw',
    boxSizing:'border-box', 
    margin:'0 auto', 
    padding:'2rem 0',
  }

  return (
    <BodyWrapper pageLayout={LayoutTypes.FullBleed} withStyle={bodyStyle}>
      <PageTitle title="Chat with Zyp Run" />
      <Header size={HeaderSize.Large} isCentered withStyle={{marginTop:0, padding:'84px 1rem 2rem'}}>
        <div style={{position:'absolute', left:'2.5vw', top:24, whiteSpace:'nowrap'}}>
          <BackLink backToText="Shop Delivery" backToPath={"/"} inHeader />
        </div>
        Zyp Run Chat 
        <div style={{marginTop:12, fontSize:'1.375rem', fontWeight:400}}>11:00am - 9:00pm EST</div>
      </Header>     
      <div style={chatBubbleStye} onClick={()=>window.Intercom('show')}>
        <span style={{paddingRight:8}}>
          Chat with Zyp Run
        </span>
        <ChatIcon 
          withStyle={{verticalAlign:-12}}
          hoverFillColor='var(--zrPrimaryVeryDark)'
          size="48px" />
      </div>
      <div style={btnWrapStyles}>
        All set?
        <Button
          withStyle={{width:'100%'}}
          text='Shop Delivery' 
          handleClick={()=>navigateTop('/')} />
      </div>
    </BodyWrapper> 
  );
};

export default ChatPage;
