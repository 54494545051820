import * as React from "react";

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';
import styles from './YourAccount.module.css';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  '@global': {
    '.MuiSwitch-colorSecondary + .MuiSwitch-track': {
      backgroundColor: 'var(--disabledGray)'
    }, 
    '.MuiSwitch-colorSecondary': {
      color: 'var(--disabledGray)'
    },  
    '.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'var(--zrPrimaryDark)'
    }, 
    '.MuiSwitch-colorSecondary.Mui-checked': {
      color: 'var(--zrPrimaryDark)'
    }
  }    
});

/**
 * Update discreet delivery setting 
 */
const DiscreetToggleForm = ({
  discreetEnabled,  /* current discreet setting returned by API */
  handleSubmit,     /* handle update to API */
  sectionRef,       /* ref for fade during submit */
  msgHandler,       /* callback to set success/fail message */
  updateMsg         /* The update response message */  
}) => {

  // MUI globals only
  useStyles();

  const [discreetChecked, setDiscreetChecked] = React.useState(false);

  const onChange = (event) => {
    const updateInfo = {};
    if (event.currentTarget.type === "checkbox") {
      const { checked } = event.currentTarget;
      // setDiscreetChecked(checked); // causes double movement
      updateInfo.prefers_discreet_delivery = checked;
      // Make API call
      handleSubmit(updateInfo, sectionRef, msgHandler);
    }
  }   
  
  React.useLayoutEffect(() => {
    if (discreetEnabled) {
      setDiscreetChecked(true);
    }
  }, [discreetEnabled])

  return (
    <div className={styles.subscribe}>
      <>
        <FormControlLabel
          label={`Discreet Delivery: (${discreetChecked ? 'ENABLED' : 'DISABLED' })`}
          control={<Switch checked={discreetChecked} onChange={onChange} />} />
        <div>With Discreet Delivery enabled, your products will be delivered in an unmarked&nbsp;bag.</div>
      </>
      {/* success / failure */}
      { updateMsg &&   
        <div style={{marginTop:12}} className={styles.updateMsg}>{updateMsg}</div> 
      }
    </div>
  );
}

DiscreetToggleForm.propTypes = {
  discreetEnabled: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,    
  sectionRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  msgHandler: PropTypes.func.isRequired,
  updateMsg: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
};

export default DiscreetToggleForm;
