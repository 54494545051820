import * as React from 'react';

import styles from './ProductSearch.module.css';

const SearchExamples = () => (
  <div className={styles.hintWrap}>
    <div className={styles.hints}>
      <span className={styles.hint}>“Hybrid Flower”</span>
      <span className={styles.hint}>“Vape”</span>
      <span className={styles.hint}>“Chews”</span>
      <span className={styles.hint}>“Insa”</span>
      <span className={styles.hint}>“SPARQ 1g Cartridge”</span>
      <span className={styles.hint}>“14g Sativa”</span>
      <span className={`${styles.hint} ${styles.hintEven}`}>“Cookies”</span>
      <span className={`${styles.hint} ${styles.hintEven}`}>“Infused Preroll Pack”</span>
      <span className={`${styles.hint} ${styles.hintEven}`}>“Motorbreath”</span>
      <span className={`${styles.hint} ${styles.hintEven}`}>“CBD Drops”</span>
      <span className={styles.hint}>“Cake Mix”</span>
      <span className={styles.hint}>“Indica Edibles”</span>
      <span className={styles.hint}>“1g Preroll”</span>
      <span className={styles.hint}>“Sativa Rosin”</span>
      <span className={`${styles.hint} ${styles.hintEven}`}>“Mango Chews”</span>
      <span className={`${styles.hint} ${styles.hintEven}`}>“CBD Balm”</span>
      <span className={`${styles.hint} ${styles.hintEven}`}>“Disposable Vape”</span>
      <span className={`${styles.hint} ${styles.hintEven}`}>“Happy Valley Flower”</span>
    </div>
  </div>
);

export default SearchExamples;
