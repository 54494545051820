import React from 'react';

import DeliveryMessages from './DeliveryMessages';

/**
 *  For any important service-related messages we need to display
 *  "Delivery not available" etc.
 */
const ServiceMessages = () => {
  return (
    <DeliveryMessages />
  );
} 

export default ServiceMessages;
