import * as React from 'react';

import { NotificationsContext } from '../notifications/NotificationsProvider';
import ReOrderItemsProvider from './ReOrderItemsProvider';
import ReOrderCarousel from './ReOrderCarousel';

const ReOrderSection = () => {
  
  const { priorOrders } = React.useContext(NotificationsContext);

  // Check for in-stock previously ordered items only if user has prior orders
  return ( priorOrders?.length
    ? <ReOrderItemsProvider>
        <ReOrderCarousel />
      </ReOrderItemsProvider>  
    : <></>
  )
};

export default ReOrderSection;
