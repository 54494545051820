import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { useAnalyticsStore } from '../../App';
import { emailSubscribedKey, getStoredValue, storeValue } from '../util/storage-utils';
import NotifyMeEmailInput from './NotifyMeEmailInput';
import PropTypes from 'prop-types';

const successMessagePrefix = "All set! We'll let you know when delivery is available in\u00A0";

// This form is for unregistered (anonymous) users
const NotifyMeForm = ({
  noDeliveryLocation, // City or Zip Code
  trackingLabel="hdr_popup"
}) => {

  const { trackEvent } = useAnalyticsStore();

  // We need to submit the search address with the email address
  const { searchLocation } = React.useContext(UserContext); 
  
  // Check device for having previously subscribed
  const [okToSubmit, setOkToSubmit] = React.useState();
  React.useEffect(() => {
    const previouslySubscribed = getStoredValue(emailSubscribedKey);
    if(!previouslySubscribed) {
      setOkToSubmit(true);
    }  
  },[])

  const onSubscribe = () => {
    trackEvent(`notify_me_subscribed_${trackingLabel}`);
    storeValue(emailSubscribedKey, true);
  }; 

  return (okToSubmit
    ? <NotifyMeEmailInput 
        location={searchLocation}
        successMessage={`${successMessagePrefix}${noDeliveryLocation}`}
        onSubscribe={onSubscribe} />
    : <></>  
  )  
}

NotifyMeForm.propTypes = {
  noDeliveryLocation: PropTypes.string,
  trackingLabel: PropTypes.string,
};

export default NotifyMeForm;
