import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import { trackingString } from '../analytics/tracking';
import { FilteredProductsContext, SHOW_ALL, THC_SORT, THC_SORT_LOW, PRICE_SORT, PRICE_SORT_HIGH} from '../products/FilteredProductsProvider';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import styles from './ProductInfoFilter.module.css';

/**
 * This component will provide a Price and THC sorting filter for both Desktop and Mobile layouts
 */ 
const SortFilter = ({
  startOpen=false,
  inModal=false
}) => {
  
  const { trackEvent } = useAnalyticsStore();

  // Current filters context
  const { sorting:selectedSorting, hasSubCategoryTHCItems, setFilters } = React.useContext(FilteredProductsContext);
  // Sorting options with all/unsorted option 
  const sortingWithAll = hasSubCategoryTHCItems
      ? [SHOW_ALL, PRICE_SORT, PRICE_SORT_HIGH, THC_SORT, THC_SORT_LOW]
      : [SHOW_ALL, PRICE_SORT, PRICE_SORT_HIGH];

  const SORT_LABELS = {
    [PRICE_SORT]: 'Price (Low to High)',
    [PRICE_SORT_HIGH]: 'Price (High to Low)',
    [THC_SORT]: 'THC (High to Low)',
    [THC_SORT_LOW]: 'THC (Low to High)',
  };

  // Toggle the display
  const [showSorting, setShowSorting] = React.useState(startOpen);

  const toggleShowSorting = () => {
    if (labelRef.current) {
      labelRef.current.setAttribute('data-show-content', !showSorting);
    } 
    setShowSorting(!showSorting);
  };

  const sortingClick = (sortBy) => {
    trackEvent(`filter_sortby_${trackingString(sortBy)}_click`); 
    setFilters({sorting:sortBy});
  };
  
  const labelRef = React.useRef();
  
  const addlClass = inModal ? styles.inModal : '';

  return ( 
    <div className={`${styles.productInfoFilter} ${addlClass}`}> 
      <div ref={labelRef} data-show-content={showSorting} className={styles.sectionHdr} onClick={toggleShowSorting}>
        SORT BY <ChevronRightRoundedIcon style={{verticalAlign:'-7px'}}/>
      </div>
      { showSorting &&
        <ul className={styles.filterLinks}>
          { sortingWithAll.map(sortBy => {
            const selectedClass = sortBy === selectedSorting ? styles.selected : '';
            const label = sortBy === SHOW_ALL
                        ? 'Unsorted'
                        : SORT_LABELS[sortBy];
            return <li key={sortBy} 
                       className={`${styles.filterLink} ${selectedClass}`} 
                       onClick={() => {sortingClick(sortBy)}}>
                     {label}
                     <span className={styles.selectedIcon}>
                       <CheckCircleRoundedIcon style={{color: '#054052', verticalAlign:'-6px'}}/>
                     </span>
                   </li>
          })}
        </ul>
      }  
    </div>
  );
}

export default SortFilter;
