import * as React from 'react';

// TODO: Host in storage
import deals from '../../assets/deals_blue.jpg';
import dealsOn from '../../assets/deals_blue_on.jpg';
import popular from '../../assets/popular_blue.jpg';
import popularOn from '../../assets/popular_blue_on.jpg';

/*
import accessories from '../../assets/accessories_blue.jpg';
import accessoriesOn from '../../assets/accessories_blue_on.jpg';
...
*/

import OptimizedImage from './OptimizedImage';

import PropTypes from 'prop-types';

// popular is not a real product category
export const popularCategory = {
  name: 'popular',
  display_name: 'Popular'
};

// deals is not a real product category
export const dealsCategory = {
  name: 'sale',
  display_name: 'Deals'
};

/*
  accessories: [accessories, accessoriesOn],
  ...
*/

const CATEGORY_IMAGES = {
  popular: [popular, popularOn],
  sale : [deals, dealsOn],
};  

/**
 * Render Category and Category hover/selected images
 */
const CategoryImage = ({
  categoryObj,
  isActive
}) => {

  const [catImage, setCatImage] = React.useState();
  const [activeCatImage, setActiveCatImage] = React.useState();

  React.useEffect(() => {
    if (CATEGORY_IMAGES[categoryObj.name]) {
      setCatImage(CATEGORY_IMAGES[categoryObj.name][0]);
      setActiveCatImage(CATEGORY_IMAGES[categoryObj.name][1]);
    } else if(categoryObj.image_url) {
      setCatImage(categoryObj.image_url);
      setActiveCatImage(categoryObj.image_url_selected);
    }      
  }, [categoryObj, isActive])

  return (
    catImage 
      ? <OptimizedImage 
          imgAlt={categoryObj.display_name}
          imgSrc={ isActive ? activeCatImage : catImage }
        />
      : null
  )  
}

CategoryImage.propTypes = {
  categoryObj: PropTypes.object.isRequired,
  isActive: PropTypes.bool
}

export default CategoryImage;
