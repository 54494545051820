import React, { useState } from "react";

import { auth } from '../../firebase';
import { activeController } from "../util/util";
import { requestLoginCodeEndpoint } from "../../constants/api-endpoints";
import Button from "../styleguide/Button";
import EnterCode from "./EnterCode";
import { DEFAULT_ERROR } from "./PhoneSignIn";

import styles from '../registration/SignUpInline.module.css';

const loginCodeSubmit = (tel_number, code, onSuccess, onFailure, controller) => {
  
  const body = JSON.stringify({
    tel_number,
    code: parseInt(code, 10)
  });
  
  if (auth.currentUser) {
    auth.currentUser.getIdToken(/* no need to force refresh */ false).then(function(idToken) {
      fetch(requestLoginCodeEndpoint, {
        method: 'POST',
        headers: {
          'Authorization': idToken,
          'Content-Type': 'application/json'
        },
        body,
        signal: controller.signal
      }).then(response => response.json())
        .then(data => {
          if (data.token) {
            onSuccess(data);
          } else {
            const { error=DEFAULT_ERROR } = data;
            onFailure({message:error});
          }
      }).catch(error => {
        onFailure(error);
      });
    });
  }
};

const SubmitLoginCode = ({loginState, onSuccess, onFailure}) => {
  const CODE_LENGTH = 5;
  const [code, setCode] = useState('');
  
  // Submit verification code
  const controllerRef = React.useRef(new AbortController());
  const submitCode = React.useCallback(() => {
    const controller = activeController(controllerRef);   
    if (code.length < CODE_LENGTH) {
      onFailure({message:`Verification codes are ${CODE_LENGTH} digits...`});
      return;
    }
    loginCodeSubmit(loginState.phone_number, code, onSuccess, onFailure, controller);
  }, [loginState, code, onSuccess, onFailure]);

  return (
    <div className={`${styles.inlineRegFlow} ${styles.phoneSignInField}`}>
      <div className={styles.codeLabel}>Enter verification code:</div>
      <EnterCode code={code} setCode={setCode} />
      <Button disabled={!code || code.length < CODE_LENGTH} isCentered text="Submit Code" handleClick={submitCode} />
   </div>
  );
};

export default SubmitLoginCode;
