import * as React from 'react';

import useProductDetails from './useProductDetails';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import Spinner from '../common/Spinner';
import AddedToCartCheck from '../products/AddedToCartCheck';
import BackLink from '../common/BackLink';
import ProductDetailsCard from './ProductDetailsCard';
import ProductNotFound from './ProductNotFound';
import PageTitle from '../common/PageTitle';
import PropTypes from 'prop-types';

/**
 * Product Detail Page
 * 
 * This component is for the single product page (SEO+Promotions mostly)
 */
const ProductDetailPage = ({itemId, location}) => {
  
  const [showAddedCheck, setShowAddedCheck] = React.useState();
  const { product, status } = useProductDetails(itemId);

  // Show the "added" check
  const onAddToCart = () => {
    setShowAddedCheck(true);
  };

  // Set path for back link - Default to home page to support promo links 
  const [returnToPath, setReturnToPath] = React.useState('/');
  
  React.useEffect(() => {
   const { returnToPath:backPath } = location?.state || {};
   if (backPath) {
     setReturnToPath(backPath);
   }
  }, [location]);

  return ( 
    <BodyWrapper pageLayout={LayoutTypes.Narrow}>
      { product
        ? <>
            <PageTitle title={product.display_info?.name} />
            <BackLink backToPath={returnToPath} backToText="Shop All Cannabis Products" />
            <div style={{margin:'1rem 0'}}>
              <ProductDetailsCard
                product={product}
                onAddToCart={onAddToCart}
                isModal={false}
              />
            </div>  
          </>
        : status === 404
          ? <ProductNotFound returnToPath={returnToPath} />
          : <Spinner />
      }
      { showAddedCheck && 
        <AddedToCartCheck closeFn={() => { setShowAddedCheck(false)} } />
      }
    </BodyWrapper>
  )
}  

ProductDetailPage.propTypes = {
  location: PropTypes.object,  /* delayed, via router */
  itemId: PropTypes.string     /* delayed, via router */
};

export default ProductDetailPage;
