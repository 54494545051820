import * as React from 'react';
import { devLog } from '../util/util';
import { AFTER_HOURS_STATUS } from './NotificationsProvider';

const minute = 60000;

const useOrderStatusInterval = (
  callback,             // ping API for orders
  hasCurrentDayOrders,  // Run only for same-day orders
  orderStatus,          // current status of the active order
) => {
  
  const callbackRef = React.useRef();
  
  // Set a reference to the callback.
  React.useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    // Adjust fetch interval based on order status
    const intervalByStatus = {
      'requested': minute,               // every minute
      'accepted': minute * 2,            // every 2 minutes
      'ready-for-pickup': minute * 5,    // every 5 minutes
      'in-transit': minute * 5,          // every 5 minutes
      [AFTER_HOURS_STATUS]: minute * 60, // every hour
    };

    const delay = intervalByStatus[orderStatus];
    function tick() {
      devLog(`Running useOrderStatusInterval callback with delay:${delay}`);
      callbackRef.current();
    }
    // For completed/canceled orders delay is undefined, so no interval
    if (hasCurrentDayOrders && delay) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [hasCurrentDayOrders, orderStatus]);
}

export default useOrderStatusInterval;
