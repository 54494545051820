import * as React from 'react';

import config from '../../config';
import { devLog } from '../util/util';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  '@global #widget iframe': {
    borderRadius: 8
  }
})

const AerosyncWidget = ({
  token,
  onWidgetLoad,
  onWidgetClose,
  onWidgetSuccess,
  onWidgetError,
}) => {  
  // Just the global iframe style
  useStyles();
  
  const environment = config.IS_DEV ? "staging" : "production";
  const widgetRef = React.useRef();

  React.useEffect(() => {
    if (token) {
      //Aerosync widget 
      window.widgetRef = new window.AerosyncConnect({
        token, /* <aerosync_token> */
        id: "widget",
        iframeTitle: "Connect",
        width: "375px",
        height: "95%",
        environment,
        onEvent: function (type, payload) {
          devLog("onEvent", type, payload);
        },
        onLoad: function () {
          onWidgetLoad();
        },
        onClose: function() {
          onWidgetClose();
        },
        onSuccess: function (event) {
          onWidgetSuccess(event);
        },
        onBankAdded: function (event) {
          // track ?
        },
        onError: function (event) {
          /**
           * Sample Error:
           * { "success":false,
           *   "error":{
           *     "code":"AP407",
           *     "message":"Bank linking disabled..."
           *   }
           * }
           */
          onWidgetError(event?.error || { message: "unexpected widget error"});          
        }
      });
    }  
  }, [token, environment, onWidgetLoad, onWidgetClose, onWidgetSuccess, onWidgetError]);

  // Auto launch the widget...
  React.useEffect(() => {
    if (token && window.widgetRef) {
      window.widgetRef.launch();
    }
  },[token])

  // Return target div for Aerosync widget iframe
  return (
    <div ref={widgetRef} id="widget"></div>
  )
}

AerosyncWidget.propTypes = {
  token: PropTypes.string.isRequired,
  onWidgetLoad: PropTypes.func.isRequired,
  onWidgetClose: PropTypes.func.isRequired,
  onWidgetSuccess: PropTypes.func.isRequired,
  onWidgetError: PropTypes.func.isRequired,
}

export default AerosyncWidget;

/**
 * Example from Docs (load widget via button)
 *   JS
 *   window.openButton = document.getElementById('openBank');
 *   window.openButton.addEventListener('click', function () {
 *     window.widgetRef.launch();
 *   });
 * 
 *   HTML
 *   <button
 *       id="openBank" 
 *       className="button" 
 *       role="button">
 *     Connect Bank
 *   </button>
 */
