import * as React from 'react';

import { RegistrationContext } from '../registration/RegistrationProvider.jsx';
import useReferralFraudReducer from './useReferralFraudReducer.js';
import { trackEvent } from '../analytics/tracking.jsx';
import Alert from '@material-ui/lab/Alert';
import StrongText from '../common/StrongText.jsx';
import ChatLink from '../intercom/ChatLink.jsx';

import styles from './ReferFraudMessage.module.css';

const ReferFraudMessage = () => {

  const { isLikelyFraud, similarName } = useReferralFraudReducer();
  const { name, referrerName } = React.useContext(RegistrationContext);

  React.useEffect(() => {
    if (isLikelyFraud) {
      trackEvent('credit_fraud_reg_msg_display');
    }
  }, [isLikelyFraud])

  return ( isLikelyFraud
    ? <Alert severity='error' className={styles.alert}>
        <div className={styles.alertHdr}>
          Warning
        </div>
        { similarName &&
          <div className={styles.detail}>
            Your name: <StrongText>{name}</StrongText> and the referrer's name: <StrongText>{referrerName}</StrongText> are similar...
          </div>
        }
        <div className={styles.detail}>
          Referral Credits will NOT be awarded unless the information provided:
          <StrongText>name, address, ID image, etc.</StrongText> 
          is sufficient to prove that the person receiving the credits is different
          than the person providing the referral link.
        </div>
        <div className={styles.detail}>
          If you're trying to refer your twin, or have other questions about our referral credits, 
          please reach out to us <ChatLink>via Chat</ChatLink>.
        </div>
      </Alert>
    : null  
  );
};

export default ReferFraudMessage;
