import * as React from 'react';

import { activeController } from '../util/util';
import { trackingString } from '../analytics/tracking';
import { useAnalyticsStore } from '../../App';
import { checkDeliveryAddressBlacklist } from '../registration/registration-utils';
import PropTypes from 'prop-types';

const useBlockedAddressCheck = (location) => {

  const [isBlocked, setIsBlocked] = React.useState();
  const { trackEvent } = useAnalyticsStore();

  // Store a ref, orginally to debounce address entry keystrokes, currently we only use 
  // Google typeahead so there's less need, if any, for the debouncing.
  const requestTimout = React.useRef();
  const requestDelayMS = 300;
  const controllerRef = React.useRef(new AbortController());

  React.useEffect(() => {
    const controller = activeController(controllerRef);
    if (location) {
      window.clearTimeout(requestTimout.current);
      requestTimout.current = window.setTimeout(() => {
        const address = {...location};
        delete address.geo_coordinates;
        checkDeliveryAddressBlacklist(address, setIsBlocked, controller);
      }, requestDelayMS);
    }
    return () => {
      window.clearTimeout(requestTimout.current);
      controller.abort();
    }  
  }, [location]);

  React.useEffect(() => {
    if (isBlocked) {
      trackEvent(`restricted_address_${trackingString(location.city)}`);
    }
  }, [trackEvent, location, isBlocked])

  return isBlocked;
}

useBlockedAddressCheck.propTypes = {
  location: PropTypes.shape({
    street_address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip_code: PropTypes.string.isRequired,
    geo_coordinates: PropTypes.any,
  })   
}

export default useBlockedAddressCheck
