import * as React from 'react';

import PriceWithWeightButton from './PriceWithWeightButton';
import BulkPriceDetailsWithCartHint from '../bundles/BulkPriceDetailsWithCartHint';
import SuperDealDetails from '../deals/SuperDealDetails';
import InCartCheck from './InCartCheck';
import PropTypes from 'prop-types';

import styles from './ProductCardForGrid.module.css';

/**
 * NOTE: The ProductCardForGrid which uses this component is currently
 * used for the full product listing AND for order history (OrderDetails).
 * Check both uses when making changes.
 */
const PriceDetails = ({
  product,
  orderQuantity, /* order history only */
  handleAddToCart
}) => {

  const {
    id,
    quantity_available,
    bundle_deal_data,
    display_sold_out_status,
  } = product || {};

  return (
    <div className={styles.productPriceWrap}>
      <div className={styles.quickAddWrap}>
        <PriceWithWeightButton
          product={product}
          handleClick={handleAddToCart} />
        {/* orderQuantity is for  order history only */}
        { orderQuantity
          ? <div className={styles.orderQuantity}>{orderQuantity}</div>
          : <div className={styles.addedWrapper}>
              <InCartCheck
                itemId={id}
                product={product}
                quantity_available={quantity_available}
                display_sold_out_status={display_sold_out_status}
                handleCartClick={handleAddToCart} />
            </div>
        }
      </div>
      { orderQuantity
        ? null // order history view
        : bundle_deal_data
          ? <div className={styles.dealDetails}>
              <BulkPriceDetailsWithCartHint product={product} />
            </div>
          : <div className={styles.dealDetails}>
              <SuperDealDetails product={product} />
            </div>
      }
    </div>
  )
}

PriceDetails.propTypes = {
  product: PropTypes.object.isRequired,
  orderQuantity: PropTypes.number, /* order history only */
  handleAddToCart: PropTypes.func.isRequired,
}

export default PriceDetails;
