import * as React from 'react';
import config, { google_maps_key_qa2 } from '../../config';
import PropTypes from 'prop-types';

export const GoogleMapsContext = React.createContext();

const GoogleMapsProvider = ({children}) => {

  const [mapsAPILoaded, setMapsAPILoaded] = React.useState();

  window.onGMapsLoaded = () => {
    window.gMapsLoaded = true;
    setMapsAPILoaded(true);
  }

  const appendMapScript = () => {
    // Support maps when running PRODUCTION on localhost
    const mapsKey = process.env.NODE_ENV === 'development'
      ? google_maps_key_qa2
      : config.GOOGLE_MAPS_KEY;
    // if (document.readyState === 'complete') {
      const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${mapsKey}&libraries=places,marker&callback=onGMapsLoaded&loading=async`;
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = scriptSrc;
      document.body.appendChild(s);
    // }
  }

  // Inject Google Maps API
  React.useEffect(() => {
    appendMapScript();
  },[]);

  return  <GoogleMapsContext.Provider value={{ mapsAPILoaded }}>
            {children}
          </GoogleMapsContext.Provider>
}

GoogleMapsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default GoogleMapsProvider;
