import React from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(props => ({
  icon: {
    padding: '2px 3px 4px;',
    '&:hover path:nth-of-type(2)': {
      fill: (props) => props.hoverFillColor
    }
  }
}));

/* Converted PNG to SVG using Adobe site */
/* Active state handled in FixedFooterNav.module.css */
const ChatIcon = ({
  fillColor='var(--zrPrimaryVeryDark)', 
  hoverFillColor='var(--zrPrimaryDark)',
  size='28px',
  withStyle={}
}) => {
  const classes = useStyles({ hoverFillColor });
  return (
    <svg className={classes.icon}
         style={withStyle}
         version="1.1" xmlns="http://www.w3.org/2000/svg" 
	       width={size} viewBox="0 0 304 260">
      <path fill="transparent" opacity="1.000000" stroke="none" 
	      d="M185.000000,261.000000 
	         C123.333344,261.000000 62.166679,261.000000 1.000016,261.000000 
	         C1.000011,174.333344 1.000011,87.666702 1.000005,1.000040 
	         C102.333305,1.000026 203.666611,1.000026 304.999939,1.000013 
	         C304.999939,87.666626 304.999939,174.333252 304.999969,260.999939 
	         C265.166656,261.000000 225.333328,261.000000 185.000000,261.000000 
           M69.500023,3.159678 
           C57.167240,3.159672 44.834454,3.151672 32.501678,3.161572 
           C14.886718,3.175714 3.001293,15.026299 3.000677,32.575127 
           C2.998260,101.406479 3.332618,170.240784 2.719633,239.066696 
           C2.596588,252.882172 15.752025,264.024078 30.509457,255.934586 
           C51.507874,244.424057 72.940704,233.697540 94.326683,222.911163 
           C97.717072,221.201157 101.832863,220.132339 105.617470,220.120605 
           C161.781982,219.946182 217.947189,220.005463 274.112244,219.997070 
           C291.056183,219.994537 302.996674,208.027817 302.998322,191.066437 
           C303.003448,138.401276 303.000793,85.736130 302.999878,33.070976 
           C302.999542,14.832061 291.296661,3.161530 272.994202,3.160775 
           C205.496140,3.157992 137.998077,3.159717 69.500023,3.159678 
          z" />
      <path fill={fillColor} opacity="1.000000" stroke="none" 
	      d="M70.000023,3.159713 
	         C137.998077,3.159717 205.496140,3.157992 272.994202,3.160775 
	         C291.296661,3.161530 302.999542,14.832061 302.999878,33.070976 
	         C303.000793,85.736130 303.003448,138.401276 302.998322,191.066437 
	         C302.996674,208.027817 291.056183,219.994537 274.112244,219.997070 
	         C217.947189,220.005463 161.781982,219.946182 105.617470,220.120605 
	         C101.832863,220.132339 97.717072,221.201157 94.326683,222.911163 
	         C72.940704,233.697540 51.507874,244.424057 30.509457,255.934586 
	         C15.752025,264.024078 2.596588,252.882172 2.719633,239.066696 
	         C3.332618,170.240784 2.998260,101.406479 3.000677,32.575127 
	         C3.001293,15.026299 14.886718,3.175714 32.501678,3.161572 
	         C44.834454,3.151672 57.167240,3.159672 70.000023,3.159713 
           M72.882248,95.025467 
	         C59.499077,97.918785 52.320900,108.925201 55.948196,120.990707 
	         C59.418945,132.535492 71.713821,138.463608 82.983109,134.025909 
	         C91.780548,130.561584 97.069977,121.194122 95.408875,112.020157 
	         C93.451973,101.212578 85.581772,95.027252 72.882248,95.025467  
           M248.107346,125.452499 
	         C253.016434,115.467514 251.035995,105.203133 243.017242,99.071068 
	         C235.518097,93.336372 224.621506,93.476669 217.678055,99.573456 
	         C211.246597,105.220703 208.784988,112.312569 211.086182,120.654945 
	         C213.209595,128.352753 218.375809,133.298843 226.169022,134.931778 
	         C235.199234,136.823883 242.476791,133.725281 248.107346,125.452499 
           M170.389832,125.686890 
	         C171.002411,124.326355 171.730881,123.005203 172.208603,121.598862 
	         C175.134430,112.985710 171.784637,103.179459 164.298676,98.277573 
	         C156.552353,93.205200 146.040848,93.992722 139.290451,100.151199 
	         C132.653534,106.206154 130.746262,116.421158 134.915176,124.427330 
	         C142.166794,138.353653 161.326965,139.373398 170.389832,125.686890 
          z" />
      <path fill="transparent" opacity="1.000000" stroke="none" 
      	d="M73.317520,95.012878 
           C85.581772,95.027252 93.451973,101.212578 95.408875,112.020157 
           C97.069977,121.194122 91.780548,130.561584 82.983109,134.025909 
           C71.713821,138.463608 59.418945,132.535492 55.948196,120.990707 
           C52.320900,108.925201 59.499077,97.918785 73.317520,95.012878 
          z" />
      <path fill="transparent" opacity="1.000000" stroke="none" 
      	d="M247.940903,125.785355 
           C242.476791,133.725281 235.199234,136.823883 226.169022,134.931778 
           C218.375809,133.298843 213.209595,128.352753 211.086182,120.654945 
           C208.784988,112.312569 211.246597,105.220703 217.678055,99.573456 
           C224.621506,93.476669 235.518097,93.336372 243.017242,99.071068 
           C251.035995,105.203133 253.016434,115.467514 247.940903,125.785355 
          z" />
      <path fill="transparent" opacity="1.000000" stroke="none" 
      	d="M170.203400,126.011543 
           C161.326965,139.373398 142.166794,138.353653 134.915176,124.427330 
           C130.746262,116.421158 132.653534,106.206154 139.290451,100.151199 
           C146.040848,93.992722 156.552353,93.205200 164.298676,98.277573 
           C171.784637,103.179459 175.134430,112.985710 172.208603,121.598862 
           C171.730881,123.005203 171.002411,124.326355 170.203400,126.011543 
          z" />
    </svg>
  );
}

export default ChatIcon;
