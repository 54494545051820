
export const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const EST = "America/New_York";

// 'monday'
export const todayLower = () => {
  return new Date().toLocaleString("en-US", 
    { timeZone: EST, weekday: "long"}).toLowerCase();
}

// 'tuesday'
export const tomorrowLower = () => {
  const today = new Date();
  return new Date(today.getDate() + 1).toLocaleString("en-US", 
    { timeZone: EST, weekday: "long"}).toLowerCase();
}

// 12
export const currentHourBoston = () => {
  const hourString = new Date().toLocaleTimeString("en-US", 
    { timeZone: EST, hour: "numeric"});
  // "11 AM" => 11
  return parseInt(hourString.replace(/AM|PM/g, "").trim(), 10);  
}

// For comparing slot dates to active order dates
// '8-13-2024'
export const deliveryDate = (mdyObject) => {
  const { month, day, year } = mdyObject || {};
  return `${month}-${day}-${year}`;
}
// '8-13-2024' => 'Tuesday, 8/13/2024'
export const displayDeliveryDate = (dateStr) => {
  return new Date(dateStr).toLocaleDateString('en-US', 
    {month:"numeric", weekday:"long", day: "numeric", year:"numeric"}
  );
}
