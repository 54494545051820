import * as React from 'react';

import { devLog } from '../util/util';
import { trackingString } from '../analytics/tracking';
import { useAnalyticsStore } from '../../App';
import useBrandPartnerData from './useBrandPartnerData';
import { BrandProductsContext } from './BrandProductsProvider';
import { FilteredProductsContext, subCategoryAll } from '../products/FilteredProductsProvider';
import { CartItemsContext } from '../checkout/CartItemsProvider';
import { navigateTop, getScrollPixels } from '../routing/router-utils';
import Spinner from '../common/Spinner';
import Header, {HeaderSize} from '../styleguide/Header';
import ServiceMessages from '../dispensary/ServiceMessages';
import ProductFilterCarousel from '../dispensary/ProductFilterCarousel';
import ProductFilters from '../productFilters/ProductFilters';
import NoMatchingProducts from '../productFilters/NoMatchingProducts';
import { ModalContext, ModalType } from '../modal/ModalProvider';
import AddedToCartCheck from '../products/AddedToCartCheck';
import BrandHeader from './BrandHeader';
import PartnerHeader from './PartnerHeader';
import ProductListing from '../dispensary/ProductListing';
import FilterIcon from '../../assets/filterIcon2.png';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import PageTitle from '../common/PageTitle';
import { PropTypes } from 'prop-types';

import styles from '../dispensary/Dispensary.module.css';
import brandStyles from './BrandProducts.module.css';

// NOTE: BrandProductsPage is the full view component
const BrandProducts = ({
  brand:pageBrand,
  pathName="brand", 
  category:pageCategory, 
  subCategory:pageSubCategory=subCategoryAll.display_name,
  backLink
}) => {

  const { trackEvent } = useAnalyticsStore();
  const { brandProductCategories, brands }  = React.useContext(BrandProductsContext);
  const { filteredProducts, categoryName, setCategory, subCategory, subCategories, setFilters, loading } = React.useContext(FilteredProductsContext);
  const { data:partnerData } = useBrandPartnerData(pageBrand);
  const [categoryHeader, setCategoryHeader] = React.useState('');
  
  // mobile filter modal
  const { displayModal } = React.useContext(ModalContext);

  // Handle category on initial render
  React.useEffect(() => {
    if (pageCategory && pageCategory!== categoryName) {
      setCategory(pageCategory);
    }

    if (subCategories.length && pageCategory === categoryName) {
      if(pageSubCategory && pageSubCategory !== subCategory.display_name) {
        const subCat = subCategories.find(sub => sub.display_name === pageSubCategory); 
        if (subCat) {
          setFilters({ 
            subCategory: subCat
          });
          devLog(`SETTING PRODUCT SUB_CATEGORY ${subCat.display_name}`);
        }
      // Reset sub-category to all when url param is removed  
      } else if (!pageSubCategory && subCategory.name !== subCategoryAll.name) {
        setFilters({ 
          subCategory: subCategoryAll
        });
        devLog(`SETTING PRODUCT SUB_CATEGORY ${subCategoryAll.display_name}`);
      }
    }
  }, [pageCategory, categoryName, setCategory, pageSubCategory, subCategory, subCategories, setFilters]);

  // Show checkmark mini-modal on add-to-cart
  const [showAddedCheck, setShowAddedCheck] = React.useState();
  const { addItem } = React.useContext(CartItemsContext);

  // Navigate on category change
  const updateProductCategory = React.useCallback((category) => {
    trackEvent(`${trackingString(pageBrand)}_category_${category.name}_click`);
    const scrollYTo = getScrollPixels(scrollToTarget)
    navigateTop(`/${pathName}/${pageBrand}/${category.display_name}`, { scrollYTo });
  }, [trackEvent, pathName, pageBrand]);

  // Navigate on sub-category change
  const updateProductSubCategory = React.useCallback((subCategory) => {
    trackEvent(`products_sub_category_${subCategory.name}_click`);
    if (subCategory.name === subCategoryAll.name) {
      navigateTop(`/${pathName}/${pageBrand}/${categoryName}`);
    } else {
      navigateTop(`/${pathName}/${pageBrand}/${categoryName}/${subCategory.display_name}`);
    }
  }, [trackEvent, categoryName, pathName, pageBrand]);

  const handleAddToCart = (id, item, quantity) => {
    setShowAddedCheck(true);
    addItem(id, item, quantity);
  }

  const scrollToTarget = React.useRef();

  // Handle custom partner tweaks like suppression of back-to-zyprun link 
  const [partnerClass, setPartnerClass] = React.useState(brandStyles.default);
  React.useEffect(() => {
    if ((!document.referrer || 
         document.referrer.indexOf('zyprun') === -1) &&
         partnerData.suppress_home_link) {
      setPartnerClass(brandStyles.suppressHomeLink); 
    }
  }, [partnerData]);

  React.useEffect(() => {
    if (pageBrand && pathName && categoryName) {
      const hdrBrand = pathName === 'partner'
        ? 'Related'  // Related Flower
        : pageBrand; // Mission Flower
      setCategoryHeader(`${hdrBrand} ${categoryName}`);
    }
  }, [pageBrand, pathName, categoryName])

  // Redirect to home if no partner products are found!
  // Needed on the /brand pages, causes lost url category on /partner pages
  React.useEffect(() => {
    if (categoryName &&
        loading === false &&
        pathName === 'brand' &&
        !filteredProducts) {
      if (brandProductCategories?.length) {
        const fallbackCategory = brandProductCategories[0].display_name;
        navigateTop(`/${pathName}/${pageBrand}/${fallbackCategory}`);
      } else {
        trackEvent(`partner_redirect_${pageBrand}`);
        navigateTop('/');  
      }
    }
  }, [trackEvent, categoryName, pathName, pageBrand, 
      loading, filteredProducts, brandProductCategories]);

  return ( 
    <BodyWrapper LayoutType={LayoutTypes.FullBleed}>
      { categoryName 
        ? <div className={partnerClass}>
            <PageTitle title={categoryHeader} />
            <ServiceMessages />
            { pathName === 'brand'
              ? <BrandHeader brand={pageBrand} />
              : <PartnerHeader brands={brands} /> 
            }      
            <ProductFilterCarousel
              currentCategory={categoryName}
              enabledCategories={brandProductCategories}
              handleClick={updateProductCategory} 
              backLink={backLink} />
            
            <div data-is-scroll-target="true" ref={scrollToTarget} style={{height:0}} ></div>
            <div className={styles.productGridWithFilters}>
              <div className={styles.headerWithFilters}>
                <Header size={HeaderSize.Large} text={categoryHeader} />
                <div className={styles.hdrFilterWrap} onClick={() => {
                  displayModal(ModalType.PRODUCT_FILTER, {
                    onSubCategoryClick: updateProductSubCategory,
                    trackContext: 'brand_prod_filter'
                  })
                }}>
                  <img className={styles.filterIcon} alt="filter" src={FilterIcon} />
                  Filter
                </div>
              </div>
              
              <div className={styles.filterCol}>
                <ProductFilters 
                  onSubCategoryClick={updateProductSubCategory}
                  startOpen />
              </div>

              <div className={styles.productGrid}>
                { loading
                  ? <Spinner />
                  : filteredProducts?.length > 0
                    ? <ProductListing
                        filteredProducts={filteredProducts}
                        handleClick={handleAddToCart} />
                    : <NoMatchingProducts 
                        categoryName={categoryName}
                        subCategory={subCategory} />
                }
              </div>
            </div>
          </div>
         : <Spinner />
      }
      
      { showAddedCheck && 
        <AddedToCartCheck closeFn={() => { setShowAddedCheck(false) }} />
      }
    </BodyWrapper>
  )
}

BrandProducts.propTypes = {
  brand: PropTypes.string,
  category: PropTypes.string,
  subCategory: PropTypes.string,
  backLink: PropTypes.string
}

export default BrandProducts;
