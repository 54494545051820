import * as React from 'react';

import { trackEvent, trackingString as trkStr} from '../analytics/tracking';
import Alert from '@material-ui/lab/Alert';
import BasicButton from '../styleguide/BasicButton';
import PropTypes from 'prop-types';

import styles from './Survey.module.css';

/**
 * Allow product requests for unavailble products (ReOrder page, etc.)
 * e.g. "shop_prod_req_flow_island:guava"
 */
const ProductRequestButton = ({
  product,
}) => {

  const [submitted, setSubmitted] = React.useState();

  const trackProductRequest = React.useCallback(() => {
    if (product.display_info) {
      const {
        name,
        brand,
        category
      } = product.display_info;
      
      const productData = [
        `${trkStr(category.name.substring(0,4))}_`,
        `${trkStr(brand.substring(0,12))}:`,
        trkStr(name.substring(0,16))
      ];
      setSubmitted(true);
      trackEvent(`prod_req_${productData.join('')}`);
    }
  },[product]);

  return ( 
    submitted 
      ? <Alert severity="success" className={styles.btnSuccessAlert}>
          Thanks for your product request, our buyers will keep this in mind! 
        </Alert>
      : <BasicButton
          withClass={styles.btnSubmitButton}
          text='Request Product Restocking'
          handleClick={trackProductRequest} />
  )
}

ProductRequestButton.propTypes = {
  product: PropTypes.object,
}

export default ProductRequestButton;
