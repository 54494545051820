import * as React from 'react';

import DisplayModal from '../common/DisplayModal';
import FixedFooterNav from './FixedFooterNav';
import ZypRunPrivacyPolicy from '../../docs/ZypRunPrivacyPolicy';
import ZypRunTermsOfService from '../../docs/ZypRunTermsOfService';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import zypRunLogo from '../../assets/zr_bubble_logo.png';

import styles from './PageFooter.module.css';

const PageFooter = () => {

  const[showTerms, setShowTerms] = React.useState();
  const[showPrivacy, setShowPrivacy] = React.useState();
  
  return (
    <> 
      <div id="footer" className={styles.pageFooter}>
        { showTerms &&
          <DisplayModal title="Terms of Service" closeFn={() => setShowTerms(false)}>
            <ZypRunTermsOfService />
          </DisplayModal>
        }
   
        { showPrivacy &&
          <DisplayModal title="Zyp Run Privacy Policy" closeFn={() => setShowPrivacy(false)}>
            <ZypRunPrivacyPolicy />
          </DisplayModal>
        }
  
        <img alt="" className={`${styles.zrLogo} ${styles.logoWhite}`} src={zypRunLogo} />
        
        <span className={styles.contentLinks}>
          <a className={styles.contentLink} href="tel:+16174050429">(617) 405-0429</a>
          <span className={styles.contentLink} onClick={() => setShowTerms(true)}>Terms of Service</span>
          <span className={styles.contentLink} onClick={() => setShowPrivacy(true)}>Privacy Policy</span>
        </span>

        <span className={styles.copyright}>&copy; {new Date().getFullYear()} Zyp Run</span> 
        <span className={styles.socialIcons}>
          <a className={styles.socialLink} target="_blank" rel="nofollow noopener noreferrer" 
             href="https://www.facebook.com/zyprun">
            <FacebookIcon />
          </a>
          <a className={styles.socialLink} target="_blank" rel="nofollow noopener noreferrer" 
             href="https://www.instagram.com/zyprun">
            <InstagramIcon />
          </a> 
          <a className={styles.socialLink} target="_blank" rel="nofollow noopener noreferrer" 
             href="https://www.twitter.com/zypruncannabis">
            <TwitterIcon />
          </a>
        </span>
      </div>
      <FixedFooterNav />
    </> 
  );
};

export default PageFooter;
