import React from 'react';

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import styles from './Button.module.css';

const useStyles = makeStyles({
  themeButton: {
    color: '#fff',
    backgroundColor: 'var(--zrPrimaryDark)',
    '&:hover': {
      backgroundColor: 'var(--zrPrimaryVeryDark)'  
    }
  },
  themeButtonDark: {
    color: '#fff',
    backgroundColor: 'var(--zrPrimaryVeryDark)',
    '&:hover': {
      backgroundColor: '#000',  
    }
  }
});

const Button = ({
  text, 
  disabled, 
  isCentered, 
  isDark, 
  withStyle={},
  withClass='',
  handleClick, 
  children}) => {

  const classes = useStyles();
  const colorClass = isDark ? classes.themeButtonDark : classes.themeButton;

  const centerStyle = isCentered ? { display:'block', marginLeft:'auto', marginRight: 'auto' } : {};
  const buttonStyle = Object.assign(centerStyle, withStyle);

  const disabledClass = disabled ? styles.disabledButton : '';
  const buttonClasses = `${styles.button} ${colorClass} ${withClass} ${disabledClass}`;

  /* Ignore click function if disabled */
  const buttonClick = (event) => { 
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (!disabled) {       
      handleClick(event);
    }
  };

  return (
    <button
      disabled={disabled}
      className={buttonClasses}
      style={buttonStyle}
      onClick={event => buttonClick(event)}>
      {children}  
      {text} 
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isCentered: PropTypes.bool,
  isDark: PropTypes.bool,
  withStyle: PropTypes.object,
  withClass: PropTypes.string,
  handleClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array])
}

export default Button;
