import React from "react";
import config from "./config";
import { Buffer } from 'buffer';
import { isTestSession } from "./components/util/storage-utils";
import { createStore } from './components/store/store';
import { trackEvent as track } from "./components/analytics/tracking";
import UserProvider from "./components/providers/UserProvider";
import Application from "./components/Application";

// Needed by base64url encode/decode
window.Buffer = Buffer;

/**
 * This store is used for tracking + analytics data.
 * This allows having a debug widget without triggering rerenders every time something is logged
 * (only the debug widget is rerendered) 
 */
export const useAnalyticsStore = createStore((get, set) => {
  return {
    sessionStartMS: new Date().getTime(),
    recentEvents: [],
    lastError: '',
    trackEvent: (actionLabel) => {
      track(actionLabel);
      return set(store => ({
        ...store,
        recentEvents: [actionLabel, ...store.recentEvents].slice(0,8)
      }));
    },
    trackByDevice: (actionLabel) => {
      track(actionLabel);
      // Log additional dt or mb event
      track(`${actionLabel}_${window.screen.width > 480 ? 'dt' : 'mb'}`);
      return set(store => ({
        ...store,
        recentEvents: [actionLabel, ...store.recentEvents].slice(0,8)
      }));
    },
    isTestGroup: isTestSession(),
    trackTestEvent: (actionLabel) => { 
      // Log when tests are running, determine test/control
      if (!config.ENABLE_TESTS) {
        return;
      } else {
        const testSuffix = isTestSession() ? '_test' : '_ctrl';
        track(`${actionLabel}${testSuffix}`);
        return set(store => ({
          ...store,
          recentEvents: [actionLabel, ...store.recentEvents].slice(0,8)
        }));
      }
    },
    trackError: (lastError) => {
      track(lastError);
      return set(store => ({ 
        ...store, 
        lastError
      }));
    },
  }
});

const App = () => {

  const firebase_SDK_url = "https://www.gstatic.com/firebasejs/7.15.1/firebase-app.js";
  const scr = document.createElement('script');
  scr.src = firebase_SDK_url;
  document.body.appendChild(scr);

  /**
   *  The UserProvider will maintain a session login for the Application 
   */
  return (
    <UserProvider>
      <Application />
    </UserProvider>
  );
}

export default App;
