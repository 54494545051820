import * as React from 'react';

import Header, { HeaderSize} from '../styleguide/Header';
import BasicButton from '../styleguide/BasicButton'; 
import PropTypes from 'prop-types';

// Address update link using Account page for registered users
const AccountLink = ({onNavigate}) => {
  
  const userMsgStyle = {
    width: 435,
    maxWidth: '95vw',
    boxSizing: 'border-box',
    padding: '.25rem 2rem 1rem',
    font: 'var(--zrFontSemi)',
    lineHeight: 1.33
  };
  
  return (
    <>
      <Header isCentered size={HeaderSize.Small} withStyle={{marginBottom:'.5rem'}}>
        Your Address
      </Header>
      <div style={userMsgStyle}>
        As a registered user, you can update your delivery address in your <b>Account&nbsp;Settings</b>.
      </div>
      <div>
        <BasicButton text="Account Settings" 
          isCentered handleClick={onNavigate} />
      </div>  
    </>
  )
}

AccountLink.propTypes = {
  onNavigate: PropTypes.func.isRequired
}

export default AccountLink
