import * as React from 'react';

import GoogleReviewLink from '../common/GoogleReviewLink';
import HowDidYouFindUsSurvey from '../survey/HowDidYouFindUsSurvey';
import PropTypes from 'prop-types';

import styles from './OrderConfirmation.module.css';

/**
 * Currently:
 * 1) First order gets "How did you hear about us?" survey
 * 2) Later orders get the "Leave a Google Review link"
 */
const AdditionalCustomerAsks = ({
  order
}) => {  
  
  const [hideSurvey, setHideSurvey] = React.useState();  

  return ( order?.customer?.has_completed_first_order
    ? <>
        <div className={styles.reviewLink}>
          Thanks for your order! While we're getting your products ready, 
          would you mind taking a minute to leave us a Google Review? 
          {' '}
          <span className={styles.emphasize}>We'd appreciate it!</span>
        </div>
        <GoogleReviewLink
          deliveryLocation={order?.customer?.location}
          trackingLabel='order_confirmation' />
      </>
    : hideSurvey
      ? null
      : <div className={styles.surveyWrap}>
          <HowDidYouFindUsSurvey
            customer={order?.customer}
            onSubmit={() => setHideSurvey(true)} />
        </div>
  )    
}

AdditionalCustomerAsks.propTypes = {
  order: PropTypes.object
}

export default AdditionalCustomerAsks;
