import * as React from 'react';

import { CartItemsContext } from './CartItemsProvider';
import { isSuperDeal } from '../products/useSaleLabelDetails';
import styles from './CartItem.module.css';

import PropTypes from 'prop-types';

/**
 * Covers these mutually exclusive pricing options:
 * 1) Bundle Pricing (if minimum met)
 * 2) Super Deal
 * 3) Quantity limit (e.g. 1)
 */
const SpecialPricingLabel = ({
  product,
  withStyle={},
}) => {

  const { bundlePricingMap } = React.useContext(CartItemsContext);

  const { bundle_deal_data, per_order_max_quantity } = product;

  return (
    bundle_deal_data && bundlePricingMap?.get(product.bundle_deal_data.id)
      ? <span className={styles.specialPricingLbl} style={withStyle}>
          {product.bundle_deal_data.id}
        </span>
      : isSuperDeal(product)
        ? <span className={`${styles.specialPricingLbl} ${styles.superDeal}`} style={withStyle}>
            Super Deal!
          </span>
        : per_order_max_quantity
          ? <span className={`${styles.specialPricingLbl} ${styles.specialQtyLimit}`} style={withStyle}>
              Limit {product.per_order_max_quantity}
            </span>
          : null // No Special Pricing
  )
}

SpecialPricingLabel.propTypes = {
  product: PropTypes.object.isRequired,
  withStyle: PropTypes.object
}

export default  SpecialPricingLabel;
