import * as React from 'react';

import { Checkmark } from 'react-checkmark';
import { ZR_PRIMARY_DARK } from '../../constants/colors';
import PropTypes from 'prop-types';

import styles from './AddedToCart.module.css';

/**
 * The purpose of this component is to display a modal chackmark and provide users with
 * feedback that the item has been added to the cart
 *  
 * @param {func} closeFn - a function that will hide the check mark
 * @param {number} closeMs - override the 1 second close delay
 * @param {string} addlMessage - show a message, e.g. switching dispensaries, etc.
 */
const AddedToCartCheck = ({closeFn, closeMs=1000, addlMessage}) => { 

  React.useEffect(() => {
    window.setTimeout(closeFn, closeMs);
  }, [closeFn, closeMs]);
  
  return ( 
    <div className={styles.addedToCartCheck}>
      <Checkmark size={41} color={ZR_PRIMARY_DARK} />
      <span className={styles.text}>
        Added to cart  
      </span>
      { addlMessage &&
        <div className={styles.addlMessage}>
          <div className={styles.addlMessageHdr}>
            Nice!  
          </div>  
          <div className={styles.addlMessageInner}>{addlMessage}</div>
        </div>
      } 
    </div>
  )
}

AddedToCartCheck.propTypes = {
  closeFn: PropTypes.func.isRequired,
  closeMs: PropTypes.number,
  addlMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array  // handy for concatenating html ['The ',<b>Name</b>]
  ])
}

export default AddedToCartCheck;
