import * as React from 'react';

import PropTypes from 'prop-types';
import { ZR_PRIMARY_DARK } from '../../constants/colors';
import styles from './Spinner.module.css';

const Spinner = ({ 
  inSmallLayout,
  isInline,
  color, 
  size=80, 
  withStyle={} 
}) => {
  
  const spinnerColor = color || ZR_PRIMARY_DARK;

  const circles = [...Array(4)].map((_, index) => {
    return (
      <div
        key={index}
        style={{
          borderColor: `${spinnerColor} transparent transparent transparent`,
          width: size * 0.8,
          height: size * 0.8,
          margin: size * 0.1,
          borderWidth: size * 0.1,
        }}
      ></div>
    )
  })

 const inlineStyle = { display:'inline-block', verticalAlign:'middle', margin:'0 6px' };
  
  const wrapperStyle = inSmallLayout ? styles.spinnerWrapTight : styles.spinnerWrap; 

  return (
    <div className={wrapperStyle} style={isInline ? inlineStyle : {}}>
      <div className={styles.ldsRing} style={{ width: size, height: size, ...withStyle }}>
        {circles}
      </div>
    </div>
  );
}

Spinner.propTypes = {
  /** adjust margin for small layout */
  inSmallLayout: PropTypes.bool,
  isInline: PropTypes.bool,
  /** hex color */
  color: PropTypes.string,
  /** size in pixel */
  size: PropTypes.number,
  /** style object */
  withStyle: PropTypes.object,
}

export default Spinner;
