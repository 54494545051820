import * as React from 'react';

import { FilteredProductsContext, subCategoryAll } from '../products/FilteredProductsProvider';
import ActiveFilterPills from './ActiveFilterPills';
import PropTypes from 'prop-types';

import styles from '../dispensary/Dispensary.module.css'; 

const NoMatchingProducts = ({
  categoryName,
  subCategory
}) => {

  const { resetFilters } = React.useContext(FilteredProductsContext); 

  return (
    <div className={styles.noResults}>
      <div className={styles.sorryMsg}>
        Sorry, there are currently no products that match your criteria!
      </div>
      <ActiveFilterPills />
      <div className={styles.noResultsShowAll} 
           onClick={resetFilters}>
        Show All { subCategory.display_name === subCategoryAll.display_name
                   ? categoryName             /* when filtering all in category */
                   : subCategory.display_name /* when filtering within sub-category */
        }
      </div>
    </div>
  )
}

NoMatchingProducts.propTypes = {
  categoryName: PropTypes.string.isRequired,
  subCategory: PropTypes.object.isRequired
}

export default NoMatchingProducts;
