import * as React from 'react';

import PaymentFormAerosync from '../account/PaymentFormAerosync';
import aeropayButton from '../../assets/purchaseWithAPBlackSm.png';
import PropTypes from 'prop-types';

import styles from './OrderButton.module.css';

/**
 * The branded Aeropay order button or linking callout
 */
const AeropayOrderButton = ({
  linkedAccountId,
  bankName='',
  placeOrderClick
}) => ( linkedAccountId
  ? <div className={styles.apButtonWrap}>
      <img className={styles.apButton} alt="Proceed with Aeropay" src={aeropayButton} onClick={placeOrderClick} />
      <div className={styles.apMessage}>Using your linked account at {bankName}</div>
    </div>
  : <>
      <div className={styles.apButtonWrap}>
        <img className={`${styles.apButton} ${styles.apButtonDisabled}`} alt="Aeropay setup required" src={aeropayButton} />
      </div>
      <div className={styles.apLinkWrap}>
        <PaymentFormAerosync isLinkOnly />
      </div>
    </>
);

AeropayOrderButton.propTypes = {
  linkedAccountId: PropTypes.string,
  bankName: PropTypes.string,
  placeOrderClick: PropTypes.func.isRequired,
};

export default AeropayOrderButton;
