import * as React from 'react';

import { CustomerCreditContext } from './CustomerCreditProvider';
import DisplayCreditDetails from './DisplayCreditDetails';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  highlight: {
    font: 'var(--zrFontSemi)',
    color: 'var(--zrPrimaryDark)',
  },
  tableHdr : {
    marginBottom: '.75rem',
  },
  note: {
    fontSize: 12,
    fontStyle: 'italic',
    marginTop: 8
  }  
});

const DisplayCreditBalance = () => {

  const classes = useStyles();
  const { activeCredits, activeCreditDisplay } = React.useContext(CustomerCreditContext);

  return (
    activeCredits?.length
      ? <>
          <div className={classes.tableHdr}>
            Your current <span className={classes.highlight}>Zyp&nbsp;Run Credit</span> balance
            is&nbsp;<span className={classes.highlight}>{activeCreditDisplay}*</span>
          </div>
          <DisplayCreditDetails activeCredits={activeCredits} />        
          <div className={classes.note}>
            * Credits can be applied to delivery orders at checkout (1 credit per order).
          </div>
        </>   
      : <div>
          Refer your friends and earn <span className={classes.highlight}>Zyp&nbsp;Run Credits!</span>
        </div>
  );
};

export default DisplayCreditBalance;
