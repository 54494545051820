import BasicButton from '../styleguide/BasicButton';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

const VisibilityButton = ({
  withClass='',
  size="24px",
  visible,
  onClick,
}) => {

  return (
    <BasicButton 
      withClass={withClass}
      withStyle={{fontSize:size}}
      handleClick={onClick}
      text={(visible
        ? <VisibilityOffOutlinedIcon fontSize="inherit" /> 
        : <VisibilityOutlinedIcon fontSize="inherit" />
      )} />
  )
}

export default VisibilityButton
