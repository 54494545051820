import { useReducer } from "react";
import { trackEvent } from "../analytics/tracking";

export const SIGNIN_STEPS = {
  ENTER_PHONE: "enter_phone",
  ENTER_CODE: "enter_code",
  COMPLETE_LOGIN: "complete_login",
}

/**
 * Handles the Login via phone/sms flow
 */
const usePhoneSignInReducer = () => {
  
  const initialState = {
    phone_number: '',
    custom_token: '',
    success_message: '',  // Login success
    info_message: '',     // Sending code
    error_message: '',    // Invalid number
    continue_path: '',    // Path after Login
    return_to_path: '',   // Back to regular Login, etc.
    prior_navigation_state: undefined, // Referring page navigation state:continuePath etc.
    current_step: SIGNIN_STEPS.ENTER_PHONE,
  }
  
  const phoneSignInReducer = (state, action) => {
    switch(action.type) {
      case 'savePaths':
        return {
          ...state,
          continue_path: action.continue_path,
          return_to_path: action.return_to_path,
          prior_navigation_state: action.prior_navigation_state, 
        }
      case 'phoneNumberSuccess':
        trackEvent('phone_signin_number');
        return {
          ...state,
          error_message: '',
          info_message: `Delivering code ${action.qa_only_code ? action.qa_only_code :''} to ${action.phone_number}`,
          phone_number: action.phone_number,
          current_step: SIGNIN_STEPS.ENTER_CODE,
        }
      case 'smsCodeSuccess':
        trackEvent('phone_signin_code');
        return {
          ...state,
          error_message: '',
          info_message: '',
          custom_token: action.custom_token,
          success_message: 'Phone verification successful!',
          current_step: SIGNIN_STEPS.COMPLETE_LOGIN,
        }
      case 'sendCodeAgain':
        trackEvent('phone_signin_retry');
        return {
          ...state,
          info_message: '',
          error_message: '',
          current_step: SIGNIN_STEPS.ENTER_PHONE,  
        }
      case 'error':
        trackEvent('phone_signin_error');
        return {
          ...state,
          info_message: '',
          error_message: action.error_message,
        }
      case 'clearMessages':
        return {
          ...state,
          info_message: '',
          error_message: '',
        }      
      default:
        return state;
    }
  }

  // ensure state is defined!
  const [phoneSignInState={}, dispatch] = useReducer(phoneSignInReducer, initialState);
  
  return [phoneSignInState, dispatch];
}

export default usePhoneSignInReducer;
