import * as React from 'react';

import config from '../../config';
import { trackEvent } from '../analytics/tracking';
import { AttributionContext } from '../providers/AttributionProvider';
import { UserContext } from "../providers/UserProvider";
import { partnersEndpoint } from '../../constants/api-endpoints';
import useFetch from '../util/useFetch';
import { devLog } from '../util/util';

/**
 * This info is in the db for PROD and here for reference.
 *
 * We currently attribute partner traffic based on the page the user
 * lands on, not the domain they came from.
 *
 * NOTE: We currently show a co-branded "Are You 21?" modal based on
 * partner attribution
 *
 * const PARTNER_DOMAINS = {
 *   AYR: 'ayrmassachusetts.com',
 *   BERKSHIRE_ROOTS: 'berkshireroots.com',
 *   CRESCO: 'crescolabs.com',
 *   GARDEN_REMEDIES: 'gardenremedies.com',
 *   GOOD_FEELS: 'getgoodfeels.com',
 *   HAPPY_VALLEY: 'happyvalley.org',
 *   HARBOR_HOUSE: 'harborhousecollective.com',
 *   MISSION: 'missiondispensaries.com',
 *   HARPOON_BREWERY: 'harpoonbrewery.com'
 * }
 */

export const PartnerContext = React.createContext({});

/**
 * Provider supplying partner/vendor data
 * the vendor_id is used to fetch partner products
 *
 * Sample Data:
 * brand_carousel_categories : (3) ['flower', 'pre-rolls', 'concentrates']
 * default_category : "flower"
 * display_name : "berkshire roots"
 * hero_image : "https://any-image-url.jpg"
 * host_names : ['berkshireroots.com']
 * id : "b6a22749-7982-49aa-9860-f4e16a56a41d"
 * is_active : true
 * logo_image : "https://any-image-url.jpg"
 * menu_config_json_string : "{json_string: 123}"
 * name : "berkshire roots"
 * overview : "Overview of this partner..."
 * tagline : "A tagline"
 * vendor_id : 41040
 */

const PartnerProvider = ({children}) => {

  const { referralDomain } = React.useContext(AttributionContext);
  const { user } = React.useContext(UserContext);
  const { data:partnerData, loading } = useFetch(user, partnersEndpoint, true);

  // All partner data, mapped.
  const [vendorIdByName, setVendorIdByName] = React.useState();
  const [vendorById, setVendorById] = React.useState();

  React.useEffect(() => {
    if (partnerData?.length > 0) {
      const nameMap = new Map();
      const idMap = new Map();
      partnerData.forEach(partner => {
        const name = partner.name.toLowerCase().replaceAll(/\W+/g, '');
        nameMap.set(name, partner.vendor_id);
        idMap.set(partner.vendor_id, partner);
      })
      setVendorIdByName(nameMap);
      setVendorById(idMap);
    } else if (partnerData?.length === 0) {
      setVendorIdByName(new Map());
      setVendorById(new Map());
    }
  },[partnerData]);

  // Attribution data to be submitted with registration, orders
  const [partnerReferralDomain, setPartnerReferralDomain] = React.useState();
  const [partnerReferralVendorId, setPartnerReferralVendorId] = React.useState();

  /**
   * 3/10/2023 - We're going to skip the document.referrer domain for partners and
   * simply use the /brand/{partner} path for partner attribution.
   * "If they started on the /brand/mission page, they're "mission" traffic.
   * A portion could also be SEO partner-page traffic
   */
  React.useEffect(() => {
    // If there's no referrer check to see if we session is starting on a partner/brand page
    if (partnerData?.length &&
        /brand\/\w+/.test(window.location.pathname)) {

      const partnerMatches = decodeURIComponent(window.location.pathname).match(/brand\/(\w+[\s+,\w+]*)/);
      const partnerName = partnerMatches?.length > 1 ? partnerMatches[1] : '';
      const partnerSafeName = partnerName.toLowerCase().replaceAll(' ', '');

      const referralPartner = partnerData.find(partner => {
        const apiPartnerName = partner.name.toLowerCase(); // Newer matchimg scheme
        return apiPartnerName === partnerSafeName ||
               partner.host_names
                      .join('')
                      .toLowerCase()
                      .indexOf(partnerSafeName) > -1 || undefined;
      });
      // If we have a partner match from location.pathname to API partner.name
      if (referralPartner) {
        setPartnerReferralDomain(partnerSafeName);
        trackEvent(`session_start_${partnerSafeName}`);
        // String value required or registration will fail!
        setPartnerReferralVendorId(referralPartner.vendor_id.toString());
        devLog(`Found partner match: ${partnerSafeName}:${referralPartner.vendor_id}`);
      }
    } else if (!loading && config.IS_DEV && /brand\/mission/.test(window.location.pathname)) {
      // For testing only
      setPartnerReferralDomain('mission');
      trackEvent('session_start_mission');
      setPartnerReferralVendorId("12345");
    }
  }, [referralDomain, partnerData, loading]);

  return (
    <PartnerContext.Provider value={{
      loading,
      vendorIdByName,
      vendorById,
      partnerReferralDomain,   // value if this was a partner referral
      partnerReferralVendorId  // value if this was a partner referral
    }}>
      {children}
    </PartnerContext.Provider>
  );
};

export default PartnerProvider;
