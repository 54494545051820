import React from "react"

import { devLog } from "../util/util";
import SuccessMessage from '../styleguide/SuccessMessage';
import Spinner from '../common/Spinner';
import BasicButton from "../styleguide/BasicButton";
import Alert from "@material-ui/lab/Alert";
import { Grid } from "@material-ui/core";
import { postNotifyMeData } from "./notify-me-util";
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';
import PropTypes from 'prop-types';

import styles from './NotifyMeForm.module.css';

const NotifyMeEmailInput = ({
  location,
  successMessage="All set! We'll let you know when delivery is available your area.",
  onSubscribe=()=>{},
}) => {

  const [email, setEmail] = React.useState();
  const [isSuccess, setIsSuccess] = React.useState();
  const [errorMsg, setErrorMsg] = React.useState();
  const [submitting, setSubmitting] = React.useState();

  const handleSubmit = React.useCallback(() => {
    
    const formData = {
      email,
      city: location.city,
      state: location.state,
      zip: location.zip_code,
    };

    devLog(JSON.stringify(formData)); 

    const controller = new AbortController();   

    const regex = /^([\w_.\-+])+@([\w-]+\.)+([\w]{2,10})+$/;

    const postData = (email, location) => {
      setSubmitting(true);
      setErrorMsg(false);
      postNotifyMeData(
        email, 
        location, 
        ()=>{
          setSubmitting(false);
          setIsSuccess(true);
          onSubscribe();
        }, 
        ()=>{
          setSubmitting(false);
          setErrorMsg('Error submitting request...');
        }, 
        controller);
    }

    // validate
    regex.test(email) 
      ? postData(email,location)
      : setErrorMsg('Please provide a valid email address...');

  }, [email,location, onSubscribe]);

  return (
    <>
      <div className={styles.promptWrap}>
        { isSuccess
          ? <SuccessMessage 
                text={successMessage} 
                withStyle={{padding:'.5rem 0', backgroundColor:'transparent'}} />
          : <Grid container alignContent="center">
              <Grid item xs={12} sm={9}>
                <div className={styles.emailWrap}>
                  <EmailRoundedIcon />
                  <input name="EMAIL" type="email" placeholder="Email address"
                         onChange={(event) => setEmail(event.target.value)} />
                </div>     
              </Grid>
              <Grid item xs={12} sm={3}>
                <BasicButton
                  withClass={styles.notifyButton} 
                  handleClick={handleSubmit}
                  disabled={submitting}
                  text="Notify Me!" />        
              </Grid>
            </Grid>
        }
      </div>
      <div className={styles.responseMsg}>
        { submitting &&  
          <div className={styles.spinWrap}>
            <Spinner size={36} inSmallLayout />
          </div>
        }
        { errorMsg && 
          <Alert severity="error">{errorMsg}</Alert>
        }  
      </div>
    </>
  );
}

NotifyMeEmailInput.propTypes = {
  location: PropTypes.shape({
    city: PropTypes.string,
    state: PropTypes.string,
    zip_code: PropTypes.string,
  }),
  successMessage: PropTypes.string,
  onSubscribe: PropTypes.func.isRequired,
};

export default NotifyMeEmailInput;
