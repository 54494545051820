import * as React from 'react';

import { UserContext } from "../providers/UserProvider";
import { NotificationsContext } from '../notifications/NotificationsProvider';
import { useAnalyticsStore } from '../../App';
import { STACKING } from '../layout/stackingConstants';
import { navigateTop, addRouteToBody } from '../routing/router-utils';
import AccountIcon from '../icons/AccountIcon';
import CartToggle, { CartIconType } from '../checkout/CartToggle';
import ChatIcon from '../icons/ChatIcon';
import OrdersIcon from '../icons/OrdersIcon';
import NewHomeIcon from '../icons/NewHomeIcon';
// import MoneyBagIcon from '../icons/MoneyBagIcon';
import OrderProgressPill from './OrderProgressPill';
import PropTypes from 'prop-types';

import styles from './FixedFooterNav.module.css';

const FixedFooterNav = () => {

  const { user } = React.useContext(UserContext);
  const { activeOrder, activeOrderPillStatus, activeOrderEtaLabel, activeCreditCount } = React.useContext(NotificationsContext);

  const { trackEvent } = useAnalyticsStore();

  // force cart closed when another icon is clicked
  const [closeCart, setCloseCart] = React.useState();

  // click on non-cart icon closes open cart
  const handleOpenCart = (event) => {
    const isBagClick = !!event.target.closest('#ftrBagIcon');
    if (!isBagClick) {
      setCloseCart(true);   
    } 
  } 

  /* MUI Account Avatar
  const avatarIconStyle = {
    color: 'var(--zrPrimaryVeryDark)',
    backgroundColor: 'white',
    width: 30,
    height: 30,
    lineHeight: '30px',
    verticalAlign: '-8px',
    textAlign: 'center'
  }
  */

  // Handle initial footer load
  React.useEffect(() => {
    addRouteToBody();
  }, []);  

  const homeClick = () => {
    trackEvent('footer_home_click');
    navigateTop(`/`);
  };

  /*
  const referClick = () => {
    trackEvent('footer_refer_click');
    if (user && !user.isAnonymous) {
      navigateTop(`/refer`, {
        state: { returnToPath: `${window.location.pathname}` }
      });
    } else {
      navigateTop(`/signIn`,{
        state: { returnToPath: `${window.location.pathname}`,
                 continuePath: `/refer` }
      });
    }
  };
  */

  const accountClick = () => {
    trackEvent('footer_account_click');
    if (user && !user.isAnonymous) {
      navigateTop(`/account`, {
        state: { returnToPath: `${window.location.pathname}` }
      });
    } else {
      navigateTop(`/signIn`,{
        state: { returnToPath: `${window.location.pathname}`,
                 continuePath: `/account` }
      });
    }
  };

  const ordersClick = () => {
    trackEvent('footer_orders_click');
    if (user && !user.isAnonymous) {
      navigateTop(`/orders`);
    } else {
      navigateTop(`/signIn`,{
        state: { returnToPath: `${window.location.pathname}`,
                 continuePath: `/orders` }
      });
    }
  };

  const bagClick = () => {
    trackEvent('footer_cart_click');
    // Undo any forced cart closing
    if (closeCart) {
      setCloseCart(false);
    }
  };

  const chatClick = () => {
    trackEvent('footer_chat_click');
  };

  return (
    /* id was used to hide in reg flow */
    <div id="footerNav"
         className={styles.fixedFooterNav} 
         style={{zIndex:STACKING.HEADER_AND_FOOTER}} 
         onClick={handleOpenCart}>
      
      <div className={`${styles.navItem} ${styles.homeIcon}`} onClick={homeClick}>
        <NewHomeIcon size="25px" />
        <div className={styles.navLabel}>Home</div>
      </div>

      <div className={`${styles.navItem} ${styles.avatarIcon}`} onClick={accountClick}>
        <AccountIcon size="25px" />
        <div className={styles.navLabel}>Account</div>
        { activeCreditCount > 0 &&
          <div className={styles.creditCount}>{activeCreditCount}</div>
        }
      </div>

      {/*
        <div className={`${styles.navItem} ${styles.referIcon}`} onClick={referClick}>
          <MoneyBagIcon size="28px" />
          <div className={styles.navLabel}>Refer</div>
        </div>
      */}

      <div id="ftrBagIcon" className={`${styles.navItem} ${styles.bagIcon}`} onClick={bagClick}>
        <CartToggle
          iconType={CartIconType.FOOTER}
          forceClose={closeCart} />
      </div>
      
      <div id="zr_orders_link" className={`${styles.navItem} ${styles.ordersIcon}`} onClick={ordersClick}> 
        <OrderProgressPill 
          order={activeOrder} 
          orderStatus={activeOrderPillStatus}
          orderEtaLabel={activeOrderEtaLabel} />
        <OrdersIcon size="22px" />
        <div className={styles.navLabel}>Orders</div>
      </div> 

      {/* chat link id set in index.html */}
      <div id="zr_chat_link" className={styles.navItem} onClick={chatClick}> 
        <ChatIcon size="28px" />
        <div className={styles.navLabel}>Chat</div>
      </div>
    </div>
  );
};

FixedFooterNav.propTypes = {
  notificationIds: PropTypes.array,
  viewedIds: PropTypes.array
}

export default FixedFooterNav;
