import * as React from "react";

import { updateUserInfo } from '../registration/registration-utils';
import useEditNameReducer from "./useEditNameReducer";
import { validateSignUpEntry } from "../registration/validation-utils";
import { activeController } from "../util/util";
import { TextField } from "@material-ui/core";
import BasicButton from "../styleguide/BasicButton";
import Spinner from "../common/Spinner";
import SuccessMessage from "../styleguide/SuccessMessage";
import ErrorMessage from "../styleguide/ErrorMessage";
import styles from './YourAccount.module.css';

import PropTypes from 'prop-types';

const successStyle = {
  margin: '6px 0 0',
  padding: '8px 0', 
  backgroundColor:'transparent',
}

const errorStyle = {
  whiteSpace: 'normal',
  textAlign: 'left',
  margin: '6px 0 0',
  padding: '8px 0', 
  backgroundColor:'transparent',
}

/**
 * Update user name (Account Page)
 */
const EditableFullName = ({
  fullName,
  refetch,
}) => {

  const [editState, dispatch] = useEditNameReducer(fullName); 

  // controlled name input
  const onChange = React.useCallback((event) => {
    const { value:name } = event.currentTarget;  
    dispatch({ type:'onInputChange', name });
  }, [dispatch]) 
  
  const onCancel = React.useCallback(() => {
    dispatch({ type:'showForm', showForm:false });
  }, [dispatch])

  const controllerRef = React.useRef(new AbortController());

  const onSubmit = React.useCallback(() => {
    // validate name: at least 'a bc' same as reg flow
    if (!validateSignUpEntry('name', editState.name)) {
      dispatch({ 
        type:'updateError', 
        error:'Please enter a valid first and last name'
      });
      return;  
    }

    dispatch({ type:'updating', updating:true });

    const controller = activeController(controllerRef);
    // submit name only
    const updateInfo = { name:editState.name };

    // Make API call
    updateUserInfo(
      updateInfo, 
      (responseOk, response) => {
        if (responseOk) {
          dispatch({ type:'updateSuccess' });
          refetch();
        } else {
          dispatch({ 
            type:'updateError',
            error: response.error,
          });
        }
      },
      controller
    );
  }, [editState, dispatch, refetch]);
  
  const toggleForm = React.useCallback(() => {
    dispatch({ type:'showForm', showForm:!editState.showForm });
  }, [editState, dispatch])

  return ( 
    <>
      { editState.showForm
        ? editState.updating
          ? <Spinner inSmallLayout size={32} />
          : <div>
              <TextField className={styles.editNameInput} value={editState.name} onChange={onChange} />
              <div className={styles.editNameButtons}>
                <BasicButton withClass={styles.editNameButton} text="Update" 
                  handleClick={onSubmit} />
                <BasicButton withClass={`${styles.editNameButton} ${styles.editNameCancelButton}`} text="Cancel" 
                  handleClick={onCancel} />  
              </div>
            </div> 
        : <div className={styles.editableName}>
            {fullName}
            <span className={styles.editableNameLink} onClick={toggleForm}>
              Update
            </span>
          </div>
      }
      <div className={styles.editNameResponse}>
        { editState.updateSuccess &&   
          <SuccessMessage withStyle={successStyle} text="Updated!" />
        }
        { editState.updateError &&   
          <ErrorMessage withStyle={errorStyle} text={editState.updateError} />
        }
      </div> 
    </>   
  );
}

EditableFullName.propTypes = {
  fullName: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default EditableFullName;
