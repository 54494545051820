import * as React from 'react';
import { activeController } from './util';

// A generic public (no auth token) fetch hook
// For use in ProductCategoryProvider etc.
function usePublicFetch(url, urlSuffix) {
  
  const [data, setData] = React.useState();
  const [loading, setLoading] = React.useState();
  const [error, setError] = React.useState();
  const [status, setStatus] = React.useState();

  const controllerRef = React.useRef(new AbortController());

  const [fetchCount, setFetchCount] = React.useState(0);
  const refetch = React.useCallback(() => {
    setFetchCount(fetchCount + 1);        
  }, [fetchCount]); 

  React.useEffect(() => {
    const controller = activeController(controllerRef);   
    setLoading(true);
    setData(undefined);
    setError(undefined);

    const pathParam = urlSuffix ? `/${urlSuffix}` : '';      
    fetch(`${url}${pathParam}`, 
      { 
        method: 'GET',
        Accept: 'application/json',
        signal: controller.signal
      })
    .then(response => response.json())
    .then(json => {
      setLoading(false);
      const { data, status, error } = json || {};
      setStatus(status);
      if (error) {
        setError(error);
      } else if (data) {
        setData(data);
      } else {
        setData(json);
      }
    })
    .catch(err => {
      setLoading(false)
      setError(err.message ? err.message : "Error: No data available.");
    })
    return () => {
      controller.abort();
    }
  }, [url, urlSuffix, fetchCount])

  return {
    data,
    status, 
    error,
    loading,    
    refetch 
  }
}

export default usePublicFetch;
