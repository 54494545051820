import * as React from 'react';

import { makeStyles } from '@material-ui/core';
import BagIcon from '../checkout/BagIcon';
import PropTypes from 'prop-types';

// styles just for the non-button icon
import styles from '../common/FixedFooterNav.module.css';

const useStyles = makeStyles(theme => ({
  button: {
    cursor: 'pointer',
    width: 250,
    maxWidth: '30vw',
    boxSizing: 'border-box',
    padding: '.25rem',
    borderRadius: '.25rem',
    backgroundColor: 'var(--zrPrimaryDark)',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'var(--zrPrimaryVeryDark)',
    }
  },
  buttonGrid: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: -3
  },
  iconWrap: {
    flex: 0,
    margin: '1px 0 -2px'
  },
  middle: {
    textAlign: 'center'
  },
  buttonValue: {
    font: 'var(--zrFontBold)',
    marginTop: 2
  },
  count: {
    flex: 0,
    height: '24px',
    lineHeight: '22px',
    font: 'var(--zrFontBold)',
    textAlign: 'center',
    minWidth: '24px',
    margin: '4px 3px',
    borderRadius: '50%',
    backgroundColor: '#fff3'
  },
  buttonLabel: {
    fontSize: '.75rem',
    font: 'var(--zrFontSemi)',
    marginTop: '-.125rem',
    textAlign: 'center'
  },   
}));

const FooterCartIcon = ({
  items=[],
  displayTotal
}) => {

  const classes = useStyles();
  const [showButton, setShowButton] = React.useState(); 
  
  React.useEffect(() => {
    if (items.length) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [items]);

  return ( showButton
    ? <div className={classes.button}>
        <div className={classes.buttonGrid}>
          <div className={classes.iconWrap}>
            <BagIcon fillColor='#fff' hoverFillColor='#fff' />
          </div>
          <div className={classes.middle} style={{flex:1}}>
            <div className={classes.buttonValue}>{displayTotal}</div>
          </div>
          <div className={classes.count}>
            {items.length}
          </div>
        </div>
        <div className={classes.buttonLabel}>View Bag</div>
      </div>
      /* Standard non-button bag icon */
    : <div style={{textAlign:'center'}}> 
        <div>
          <BagIcon fillColor='var(--zrPrimaryVeryDark)' />
        </div>
        <div className={styles.navLabel}>Your Bag</div>
      </div> 
  );
};

FooterCartIcon.propTypes = {
  items: PropTypes.array.isRequired,
  displayTotal: PropTypes.string.isRequired
}

export default FooterCartIcon;
