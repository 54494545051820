import * as React from 'react';

import { DispensaryContext } from '../dispensary/DispensaryProvider';
import { CurrentTimeSlotContext } from './CurrentTimeSlotProvider';
import CurrentSlotETAText from './CurrentSlotETAText';
import PrioritySlotETAText from './PrioritySlotETAText';
import { makeStyles } from '@material-ui/core';

// Styles for the Carousel header placement
const useStyles = makeStyles({
  deliveryByWrap: {
    font: 'var(--zrFontSemi)',
    fontSize: '14px',
    color: 'var(--zrPrimaryDark)',
    whiteSpace: 'nowrap',
    marginTop: 4,
  },
  priorityByWrap: {
    marginTop: 0,
  },
  boltIcon: {
    verticalAlign: -5,
    paddingRight: 2,
  },
  clockIcon: {
    fontSize: '17px',
    verticalAlign: -3,
    marginRight: 2,
  },
});

const DisplayCurrentSlotETACompact = () => {

  const classes = useStyles();
  // Determine which ETA messaging to display
  const { priorityETA } = React.useContext(DispensaryContext);

  const {
    slotLabel,
    isBostonDefault,
    isNextDay,
  } = React.useContext(CurrentTimeSlotContext);

  return ( priorityETA
    ? <PrioritySlotETAText
        slotLabel={priorityETA}
        classes={classes} />
    : slotLabel
      ? <CurrentSlotETAText
          slotLabel={slotLabel}
          isBostonDefault={isBostonDefault}
          isNextDay={isNextDay}
          classes={classes} />
      : null
  )
}

export default DisplayCurrentSlotETACompact;
