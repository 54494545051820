import * as React from 'react';
import { activeController } from './util';

// A generic fetch hook similar to useQuery used in other ZR apps
function useFetch(user, url, allowAnonymous, urlSuffix, isPaused) {
  
  const [data, setData] = React.useState();
  const [loading, setLoading] = React.useState();
  const [error, setError] = React.useState();
  const [status, setStatus] = React.useState();

  const controllerRef = React.useRef(new AbortController());

  const requestEnabled = React.useCallback((user) => {
    if (user && allowAnonymous && !isPaused) {
      return true;
    } else {
      return user && !user.isAnonymous && !isPaused;
    }
  }, [allowAnonymous, isPaused]);

  const [fetchCount, setFetchCount] = React.useState(0);
  const refetch = React.useCallback(() => {
    setFetchCount(fetchCount + 1);        
  }, [fetchCount]); 

  React.useEffect(() => {
    const controller = activeController(controllerRef);   
    if (requestEnabled(user)) {
      user.getIdToken().then(idToken => {
        setLoading(true);
        setData(undefined);
        setError(undefined);

        const pathParam = urlSuffix ? `/${urlSuffix}` : '';
        
        fetch(`${url}${pathParam}`, 
          { 
            method: 'GET',
            headers: {
              'Authorization': idToken
            },
            Accept: 'application/json',
            signal: controller.signal
          })
        .then(response => response.json())
        .then(json => {
          setLoading(false);
          const { data, status, error } = json || {};
          setStatus(status);
          if (error) {
            setError(error);
          } else if (data) {
            setData(data);
          } else {
            setData(json);
          }
        })
        .catch(err => {
          setLoading(false)
          setError(err.message ? err.message : "Error: No data available.");
        })
      })
    }
    return () => {
      controller.abort();
    }
  }, [user, url, urlSuffix, fetchCount, requestEnabled])

  return {
    data,
    status, 
    error,
    loading,    
    refetch 
  }
}

export default useFetch;
